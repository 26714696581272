import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Text,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';

export default function FilterDrawer({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Button
        ref={btnRef}
        variant="outline"
        colorScheme="orange"
        size="sm"
        onClick={onOpen}
      >
        <Text fontWeight="thin" fontSize="xs">
          <FaFilter />
        </Text>
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="orange" px="6" pt="4" pb="0">
            Filters
            <Box color="red.400">
              <Text fontSize="sm" as="i">
                Use * for wildcard searches
              </Text>
            </Box>
          </DrawerHeader>

          <DrawerBody px="6" py="2">
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
