import moment from 'helpers/moment';
import { MESSAGE_STATUS } from 'configs/messageStatus';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Badge,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

const getBadgeColor = status => {
  if (status === MESSAGE_STATUS.sent) {
    return 'blue';
  } else if (status === MESSAGE_STATUS.delivered) {
    return 'green';
  } else {
    return 'red';
  }
};

const renderMessages = cell => {
  if (!cell.value || cell.value.length === 0) {
    return (
      <Badge variant="outline" colorScheme="red" role="button" px="2">
        SENT : 0
      </Badge>
    );
  }
  return (
    <>
      <Popover isLazy placement="bottom-start">
        <PopoverTrigger>
          <Badge variant="outline" colorScheme="green" role="button">
            SENT : {cell.value.length}
          </Badge>
        </PopoverTrigger>
        <PopoverContent w="full" bg="blue.700" color="gray.100">
          <PopoverArrow bg="teal.500" />
          <PopoverCloseButton />
          <PopoverBody>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th color="gray.100">Mobile</Th>
                  <Th color="gray.100">Status</Th>
                  <Th color="gray.100">Delivery Time</Th>
                  <Th color="gray.100">Last Checked On</Th>
                </Tr>
              </Thead>
              <Tbody>
                {cell.value.map((data, id) => {
                  return (
                    <Tr key={id}>
                      <Td>{data?.mobile}</Td>
                      <Td>
                        <Badge
                          variant="solid"
                          colorScheme={getBadgeColor(data?.status)}
                        >
                          {data?.status ? data?.status : '-'}
                        </Badge>
                      </Td>
                      <Td>
                        {data?.delivery
                          ? moment(data?.delivery).format(
                              moment.defaultDateTimeFormat
                            )
                          : '-'}
                      </Td>
                      <Td>
                        {data?.last_delivery_status_check
                          ? moment(data?.last_delivery_status_check).format(
                              moment.defaultDateTimeFormat
                            )
                          : '-'}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default renderMessages;
