import API from 'helpers/API';

const login = async formData => {
  const { username, password } = formData;

  try {
    const response = await API.post('/auth/login', {
      username: username,
      password: password,
    });
    return response.data;
  } catch (err) {
    // console.log(err.message);
  }
};

const logout = async () => {
  try {
    const response = await API.post('/auth/logout', {});
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getNewAccessToken = async () => {
  try {
    const response = await API.post('/auth/token');
    if (response && response.data) {
      localStorage.setItem('token', response?.data.data);
    }
  } catch (err) {
    console.log(`Failed to Refresh the access Token`, err);
  }
};

const authService = {
  login,
  logout,
  getNewAccessToken,
};

export default authService;
