import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  code: yup
    .string()
    .required(messages.code.required)
    .trim()
    .min(2)
    .max(20)
    .label('Code'),
  name: yup
    .string()
    .required(messages.name.required)
    .trim()
    .min(2)
    .max(200)
    .label('Name'),
  description: yup.string().trim().max(500).label('Description'),
  catagory: yup.object().required(messages.catagory.required).label('Catagory'),
  unit_in: yup.object().required(messages.unit_in.required).label('Unit (IN)'),
  unit_out: yup
    .object()
    .required(messages.unit_out.required)
    .label('Unit (OUT)'),
  conversion: yup
    .number(messages.conversion.invalid)
    .min(1)
    .positive(messages.conversion.invalid)
    .required(messages.conversion.required)
    .typeError(messages.conversion.invalid)
    .label('Conversion Factor'),
  consuption_price: yup
    .number(messages.consuption_price.invalid)
    .min(0)
    .positive(messages.consuption_price.invalid)
    .typeError(messages.consuption_price.invalid)
    .label('Consumtion Price'),
  sales_price: yup
    .number(messages.sales_price.invalid)
    .positive(messages.sales_price.invalid)
    .min(0)
    .required(messages.sales_price.required)
    .typeError(messages.sales_price.invalid)
    .label('Sales Price'),
  tax_type: yup
    .object()
    .required(messages.tax_type.required)
    .nullable(true)
    .label('Tax Type'),
  hsn: yup.string().trim().nullable(true).label('HSN'),
  sgst: yup.object().nullable(true).label('SGST'),
  cgst: yup.object().nullable(true).label('CGST'),
  igst: yup.object().nullable(true).label('IGST'),
  tradable: yup
    .boolean()
    .required(messages.tradable.required)
    .nullable(true)
    .label('Tradable'),
  consumable: yup
    .boolean()
    .required(messages.consumable.required)
    .nullable(true)
    .label('Consumable'),
  is_active: yup
    .boolean()
    .required(messages.is_active.required)
    .nullable(true)
    .label('Status'),
  is_discontinued: yup
    .boolean()
    .required(messages.is_discontinued.required)
    .nullable(true)
    .label('Discontinued'),
});

export default addSchema;
