import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Button } from '@chakra-ui/react';
import TableActionColumn from 'components/tables/TableActionColumn';
import { FaEye } from 'react-icons/fa';

const hasCustom = ['superadmin', 'storeadmin'];

export default function ActionColumn({ row }) {
  const customButton = (
    <Flex>
      <Button
        type="button"
        size="xs"
        mr="2"
        colorScheme="purple"
        as={Link}
        target="_blank"
        to={{
          pathname: `/documents/${row?.tr_type?.toLowerCase()}/${row._id}`,
          // state: { ...row },
        }}
      >
        <FaEye size="16" className="text-white" />
      </Button>
    </Flex>
  );

  return (
    <TableActionColumn
      row={row}
      hasCustom={hasCustom}
      customComponent={customButton}
    />
  );
}
