import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Heading,
  Button,
  useColorModeValue,
  Center,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { toIndianFormat } from 'helpers/genHelpers';
import dashboardSocietyService from 'services/dashboardSocietyService';

function SocietyAdultrationsTop() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const tblBottomBg = useColorModeValue('red.800', 'red.200');
  const tblBottomColor = useColorModeValue('white', 'black');

  const tblBodyTextColor = useColorModeValue('gray.900', 'gray.100');

  const refreshData = async () => {
    setLoading(true);
    setData([]);
    const resData = await dashboardSocietyService.getTopAdultrations();
    if (resData && resData?.data) {
      setData(resData?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      direction={['column', 'column']}
      justifyContent="space-between"
      alignItems="center"
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      w="full"
      h="full"
      p={4}
    >
      <Flex
        h="50px"
        w={['full', 'full']}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb="4"
      >
        <Heading fontSize="xl" color={useColorModeValue('red.500', 'red.200')}>
          TOP ADULTERATIONS SOCIETIES
        </Heading>

        <Button
          variant="ghost"
          isLoading={loading}
          loadingText="Refreshing..."
          onClick={refreshData}
          outline="none"
          _focus={{ boxShadow: 'none' }}
          _hover={{
            bg: 'transperent',
            scale: '50',
            color: 'teal.500',
            size: 'xs',
          }}
        >
          <RepeatIcon w={8} h={8} color="green.500" />
        </Button>
      </Flex>

      <Box h="full" w={['full', 'full']} minW="full">
        {loading && (
          <Box minH="200px" w="full">
            <Center>
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
              />
            </Center>
          </Box>
        )}

        {!loading && (
          <Table size="sm" varient="striped" colorScheme={tblBottomBg}>
            <Thead>
              <Tr bg={tblBottomBg}>
                <Th
                  isNumeric
                  py="2"
                  fontSize="md"
                  fontWeight="bold"
                  color={tblBottomColor}
                  w="2%"
                >
                  #
                </Th>
                <Th
                  py="2"
                  fontSize="md"
                  fontWeight="bold"
                  textAlign="center"
                  color={tblBottomColor}
                  w="5%"
                >
                  CODE
                </Th>
                <Th
                  py="2"
                  fontSize="md"
                  fontWeight="bold"
                  textAlign="left"
                  color={tblBottomColor}
                  w="auto"
                >
                  NAME
                </Th>
                <Th
                  isNumeric
                  py="2"
                  fontSize="md"
                  fontWeight="bold"
                  color={tblBottomColor}
                  w="10%"
                >
                  AMOUNT
                </Th>
                <Th
                  isNumeric
                  py="2"
                  fontSize="md"
                  fontWeight="bold"
                  color={tblBottomColor}
                  w="10%"
                >
                  QTY
                </Th>
              </Tr>
            </Thead>
            <Tbody color={tblBodyTextColor} fontWeight="bold">
              {data.map((society, i) => (
                <Tr key={i}>
                  <Th isNumeric fontWeight="bold">
                    {i + 1}
                  </Th>
                  <Td textAlign="center">{society.society_code.pad(6)}</Td>
                  <Td textAlign="left">{society.society_name}</Td>
                  <Td isNumeric>{toIndianFormat(society?.amount)}</Td>
                  <Td isNumeric>{toIndianFormat(society?.weight_kg)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Flex>
  );
}

export default SocietyAdultrationsTop;
