import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Flex,
  Box,
  Button,
  Heading,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Center,
  Text,
  Badge,
} from '@chakra-ui/react';

import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

import materialService from 'services/materialService';
import materialCatagoryService from 'services/materialCatagoryService';
import InputFieldSimple from 'components/form/InputFieldSimple';
import SelectFieldSimple from 'components/form/SelectFieldSimple';

const TotalsFontSize = 12;
const TotalsFontColor = 'orange.600';

export default function MaterialSalesCart({
  cart = [],
  setCart,
  error = null,
  tradable,
  consumable,
  clearErrors,
  selectedPlant,
  tr_type,
}) {
  const [catagories, setCatagories] = useState([]);
  const [catagory, setCatagory] = useState(null);
  const [materialsList, setMaterialsList] = useState([]);
  const [material, setMaterial] = useState(null);

  // Cart Items
  const [qty, setQty] = useState(0);
  const [rate, setRate] = useState(0);
  const [discount, setDiscount] = useState(0);

  // Totals
  const [grossTotal, setGrossTotal] = useState(0);
  const [taxableTotal, setTaxableTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [sgstTotal, setSgstTotal] = useState(0);
  const [cgstTotal, setCgstTotal] = useState(0);
  const [igstTotal, setIgstTotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);

  const [cartDetails, setCartDetails] = useState([]);
  const [plantStock, setPlantStock] = useState(null);

  const fetchIdCatagoryRef = useRef(0);
  const fetchIdMaterialRef = useRef(0);

  const fetchCatagories = async () => {
    const fetchIdCatagory = ++fetchIdCatagoryRef.current;

    if (fetchIdCatagory === fetchIdCatagoryRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'name';
      query.fields = '_id,name';
      const responseData = await materialCatagoryService.getAll(query);

      if (responseData) {
        setCatagories(responseData?.data);
      }
    }
  };

  const fetchMaterialsList = async () => {
    const fetchIdMaterial = ++fetchIdMaterialRef.current;

    if (fetchIdMaterial === fetchIdMaterialRef.current && catagory) {
      const query = {};
      query.filters = {};
      query.pagination = false;
      query.sortby = 'name';
      // query.fields = '_id,code,catagory,name';
      query.filters.catagory = catagory?.name;
      query.filters.is_active = true;
      query.filters.is_discontinued = false;
      if (tradable && (tradable === true || tradable === false)) {
        query.filters.tradable = tradable;
      }
      if (consumable && (consumable === true || consumable === false)) {
        query.filters.consumable = consumable;
      }
      const responseData = await materialService.getAll(query);

      if (responseData) {
        setMaterialsList(responseData?.data);
      }
    }
  };

  useEffect(() => {
    fetchCatagories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (catagory) {
      fetchMaterialsList();
    }
    // eslint-disable-next-line
  }, [catagory]);

  useEffect(() => {
    if (cart.length === 0) {
      setCartDetails([]);
      resetCartTotals();
    } else {
      clearErrors('materials');
    }
    // eslint-disable-next-line
  }, [cart]);

  useEffect(() => {
    if (selectedPlant && material) {
      setPlantStock(material?.stock[selectedPlant?.plant_code]);
    } else {
      setPlantStock(null);
    }
    // eslint-disable-next-line
  }, [selectedPlant, material]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [material, qty, rate, discount]);

  const resetCartItem = () => {
    setMaterial(null);
    setQty(0);
    setRate(0);
    setDiscount(0);
  };

  const resetCartTotals = () => {
    setGrossTotal(0);
    setTaxableTotal(0);
    setDiscountTotal(0);
    setSgstTotal(0);
    setCgstTotal(0);
    setIgstTotal(0);
    setNetTotal(0);
  };

  const handleCatagoryChange = (selected, action) => {
    handleMaterialChange(null, 'clear');
    resetCartItem();
    if (action === 'clear') {
      setCatagory(null);
      setPlantStock(null);
    }
    setCatagory(selected);
  };

  const handleMaterialChange = (selected, action) => {
    resetCartItem();
    if (action === 'clear') {
      setMaterial(null);
    }
    setMaterial(selected);
  };

  const hasInsufficimport = () => {
    const ALLOWED_INSUFFICIENT_STOCK = ['PURCHASE'];

    if (ALLOWED_INSUFFICIENT_STOCK.includes(tr_type)) {
      return false;
    }

    return !plantStock || plantStock < qty;
  };

  const addToCart = () => {
    if (material && material?.sales_price * qty < discount) {
      toast.warning('Discout can not be greater than total amount');
      return;
    }
    const insufficientstock = hasInsufficimport();
    if (insufficientstock) {
      toast.warning('Insufficient stock for selected plant');
      return;
    }
    const exist = _.find(cart, { _id: material?._id });
    if (!exist) {
      const cartItem = {};
      cartItem.material_id = material?._id;
      cartItem.code = material?.code;
      cartItem.name = material?.name;
      cartItem.catagory = catagory?.name;

      cartItem.qty = parseFloat(qty);
      cartItem.rate = parseFloat(material?.sales_price);
      cartItem.gross = parseFloat(qty * material?.sales_price);
      cartItem.discount = parseFloat(discount);
      cartItem.taxable = parseFloat(
        (cartItem.gross - cartItem.discount).toFixed(2)
      );

      cartItem.sgst_rate = material?.sgst;
      cartItem.cgst_rate = material?.cgst;
      cartItem.igst_rate = material?.igst;

      cartItem.sgst = cartItem.taxable * (cartItem?.sgst_rate / 100);
      cartItem.cgst = cartItem.taxable * (cartItem?.cgst_rate / 100);
      cartItem.igst = cartItem.taxable * (cartItem?.igst_rate / 100);

      cartItem.net = parseFloat(
        (
          cartItem.taxable +
          cartItem.sgst +
          cartItem.cgst +
          cartItem.igst
        ).toFixed(2)
      );
      const newCart = [...cartDetails, cartItem];
      setCartDetails(newCart);
      calculateTotals(cartItem, 'add');
      handleCatagoryChange(null, 'clear');
      const cartSend = [];
      newCart.forEach(c => {
        const newObj = _.pick(c, ['material_id', 'qty', 'discount']);
        cartSend.push(newObj);
      });
      setCart(cartSend);
    } else {
      toast.warning('Material already exist in cart');
    }
  };

  const removeFromCart = material_id => {
    const newCart = [...cartDetails];
    const item = _.find(newCart, { material_id });
    calculateTotals(item, 'remove');
    _.remove(newCart, { material_id: material_id });
    setCartDetails(newCart);
    const cartSend = [];
    newCart.forEach(c => {
      const newObj = _.pick(c, ['material_id', 'qty', 'discount']);
      cartSend.push(newObj);
    });
    setCart(cartSend);
  };

  const calculateTotals = (cartItem, operation) => {
    if (operation === 'add') {
      //  Add To Totals
      setGrossTotal(grossTotal + cartItem.gross);
      setDiscountTotal(discountTotal + cartItem.discount);
      setTaxableTotal(taxableTotal + cartItem.taxable);
      setSgstTotal(sgstTotal + cartItem.sgst);
      setCgstTotal(cgstTotal + cartItem.cgst);
      setIgstTotal(igstTotal + cartItem.igst);
      setNetTotal(parseFloat((netTotal + cartItem.net).toFixed(2)));
    } else if (operation === 'remove') {
      // Remove from Totals
      setGrossTotal(grossTotal - cartItem.gross);
      setDiscountTotal(discountTotal - cartItem.discount);
      setTaxableTotal(taxableTotal - cartItem.taxable);
      setSgstTotal(sgstTotal - cartItem.sgst);
      setCgstTotal(cgstTotal - cartItem.cgst);
      setIgstTotal(igstTotal - cartItem.igst);
      setNetTotal(parseFloat((netTotal - cartItem.net).toFixed(2)));
    }
  };

  return (
    <Flex direction={['column', 'column']} w="full" h="full" p="2">
      <Flex direction={['column', 'row']} w={['full', 'full', 'full']} h="full">
        <Box w={['full', '30%']} mr={[0, 4]} mb={[1, 0]}>
          <SelectFieldSimple
            isMulti={false}
            isClearable={true}
            name="catagory"
            label="Catagory"
            labelKey="name"
            valueKey="name"
            options={catagories}
            placeholder="Select Catagory"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option => option.name}
            getOptionValue={option => option?.name}
            handleChange={handleCatagoryChange}
            defaultValue={catagory}
          />
        </Box>
        <Box w={['full', '30%']} mr={[0, 4]} mb={[1, 0]}>
          <SelectFieldSimple
            isMulti={false}
            isClearable={true}
            name="materials"
            label="Material"
            labelKey="name"
            valueKey="name"
            options={materialsList}
            placeholder="Select Material"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option => `${option.name} (${option.code})`}
            getOptionValue={option => option?._id}
            handleChange={handleMaterialChange}
            defaultValue={material}
            disabled={!catagory}
          />
        </Box>
        <Box w={['full', '10%']} mr={[0, 4]} mb={[1, 0]}>
          <InputFieldSimple
            type="number"
            step={0.1}
            name="qty"
            id="qty"
            label="Qty"
            placeholder="Qty"
            value={qty}
            onChange={e => {
              setQty(e.target.value * 1);
            }}
          />
        </Box>
        <Box w={['full', '10%']} mr={[0, 4]} mb={[1, 0]}>
          <InputFieldSimple
            type="number"
            step={0.1}
            name="discount"
            id="discount"
            label="Discount (₹)"
            placeholder="Discount (₹)"
            value={discount}
            onChange={e => {
              setDiscount(e.target.value * 1);
            }}
          />
        </Box>
        <Box w={['full', '5%']} ml={[0, 4]} mt={[1, 4]}>
          <Button
            w="full"
            mt="4"
            type="button"
            colorScheme="purple"
            size="sm"
            // mr={[0, 4]}
            // mb={[4, 0]}
            px="12"
            disabled={!material || qty <= 0}
            onClick={addToCart}
          >
            ADD
          </Button>
        </Box>
      </Flex>
      {material && (
        <Flex direction={['column', 'row']} w="full" h="full" p="2">
          <Table size="sm">
            <Tbody>
              <Tr>
                <Th colSpan="4">
                  <Heading fontSize="lg" color="orange">
                    MATERIAL DETAILS
                  </Heading>
                </Th>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Stock
                </Th>
                <Td>
                  <Badge variant="solid" colorScheme="purple">
                    {material ? material?.stock_total : 0}
                  </Badge>
                </Td>
                <Th color="teal.500" fontWeight="extrabold">
                  Stock Current Plant
                  {selectedPlant ? ` - ${selectedPlant?.plant_code}` : '-'}
                </Th>
                <Td>
                  <Badge variant="solid" colorScheme="green">
                    {plantStock ? plantStock : 0}
                  </Badge>
                </Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Sales Price
                </Th>
                <Td fontWeight={'bold'}>
                  <Text color="blue">
                    {material ? material?.sales_price : '-'}
                  </Text>
                </Td>
                <Th color="teal.500" fontWeight="extrabold">
                  Consumption Price :
                </Th>
                <Td>{material ? material?.consuption_price : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Unit (IN)
                </Th>
                <Td>{material ? material?.unit_in : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold">
                  Unit (OUT)
                </Th>
                <Td>{material ? material?.unit_out : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Tax Type
                </Th>
                <Td>{material ? material?.tax_type : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold">
                  HSN
                </Th>
                <Td>{material ? material?.hsn : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  SGST
                </Th>
                <Td>{material?.sgst ? material?.sgst : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold">
                  CGST
                </Th>
                <Td>{material?.cgst ? material?.cgst : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  IGST
                </Th>
                <Td>{material?.igst ? material?.igst : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold"></Th>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </Flex>
      )}
      <Flex direction={['column', 'row']} w={['full', 'full', 'full']} h="full">
        <Table size="sm">
          <Tbody>
            <Tr>
              <Th colSpan="13">
                <Flex direction={['column', 'row']}>
                  <Heading
                    fontSize="lg"
                    color={error && error.message ? 'red.500' : 'orange'}
                    mr={[0, 4]}
                    mb={[4, 0]}
                  >
                    CART
                  </Heading>
                  <Text color="red.500" fontWeight="bold">
                    {error && error.message}
                  </Text>
                </Flex>
              </Th>
            </Tr>
            <Tr>
              <Th color="teal.500" fontWeight="extrabold">
                Code
              </Th>
              <Th color="teal.500" fontWeight="extrabold">
                Material
              </Th>
              <Th color="teal.500" fontWeight="extrabold">
                Catagory
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                Qty
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                Rate (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                Gross (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                Discount (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                Taxable (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                SGST (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                CGST (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                IGST (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold" textAlign={'right'}>
                Net (₹)
              </Th>
              <Th color="teal.500" fontWeight="extrabold">
                <Center>Remove</Center>
              </Th>
            </Tr>
            {!cartDetails.length ? (
              <Tr>
                <Td colSpan="13">No Items In Cart</Td>
              </Tr>
            ) : (
              cartDetails.map(item => (
                <Tr key={item.material_id}>
                  <Td>{item?.code}</Td>
                  <Td>{item?.name}</Td>
                  <Td>{item?.catagory}</Td>
                  <Td textAlign={'right'}>{item?.qty}</Td>
                  <Td textAlign={'right'}>{item?.rate}</Td>
                  <Td textAlign={'right'}>{item?.gross}</Td>
                  <Td textAlign={'right'}>{item?.discount}</Td>
                  <Td textAlign={'right'}>{item?.taxable}</Td>
                  <Td textAlign={'right'}>{item?.sgst}</Td>
                  <Td textAlign={'right'}>{item?.cgst}</Td>
                  <Td textAlign={'right'}>{item?.igst}</Td>
                  <Td textAlign={'right'}>{item?.net}</Td>
                  <Td>
                    <Center>
                      <Button
                        size="xs"
                        color="red"
                        type="button"
                        variant="outline"
                        onClick={() => removeFromCart(item.material_id)}
                      >
                        <FaTrash />
                      </Button>
                    </Center>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th colSpan="5">Total Items : {cartDetails.length}</Th>
              <Th colSpan="1" textAlign={'right'}>
                {grossTotal}
              </Th>
              <Th colSpan="1" textAlign={'right'}>
                {discountTotal}
              </Th>
              <Th colSpan="1" textAlign={'right'}>
                {taxableTotal}
              </Th>
              <Th colSpan="1" textAlign={'right'}>
                {sgstTotal}
              </Th>
              <Th colSpan="1" textAlign={'right'}>
                {cgstTotal}
              </Th>
              <Th colSpan="1" textAlign={'right'}>
                {igstTotal}
              </Th>
              <Th colSpan="1" textAlign={'right'}>
                {netTotal}
              </Th>
              <Th colSpan="1" textAlign={'right'}></Th>
            </Tr>

            {/* Totals */}
            <Tr>
              <Th colSpan="9" fontSize={TotalsFontSize}>
                {' '}
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                Gross Total
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                ₹ {grossTotal}
              </Th>
            </Tr>
            <Tr>
              <Th colSpan="9" fontSize={TotalsFontSize}>
                {' '}
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                Discount
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                ₹ {discountTotal}
              </Th>
            </Tr>
            <Tr>
              <Th colSpan="9" fontSize={TotalsFontSize}>
                {' '}
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                Taxable
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                ₹ {taxableTotal}
              </Th>
            </Tr>
            <Tr>
              <Th colSpan="9"> </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                SGST
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                ₹ {sgstTotal}
              </Th>
            </Tr>
            <Tr>
              <Th colSpan="9"> </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                CGST
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                ₹ {cgstTotal}
              </Th>
            </Tr>
            <Tr>
              <Th colSpan="9"> </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                IGST
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={TotalsFontColor}
              >
                ₹ {igstTotal}
              </Th>
            </Tr>
            <Tr>
              <Th colSpan="9"> </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={`purple.600`}
              >
                Net Amount
              </Th>
              <Th
                colSpan="2"
                textAlign={'right'}
                fontSize={TotalsFontSize}
                color={`purple.600`}
              >
                ₹ {netTotal}
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </Flex>
    </Flex>
  );
}
