import React from 'react';
import {
  useColorModeValue,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';

const TextAreaField = ({
  name,
  id,
  placeholder,
  label,
  register,
  error,
  disabled = false,
  readonly = false,
  size = 'sm',
}) => {
  return (
    <FormControl id={id} isInvalid={error} py="2">
      {label && (
        <FormLabel
          htmlFor={name}
          color={disabled ? 'brand.light' : error ? 'red.500' : 'brand.default'}
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        variant="outline"
        focusBorderColor="orange.400"
        bg={useColorModeValue('gray.50', 'gray.700')}
        color={useColorModeValue('gray.800', 'white')}
        _disabled={{ color: useColorModeValue('gray.500', 'gray.400') }}
        autoComplete="off"
        width="full"
        name={name}
        placeholder={placeholder}
        {...register}
        isDisabled={disabled === true ? true : false}
        isReadOnly={readonly === true ? true : false}
        size={size}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

export default TextAreaField;
