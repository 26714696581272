import * as yup from 'yup';
import messages from './messages';

const approveRejectBulkSchema = yup.object().shape({
  approve_note: yup
    .string(messages.approve_note.invalid)
    .required(messages.approve_note.required)
    .trim()
    .label('Approve Note'),
});

export default approveRejectBulkSchema;
