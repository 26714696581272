import * as yup from 'yup';

const filterSchema = yup.object().shape({
  mobile: yup.string().trim().max(10).nullable(true).label('Mobile'),
  sms_service: yup.string().trim().max(10).nullable(true).label('SMS Service'),
  template: yup.string().trim().max(10).nullable(true).label('Template'),
  status: yup.string().trim().max(10).nullable(true).label('Status'),
  createdAt: yup.object().nullable(true).label('Sent On'),
  message: yup.string().trim().max(20).nullable(true).label('Message'),
});

export default filterSchema;
