import * as yup from 'yup';
import messages from './messages';

const updateEmailSchema = yup.object().shape({
  email: yup
    .string()
    .label('Email')
    .required(messages.email.required)
    .email(messages.email.invalid),
});

export default updateEmailSchema;
