import authConstants from 'constants/authConstants';
let token = localStorage.getItem('token');
let user = localStorage.getItem('user');
let loggedIn = token ? true : false;

const initialState = {
  loggedIn: loggedIn,
  user: user ? JSON.parse(user) : {},
};

const authReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case authConstants.SET_AUTH_STATUS:
      return {
        ...state,
        loggedIn: true,
      };
    case authConstants.UNSET_AUTH_STATUS:
      return {
        ...state,
        loggedIn: false,
      };
    case authConstants.SET_USER:
      return {
        ...state,
        user: {
          _id: payload._id,
          username: payload.username,
          role: payload.role,
          is_active: payload.is_active,
          plant: payload.plant,
          firstname: payload.firstname,
          middlename: payload.middlename,
          lastname: payload.lastname,
          societies: payload.societies,
          mobile: payload.mobile,
          employee_code: payload.employee_code,
          mobile_verified: payload.mobile_verified,
          email: payload.email,
          email_verified: payload.email_verified,
          last_password_change: payload.last_password_change,
          profile_picture: payload.profile_picture,
          profile_image_url: payload.profile_image_url,
          force_password_change: payload.force_password_change,
        },
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default authReducer;
