import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderMessageStatus from 'components/tables/helpers/renderMessageStatus';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'mobile',
    accessor: 'mobile',
    Header: 'Mobile',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'message',
    accessor: 'message',
    Header: 'message',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'sms_service',
    accessor: 'sms_service',
    Header: 'SMS Service',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'template',
    accessor: 'template',
    Header: 'Template',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'response_code',
    accessor: 'response_code',
    Header: 'ResCode',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'response_message',
    accessor: 'response_message',
    Header: 'ResMsg',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'job_id',
    accessor: 'job_id',
    Header: 'JobId',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'message_id',
    accessor: 'message_id',
    Header: 'MsgId',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'delivery',
    accessor: 'delivery',
    Header: 'Delivery',
    type: 'datetime',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'status',
    accessor: 'status',
    Header: 'Status',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderMessageStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'last_delivery_status_check',
    accessor: 'last_delivery_status_check',
    Header: 'Last Status Check',
    type: 'datetime',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Sent On',
    type: 'datetime',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
];

export default columns;
