import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Flex,
  Box,
  Center,
  Text,
  VStack,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import InputField from 'components/form/InputField';

import userService from 'services/userService';

import updateUserSchema from './updateUserSchema';

export default function UpdateUser({ data }) {
  const fetchIdRef = useRef(0);
  const apiError = useSelector(state => state.error);

  const allowedFields = [
    'firstname',
    'middlename',
    'lastname',
    'employee_code',
  ];

  const defaultValues = {
    firstname: data?.firstname,
    middlename: data?.middlename,
    lastname: data?.lastname,
    employee_code: data?.employee_code,
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  useEffect(() => {
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (allowedFields.includes(key) && value) {
          setValue(key, value);
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      for (const [key, value] of Object.entries(formData)) {
        if (value === '') {
          delete formData[key];
        }
      }

      const responseData = await userService.update(data._id, formData);

      if (responseData) {
        toast.success(`${responseData.message}`);

        if (responseData.data) {
          for (const [key, value] of Object.entries(responseData.data)) {
            if (allowedFields.includes(key)) {
              setValue(key, value);
            }
          }
        }
      }
    }
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(updateUserSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
  } = useForm(formOptions);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        maxW={'350px'}
        minW={'350px'}
        w={'full'}
        h={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        p={4}
      >
        <Flex
          direction={['column', 'column']}
          justifyContent="space-between"
          h="full"
          w="full"
        >
          <Stack
            textAlign={'center'}
            p={6}
            color={useColorModeValue('gray.800', 'white')}
            align={'center'}
          >
            <Text
              fontSize={'sm'}
              fontWeight={500}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              px={3}
              color={'blue.500'}
              rounded={'full'}
            >
              UPDATE USER
            </Text>
          </Stack>
          <Center>
            <VStack spacing={6} align="center" h="full" w="full">
              <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                <InputField
                  type="text"
                  name="employee_code"
                  id="employee_code"
                  label="Employee Code"
                  placeholder="Employee Code"
                  error={errors.employee_code}
                  register={register('employee_code')}
                />
              </Box>
              <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                <InputField
                  type="text"
                  name="firstname"
                  id="firstname"
                  label="Firstname"
                  placeholder="Firstname"
                  error={errors.firstname}
                  register={register('firstname')}
                />
              </Box>
              <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                <InputField
                  type="text"
                  name="middlename"
                  id="middlename"
                  label="Middlename"
                  placeholder="Middlename"
                  error={errors.middlename}
                  register={register('middlename')}
                />
              </Box>
              <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                <InputField
                  type="text"
                  name="lastname"
                  id="lastname"
                  label="Lastname"
                  placeholder="Lastname"
                  error={errors.lastname}
                  register={register('lastname')}
                />
              </Box>
            </VStack>
          </Center>
          <Box>
            <Button
              type="submit"
              mt={10}
              w={'full'}
              bg={'orange.500'}
              color={'white'}
              rounded={'xl'}
              boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              SAVE
            </Button>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
