import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderStatus from 'components/tables/helpers/renderStatus';

import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'code',
    accessor: 'code',
    Header: 'Code',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Material',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'description',
    accessor: 'description',
    Header: 'Description',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'catagory',
    accessor: 'catagory',
    Header: 'Catagory',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'unit_in',
    accessor: 'unit_in',
    Header: 'Unit IN',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'unit_out',
    accessor: 'unit_out',
    Header: 'Unit Out',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'conversion',
    accessor: 'conversion',
    Header: 'Conversion',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'consuption_price',
    accessor: 'consuption_price',
    Header: 'Consuption Price',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'sales_price',
    accessor: 'sales_price',
    Header: 'Sales Price',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'tax_type',
    accessor: 'tax_type',
    Header: 'Tax Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: cell => <div>{cell.value ? cell.value : 'TAX-FREE'}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'hsn',
    accessor: 'hsn',
    Header: 'HSN',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'sgst',
    accessor: 'sgst',
    Header: 'SGST',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'cgst',
    accessor: 'cgst',
    Header: 'CGST',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'igst',
    accessor: 'igst',
    Header: 'IGST',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'tradable',
    accessor: 'tradable',
    Header: 'Tradable',
    type: 'boolean',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'consumable',
    accessor: 'consumable',
    Header: 'Consumable',
    type: 'boolean',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'is_active',
    accessor: 'is_active',
    Header: 'Status',
    type: 'boolean',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'is_discontinued',
    accessor: 'is_discontinued',
    Header: 'Discontinued',
    type: 'boolean',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columns;
