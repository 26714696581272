import * as yup from 'yup';
import messages from './messages';

const filterSchema = yup.object().shape({
  username: yup.string().label('Username'),
  mobile: yup
    .string()
    .label('Mobile')
    .min(0, messages.mobile.invalid)
    .max(10, messages.mobile.invalid),
  email: yup
    .string()
    .label('E-Mail')
    .min(0, messages.email.invalid)
    .max(50, messages.email.invalid),
});

export default filterSchema;
