const fields = {
  names: ['current_password', 'password', 'password_confirmation'],
  current_password: {
    default: '',
    disabled: false,
    readonly: false,
  },
  password: {
    default: '',
    disabled: false,
    readonly: false,
  },
  password_confirmation: {
    default: '',
    disabled: false,
    readonly: false,
  },
};

export default fields;
