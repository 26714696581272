import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
import { Container, Box, Button, VStack, StackDivider } from '@chakra-ui/react';

// import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import testSchema from './testSchema';

const IndexPage = props => {
  const [data, setData] = useState(null);

  const apiError = useSelector(state => state.error);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(testSchema),
    defaultValues: { demo_date: null },
  };

  const {
    handleSubmit,
    // register,
    formState: {
      // errors,
      isSubmitting,
      //  isDirty,
      //  isValid
    },
    setError,
    // setValue,
    // getValues,
    // reset,
    // control,
    // watch,
  } = useForm(formOptions);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const onSubmit = async formData => {
    console.log(formData);
    setData(formData);
    // const resData = await testService.test({});
    // if (resData) {
    //   setData(resData?.data);
    // }
  };

  // console.log(props);
  return (
    <Container>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
      >
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>Test</Box>
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              disabled={isSubmitting}
            >
              SEND
            </Button>
          </form>
        </Box>
        <Box>
          <pre>
            <code>{JSON.stringify(data, null, 4)} </code>
          </pre>
        </Box>

        {/* Users */}

        <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
          empty
        </Box>
      </VStack>
    </Container>
  );
};

const Test = {
  routeProps: {
    path: '/test',
    component: IndexPage,
    exact: true,
  },
  name: 'Test',
  title: 'Test',
};

export default Test;
