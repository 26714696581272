import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Flex,
  Box,
  Center,
  Text,
  VStack,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import InputField from 'components/form/InputField';

import userService from 'services/userService';

import updatePasswordSchema from './updatePasswordSchema';

export default function UpdatePassword({ data }) {
  const fetchIdRef = useRef(0);
  const apiError = useSelector(state => state.error);

  const defaultValues = {
    password: '',
    password_confirmation: '',
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(updatePasswordSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    reset,
  } = useForm(formOptions);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const responseData = await userService.updatePassword(data._id, formData);

      if (responseData) {
        reset(defaultValues);
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        maxW={'350px'}
        minW={'350px'}
        w={'full'}
        h={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        p={4}
      >
        <Flex
          direction={['column', 'column']}
          justifyContent="space-between"
          h="full"
          w="full"
        >
          <Stack
            textAlign={'center'}
            p={6}
            color={useColorModeValue('gray.800', 'white')}
            align={'center'}
          >
            <Text
              fontSize={'sm'}
              fontWeight={500}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              px={3}
              color={'blue.500'}
              rounded={'full'}
            >
              CHANGE PASSWORD
            </Text>
          </Stack>
          <Center>
            <VStack spacing={6} align="center" h="full" w="full">
              <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                <InputField
                  type="password"
                  name="password"
                  id="password"
                  label="Password"
                  placeholder="Password"
                  error={errors.password}
                  register={register('password')}
                />
              </Box>
              <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                <InputField
                  type="password"
                  name="password_confirmation"
                  id="password_confirmation"
                  label="Confirmation Password"
                  placeholder="Confirmation Password"
                  error={errors.password_confirmation}
                  register={register('password_confirmation')}
                />
              </Box>
            </VStack>
          </Center>
          <Box>
            <Button
              type="submit"
              mt={10}
              w={'full'}
              bg={'orange.500'}
              color={'white'}
              rounded={'xl'}
              boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              CHANGE PASSWORD
            </Button>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
