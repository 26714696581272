import _CONSTATNTS from 'constants/_Constants';

export const resetAll = () => {
  return async dispatch => {};
};

export const showBanner = payload => {
  return {
    type: _CONSTATNTS.SHOW_BANNER,
    payload: payload,
  };
};

export const hideBanner = () => {
  return {
    type: _CONSTATNTS.HIDE_BANNER,
  };
};

export const showLoader = () => {
  return {
    type: _CONSTATNTS.SHOW_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: _CONSTATNTS.HIDE_LOADER,
  };
};
