import React, { useEffect } from 'react';
import { Link as RLink, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Flex,
  Box,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { hideErrorState } from 'actions/errorActions';

import userService from 'services/userService';

import InputField from 'components/form/InputField';
import schema from './schema';

function ForcePasswordChangePage() {
  let history = useHistory();
  const dispatch = useDispatch();

  const apiError = useSelector(state => state.error);
  const loggedIn = useSelector(state => state.auth?.loggedIn);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    reset,
  } = useForm(formOptions);

  const onSubmit = async formData => {
    const resData = await userService.forcePasswordChange(formData);
    if (resData) {
      onReset();
      history.push('/login');
      toast.success(`${resData.message}`);
    }
  };

  const onReset = () => {
    reset();
    return;
  };

  useEffect(() => {
    if (apiError.code === 307) {
      dispatch(hideErrorState());
    }
    // eslint-disable-next-line
  }, [apiError]);

  // Clear error
  useEffect(() => {
    if (loggedIn) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={['4', '6']} mx={'auto'} maxW={['xs', 'lg']}>
        <Stack align={'center'} w={['xs', 'lg']}>
          <Heading
            fontSize={['2xl', '4xl']}
            color={useColorModeValue('orange.500', 'orange.400')}
          >
            Password Change Force
          </Heading>

          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Your Password is expired!</AlertTitle>
            <AlertDescription>
              Please change password and try login.
            </AlertDescription>
          </Alert>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('gray.100', 'gray.700')}
          boxShadow={'2xl'}
          p={['4', '10']}
        >
          <Stack spacing={['2', '4']} align="stretch">
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                type="text"
                name="username"
                id="username"
                label="Username"
                placeholder="Username"
                error={errors.username}
                register={register('username')}
                autoFocus={true}
              />
              <InputField
                type="password"
                name="current_password"
                id="current_password"
                label="Current Password"
                placeholder="Current Password"
                error={errors.current_password}
                register={register('current_password')}
              />
              <InputField
                type="password"
                name="password"
                id="password"
                label="Password"
                placeholder="Password"
                error={errors.password}
                register={register('password')}
              />
              <InputField
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                label="Confirm Password"
                placeholder="Confirm Password"
                error={errors.password_confirmation}
                register={register('password_confirmation')}
              />

              <Button
                colorScheme="orange"
                w="full"
                type="submit"
                mt={4}
                isLoading={isSubmitting}
                disabled={!isValid || !isDirty}
              >
                Change Password
              </Button>
            </form>

            <Flex
              direction={{ base: 'column', sm: 'row' }}
              align={'center'}
              justifyContent="space-between"
              justifyItems="center"
              width="full"
            >
              <Link
                to="/login"
                as={RLink}
                color={'brand.default'}
                fontWeight="bold"
                my="1"
              >
                Login
              </Link>
            </Flex>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

const ForcePasswordChange = {
  routeProps: {
    path: '/force-password-change',
    component: ForcePasswordChangePage,
    exact: true,
  },
  name: 'Force Password Change',
  title: 'Force Password Change',
};

export default ForcePasswordChange;
