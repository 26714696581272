import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Flex,
  Box,
  Button,
  Heading,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Center,
  Text,
  Badge,
} from '@chakra-ui/react';

import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

import materialService from 'services/materialService';
import materialCatagoryService from 'services/materialCatagoryService';
import InputFieldSimple from 'components/form/InputFieldSimple';
import SelectFieldSimple from 'components/form/SelectFieldSimple';

export default function MaterialCart({
  cart = [],
  setCart,
  error = null,
  tradable,
  consumable,
  clearErrors,
  selectedPlant,
  tr_type,
}) {
  const [catagories, setCatagories] = useState([]);
  const [catagory, setCatagory] = useState(null);
  const [materialsList, setMaterialsList] = useState([]);
  const [material, setMaterial] = useState(null);
  const [qty, setQty] = useState(0);
  const [cartDetails, setCartDetails] = useState([]);
  const [plantStock, setPlantStock] = useState(null);

  const fetchIdCatagoryRef = useRef(0);
  const fetchIdMaterialRef = useRef(0);

  const fetchCatagories = async () => {
    const fetchIdCatagory = ++fetchIdCatagoryRef.current;

    if (fetchIdCatagory === fetchIdCatagoryRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'name';
      query.fields = '_id,name';
      const responseData = await materialCatagoryService.getAll(query);

      if (responseData) {
        setCatagories(responseData?.data);
      }
    }
  };

  const fetchMaterialsList = async () => {
    const fetchIdMaterial = ++fetchIdMaterialRef.current;

    if (fetchIdMaterial === fetchIdMaterialRef.current && catagory) {
      const query = {};
      query.filters = {};
      query.pagination = false;
      query.sortby = 'name';
      // query.fields = '_id,code,catagory,name';
      query.filters.catagory = catagory?.name;
      query.filters.is_active = true;
      query.filters.is_discontinued = false;
      if (tradable && (tradable === true || tradable === false)) {
        query.filters.tradable = tradable;
      }
      if (consumable && (consumable === true || consumable === false)) {
        query.filters.consumable = consumable;
      }
      const responseData = await materialService.getAll(query);

      if (responseData) {
        setMaterialsList(responseData?.data);
      }
    }
  };

  useEffect(() => {
    fetchCatagories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (catagory) {
      fetchMaterialsList();
    }
    // eslint-disable-next-line
  }, [catagory]);

  useEffect(() => {
    if (cart.length === 0) {
      setCartDetails([]);
    } else {
      clearErrors('materials');
    }
    // eslint-disable-next-line
  }, [cart]);

  useEffect(() => {
    if (selectedPlant && material) {
      setPlantStock(material?.stock[selectedPlant?.plant_code]);
    } else {
      setPlantStock(null);
    }
    // eslint-disable-next-line
  }, [selectedPlant, material]);

  const handleCatagoryChange = (selected, action) => {
    handleMaterialChange(null, 'clear');
    setQty(0);
    if (action === 'clear') {
      setCatagory(null);
      setPlantStock(null);
    }
    setCatagory(selected);
  };

  const handleMaterialChange = (selected, action) => {
    setQty(0);
    if (action === 'clear') {
      setMaterial(null);
    }
    setMaterial(selected);
  };

  const hasInsufficimport = () => {
    const ALLOWED_INSUFFICIENT_STOCK = ['PURCHASE'];

    if (ALLOWED_INSUFFICIENT_STOCK.includes(tr_type)) {
      return false;
    }

    // console.log(`tr_type`, tr_type);
    // console.log(`ALLOWED_INSUFFICIENT_STOCK`, ALLOWED_INSUFFICIENT_STOCK);
    // console.log(`ALLOW`, ALLOWED_INSUFFICIENT_STOCK.includes(tr_type));
    // console.log(`plantStock`, plantStock);
    // console.log(`qty`, qty);
    // console.log(`stock`, plantStock < qty);

    return !plantStock || plantStock < qty;
  };

  const addToCart = () => {
    const insufficientstock = hasInsufficimport();
    if (insufficientstock) {
      toast.warning('Insufficient stock for selected plant');
      return;
    }
    const exist = _.find(cart, { _id: material?._id });
    if (!exist) {
      const cartItem = {};
      cartItem.material_id = material?._id;
      cartItem.code = material?.code;
      cartItem.name = material?.name;
      cartItem.catagory = catagory?.name;
      cartItem.qty = qty;
      const newCart = [...cartDetails, cartItem];
      setCartDetails(newCart);
      handleCatagoryChange(null, 'clear');
      const cartSend = [];
      newCart.forEach(c => {
        const newObj = _.pick(c, ['material_id', 'qty']);
        cartSend.push(newObj);
      });
      setCart(cartSend);
    } else {
      toast.warning('Material already exist in cart');
    }
  };

  const removeFromCart = material_id => {
    const newCart = [...cartDetails];
    _.remove(newCart, { material_id: material_id });
    setCartDetails(newCart);
    const cartSend = [];
    newCart.forEach(c => {
      const newObj = _.pick(c, ['material_id', 'qty']);
      cartSend.push(newObj);
    });
    setCart(cartSend);
  };

  return (
    <Flex direction={['column', 'column']} w="full" h="full" p="2">
      <Flex direction={['column', 'row']} w="full" h="full" p="2">
        <Flex
          direction={['column', 'row']}
          w={['full', 'full', '20%']}
          h="full"
        >
          <Box w="full" h="full">
            <SelectFieldSimple
              isMulti={false}
              isClearable={true}
              name="catagory"
              label="Catagory"
              labelKey="name"
              valueKey="name"
              options={catagories}
              placeholder="Select Catagory"
              closeMenuOnSelect={false}
              size="sm"
              getOptionLabel={option => option.name}
              getOptionValue={option => option?.name}
              handleChange={handleCatagoryChange}
              defaultValue={catagory}
            />
            <SelectFieldSimple
              isMulti={false}
              isClearable={true}
              name="materials"
              label="Material"
              labelKey="name"
              valueKey="name"
              options={materialsList}
              placeholder="Select Material"
              closeMenuOnSelect={false}
              size="sm"
              getOptionLabel={option => `${option.name} (${option.code})`}
              getOptionValue={option => option?._id}
              handleChange={handleMaterialChange}
              defaultValue={material}
              disabled={!catagory}
            />
            <InputFieldSimple
              type="number"
              step="0.1"
              name="qty"
              id="qty"
              label="Qty"
              placeholder="Qty"
              value={qty}
              onChange={e => {
                setQty(e.target.value);
              }}
            />
            <Button
              w="full"
              mt="4"
              type="button"
              colorScheme="purple"
              size="sm"
              mr={[0, 4]}
              mb={[4, 0]}
              px="12"
              disabled={!material || qty <= 0}
              onClick={addToCart}
            >
              ADD TO CART
            </Button>
          </Box>
        </Flex>
        <Flex direction={['column', 'row']} w="full" h="full" flex="1" p="4">
          <Table size="sm">
            <Tbody>
              <Tr>
                <Th colSpan="7">
                  <Flex direction={['column', 'row']}>
                    <Heading
                      fontSize="lg"
                      color={error && error.message ? 'red.500' : 'orange'}
                      mr={[0, 4]}
                      mb={[4, 0]}
                    >
                      CART
                    </Heading>
                    <Text color="red.500" fontWeight="bold">
                      {error && error.message}
                    </Text>
                  </Flex>
                </Th>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Code
                </Th>
                <Th color="teal.500" fontWeight="extrabold">
                  Material
                </Th>
                <Th color="teal.500" fontWeight="extrabold">
                  Catagory
                </Th>
                <Th color="teal.500" fontWeight="extrabold">
                  Qty
                </Th>
                <Th color="teal.500" fontWeight="extrabold">
                  <Center>Remove</Center>
                </Th>
              </Tr>
              {!cartDetails.length ? (
                <Tr>
                  <Td colSpan="5">No Items In Cart</Td>
                </Tr>
              ) : (
                cartDetails.map(item => (
                  <Tr key={item.material_id}>
                    <Td>{item?.code}</Td>
                    <Td>{item?.name}</Td>
                    <Td>{item?.catagory}</Td>
                    <Td>{item?.qty}</Td>
                    <Td>
                      <Center>
                        <Button
                          size="xs"
                          color="red"
                          type="button"
                          variant="outline"
                          onClick={() => removeFromCart(item.material_id)}
                        >
                          <FaTrash />
                        </Button>
                      </Center>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th colSpan="7">Total Items : {cartDetails.length}</Th>
              </Tr>
            </Tfoot>
          </Table>
        </Flex>
      </Flex>
      {material && (
        <Flex direction={['column', 'row']} w="full" h="full" p="2">
          <Table size="sm">
            <Tbody>
              <Tr>
                <Th colSpan="4">
                  <Heading fontSize="lg" color="orange">
                    MATERIAL DETAILS
                  </Heading>
                </Th>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Stock
                </Th>
                <Td>
                  <Badge variant="solid" colorScheme="purple">
                    {material ? material?.stock_total : 0}
                  </Badge>
                </Td>
                <Th color="teal.500" fontWeight="extrabold">
                  Stock Current Plant
                  {selectedPlant ? ` - ${selectedPlant?.plant_code}` : '-'}
                </Th>
                <Td>
                  <Badge variant="solid" colorScheme="green">
                    {plantStock ? plantStock : 0}
                  </Badge>
                </Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Sales Price
                </Th>
                <Td fontWeight={'bold'}>
                  <Text color="blue">
                    {material ? material?.sales_price : '-'}
                  </Text>
                </Td>
                <Th color="teal.500" fontWeight="extrabold">
                  Consumption Price :
                </Th>
                <Td>{material ? material?.consuption_price : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Unit (IN)
                </Th>
                <Td>{material ? material?.unit_in : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold">
                  Unit (OUT)
                </Th>
                <Td>{material ? material?.unit_out : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  Tax Type
                </Th>
                <Td>{material ? material?.tax_type : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold">
                  HSN
                </Th>
                <Td>{material ? material?.hsn : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  SGST
                </Th>
                <Td>{material?.sgst ? material?.sgst : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold">
                  CGST
                </Th>
                <Td>{material?.cgst ? material?.cgst : '-'}</Td>
              </Tr>
              <Tr>
                <Th color="teal.500" fontWeight="extrabold">
                  IGST
                </Th>
                <Td>{material?.igst ? material?.igst : '-'}</Td>
                <Th color="teal.500" fontWeight="extrabold"></Th>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </Flex>
      )}
    </Flex>
  );
}
