import React from 'react';
// import moment from 'helpers/moment';
import {
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';

function DateField(props) {
  let {
    label,
    id,
    name,
    placeholder,
    register,
    error,
    disabled,
    readonly,
    autoFocus,
    size = 'sm',
  } = props;

  return (
    <FormControl id={id} isInvalid={error} py="2">
      {label && (
        <FormLabel
          htmlFor={name}
          color={disabled ? 'brand.light' : error ? 'red.500' : 'brand.default'}
        >
          {label}
        </FormLabel>
      )}

      <Input
        focusBorderColor="orange.400"
        variant="outline"
        bg={useColorModeValue('gray.50', 'gray.700')}
        color={useColorModeValue('gray.800', 'white')}
        _disabled={{ color: useColorModeValue('gray.500', 'gray.400') }}
        type={`date`}
        autoComplete="off"
        width="full"
        name={name}
        placeholder={placeholder}
        {...register}
        disabled={disabled === true ? true : false}
        readOnly={readonly === true ? true : false}
        size={size}
        autoFocus={autoFocus === true ? true : false}
      />

      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}

export default DateField;
