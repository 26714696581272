import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';
import fields from './fields';
import materialCatagoryService from 'services/materialCatagoryService';

import { TAX_TYPE_OPTIONS } from 'configs/taxTypeOptions';
import { TRADABLE_OPTIONS } from 'configs/tradableOptions';
import { CONSUMABLE_OPTIONS } from 'configs/consumableOptions';
import { STATUS_OPTIONS } from 'configs/statusOptions';
import { DISCONTINUED_OPTIONS } from 'configs/discontinuedOptions';

import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);
  const [catagories, setCatagories] = useState([]);

  const fetchIdCatagoryRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const fetchCatagories = async () => {
    const fetchIdCatagory = ++fetchIdCatagoryRef.current;

    if (fetchIdCatagory === fetchIdCatagoryRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'name';
      query.fields = '_id,name';
      const responseData = await materialCatagoryService.getAll(query);

      if (responseData) {
        setCatagories(responseData?.data);
      }
    }
  };

  useEffect(() => {
    fetchCatagories();
    // eslint-disable-next-line
  }, []);

  const defaultValues = {
    code: fields?.code?.default,
    name: fields?.name?.default,
    catagory: fields?.catagory?.default,
    tax_type: fields?.tax_type?.default,
    tradable: fields?.tradable?.default,
    consumable: fields?.consumable?.default,
    is_active: fields?.is_active?.default,
    is_discontinued: fields?.is_discontinued?.default,
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const watchCatagory = watch('catagory', '');
  const watchTaxType = watch('tax_type', '');
  const watchTradable = watch('tradable', '');
  const watchConsumable = watch('consumable', '');
  const watchStatus = watch('is_active', '');
  const watchDiscontinued = watch('discontinued', '');

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'catagory') {
        value = _.find(catagories, { name: value });
        setValue('catagory', value);
      } else if (key === 'tax_type') {
        if (value === null) value = 'TAX-FREE';
        value = _.find(TAX_TYPE_OPTIONS, { value: value });
        setValue('tax_type', value);
      } else if (key === 'tradable') {
        value = _.find(TRADABLE_OPTIONS, { value: value });
        setValue('tradable', value);
      } else if (key === 'consumable') {
        value = _.find(CONSUMABLE_OPTIONS, { value: value });
        setValue('consumable', value);
      } else if (key === 'is_active') {
        value = _.find(STATUS_OPTIONS, { value: value });
        setValue('is_active', value);
      } else if (key === 'is_discontinued') {
        value = _.find(DISCONTINUED_OPTIONS, { value: value });
        setValue('is_discontinued', value);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [defaultFilters]);

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      handleCatagoryChange(null, 'clear');
      handleTaxTypeChange(null, 'clear');
      handleTradableChange(null, 'clear');
      handleConsumableChange(null, 'clear');
      handleStatusChange(null, 'clear');
      handleDiscontinuedChange(null, 'clear');
      reset(defaultValues);
    }
    setFilters({});
  };

  const onSubmit = formData => {
    // console.log(formData);
    const formDataSend = {};
    for (const key in formData) {
      if (formData[key] === undefined || formData[key] === '') {
        delete formData[key];
      } else if (key === 'catagory' && formData[key]) {
        formDataSend[key] = formData[key].name;
      } else if (key === 'tax_type' && formData[key]) {
        formDataSend[key] = formData[key].value;
      } else if (
        ['tradable', 'consumable', 'is_active', 'is_discontinued'].includes(
          key
        ) &&
        formData[key]
      ) {
        formDataSend[key] = formData[key].value;
      } else if (formData[key] !== null) {
        formDataSend[key] = formData[key];
      }
    }

    if (!_.isEmpty(formDataSend)) {
      onFilter(formDataSend);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  const handleCatagoryChange = (selected, action) => {
    if (action === 'clear') {
      setValue('catagory', null);
      return;
    }
    setValue('catagory', selected);
  };

  const handleTaxTypeChange = (selected, action) => {
    if (action === 'clear') {
      setValue('tax_type', null);
      return;
    }
    setValue('tax_type', selected);
  };

  const handleTradableChange = (selected, action) => {
    if (action === 'clear') {
      setValue('tradable', null);
      return;
    }
    setValue('tradable', selected);
  };

  const handleConsumableChange = (selected, action) => {
    if (action === 'clear') {
      setValue('is_discontinued', null);
      return;
    }
    setValue('is_discontinued', selected);
  };

  const handleStatusChange = (selected, action) => {
    if (action === 'clear') {
      setValue('is_active', null);
      return;
    }
    setValue('is_active', selected);
  };

  const handleDiscontinuedChange = (selected, action) => {
    if (action === 'clear') {
      setValue('discontinued', null);
      return;
    }
    setValue('discontinued', selected);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}
        <InputField
          type="text"
          name="code"
          id="code"
          label="Code"
          placeholder="Code"
          error={errors.code}
          register={register('code')}
        />
        <InputField
          type="text"
          name="name"
          id="name"
          label="Material"
          placeholder="Material"
          error={errors.name}
          register={register('name')}
        />

        <SelectField
          isMulti={false}
          isClearable={true}
          name="catagory"
          label="Catagory"
          labelKey="name"
          valueKey="name"
          options={catagories}
          placeholder="Select Catagory"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.name}
          handleChange={handleCatagoryChange}
          error={errors.catagory}
          control={control}
          defaultValue={watchCatagory}
        />

        <SelectField
          isMulti={false}
          isClearable={true}
          name="tax_type"
          label="Tax Type"
          labelKey="name"
          valueKey="value"
          options={TAX_TYPE_OPTIONS}
          placeholder="Select Tax Type"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.value}
          handleChange={handleTaxTypeChange}
          error={errors.tax_type}
          control={control}
          defaultValue={watchTaxType}
        />

        <SelectField
          isMulti={false}
          isClearable={true}
          name="tradable"
          label="Tradable"
          labelKey="name"
          valueKey="value"
          options={TRADABLE_OPTIONS}
          placeholder="Tradable ?"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.value}
          handleChange={handleTradableChange}
          error={errors.tradable}
          control={control}
          defaultValue={watchTradable}
        />

        <SelectField
          isMulti={false}
          isClearable={true}
          name="consumable"
          label="Consumable"
          labelKey="name"
          valueKey="value"
          options={CONSUMABLE_OPTIONS}
          placeholder="Consumable ?"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.value}
          handleChange={handleConsumableChange}
          error={errors.consumable}
          control={control}
          defaultValue={watchConsumable}
        />

        <SelectField
          isMulti={false}
          isClearable={true}
          name="is_active"
          label="Status"
          labelKey="name"
          valueKey="value"
          options={STATUS_OPTIONS}
          placeholder="Status ?"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.value}
          handleChange={handleStatusChange}
          error={errors.status}
          control={control}
          defaultValue={watchStatus}
        />

        <SelectField
          isMulti={false}
          isClearable={true}
          name="is_discontinued"
          label="Discontinued"
          labelKey="name"
          valueKey="value"
          options={DISCONTINUED_OPTIONS}
          placeholder="Discontinued ?"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.value}
          handleChange={handleDiscontinuedChange}
          error={errors.is_discontinued}
          control={control}
          defaultValue={watchDiscontinued}
        />

        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
