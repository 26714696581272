import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Center,
  Flex,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  useColorModeValue,
  Spinner,
  Select,
  FormControl,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { toIndianFormat } from 'helpers/genHelpers';
import { useSelector } from 'react-redux';

import dashboardMilkService from 'services/dashboardMilkService';

const DAY_OPTIONS = [1, 5, 10];

function TotalMilkForLastDays() {
  const plants = useSelector(state => state.plants);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState(DAY_OPTIONS[0]);
  const [plantOptions, setPlantOptions] = useState(['ALL']);
  const [selectedPlant, setSelectedPlant] = useState('ALL');

  const refreshData = async () => {
    setLoading(true);
    setData([]);
    const resData = await dashboardMilkService.getTotalMilkForLastDays(
      selectedDays,
      selectedPlant
    );
    if (resData && resData?.data) {
      setData(resData?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [selectedDays, selectedPlant]);

  useEffect(() => {
    const options = ['ALL'];
    if (plants.length) {
      const activePlants = _.filter(plants, { is_active: true });
      for (let i = 0; i < activePlants.length; i++) {
        const activePlant = activePlants[i];
        options.push(activePlant?.plant_code);
      }
      setPlantOptions(options);
    }
  }, [plants]);

  const tblGoodHeaderBg = useColorModeValue('green.800', 'green.200');
  const tblGoodHeaderColor = useColorModeValue('white', 'black');

  const tblSourHeaderBg = useColorModeValue('red.800', 'red.200');
  const tblSourHeaderColor = useColorModeValue('white', 'black');

  const tblSubHeaderBg = useColorModeValue('blue.700', 'blue.200');
  const tblSubHeaderColor = useColorModeValue('white', 'black');

  return (
    <Box
      h="full"
      w="full"
      p={2}
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      bg={useColorModeValue('gray.100', 'gray.700')}
      color={useColorModeValue('gray.800', 'gray.200')}
    >
      <Flex
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        h="full"
        w="full"
      >
        <Box height="15%" mb="4">
          <Flex justifyContent={'space-between'} alignItems={'center'} mb="4">
            <Heading
              fontSize="md"
              color={useColorModeValue('orange.500', 'orange.200')}
            >
              MILK FOR LAST : {selectedDays} {selectedDays > 1 ? 'Days' : 'Day'}
            </Heading>
            <Heading
              fontSize="md"
              color={useColorModeValue('orange.500', 'orange.200')}
            >
              PLANT : {selectedPlant}
            </Heading>

            <Box mx="2" my="2">
              <FormControl>
                <InputGroup size="sm">
                  <InputLeftAddon children="Days" />
                  <Select
                    size="sm"
                    varient="filled"
                    colorScheme="orange"
                    focusBorderColor="orange.500"
                    onChange={e => setSelectedDays(e.target.value)}
                    defaultValue={selectedDays}
                  >
                    {DAY_OPTIONS.map(day => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <InputGroup size="sm">
                  <InputLeftAddon children="Plant" />
                  <Select
                    size="sm"
                    varient="filled"
                    colorScheme="orange"
                    focusBorderColor="orange.500"
                    onChange={e => setSelectedPlant(e.target.value)}
                    defaultValue={selectedPlant}
                  >
                    {plantOptions.map(plant => (
                      <option key={plant} value={plant}>
                        {plant}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </FormControl>
            </Box>

            <Button
              variant="ghost"
              isLoading={loading}
              loadingText="Refreshing..."
              onClick={refreshData}
              outline="none"
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'transperent',
                scale: '50',
                color: 'teal.500',
                size: 'xs',
              }}
            >
              <RepeatIcon w={8} h={8} color="green.500" />
            </Button>
          </Flex>
        </Box>

        {loading && (
          <Box minH="200px" w="full">
            <Center>
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
              />
            </Center>
          </Box>
        )}

        {!loading && (
          <>
            <Box flex="1" p="2">
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                mb="4"
              >
                <Table size="sm" varient="simple" mr="4">
                  <Thead>
                    <Tr bg={tblGoodHeaderBg}>
                      <Th
                        colSpan={4}
                        textAlign="center"
                        fontSize="lg"
                        color={tblGoodHeaderColor}
                      >
                        GOOD MILK
                      </Th>
                      {/* <Th isNumeric>multiply by</Th> */}
                    </Tr>
                    <Tr bg={tblSubHeaderBg}>
                      <Th
                        textAlign="center"
                        fontSize="sm"
                        color={tblSubHeaderColor}
                      >
                        TYPE
                      </Th>
                      <Th
                        textAlign="center"
                        fontSize="sm"
                        color={tblSubHeaderColor}
                        isNumeric
                      >
                        WEIGHT
                      </Th>
                      <Th
                        textAlign="center"
                        fontSize="sm"
                        color={tblSubHeaderColor}
                        isNumeric
                      >
                        KGFAT
                      </Th>
                      <Th
                        textAlign="center"
                        fontSize="sm"
                        color={tblSubHeaderColor}
                        isNumeric
                      >
                        KGSNF
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Th textAlign="center" fontWeight="bold" fontSize="md">
                        BUFF
                      </Th>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.good?.buff?.weight)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.good?.buff?.kgfat)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.good?.buff?.kgsnf)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th textAlign="center" fontWeight="bold" fontSize="md">
                        COW
                      </Th>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.good?.cow?.weight)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.good?.cow?.kgfat)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.good?.cow?.kgsnf)}
                      </Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr bg={tblGoodHeaderBg} fontWeight="semibold">
                      <Th
                        textAlign="center"
                        color={tblGoodHeaderColor}
                        fontSize="md"
                      >
                        TOTAL
                      </Th>
                      <Td isNumeric color={tblGoodHeaderColor} fontSize="md">
                        {toIndianFormat(data?.results?.good?.total?.weight)}
                      </Td>
                      <Td isNumeric color={tblGoodHeaderColor} fontSize="md">
                        {toIndianFormat(data?.results?.good?.total?.kgfat)}
                      </Td>
                      <Td isNumeric color={tblGoodHeaderColor} fontSize="md">
                        {toIndianFormat(data?.results?.good?.total?.kgsnf)}
                      </Td>
                    </Tr>
                  </Tfoot>
                </Table>

                <Table size="sm" varient="simple">
                  <Thead>
                    <Tr bg={tblSourHeaderBg}>
                      <Th
                        colSpan={4}
                        textAlign="center"
                        fontSize="lg"
                        color={tblSourHeaderColor}
                      >
                        SOUR MILK
                      </Th>
                      {/* <Th isNumeric>multiply by</Th> */}
                    </Tr>
                    <Tr bg={tblSubHeaderBg}>
                      <Th
                        textAlign="center"
                        fontSize="md"
                        color={tblSubHeaderColor}
                      >
                        TYPE
                      </Th>
                      <Th
                        textAlign="center"
                        fontSize="md"
                        color={tblSubHeaderColor}
                        isNumeric
                      >
                        WEIGHT
                      </Th>
                      <Th
                        textAlign="center"
                        fontSize="md"
                        color={tblSubHeaderColor}
                        isNumeric
                      >
                        KGFAT
                      </Th>
                      <Th
                        textAlign="center"
                        fontSize="md"
                        color={tblSubHeaderColor}
                        isNumeric
                      >
                        KGSNF
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Th textAlign="center" fontWeight="bold" fontSize="md">
                        BUFF
                      </Th>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.sour?.buff?.weight)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.sour?.buff?.kgfat)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.sour?.buff?.kgsnf)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th textAlign="center" fontWeight="bold" fontSize="md">
                        COW
                      </Th>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.sour?.cow?.weight)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.sour?.cow?.kgfat)}
                      </Td>
                      <Td isNumeric fontSize="md">
                        {toIndianFormat(data?.results?.sour?.cow?.kgsnf)}
                      </Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr bg={tblSourHeaderBg} fontWeight="bold">
                      <Th textAlign="center" color={tblSubHeaderColor}>
                        TOTAL
                      </Th>
                      <Td isNumeric color={tblSubHeaderColor}>
                        {toIndianFormat(data?.results?.sour?.total?.weight)}
                      </Td>
                      <Td isNumeric color={tblSubHeaderColor}>
                        {toIndianFormat(data?.results?.sour?.total?.kgfat)}
                      </Td>
                      <Td isNumeric color={tblSubHeaderColor}>
                        {toIndianFormat(data?.results?.sour?.total?.kgsnf)}
                      </Td>
                    </Tr>
                  </Tfoot>
                </Table>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default TotalMilkForLastDays;
