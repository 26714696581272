import * as yup from 'yup';

const messages = {
  email: {
    required: 'E-Mail is required',
    invalid: 'Invalid E-Mail number',
  },
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email(messages.email.invalid)
    .required(messages.email.required),
});

export default schema;
