import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Flex } from '@chakra-ui/react';
import dashboardService from 'services/dashboardService';
import { useSelector } from 'react-redux';

// Dashboard Components
import UserStats from 'modules/Dashboard/Components/UserStats';
import SmsCreditStats from 'modules/Dashboard/Components/SmsCreditStats';
import SocietyTotalStats from 'modules/DashboardSociety/Components/SocietyTotalStats';
import MilkUploadStatus from 'modules/Dashboard/Components/MilkUploadStatus';

const USER_STATS_ROLES = ['superadmin'];
const SMS_CREDIT_STATS_ROLES = ['superadmin'];
const SOCIETY_STATS = ['superadmin', 'admin', 'md', 'supervisor'];
const MILKFILE_UPLOAD_STATUS = ['superadmin', 'admin', 'md'];

const IndexPage = props => {
  const user = useSelector(state => state.auth.user);
  const [userRoles, setUserRoles] = useState([]);

  const [allowUserStats, setAllowUserStats] = useState(false);
  const [allowSmsCreditStats, setAllowSmsCreditStats] = useState(false);
  const [allowSocietyStats, setAllowSocietyStats] = useState(false);
  useState(false);
  const [allowMilkFileUploadStatus, setAllowMilkFileUploadStatus] =
    useState(false);

  const [societyData, setSocietyData] = useState([]);
  const [loading, setLoading] = useState(false);

  const refreshSocietyData = async () => {
    setLoading(true);
    const query = { filters: { fields: 'society' } };
    const resData = await dashboardService.getAll(query);
    if (resData && resData?.data?.society) {
      setSocietyData(resData?.data?.society);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUserRoles(user.role);
  }, [user]);

  useEffect(() => {
    if (_.intersection(USER_STATS_ROLES, userRoles).length > 0) {
      setAllowUserStats(true);
    }
    if (_.intersection(SMS_CREDIT_STATS_ROLES, userRoles).length > 0) {
      setAllowSmsCreditStats(true);
    }

    if (_.intersection(SOCIETY_STATS, userRoles).length > 0) {
      setAllowSocietyStats(true);
    }

    if (_.intersection(MILKFILE_UPLOAD_STATUS, userRoles).length > 0) {
      setAllowMilkFileUploadStatus(true);
    }
  }, [userRoles]);

  useEffect(() => {
    refreshSocietyData();
  }, []);

  return (
    <Box h="full" w="full" p={4} mt={{ sm: 24, md: 12, lg: 2 }}>
      <Flex direction="column" justifyContent="start" alignItems="start">
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          justifyContent="start"
          alignItems="start"
          w="full"
          h="full"
        >
          {allowUserStats && (
            <Box height="300px" width={{ sm: 'full', md: '250px' }} p="2">
              <UserStats />
            </Box>
          )}
          {allowSocietyStats && (
            <Box height="300px" width={{ sm: 'full', md: '250px' }} p="2">
              <SocietyTotalStats
                loading={loading}
                data={societyData}
                refreshData={refreshSocietyData}
              />
            </Box>
          )}
          {allowSmsCreditStats && (
            <Box height="300px" width={{ sm: 'full', md: '250px' }} p="2">
              <SmsCreditStats />
            </Box>
          )}
          {allowMilkFileUploadStatus && (
            <Box
              height="auto"
              width={{ sm: 'full', md: 'auto' }}
              minW="600px"
              p="2"
            >
              <MilkUploadStatus />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

const Dashboard = {
  routeProps: {
    path: '/dashboard',
    component: IndexPage,
    exact: true,
  },
  name: 'Dashboard',
  title: 'Dashboard',
};

export default Dashboard;
