import axios from 'axios';
import { authHeader } from 'helpers/authHeader';
import { isTokenExpired } from 'helpers/authHelper';
import store from 'helpers/store';
import { showLoader, hideLoader } from 'actions/_Actions';
import { showBanner, hideBanner } from 'actions/_Actions';
import { setData, unSetData, setMeta, unSetMeta } from 'actions/successActions';
import { setError, hideError } from 'actions/errorActions';
import authActions from 'actions/api/authActions';

const { dispatch } = store;

// const avoideBannerUrls = [
//   "/auth/login",
//   "/auth/logout",
//   "/auth/token",
//   "/users/profile/me",
// ];

const includeBannerUrls = [
  '/auth/forgetpassword/mobile',
  '/auth/resetpassword/mobile',
  '/auth/changepassword',
  '/auth/change/mobile',
  '/auth/change/email',
  '/milk/upload',
];

const hideLoaderUrls = [
  '/milk/upload',
  '/dashboard/all',
  '/dashboardmilk',
  '/dashboardsociety',
];

// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// let loop = 0;
let isRefreshing = false;
let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRrefreshed(token) {
  subscribers.map(cb => cb(token));
}

const headers = {
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
  responseType: 'json',
  headers,
});

const checkLoaderEnabledUrl = currentUrl => {
  const noQuryUrl = currentUrl.split('?')[0];
  for (let i = 0; i < hideLoaderUrls.length; i++) {
    const hiddenUrl = hideLoaderUrls[i];
    if (noQuryUrl.startsWith(hiddenUrl)) {
      return true;
    }
  }
  return false;
};

// // Add a request interceptor
axiosClient.interceptors.request.use(
  config => {
    if (!checkLoaderEnabledUrl(config?.url)) {
      dispatch(showLoader());
    }
    // dispatch(hideBanner());
    dispatch(unSetData());
    dispatch(unSetMeta());
    dispatch(hideError());
    dispatch(hideBanner());

    const refresh_token = localStorage.getItem('refresh_token');

    if (!refresh_token) {
      dispatch(authActions.logout());
    }

    if (config.url === '/auth/token') {
      const expiredRefreshToken = isTokenExpired(refresh_token);
      if (expiredRefreshToken) {
        authActions.logout();
        return {
          ...config,
          cancelToken: new axios.CancelToken(cancel =>
            cancel('Cancel repeated request')
          ),
        };
      } else {
        config.headers['x-refresh-token'] = refresh_token;
      }
    }

    const token = authHeader();

    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  err => {
    // Do something with request error
    return Promise.reject(err);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  response => {
    dispatch(hideLoader());
    if (response.status === 500) {
      const errData = {
        code: 500,
        status: 'error',
        isOperational: false,
        message: `Server Error. Something went wrong.`,
        errors: {},
      };
      dispatch(setError(errData));
    }

    if (response.status === 403) {
      const errData = {
        code: 403,
        status: 'error',
        isOperational: false,
        message: `You are not authorized to perform this action`,
        errors: {},
      };
      dispatch(setError(errData));
    }

    dispatch(setData(response?.data));

    if (response?.data?.meta) {
      dispatch(setMeta(response.data.meta));
    }
    // if (!avoideBannerUrls.includes(response.config.url)) {
    //   dispatch(showBanner());
    // }

    if (includeBannerUrls.includes(response.config.url)) {
      dispatch(
        showBanner({
          status: 'success',
          title: 'Success',
          message: response?.data?.message,
        })
      );
    }

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  },
  err => {
    if (!err.response) {
      if (err.message === 'Network Error') {
        const errData = {
          code: 504,
          status: 'error',
          isOperational: false,
          message: `Network Connection Error...! Please Check Your Internet Connection.`,
          errors: {},
        };
        dispatch(setError(errData));
      }

      dispatch(hideLoader());
      return new Promise((resolve, reject) => {
        reject(err);
      });
    }

    const {
      config,
      response: { status },
    } = err;
    const originalRequest = config;

    const refresh_token = localStorage.getItem('refresh_token');

    if (status === 401 && config.url !== '/auth/login' && refresh_token) {
      localStorage.removeItem('token');

      // Get New Access Token
      // loop++;
      if (!isRefreshing) {
        isRefreshing = true;
        axiosClient
          .post('/auth/token')
          .then(response => {
            isRefreshing = false;
            localStorage.setItem('token', response?.data.data);
            onRrefreshed(response?.data.data);
            subscribers = [];
          })
          .catch(err => {
            // console.log(`Error in Refresh request`, err);
            dispatch(authActions.logout());
          });
      }

      return new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axiosClient(originalRequest));
        });
      });
    }

    dispatch(setError(err.response?.data));
    dispatch(hideLoader());

    if (err.response?.data?.code !== 422) {
      dispatch(
        showBanner({
          status: 'error',
          title: 'Error',
          message: err.response?.data?.message,
        })
      );
    }
    return Promise.reject(err);
  }
);

export default axiosClient;
