import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import schema from './schema';

import SelectField from 'components/form/SelectField';
import AsyncSelectField from 'components/form/AsyncSelectField';
import RenderUserOption from 'components/form/RenderUserOption';
import RenderSocietyOption from 'components/form/RenderSocietyOption';

import userService from 'services/userService';
import societyService from 'services/societyService';
import authorizationService from 'services/authorizationService';

const FormTitle = 'AUTHORIZE USER SOCIETY';

const defaultValues = {
  user: '',
  societies: '',
};

const IndexPage = () => {
  const apiError = useSelector(state => state.error);

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);
  const fetchIdUserDetailsRef = useRef(0);

  const [users, setUsers] = useState([]);
  const [societies, setSocieties] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentSocieties, setCurrentSocieties] = useState([]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    handleSocietyChange('clear', null);
    handleUserChange('clear', null);
  };

  const watchSociety = watch('society', null);
  const watchUser = watch('user', null);

  useEffect(() => {
    fetchUsers();
    fetchSocieties();
  }, []);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const responseData = await authorizationService.userSocietyAuthorize(
        formData.user?._id,
        formData.society?._id
      );

      if (responseData) {
        onReset();
        fetchUsers();
        fetchSocieties();
        toast.success(`${responseData.message}`);
      }
    }
  };

  const fetchUsers = async () => {
    const fetchIdUsers = ++fetchIdUserDetailsRef.current;

    if (fetchIdUsers === fetchIdUserDetailsRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'username';
      query.filters = { role: 'supervisor' };
      query.fields =
        '-createdAt -updatedAt -email_verified -force_password_change -last_login_timestamp -last_password_change -mobile_verified ';
      const responseData = await userService.getAll(query);

      if (responseData && responseData?.data) {
        setUsers(responseData?.data);
      }
    }
  };

  const fetchSocieties = async () => {
    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'society_code';
      query.fields = '_id,society_code,society_name,is_active, city, district';
      const responseData = await societyService.getAll(query);

      if (responseData) {
        setSocieties(responseData?.data);
      }
    }
  };

  const handleSocietyChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society', null);
      setCurrentUser(null);
      setCurrentSocieties([]);
      return;
    }
    setValue('society', selected);

    if (selected && selected?.supervisor) {
      setCurrentUser(selected?.supervisor?.username);
    } else {
      setCurrentUser(null);
    }
  };

  const handleUserChange = (selected, action) => {
    handleSocietyChange(null, 'clear');
    if (action === 'clear') {
      setValue('user', null);
      setCurrentSocieties([]);
      return;
    }
    setValue('user', selected);
    if (selected && selected.societies && selected?.societies.length) {
      setCurrentSocieties(selected?.societies);
    }
    return;
  };

  const filterValues = (inputValue = '') => {
    const activeOnly = true;
    if (!activeOnly) {
      if (inputValue.startsWith('*')) {
        return societies.filter(i =>
          i?.society_name
            ?.toLowerCase()
            ?.startsWith(inputValue.toLowerCase().slice(1))
        );
      } else {
        return societies.filter(i =>
          i?.society_code?.toString()?.startsWith(inputValue)
        );
      }
    }

    // Return Active Only
    const activeSocieties = _.filter(societies, { is_active: true });

    if (inputValue.startsWith('*')) {
      return activeSocieties.filter(i =>
        i?.society_name
          ?.toLowerCase()
          ?.startsWith(inputValue.toLowerCase().slice(1))
      );
    } else {
      return activeSocieties.filter(i => {
        return i?.society_code?.toString()?.startsWith(inputValue);
      });
    }
  };

  const customUserFilter = (option, searchText) => {
    if (option.data.username.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      w="full"
      h="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
        </Flex>
      </Box>

      {/* Form */}
      <Box w="full" h="full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="user"
                    label="User"
                    labelKey="username"
                    valueKey="_id"
                    options={users}
                    placeholder="Select User"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => (
                      <RenderUserOption user={option} avatarSize={40} />
                    )}
                    getOptionValue={option => option?._id}
                    handleChange={handleUserChange}
                    error={errors.user}
                    control={control}
                    defaultValue={watchUser}
                    filterOption={customUserFilter}
                  />
                </Box>

                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <AsyncSelectField
                    isMulti={false}
                    isClearable={true}
                    name="society"
                    label="Society"
                    labelKey="society_code"
                    valueKey="_id"
                    options={societies}
                    placeholder="Select Society"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => (
                      <RenderSocietyOption
                        society={option}
                        showCity={true}
                        showDistrict={true}
                        showPlant={true}
                        showSupervisor={true}
                      />
                    )}
                    getOptionValue={option => option?._id}
                    handleChange={handleSocietyChange}
                    error={errors.society}
                    control={control}
                    defaultValue={watchSociety}
                    filterValues={filterValues}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ASSIGN SOCIETY
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>

              {/* Current Data */}

              <Flex direction={['column', 'row']}>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <Text fontSize="md" fontWeight="bold">
                    Current Supervisor
                  </Text>
                </Box>
              </Flex>

              <Flex direction={['column', 'row']}>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <Text>
                    {currentUser ? currentUser : `No Supervisor Assigned`}
                  </Text>
                </Box>
              </Flex>

              <Flex direction={['column', 'row']}>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <Text fontSize="md" fontWeight="bold">
                    Current Societies
                  </Text>
                </Box>
              </Flex>

              <Flex direction={['column', 'row']}>
                <Box w="full" mr={[0, 4]} mb={[1, 0]}>
                  {currentSocieties.length ? (
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th>CODE</Th>
                          <Th>NAME</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {currentSocieties.map(soc => (
                          <Tr key={soc._id}>
                            <Td>{soc.society_code}</Td>
                            <Td>{soc.society_name}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    <Text>No Societies assigned to selected user</Text>
                  )}
                </Box>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const AuthorizeUserSociety = {
  routeProps: {
    path: '/authorize/society',
    component: IndexPage,
    exact: true,
  },
  name: 'Authorize Society',
  title: 'Authorize Society',
};

export default AuthorizeUserSociety;
