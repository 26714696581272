import _CONSTATNTS from 'constants/_Constants';

// export const setData = (data) => {
//   return async (dispatch) => {
//     dispatch(setDataState(data));
//     toast.success(`${data.message}`);
//   };
// };

// export const unSetData = () => {
//   return async (dispatch) => {
//     dispatch(unsetDataState());
//   };
// };

export const setData = payload => {
  return {
    type: _CONSTATNTS.SET_DATA,
    payload: payload || {},
  };
};

export const setMeta = payload => {
  return {
    type: _CONSTATNTS.SET_META,
    payload: payload || {},
  };
};

export const unSetData = () => {
  return {
    type: _CONSTATNTS.UNSET_DATA,
  };
};

export const unSetMeta = () => {
  return {
    type: _CONSTATNTS.UNSET_META,
  };
};
