import * as yup from 'yup';
import messages from './messages';

const updatePasswordSchema = yup.object().shape({
  password: yup.string().label('Password').required(messages.password.required),
  password_confirmation: yup
    .string()
    .label('Confirm Password')
    .test(
      'passwords-match',
      messages.password_confirmation.match,
      function (value) {
        return this.parent.password === value;
      }
    ),
});

export default updatePasswordSchema;
