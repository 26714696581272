const messages = {
  code: {
    required: 'Code is required',
    invalid: 'Code has invalid value',
  },
  name: {
    required: 'Customer Name is required',
    invalid: 'Customer Name has invalid value',
  },
  address: {
    required: 'Address is required',
    invalid: 'Address has invalid value',
  },
  city: {
    required: 'City is required',
    invalid: 'City has invalid value',
  },
  state: {
    required: 'State is required',
    invalid: 'State has invalid value',
  },
  pan: {
    required: 'PAN is required',
    invalid: 'PAN has invalid value',
  },
  pan_name: {
    required: 'Name is in PAN is required',
    invalid: 'Name is in PAN has invalid value',
  },
  gst: {
    required: 'GST is required',
    invalid: 'GST has invalid value',
  },
  gst_name: {
    required: 'Name is in GST is required',
    invalid: 'Name is in GST has invalid value',
  },
  mobile: {
    required: 'Mobile Price is required',
    invalid: 'Mobile Price has invalid value',
  },
  email: {
    required: 'E-Mail is required',
    invalid: 'E-Mail has invalid value',
  },
  is_active: {
    required: 'Status is required',
    invalid: 'Status has invalid value',
  },
};

export default messages;
