import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Button,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
} from '@chakra-ui/react';
import { getFullname } from '../../helpers/genHelpers';

import { Link, useParams } from 'react-router-dom';

import EditButtons from './EditButtons';
import societyService from 'services/societyService';

const ShowSupervisorDetailsPage = () => {
  const FormTitle = 'SUPERVISOR DETAILS';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const [data, setData] = useState(null);
  const [details, setDetails] = useState(null);

  const { _id } = useParams();

  useEffect(() => {
    const fetchSociety = async () => {
      const responseData = await societyService.getById(_id);
      if (responseData && responseData.data) {
        setData(responseData.data);
      }
    };
    fetchSociety();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data) && data?.supervisor) {
      setDetails(data?.supervisor);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      <Box h="20px" mb={[4, 4]}>
        <EditButtons _id={data?._id} />
      </Box>

      <Box>
        {!details ? (
          <Heading as="h4" size="md">
            Society is not associated with any Supervisor
          </Heading>
        ) : (
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Th>Supervisor Username</Th>
                <Td>{details?.username}</Td>
              </Tr>
              <Tr>
                <Th>Supervisor Name</Th>
                <Td>
                  {getFullname(
                    details?.firstname,
                    details?.middlename,
                    details?.lastname
                  )}
                </Td>
              </Tr>
              <Tr>
                <Th>Supervisor Mobile</Th>
                <Td>{details?.mobile}</Td>
              </Tr>
              <Tr>
                <Th>Supervisor E-Mail</Th>
                <Td>{details?.email ? details?.email : '-'}</Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Box>
    </VStack>
  );
};

// Exports

const ShowSupervisorDetails = {
  routeProps: {
    path: '/societies/details/supervisor/:_id',
    component: ShowSupervisorDetailsPage,
    exact: true,
  },
  name: 'Supervisor Details',
  title: 'Supervisor Details',
};

export default ShowSupervisorDetails;
