import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Flex,
  Box,
  Text,
  VStack,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import userService from 'services/userService';
import roleService from 'services/roleService';

import SelectField from 'components/form/SelectField';

import updateRoleSchema from './updateRoleSchema';

export default function UpdateRole({ data }) {
  const fetchIdRef = useRef(0);
  const apiError = useSelector(state => state.error);
  const [roles, setRoles] = useState([]);

  const defaultValues = {
    role: data?.role,
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  // Get User Roles
  const fetchRoles = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const query = {};
      const resData = await roleService.getAll(query);
      if (resData) {
        setRoles(resData?.data);
      }
    }
  }, []);

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const roles_send = [];
      formData?.role.forEach(role => {
        roles_send.push(role.role);
      });

      const responseData = await userService.updateRole(data._id, {
        role: roles_send,
      });

      if (responseData) {
        toast.success(`${responseData.message}`);
        defaultValues.role = roles_send;
      }
    }
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(updateRoleSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    control,
    watch,
  } = useForm(formOptions);

  const watchRole = watch('role', null);

  useEffect(() => {
    const selectedRoles = [];
    defaultValues?.role?.forEach(r => {
      selectedRoles.push(roles.find(option => option?.role === r));
    });
    setValue('role', selectedRoles);
    // eslint-disable-next-line
  }, [roles]);

  const handleRoleChange = (selected, action) => {
    if (action === 'clear') {
      setValue('role', null);
      return;
    }
    return setValue('role', selected);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        maxW={'350px'}
        minW={'350px'}
        w={'full'}
        h={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        p={4}
      >
        <Flex
          direction={['column', 'column']}
          justifyContent="space-between"
          h="full"
        >
          <Stack
            textAlign={'center'}
            p={6}
            color={useColorModeValue('gray.800', 'white')}
            align={'center'}
          >
            <Text
              fontSize={'sm'}
              fontWeight={500}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              px={3}
              color={'blue.500'}
              rounded={'full'}
            >
              USER ROLES
            </Text>
          </Stack>
          <VStack spacing={6} align="stretch" h="full" w="full">
            <Box>
              <SelectField
                isMulti={true}
                isClearable={true}
                name="role"
                label="Roles"
                labelKey="role"
                valueKey="role"
                options={roles}
                placeholder="Select Role"
                closeMenuOnSelect={false}
                size="sm"
                getOptionLabel={option => `${option.role}`}
                getOptionValue={option => option?.role}
                handleChange={handleRoleChange}
                error={errors.role}
                control={control}
                defaultValue={watchRole}
              />
            </Box>
          </VStack>
          <Box>
            <Button
              type="submit"
              mt={10}
              w={'full'}
              bg={'orange.500'}
              color={'white'}
              rounded={'xl'}
              boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              UPDATE ROLE
            </Button>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
