import moment from 'helpers/moment';

const renderDateTime = cell => {
  let value = cell.value;
  if (!value) {
    value = '-';
  } else {
    value = moment(cell?.value).format(moment.defaultDateTimeFormat);
  }

  return <div style={{ minWidth: '100px' }}>{value}</div>;
};

export default renderDateTime;
