import _ from 'lodash';
import moment from './moment';

const getQueryString = queryObj => {
  let queryString = '';
  let sort;
  let pagination = true;

  if (queryObj?.pagination === false) {
    pagination = false;
  }

  if (pagination) {
    if (queryObj.page) {
      queryString = `${queryString}page=${queryObj.page}`;
    }
    if (queryObj.limit) {
      queryString = `${queryString}&limit=${queryObj.limit}`;
    }
  } else {
    queryString = `${queryString}pagination=${pagination}`;
  }

  if (queryObj.sortby) {
    const sortArray = queryObj.sortby.split('.');

    if (sortArray.length > 1) {
      sort = sortArray[1];
    } else {
      sort = sortArray[0];
    }
  }

  if (queryObj.sortby && queryObj.sortorder === 'desc') {
    sort = `-${sort}`;
  }

  if (queryObj.sortby) {
    queryString = `${queryString}&sort=${sort}`;
  }

  if (queryObj.fields) {
    queryString = `${queryString}&fields=${queryObj.fields}`;
  }

  if (queryObj.filters && !_.isEmpty(queryObj.filters)) {
    let filterQry = '';
    const filt = queryObj.filters;

    for (const key in filt) {
      if (filt[key] !== undefined) {
        if (filt[key] !== '') {
          // Check if object
          if (typeof filt[key] === 'object') {
            if (filt[key] && filt[key]['from'] && filt[key]['to']) {
              const from = moment(filt[key]['from']).format(
                moment.defaultDateDb
              );
              const to = moment(filt[key]['to']).format(moment.defaultDateDb);
              filterQry = `${filterQry}&${key}[gte]=${from}&${key}[lte]=${to}`;
            }
          }
          // is boolean
          else if (filt[key] === true || filt[key] === false) {
            filterQry = `${filterQry}&${key}=${filt[key]}`;
          }
          // Not object and Check Wildcard Searches
          else if (filt[key].toString().includes('*')) {
            filterQry = `${filterQry}&${key}[like]=${filt[key]}`;
          } else {
            filterQry = `${filterQry}&${key}=${filt[key]}`;
          }
        }
      }
    }
    queryString = `${queryString}${filterQry}`;
  }

  return queryString;
};

export default getQueryString;
