import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import renderDate from 'components/tables/helpers/renderDate';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderMilkFileUploadStatus from 'components/tables/helpers/renderMilkFileUploadStatus';
import renderStatus from 'components/tables/helpers/renderStatus';

import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'proc_date',
    accessor: 'proc_date',
    Header: 'Proc Date',
    type: 'date',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDate(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.date.maxWidth,
    minWidth: tblConsts.date.minWidth,
  },
  {
    id: 'shift',
    accessor: 'shift',
    Header: 'Shift',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'plant_code',
    accessor: 'plant_code',
    Header: 'Code',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'plant_name',
    accessor: 'plant_name',
    Header: 'Name',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },
  {
    id: 'proc_type',
    accessor: 'proc_type',
    Header: 'Proc Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'filename',
    accessor: 'filename',
    Header: 'Filename',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    Cell: cell => <div style={{ minWidth: '80px' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'progress',
    accessor: 'progress',
    Header: 'Status',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => renderMilkFileUploadStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'messages',
    accessor: 'messages',
    Header: 'Messages',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: cell => <div style={{ minWidth: '500px' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'is_finalized',
    accessor: 'is_finalized',
    Header: 'Finalized',
    type: 'boolean',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'username_create',
    accessor: 'username_create',
    Header: 'Uploaded By',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'username_update',
    accessor: 'username_update',
    Header: 'Last Updated By',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'fiscal_year',
    accessor: 'fiscal_year',
    Header: 'Fiscal Year',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: cell => <div style={{ minWidth: '50px' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'jobs',
    accessor: 'jobs',
    Header: 'Jobs',
    // type: 'string',
    hidden: true,
    exportable: false,
    disableHideColumn: true,
    disableSortBy: true,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'cemter',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
];

export default columns;
