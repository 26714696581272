import React from 'react';
import { Link } from 'react-router-dom';
import { Center, Box, Heading, Text, Button } from '@chakra-ui/react';

function _Error403() {
  return (
    <Center>
      <Box textAlign="center" py={10} px={6} h="100vh" w="100vw">
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, orange.400, orange.600)"
          backgroundClip="text"
        >
          403
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Unauthorized
        </Text>
        <Text color={'gray.500'} mb={6}>
          Your are not authorized to access this page or resource.
        </Text>

        <Button
          as={Link}
          to="/dashboard"
          colorScheme="orange"
          bgGradient="linear(to-r, orange.400, orange.500, orange.600)"
          color="white"
          variant="solid"
        >
          GO TO HOME
        </Button>
      </Box>
    </Center>
  );
}

const _403 = {
  routeProps: {
    path: '/unauthorized',
    component: _Error403,
    exact: true,
  },
  name: 'Unauthorised',
  title: 'Unauthorised',
};

export default _403;
