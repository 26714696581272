import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';
import { checkDatePeriod } from 'helpers/genHelpers';
import { SHIFT_OPTIONS } from 'configs/ShiftOptions';

import DateField from 'components/form/DateField';
import SelectField from 'components/form/SelectField';
import InputField from 'components/form/InputField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    proc_date_from: null,
    proc_date_to: null,
    shift: '',
    plant: '',
    society_code: null,
    amount: '',
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const watchPlant = watch('plant', '');
  const watchShift = watch('shift', null);

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'proc_date' && value) {
        const from = moment(value.from).format(moment.defaultDateDb);
        const to = moment(value.to).format(moment.defaultDateDb);
        setValue('proc_date_from', from);
        setValue('proc_date_to', to);
      } else if (key === 'shift') {
        value = _.find(SHIFT_OPTIONS, { value: value });
        setValue('shift', value);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [plants]);

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      reset(defaultValues);
    }
    handlePlantChange(null, 'clear');
    handleShiftChange(null, 'clear');
    setFilters({});
  };

  const onSubmit = formData => {
    for (const key in formData) {
      if (formData[key] === '') {
        delete formData[key];
      }
    }

    if (formData?.plant) {
      formData.plant_code = formData?.plant?.plant_code;
      delete formData['plant'];
    } else {
      delete formData.proc_date;
    }

    if (formData?.shift) {
      formData.shift = formData?.shift?.value;
    }

    if (formData.proc_date_from && formData.proc_date_to) {
      const valPeriod = checkDatePeriod(
        formData.proc_date_from,
        formData.proc_date_to,
        31
      );

      delete formData['proc_date_from'];
      delete formData['proc_date_to'];

      if (!valPeriod) {
        setError('proc_date', {
          type: 'manual',
          message: `Invalid Date range or Date period exceeded`,
        });
        return;
      }

      formData.proc_date = valPeriod;
    }

    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant', null);
      return;
    }
    setValue('plant', selected);
  };

  const handleShiftChange = (selected, action) => {
    if (action === 'clear') {
      setValue('shift', null);
      return;
    }
    return setValue('shift', selected);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}
        <Box flex="1">
          <Box border="1px" borderColor="gray.200" p={2} mb={4}>
            <DateField
              name="proc_date_from"
              label="Proc Date From"
              error={errors.proc_date}
              register={register('proc_date_from')}
            />
            <DateField
              name="proc_date_to"
              label="Proc Date To"
              error={errors.proc_date}
              register={register('proc_date_to')}
            />
          </Box>

          <SelectField
            isMulti={false}
            isClearable={true}
            name="shift"
            label="Shift"
            labelKey="name"
            valueKey="value"
            options={SHIFT_OPTIONS}
            placeholder="Select Shift"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => option?.value}
            handleChange={handleShiftChange}
            error={errors.shift}
            control={control}
            defaultValue={watchShift}
          />

          <InputField
            type="text"
            name="type"
            id="type"
            label="Adulteration Type"
            placeholder="Adulteration Type"
            error={errors.type}
            register={register('type')}
          />

          <SelectField
            isMulti={false}
            isClearable={true}
            name="plant"
            label="Plant"
            labelKey="plant_code"
            valueKey="_id"
            options={plants}
            placeholder="Select Plant"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option =>
              `${option.plant_code} - ${option.plant_name}`
            }
            getOptionValue={option => option?._id}
            handleChange={handlePlantChange}
            error={errors.plant_code}
            control={control}
            defaultValue={watchPlant}
          />
        </Box>

        <InputField
          type="text"
          name="society_code"
          id="society_code"
          label="Society Code"
          placeholder="Society Code"
          error={errors.society_code}
          register={register('society_code')}
        />

        <InputField
          type="number"
          step="0.01"
          name="amount"
          id="amount"
          label="Amount"
          placeholder="Amount"
          error={errors.amount}
          register={register('amount')}
        />
        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
