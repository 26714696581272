const messages = {
  code: {
    required: 'Code is required',
    invalid: 'Code has invalid value',
  },
  name: {
    required: 'Material Name is required',
    invalid: 'Material Name has invalid value',
  },
  description: {
    required: 'Description is required',
    invalid: 'Description has invalid value',
  },
  catagory: {
    required: 'Catagory is required',
    invalid: 'Catagory has invalid value',
  },
  unit_in: {
    required: 'Unit (IN) is required',
    invalid: 'Unit (IN) has invalid value',
  },
  unit_out: {
    required: 'Unit (OUT) is required',
    invalid: 'Unit (OUT) has invalid value',
  },
  conversion: {
    required: 'Converion Factor is required',
    invalid: 'Converion Factor has invalid value',
  },
  consuption_price: {
    required: 'Consumption Price is required',
    invalid: 'Consumption Price has invalid value',
  },
  sales_price: {
    required: 'Sales Price is required',
    invalid: 'Sales Price has invalid value',
  },
  tax_type: {
    required: 'Tax Type is required',
    invalid: 'Tax Type has invalid value',
  },
  sgst: {
    required: 'SGST is required',
    invalid: 'SGST has invalid value',
  },
  cgst: {
    required: 'CGST is required',
    invalid: 'CGST has invalid value',
  },
  igst: {
    required: 'IGST is required',
    invalid: 'IGST has invalid value',
  },
  tradable: {
    required: 'Tradable is required',
    invalid: 'Tradable has invalid value',
  },
  consumable: {
    required: 'Consumable is required',
    invalid: 'Consumable has invalid value',
  },
  is_active: {
    required: 'Status is required',
    invalid: 'Status has invalid value',
  },
  is_discontinued: {
    required: 'Discontinued is required',
    invalid: 'Discontinued has invalid value',
  },
};

export default messages;
