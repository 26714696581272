import React, { useEffect, useState, useRef, useCallback } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import roleService from 'services/roleService';

import filterSchema from './filterSchema';

import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  const [roles, setRoles] = useState([]);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  // Get User Roles
  const fetchRoles = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const query = {};
      const resData = await roleService.getAll(query);
      if (resData) {
        setRoles(resData?.data);
      }
    }
  }, []);

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const defaultValues = {
    username: '',
    mobile: '',
    email: '',
    plant: '',
    is_active: '',
    role: '',
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const watchPlant = watch('plant', '');
  const watchRole = watch('role', '');

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'plant_id') {
        value = _.find(plants, { _id: value });
        setValue('plant', value);
      } else if (key === 'role') {
        value = _.find(roles, { _id: value });
        setValue('role', value);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [plants, roles]);

  const handleClear = () => {
    handlePlantChange(null, 'clear');
    handleRoleChange(null, 'clear');
    if (!_.isEmpty(defaultFilters)) {
      reset(defaultValues);
      setValue('role', '');
      setFilters({});
    }
  };

  const onSubmit = formData => {
    if (formData?.plant) {
      formData.plant_id = formData?.plant?._id;
      delete formData['plant'];
    }

    if (formData?.role) {
      formData.role = formData?.role?._id;
    }

    for (const key in formData) {
      if (formData[key] === '') {
        delete formData[key];
      }
    }

    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  const handleRoleChange = (selected, action) => {
    if (action === 'clear') {
      setValue('role', null);
      return;
    }
    setValue('role', selected);
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant', null);
      return;
    }
    setValue('plant', selected);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}

        <Box flex="1">
          <InputField
            type="text"
            name="username"
            id="username"
            label="Username"
            placeholder="Username"
            error={errors.username}
            register={register('username')}
          />
          <InputField
            type="text"
            name="mobile"
            id="mobile"
            label="Mobile"
            placeholder="Mobile"
            error={errors.mobile}
            register={register('mobile')}
          />

          <InputField
            type="text"
            name="email"
            id="email"
            label="E-Mail"
            placeholder="E-Mail"
            error={errors.email}
            register={register('email')}
          />

          <SelectField
            isMulti={false}
            isClearable={true}
            name="role"
            label="Role"
            labelKey="role"
            valueKey="role"
            options={roles}
            placeholder="Select Role"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option => `${option['role']}`}
            getOptionValue={option => option?.role}
            handleChange={handleRoleChange}
            error={errors.role}
            control={control}
            defaultValue={watchRole}
          />

          <SelectField
            isMulti={false}
            isClearable={true}
            name="plant"
            label="Plant"
            labelKey="plant_code"
            valueKey="_id"
            options={plants}
            placeholder="Select Plant"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option =>
              `${option.plant_code} - ${option.plant_name}`
            }
            getOptionValue={option => option?._id}
            handleChange={handlePlantChange}
            error={errors.plant_id}
            control={control}
            defaultValue={watchPlant}
          />
        </Box>
        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
