import React from 'react';
import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import TableActionColumn from 'components/tables/TableActionColumn';
import { useHistory } from 'react-router';
import { FaSkullCrossbones } from 'react-icons/fa';

const hasDelete = ['superadmin', 'admin', 'plantincharge'];
const hasCustom = ['superadmin'];

export default function ActionColumn({ row }) {
  const history = useHistory();

  const deleteRecord = () => {
    if (row?.approval_status === 'P') {
      history.push({
        pathname: `/milktransactionedits/delete/${row._id}`,
        state: row,
      });
    } else {
      toast.info(`Can not delete the Entry as Already Approved or Rejected.`);
    }
  };

  const customAction = () => {
    history.push({
      pathname: `/milktransactionedits/force/delete/${row._id}`,
      state: row,
    });
  };

  const customButton = row?.approval_status !== 'P' && (
    <Button type="button" size="xs" colorScheme="red" onClick={customAction}>
      <FaSkullCrossbones className="text-white" />
    </Button>
  );

  return (
    <TableActionColumn
      row={row}
      hasDelete={hasDelete}
      handleDelete={deleteRecord}
      hasCustom={hasCustom}
      customComponent={customButton}
    />
  );
}
