import _CONSTATNTS from 'constants/_Constants';

const initialState = {};

const errorReducer = (state = initialState, action) => {
  const { error } = action;

  switch (action.type) {
    case _CONSTATNTS.SET_ERROR:
      return {
        code: error.code,
        status: error.status,
        isOperational: error.isOperational,
        message: error.message,
        errors: error.errors,
      };
    case _CONSTATNTS.HIDE_ERROR:
      return {};
    default:
      return state;
  }
};

export default errorReducer;
