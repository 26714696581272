const fields = {
  names: ['email'],
  email: {
    default: '',
    disabled: false,
    readonly: false,
  },
};

export default fields;
