import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import editSchema from './editSchema';
import fields from 'modules/FiscalYears/fields';
import milkAdulterationTypeService from 'services/milkAdulterationTypeService';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';
import SwitchField from 'components/form/SwitchField';

const EditPage = () => {
  const FormTitle = 'EDIT ADULTRATION TYPE';
  const IndexPageTitle = 'ADULTRATION TYPES';
  const IndexPagePath = '/milkadulterationtypes';

  const defaultValues = {
    note: fields?.note?.default,
    is_active: fields?.is_active?.default,
    amount: fields?.amount?.default,
  };

  const apiError = useSelector(state => state.error);
  const [data, setData] = useState(null);

  const fetchIdRef = useRef(0);

  const location = useLocation();

  useEffect(() => {
    setData(location?.state);
  }, [location]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(editSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    watch,
    setValue,
  } = useForm(formOptions);

  const watchIsActive = watch('is_active', false);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        note: formData?.note,
        is_active: formData?.is_active,
        amount: formData?.amount,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (value === null || value === '' || value === undefined) {
          delete formDataSend[key];
        }
      }

      const responseData = await milkAdulterationTypeService.update(
        data?._id,
        formDataSend
      );

      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="type"
                    id="type"
                    label="Adultration Type"
                    placeholder="Adultration Type"
                    error={errors.type}
                    register={register('type')}
                    disabled={true}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.01"
                    name="amount"
                    id="amount"
                    label="Amount"
                    placeholder="Amount"
                    error={errors.amount}
                    register={register('amount')}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="is_active"
                    id="is_active"
                    label="Status"
                    error={errors.is_active}
                    register={register('is_active')}
                    defaultChecked={watchIsActive}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="note"
                    id="note"
                    label="Note"
                    placeholder="Description For Adulteration Penelty Type"
                    error={errors.note}
                    register={register('note')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MilkAdulterationTypeEdit = {
  routeProps: {
    path: '/milkadulterationtypes/edit/:_id',
    component: EditPage,
    exact: true,
  },
  name: 'Edit Adultration Type',
  title: 'Edit Adultration Type',
};

export default MilkAdulterationTypeEdit;
