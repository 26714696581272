import moment from 'helpers/moment';

export const getMasked = mobile => {
  if (!mobile) return '';
  mobile = mobile.toString();
  const splited = mobile.split('');
  const unMaksIndex = [0, 7, 8, 9];
  // eslint-disable-next-line
  for (const [i, v] of splited.entries()) {
    if (!unMaksIndex.includes(i)) splited[i] = 'X';
  }
  const result = splited.join('');
  return result;
};

export const getFullname = (firstname, middlename, lastname) => {
  let fullname = '';
  if (!firstname || !lastname) return '-';

  if (firstname && lastname && !middlename) {
    fullname = `${firstname} ${lastname}`;
  } else if (firstname && lastname && middlename) {
    fullname = `${firstname} ${middlename} ${lastname}`;
  } else if (firstname && !lastname) {
    fullname = `${firstname}`;
  } else if (!firstname && lastname) {
    fullname = `${lastname}`;
  }
  return fullname.toUpperCase();
};

export const checkDatePeriod = (from, to, diff = 0) => {
  if (!from || !to || diff < 0) return false;

  const fromObj = moment(from);
  const toObj = moment(to);

  if (!fromObj.isValid() || !toObj.isValid()) return false;

  const isBefore = toObj.isBefore(fromObj);

  if (isBefore) return false;

  const periodDays = toObj.diff(fromObj, 'days');

  if (periodDays > diff) return false;

  return {
    from: from,
    to: to,
  };
};

export const toIndianFormat = number => {
  if (!number) return '0';
  if (typeof number === 'string') number = parseFloat(number);

  return number.toFixed(2).toLocaleString('en-IN');
};

export const stringTruncate = function (str, length) {
  let dots = str.length > length ? '...' : '';
  return str.substring(0, length) + dots;
};
