import React from 'react';
import TableActionColumn from 'components/tables/TableActionColumn';
import { useHistory } from 'react-router';

const hasDelete = ['superadmin', 'admin', 'plantincharge'];

export default function ActionColumn({ row }) {
  const history = useHistory();

  const deleteRecord = () => {
    history.push({
      pathname: `/milkadulterations/delete/${row._id}`,
      state: row,
    });
  };

  return (
    <TableActionColumn
      row={row}
      hasDelete={hasDelete}
      handleDelete={deleteRecord}
    />
  );
}
