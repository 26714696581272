const messages = {
  mobile: {
    required: 'Mobile is required',
    invalid: 'Invalid Mobile number',
  },
  password_reset_otp: {
    required: 'Mobile OTP is required',
    invalid: 'Invalid Mobile OTP',
  },
  password: {
    required: 'Password is required',
    min: 'Password length too short',
    max: 'Password length exceeded',
    match: 'Password and confirm password do not match',
  },
  password_confirmation: {
    required: 'Confirm Password is required',
    min: 'Confirm Password length too short',
    max: 'Confirm Password length exceeded',
    match: 'Password and confirm password do not match',
  },
};
export default messages;
