import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { SHIFT_OPTIONS } from 'configs/ShiftOptions';

import addSchema from './addSchema';
import fields from './fields';
import milkAdulterationService from 'services/milkAdulterationService';
import milkAdulterationTypeService from 'services/milkAdulterationTypeService';
import societyService from 'services/societyService';
import DateField from 'components/form/DateField';
import SelectField from 'components/form/SelectField';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';
import AsyncSelectField from 'components/form/AsyncSelectField';
import RenderSocietyOption from 'components/form/RenderSocietyOption';

const AddPage = () => {
  const FormTitle = 'ADD ADULTERATION PENALTY';
  const IndexPageTitle = 'ADULTERATION PENALTIES';
  const IndexPagePath = '/milkadulterations';

  const defaultValues = {
    type: fields?.type?.default,
    proc_date: fields?.proc_date?.default,
    shift: fields?.shift?.default,
    society_id: fields?.society_id?.default,
    cans: fields?.cans?.default,
    weight_kg: fields?.weight_kg?.default,
    note: fields?.note?.default,
    sample_no: fields?.sample_no?.default,

    //
    plant_code: null,
    plant_name: null,
    amount: 0,
    total_amount: 0,
    city: null,
  };

  const apiError = useSelector(state => state.error);
  const [types, setTypes] = useState([]);
  const [societies, setSocieties] = useState([]);

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);
  const fetchIdTypeyRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    handleSocietyChange('clear', null);
    handleShiftChange('clear', null);
    handleTypeChange('clear', null);
  };

  const watchShift = watch('shift', null);
  const watchSociety = watch('society_id', null);
  const watchType = watch('type', null);
  const watchCans = watch('cans', 0);

  const onSubmit = async formData => {
    const formDataSend = {
      type: formData?.type?.type,
      proc_date: formData?.proc_date,
      shift: formData?.shift?.value,
      society_id: formData?.society_id?._id,
      cans: formData?.cans,
      weight_kg: formData?.weight_kg,
      note: formData?.note,
      sample_no: formData?.sample_no,
    };

    if (formDataSend.sample_no === '') {
      formDataSend.sample_no = null;
    }

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const responseData = await milkAdulterationService.create(formDataSend);

      if (responseData) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  const fetchMilkadultartionTypes = async () => {
    const fetchIdType = ++fetchIdTypeyRef.current;

    if (fetchIdType === fetchIdTypeyRef.current) {
      const query = { is_active: true };
      query.pagination = false;
      query.sortby = 'type';
      const responseData = await milkAdulterationTypeService.getAll(query);

      if (responseData) {
        setTypes(responseData?.data);
      }
    }
  };

  const fetchSocieties = async () => {
    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      const query = { is_active: true };
      query.pagination = false;
      query.sortby = 'society_code';
      query.fields =
        '_id,society_code,society_name,is_active,plant_id, city, district,supervisor';
      const responseData = await societyService.getAll(query);

      if (responseData) {
        setSocieties(responseData?.data);
      }
    }
  };

  const filterValues = (inputValue = '') => {
    if (inputValue.startsWith('*')) {
      return societies.filter(i =>
        i?.society_name
          ?.toLowerCase()
          ?.startsWith(inputValue.toLowerCase().slice(1))
      );
    } else {
      return societies.filter(i =>
        i?.society_code?.toString()?.startsWith(inputValue)
      );
    }
  };

  useEffect(() => {
    fetchSocieties();
    fetchMilkadultartionTypes();
  }, []);

  const handleSocietyChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society_id', null);
      setValue('plant_code', '');
      setValue('plant_code', '');
      setValue('city', '');
      return;
    }
    setValue('society_id', selected);
    setValue('plant_code', selected?.plant_id?.plant_code);
    setValue('plant_name', selected?.plant_id?.plant_name);
    setValue('city', selected?.city);
  };

  const handleShiftChange = (selected, action) => {
    if (action === 'clear') {
      setValue('shift', null);
      return;
    }
    return setValue('shift', selected);
  };

  const handleTypeChange = (selected, action) => {
    if (action === 'clear') {
      setValue('type', null);
      setValue('amount', 0);
      return;
    }
    setValue('type', selected);
    setValue('amount', selected?.amount);
    return;
  };

  useEffect(() => {
    const newCans = watchCans ? watchCans * 1 : 0;
    const rate = watchType?.amount ? watchType?.amount * 1 : 0;
    const totalAmount = newCans * rate;
    setValue('total_amount', totalAmount);
    // eslint-disable-next-line
  }, [watchCans, watchType]);

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Flex direction={['column', 'row']} justifyContent="space-between">
            <Text fontSize="xl" color="orange.500" fontWeight="bold">
              {FormTitle}
            </Text>
          </Flex>
          <Flex direction={['column', 'row']} justifyContent="space-between">
            <Button
              as={Link}
              to={IndexPagePath}
              type="button"
              colorScheme="orange"
              size="sm"
              px="8"
              mt={[2, 0]}
              mr={[0, 2]}
            >
              {IndexPageTitle}
            </Button>
          </Flex>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[12, 0]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <AsyncSelectField
                    isMulti={false}
                    isClearable={true}
                    name="society_id"
                    label="Society"
                    labelKey="society_code"
                    valueKey="_id"
                    options={societies}
                    placeholder="Select Society"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => (
                      <RenderSocietyOption
                        society={option}
                        showCity={true}
                        showDistrict={true}
                        showPlant={true}
                        showSupervisor={true}
                      />
                    )}
                    getOptionValue={option => option?._id}
                    handleChange={handleSocietyChange}
                    error={errors.society}
                    control={control}
                    defaultValue={watchSociety}
                    filterValues={filterValues}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="city"
                    id="city"
                    label="City"
                    placeholder="City"
                    error={errors.city}
                    register={register('city')}
                    readonly={true}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_code"
                    id="plant_code"
                    label="Plant Code"
                    placeholder="Plant Code"
                    error={errors.plant_code}
                    register={register('plant_code')}
                    readonly={true}
                  />
                </Box>
                <Box w={['full', 2 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_name"
                    id="plant_name"
                    label="Plant Name"
                    placeholder="Plant Name"
                    error={errors.plant_name}
                    register={register('plant_name')}
                    readonly={true}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="Adulteration Type"
                    label="Adulteration Type"
                    labelKey="type"
                    valueKey="type"
                    options={types}
                    placeholder="Select Adultration Type"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option?.type}
                    getOptionValue={option => option?.type}
                    handleChange={handleTypeChange}
                    error={errors.type}
                    control={control}
                    defaultValue={watchType}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="amount"
                    id="amount"
                    label="Penalty Rate"
                    placeholder="Penalty Rate"
                    error={errors.amount}
                    register={register('amount')}
                    readonly={true}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="sample_no"
                    id="sample_no"
                    label="Sample No"
                    placeholder="Sample No"
                    error={errors.sample_no}
                    register={register('sample_no')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="proc_date"
                    label="Proc Date"
                    placeholder="DD-MM-YYYY"
                    error={errors.proc_date}
                    register={register('proc_date')}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="Shift"
                    label="Shift"
                    labelKey="name"
                    valueKey="value"
                    options={SHIFT_OPTIONS}
                    placeholder="Select Shift"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleShiftChange}
                    error={errors.shift}
                    control={control}
                    defaultValue={watchShift}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    name="cans"
                    id="cans"
                    label="Cans"
                    placeholder="Cans"
                    error={errors.cans}
                    register={register('cans')}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.1"
                    name="weight_kg"
                    id="weight_kg"
                    label="Weight (KG)"
                    placeholder="Weight (KG)"
                    error={errors.weight_kg}
                    register={register('weight_kg')}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="total_amount"
                    id="total_amount"
                    label="Total Penalty Amount"
                    placeholder="Total Penalty Amount"
                    error={errors.amount}
                    register={register('total_amount')}
                    readonly={true}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="note"
                    id="note"
                    label="Note"
                    placeholder="Write Your Remark For The Edit Entry"
                    error={errors.note}
                    register={register('note')}
                  />
                </Box>
              </Flex>

              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MilkAdulterationAdd = {
  routeProps: {
    path: '/milkadulterations/add',
    component: AddPage,
    exact: true,
  },
  name: 'Milk Adulteration Add',
  title: 'Milk Adulteration Add',
};

export default MilkAdulterationAdd;
