const messages = {
  username: {
    required: 'Username is required',
    invalid: 'Invalid Username',
  },
  mobile: {
    required: 'Mobile is required',
    invalid: 'Invalid Mobile number',
  },
  email: {
    required: 'E-Mail is required',
    invalid: 'Invalid E-Mail',
  },
  password: {
    required: 'Password is required',
    same: 'Password and Confirm Password must be same',
    complexity: 'Password do not match complexity requirements',
  },
  password_confirmation: {
    required: 'Confirm Password is required',
    match: 'Password and Confirm Password do not match',
  },
  role: {
    required: 'Roles is required',
    invalid: 'Invalid Roles',
  },
  current_password: {
    required: 'Current Password is required',
  },
  firstname: {
    required: 'Firstname is required',
    min: 'Firstname is too short',
    max: 'Firstname length exceeded',
  },
  middlename: {
    required: 'Middlename is required',
    min: 'Middlename is too short',
    max: 'Middlename length exceeded',
  },
  lastname: {
    required: 'Last is required',
    min: 'Last is too short',
    max: 'Last length exceeded',
  },
  employee_code: {
    required: 'Employee Code is required',
    invalid: 'Invalid Employee Code',
  },
};

export default messages;
