import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { FaSync } from 'react-icons/fa';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import jobService from 'services/jobService';
import columns from './columns';
import renderCron from 'components/tables/helpers/renderCron';

// Table
import TableComp from 'components/tables/TableComp';
import Filters from './Filters';

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'createdAt',
      desc: true,
    },
  ];

  const tblColumns = useMemo(() => columns, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [filters, setFilters] = useState({});
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        filters.type = 'normal';
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await jobService.getAll(query);
        if (resData) {
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  const refreshData = async () => {
    await fetchData({
      pageSize: PAGINATION_OPTIONS.limit,
      pageIndex: 0,
      sortBy: defaultSortBy,
    });
  };

  const handleOnExport = data => {
    const newData = [];

    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const newD = { ...d };
      if (d.repeatInterval) {
        newD.repeatInterval = renderCron({ value: d.repeatInterval });
      }
      newData.push(newD);
    }
    return newData;
  };

  const TableHeader = (
    <Flex direction={['column', 'row']} justifyContent="space-between">
      <Text mr={[4, 4]}>JOBS</Text>
      <Button
        type="button"
        variant="outline"
        colorScheme="orange"
        size="sm"
        onClick={refreshData}
      >
        <FaSync />
      </Button>
    </Flex>
  );

  return (
    <Box display={{ md: 'flex' }} w="full" h="full" mb="4">
      <TableComp
        columns={tblColumns}
        data={data}
        getRowId={row => row._id}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        defaultSortBy={defaultSortBy}
        //
        tblHeader={TableHeader}
        showColumnHide={true}
        isExpandable={true}
        enableExport={true}
        exportFilename={`Jobs`}
        onExport={handleOnExport}
        filters={filters}
        filterForm={
          <Filters
            onFilter={onFilterChange}
            defaultFilters={filters}
            setFilters={setFilters}
          />
        }
      />
    </Box>
  );
};

// Exports

const Jobs = {
  routeProps: {
    path: '/maintenance/jobs',
    component: IndexPage,
    exact: true,
  },
  name: 'Jobs',
  title: 'Jobs',
};

export default Jobs;
