import React, { useState, useMemo, useCallback, useRef } from 'react';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import materialService from 'services/materialService';
import columns from './columns';
import Filters from './Filters';

// Table
import TableComp from 'components/tables/TableComp';

const allowAdd = ['superadmin'];

const TableHeader = 'MATERIALS';

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  const tblColumns = useMemo(() => columns, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [filters, setFilters] = useState({});

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await materialService.getAll(query);
        if (resData) {
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  return (
    <TableComp
      columns={tblColumns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      resultCount={resultCount}
      defaultSortBy={defaultSortBy}
      //
      tblHeader={TableHeader}
      showColumnHide={true}
      isExpandable={true}
      // ExpandedComponent={ExpandRow}
      addLink="/materials/add"
      allowAdd={allowAdd}
      enableExport={true}
      exportFilename={`Materials`}
      filters={filters}
      filterForm={
        <Filters
          onFilter={onFilterChange}
          defaultFilters={filters}
          setFilters={setFilters}
        />
      }
    />
  );
};

// Exports

const Materials = {
  routeProps: {
    path: '/materials',
    component: IndexPage,
    exact: true,
  },
  name: 'Materials',
  title: 'Materials',
};

export default Materials;
