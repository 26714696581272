import React from 'react';
import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import TableActionColumn from 'components/tables/TableActionColumn';

const hasEdit = ['superadmin', 'storeadmin'];
const hasCustom = ['superadmin'];

export default function ActionColumn({ row }) {
  const history = useHistory();

  const customAction = () => {
    history.push({
      pathname: `/materials/discontinue/${row._id}`,
      state: row,
    });
  };

  const customButton = row?.approval_status !== 'P' && (
    <Button type="button" size="xs" colorScheme="red" onClick={customAction}>
      DISCON
    </Button>
  );

  return (
    <TableActionColumn
      row={row}
      hasEdit={hasEdit}
      editLink={`/materials/edit/${row._id}`}
      hasCustom={hasCustom}
      customComponent={customButton}
    />
  );
}
