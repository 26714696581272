import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  city: yup
    .string()
    .required(messages.city.required)
    .max(50, messages.city.max)
    .trim(),
  gu_city: yup
    .string()
    .required(messages.city.required)
    .max(100, messages.gu_city.max),
});

export default addSchema;
