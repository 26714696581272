const messages = {
  society: {
    required: 'Please select a society',
    invalid: 'Invalid society',
  },
  ifsc: {
    required: 'Please provide IFSC Code',
    invalid: 'Invalid IFSC Code',
  },
  account_number: {
    required: 'Please provide account number',
    invalid: 'Invalid account number',
  },
};

export default messages;
