import React, { useEffect, useRef } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import PROC_TYPES from 'configs/procTypes';

import addSchema from './addSchema';
import fields from 'modules/Plants/fields';
import plantService from 'services/plantService';
import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';
import SwitchField from 'components/form/SwitchField';

const AddPage = () => {
  const FormTitle = 'ADD PLANT';
  const IndexPageTitle = 'PLANTS';
  const IndexPagePath = '/plants';

  const defaultValues = {
    plant_code: fields?.plant_code?.default,
    plant_name: fields?.plant_name?.default,
    is_active: fields?.is_active?.default,
    proc_type: fields?.proc_type?.default,
    city: fields?.city?.default,
    gu_plant_name: fields?.gu_plant_name?.default,
    gu_city: fields?.gu_city?.default,
  };

  const apiError = useSelector(state => state.error);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    handleProcTypeChange('clear', null);
  };

  const watchProcType = watch('proc_type', null);
  const watchIsActive = watch('is_active', false);

  const handleProcTypeChange = (selected, action) => {
    if (action === 'clear') {
      setValue('proc_type', null);
      return;
    }
    return setValue('proc_type', selected);
  };

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        plant_code: formData.plant_code,
        plant_name: formData.plant_name,
        city: formData.city,
        proc_type: formData.proc_type,
        is_active: formData.is_active,
        gu_plant_name: formData.gu_plant_name,
        gu_city: formData.gu_city,
      };

      if (formData?.proc_type) {
        formDataSend.proc_type = formData.proc_type.value;
      }

      const responseData = await plantService.create(formDataSend);

      if (responseData) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_code"
                    id="plant_code"
                    label="Code"
                    placeholder="Code"
                    error={errors.plant_code}
                    register={register('plant_code')}
                  />
                </Box>
                <Box w={['full', 2 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_name"
                    id="plant_name"
                    label="Name"
                    placeholder="Name"
                    error={errors.plant_name}
                    register={register('plant_name')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="city"
                    id="city"
                    label="city"
                    placeholder="city"
                    error={errors.city}
                    register={register('city')}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="proc_type"
                    label="Procurement Type"
                    labelKey="proc_type"
                    valueKey="proc_type"
                    options={PROC_TYPES}
                    placeholder="Select Procurement Type"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.label}`}
                    getOptionValue={option => option?.value}
                    handleChange={handleProcTypeChange}
                    error={errors.proc_type}
                    control={control}
                    defaultValue={watchProcType}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="is_active"
                    id="is_active"
                    label="Status"
                    error={errors.is_active}
                    register={register('is_active')}
                    defaultChecked={watchIsActive}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_city"
                    id="gu_city"
                    label="ગામ"
                    placeholder="ગામ"
                    error={errors.gu_city}
                    register={register('gu_city')}
                  />
                </Box>
                <Box w={['full', 2 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_plant_name"
                    id="gu_plant_name"
                    label="પ્લાન્ટ નું નામ"
                    placeholder="પ્લાન્ટ નું નામ"
                    error={errors.gu_plant_name}
                    register={register('gu_plant_name')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const PlantAdd = {
  routeProps: {
    path: '/plants/add',
    component: AddPage,
    exact: true,
  },
  name: 'Add Plant',
  title: 'Add Plant',
};

export default PlantAdd;
