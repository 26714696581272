import React from 'react';
import styles from './styles';
import PrintButton from './PrintButton';

const CONTACT_DETAILS = {
  phone: '+1 (123) 456-7890',
  email: 'purchase@sudamadairy.com',
  pan: 'AABCD1234E',
  gst: '24asdad1234ABCD',
  stateCode: '24',
};

export default function DocHeader({ docTYpe }) {
  return (
    <>
      {docTYpe && <PrintButton />}
      <div style={styles.companyDetails}>
        <div style={styles.imageDiv}>
          <img alt="logo" src="/logo192.png" style={styles.logoImage} />
        </div>

        <div style={styles.companyDiv}>
          <div style={styles.companyName}>PORBANDAR DCMPU LTD</div>
          <div style={styles.companyAddress}>
            <div>
              SUDAMA DAIRY, Plot No - 277, GIDC Udhyog Nagar, Porbandar - 360
              575, Gujarat, India
            </div>
            <br />
            <div>
              Ph: {CONTACT_DETAILS.phone} | E-Mail :{' '}
              <a href={`mailto:${CONTACT_DETAILS.email}`}>
                {CONTACT_DETAILS.email}
              </a>
            </div>
            <br />
            <div>
              PAN - {CONTACT_DETAILS.pan} | GST - {CONTACT_DETAILS.gst} | State
              Code - {CONTACT_DETAILS.stateCode}
            </div>
          </div>
        </div>
        <div style={styles.companyDummyDiv}></div>
      </div>
      <div style={styles.heading}>{docTYpe}</div>
    </>
  );
}
