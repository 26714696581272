import React, { useState, useEffect } from 'react';
import { Switch } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import jobService from 'services/jobService';

export default function JobScheduleStatus({ _id, status, type }) {
  const [buttonStatus, setButtonStatus] = useState(false);

  useEffect(() => {
    setButtonStatus(status);
  }, [status, type]);

  const enableJob = async () => {
    const resData = await jobService.enable(_id);
    if (resData) {
      setButtonStatus(false);
      toast.success(`${resData.message}`);
    }
  };

  const disableJob = async () => {
    const resData = await jobService.disable(_id);
    if (resData) {
      setButtonStatus(true);
      toast.success(`${resData.message}`);
    }
  };

  if (buttonStatus) {
    return (
      <Switch
        colorScheme="green"
        width="full"
        name="status"
        size={`sm`}
        defaultChecked={false}
        isChecked={false}
        onChange={enableJob}
      />
    );
  } else {
    return (
      <Switch
        colorScheme="green"
        width="full"
        name="status"
        size={`sm`}
        defaultChecked={true}
        isChecked={true}
        onChange={disableJob}
      />
    );
  }
}
