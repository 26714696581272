import { HStack, Tag } from '@chakra-ui/react';

const renderTags = cell => {
  if (cell.value && cell.value.length > 0) {
    return (
      <HStack spacing={1}>
        {cell.value.map(tag => (
          <Tag
            size={'sm'}
            key={tag}
            variant="outline"
            colorScheme="orange"
            borderRadius="full"
          >
            {tag}
          </Tag>
        ))}
      </HStack>
    );
  } else {
    return <span>-</span>;
  }
};

export default renderTags;
