import React, { useEffect } from 'react';
import { Link as RLink, useHistory } from 'react-router-dom';
import {
  Flex,
  Box,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import authActions from 'actions/api/authActions';

import InputField from 'components/form/InputField';
import schema from './schema';

function LoginPage() {
  let history = useHistory();
  const dispatch = useDispatch();

  const apiError = useSelector(state => state.error);
  const loggedIn = useSelector(state => state.auth?.loggedIn);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
  } = useForm(formOptions);

  function onSubmit(formData) {
    dispatch(authActions.login(formData));
  }

  useEffect(() => {
    if (loggedIn) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }

    // Redirect if force password change
    if (apiError && apiError.code === 307) {
      history.push('/force-password-change');
    }
    // eslint-disable-next-line
  }, [apiError]);

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={['4', '6']} mx={'auto'} maxW={['xs', 'lg']}>
        <Stack align={'center'} w={['xs', 'lg']}>
          <Heading
            fontSize={['2xl', '4xl']}
            color={useColorModeValue('orange.500', 'orange.400')}
          >
            Login
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('gray.100', 'gray.700')}
          boxShadow={'2xl'}
          p={['4', '10']}
        >
          <Stack spacing={['2', '4']} align="center">
            <Image
              borderRadius="full"
              boxSize={['100px', '150px']}
              src="/logo512.png"
              alt="brand_logo"
              fallbackSrc="https://via.placeholder.com/150"
            />
          </Stack>
          <Stack spacing={['2', '4']} align="stretch">
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                type="text"
                name="username"
                id="username"
                label="Username"
                placeholder="Username"
                error={errors.username}
                register={register('username')}
                autoFocus={true}
              />
              <InputField
                type="password"
                name="password"
                id="password"
                label="Password"
                placeholder="Password"
                error={errors.password}
                register={register('password')}
              />

              <Button
                colorScheme="orange"
                w="full"
                type="submit"
                mt={4}
                isLoading={isSubmitting}
                disabled={!isValid || !isDirty}
              >
                Login
              </Button>
            </form>

            <Flex
              direction={{ base: 'column', sm: 'row' }}
              align={'center'}
              justifyContent="space-between"
              justifyItems="center"
              width="full"
            >
              <Link
                to="/forgot-password"
                as={RLink}
                color={'brand.default'}
                fontWeight="bold"
                my="1"
              >
                Forgot password?
              </Link>
              <Link
                to="/verify-mobile"
                as={RLink}
                color={'brand.default'}
                fontWeight="bold"
                my="1"
              >
                Verify Mobile ?
              </Link>
            </Flex>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

const Login = {
  routeProps: {
    path: '/login',
    component: LoginPage,
    exact: true,
  },
  name: 'Login',
  title: 'Login',
};

export default Login;
