import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);

  try {
    const response = await API.get(`/histories/stock?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const materialStockHistoryService = {
  getAll,
};

export default materialStockHistoryService;
