import {
  Center,
  ListItem,
  UnorderedList,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

const renderHistoryDetails = value => {
  if (!value || value.length === 0) {
    return <Center>-</Center>;
  }

  return (
    <UnorderedList>
      {value.map((item, i) => {
        return (
          <ListItem key={i}>
            <Flex>
              <Heading as="h6" size="xs" mr="2" color="purple">
                {item?.field}
              </Heading>
              <Text mr="2" color="black" fontWeight="extrabold">
                -
              </Text>
              <Text mr="2" color="red">
                {item?.old ? item?.old.toString() : null}
              </Text>
              <Text mr="2" color="black" fontWeight="extrabold">
                -
              </Text>
              <Text mr="2" color="green">
                {item?.new ? item?.new.toString() : null}
              </Text>
            </Flex>
          </ListItem>
        );
      })}
    </UnorderedList>
  );
};

export default renderHistoryDetails;
