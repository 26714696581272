import React from 'react';

export default function RenderExpandArrows({ row }) {
  return (
    <div style={{ cursor: 'pointer' }} {...row.getToggleRowExpandedProps()}>
      <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
        {/* {row.isExpanded ? '👇' : '👉'} */}
        {row.isExpanded ? ' . ' : '...'}
      </span>
    </div>
  );
}

// const renderExpandArrows = row => {
//   return (
//     <span {...row.getToggleRowExpandedProps()}>
//       {row.isExpanded ? '👇' : '👉'}
//     </span>
//   );
// };

// export default renderExpandArrows;
