import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAllForSuperadmin = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/plants/getall?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/plants?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async _id => {
  try {
    const response = await API.get(`/plants/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const create = async (formData = {}) => {
  // console.log(formData);
  try {
    const response = await API.post(`/plants/create`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const update = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/plants/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const plantService = {
  getAll,
  getAllForSuperadmin,
  getById,
  create,
  update,
};

export default plantService;
