const messages = {
  proc_date: {
    required: 'Procurement Date is required',
    invalid: 'Invalid Procurement Date',
  },
  shift: {
    required: 'Please Select a Shift',
    invalid: 'Inavldi Shift selected',
  },
  plant_id: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant selected',
  },
  plant_code: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant selected',
  },
  society_id: {
    required: 'Please select a society',
    invalid: 'Invalid Society selected',
  },
  society_code: {
    required: 'Society Code is required',
    invalid: 'Invalid Society Code',
  },
  milk_catagory: {
    required: 'Please select a Milk Catagory',
    invalid: 'Invalid Milk Catagory selected',
  },
  milk_type: {
    required: 'Please select a Milk Type',
    invalid: 'Invalid Milk Type selected',
  },
  cans: {
    required: 'Can is required',
    invalid: 'Invalid Can value',
    // min: 'Invalid Can value. Lower limit exceeded.',
    // max: 'Invalid Can value. Upper limit exceeded.',
  },
  weight_kg: {
    required: 'Weight is required',
    invalid: 'Invalid Weight value',
    // min: 'Invalid Weight value. Lower limit exceeded.',
    // max: 'Invalid Weight value. Upper limit exceeded.',
  },
  fat: {
    required: 'FAT is required',
    invalid: 'Invalid FAT value',
    // min: 'Invalid FAT value. Lower limit exceeded.',
    // max: 'Invalid FAT value. Upper limit exceeded.',
  },
  snf: {
    required: 'SNF is required',
    invalid: 'Invalid SNF value',
    // min: 'Invalid SNF value. Lower limit exceeded.',
    // max: 'Invalid SNF value. Upper limit exceeded.',
  },
  amount: {
    required: 'Amount is required',
    invalid: 'Invalid Amount value',
    // min: 'Invalid Amount value. Lower limit exceeded.',
    // max: 'Invalid Amount value. Upper limit exceeded.',
  },
  note: {
    required: 'Note is required',
    invalid: 'Invalid Note value',
  },
  approve_note: {
    required: 'Approve Note is required',
    invalid: 'Invalid Approve Note value',
  },
};

export default messages;
