import API from 'helpers/API';
// import getQueryString from 'helpers/queryHelper';

const getPlantQty = async (type, days) => {
  try {
    const response = await API.get(`/dashboardmilk/plant/${type}/days/${days}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getTotalMilkYesterday = async (type, days) => {
  try {
    const response = await API.get(`/dashboardmilk/totalmilk/yesterday`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getTotalMilkForLastDays = async (days = 1, plant_code = 'ALL') => {
  try {
    const response = await API.get(
      `/dashboardmilk/totalmilk/lastdays/${days}/${plant_code}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getTotalMilkByMonth = async (formData = {}) => {
  try {
    const response = await API.post(
      `/dashboardmilk/totalmilk/bymonth/`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const dashboardMilkService = {
  getPlantQty,
  getTotalMilkYesterday,
  getTotalMilkForLastDays,
  getTotalMilkByMonth,
};

export default dashboardMilkService;
