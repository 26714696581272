const messages = {
  society_code: {
    invalid: 'Society Code invalid',
    match: 'Society Code invalid',
    required: 'Society Code is required',
    max: 'Society Code invalid',
  },
  society_name: {
    max: 'Society Name length exceed',
    required: 'Society Name is required',
  },
  society_type: {
    required: 'Society Type is required',
    invalid: 'Invalid Society Type value',
  },
  society_gender: {
    required: 'Society Gender is required',
    invalid: 'Invalid Society Gender value',
  },
  is_active: {
    invalid: 'Invalid Status',
    required: 'Society Status is required',
  },

  plant_id: {
    oneOf: 'Invalid Plant',
  },
  gu_society_name: {
    max: 'Society Name (Guj) length exceed',
    required: 'Society Name (Guj) is required',
  },
  city: {
    required: 'City name required',
    min: 'City name length too short',
    max: 'City name length exceeded',
    exist: 'City already exist',
  },
  taluka: {
    required: 'Taluka name required',
    min: 'Taluka name length too short',
    max: 'Taluka name length exceeded',
  },
  district: {
    required: 'District name required',
    min: 'District name length too short',
    max: 'District name length exceeded',
  },
  state: {
    required: 'State name required',
  },
  country: {
    required: 'Country name required',
  },
  gu_city: {
    required: 'City name (Guj) required',
    max: 'City name length (Guj) exceeded',
  },
  gu_taluka: {
    required: 'Taluka name (Guj) required',
  },
  gu_district: {
    required: 'District name (Guj) required',
  },
  gu_state: {
    required: 'State name (Guj) required',
  },
  gu_country: {
    required: 'Country name (Guj) required',
  },
  send_message: {
    required: 'Message Send enable is required',
    invalid: 'Message Send active has invalid value',
  },
  send_message_supervisor: {
    required: 'Supervisor message enable is required',
    invalid: 'Supervisor message send invalid value',
  },
  proposed_date: {
    required: 'Proposed date is required',
    invalid: 'Invalid Proposed date value',
  },
  registration_date: {
    required: 'Registration date is required',
    invalid: 'Invalid Registration date value',
  },
  registration_no: {
    required: 'Registration Number is required',
    invalid: 'Invalid Registration Number value',
  },
  ifsc: {
    required: 'Please provide IFSC Code',
    invalid: 'Invalid IFSC Code',
  },
  account_number: {
    required: 'Please provide account number',
    invalid: 'Invalid account number',
  },
  pan: {
    required: 'Please provide PAN Number',
    invalid: 'Invalid PAN Number',
  },
  pan_name: {
    required: 'Please provide name as in PAN',
    invalid: 'Invalid name as in PAN',
  },
  gst: {
    required: 'Please provide GST Number',
    invalid: 'Invalid GST Number',
  },
  gst_name: {
    required: 'Please provide name as in GST',
    invalid: 'Invalid name as in GST',
  },
  chairman_fname: {
    required: 'Please provide chairman firstname',
    invalid: 'Invalid chairman firstname',
    max: 'chairman firstname length exceeded',
  },
  chairman_mname: {
    required: 'Please provide chairman middlename',
    invalid: 'Invalid chairman middlename',
    max: 'chairman middlename length exceeded',
  },
  chairman_lname: {
    required: 'Please provide chairman lastname',
    invalid: 'Invalid chairman lastname',
    max: 'chairman lastname length exceeded',
  },
  chairman_address1: {
    required: 'Please provide chairman Address 1',
    invalid: 'Invalid chairman Address 1',
    max: 'chairman Address 1 length exceeded',
  },
  chairman_address2: {
    required: 'Please provide chairman Address 2',
    invalid: 'Invalid chairman Address 2',
    max: 'chairman Address 2 length exceeded',
  },
  chairman_address3: {
    required: 'Please provide chairman Address 3',
    invalid: 'Invalid chairman Address 3',
    max: 'chairman Address 3 length exceeded',
  },
  chairman_city: {
    required: 'Please provide chairman city',
    invalid: 'Invalid chairman city',
    max: 'chairman city length exceeded',
  },
  chairman_pin: {
    required: 'Please provide chairman PIN',
    invalid: 'Invalid chairman PIN',
    max: 'chairman PIN length exceeded',
  },
  chairman_mobile: {
    required: 'Please provide chairman mobile',
    invalid: 'Invalid chairman mobile',
    max: 'chairman mobile length exceeded',
  },
  chairman_email: {
    required: 'Please provide chairman E-Mail',
    invalid: 'Invalid chairman E-Mail',
    max: 'chairman E-Mail length exceeded',
  },
  chairman_pan: {
    required: 'Please provide chairman PAN',
    invalid: 'Invalid chairman PAN',
    max: 'chairman PAN length exceeded',
  },
  chairman_pan_name: {
    required: 'Please provide chairman name as in PAN',
    invalid: 'Invalid chairman name as in PAN',
    max: 'chairman name as in PAN length exceeded',
  },
  chairman_adhar: {
    required: 'Please provide chairman Adhar',
    invalid: 'Invalid chairman Adhar',
    max: 'chairman Adhar length exceeded',
  },
  secretary_fname: {
    required: 'Please provide secretary firstname',
    invalid: 'Invalid secretary firstname',
    max: 'secretary firstname length exceeded',
  },
  secretary_mname: {
    required: 'Please provide secretary middlename',
    invalid: 'Invalid secretary middlename',
    max: 'secretary middlename length exceeded',
  },
  secretary_lname: {
    required: 'Please provide secretary lastname',
    invalid: 'Invalid secretary lastname',
    max: 'secretary lastname length exceeded',
  },
  secretary_address1: {
    required: 'Please provide secretary Address 1',
    invalid: 'Invalid secretary Address 1',
    max: 'secretary Address 1 length exceeded',
  },
  secretary_address2: {
    required: 'Please provide secretary Address 2',
    invalid: 'Invalid secretary Address 2',
    max: 'secretary Address 2 length exceeded',
  },
  secretary_address3: {
    required: 'Please provide secretary Address 3',
    invalid: 'Invalid secretary Address 3',
    max: 'secretary Address 3 length exceeded',
  },
  secretary_city: {
    required: 'Please provide secretary city',
    invalid: 'Invalid secretary city',
    max: 'secretary city length exceeded',
  },
  secretary_pin: {
    required: 'Please provide secretary PIN',
    invalid: 'Invalid secretary PIN',
    max: 'secretary PIN length exceeded',
  },
  secretary_mobile: {
    required: 'Please provide secretary mobile',
    invalid: 'Invalid secretary mobile',
    max: 'secretary mobile length exceeded',
  },
  secretary_email: {
    required: 'Please provide secretary E-Mail',
    invalid: 'Invalid secretary E-Mail',
    max: 'secretary E-Mail length exceeded',
  },
  secretary_pan: {
    required: 'Please provide secretary PAN',
    invalid: 'Invalid secretary PAN',
    max: 'secretary PAN length exceeded',
  },
  secretary_pan_name: {
    required: 'Please provide secretary name as in PAN',
    invalid: 'Invalid secretary name as in PAN',
    max: 'secretary name as in PAN length exceeded',
  },
  secretary_adhar: {
    required: 'Please provide secretary Adhar',
    invalid: 'Invalid secretary Adhar',
    max: 'secretary Adhar length exceeded',
  },
};

export default messages;
