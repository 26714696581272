import * as yup from 'yup';
import messages from './messages';

const editOtherSchema = yup.object().shape({
  send_message: yup.bool(messages.send_message.invalid),
  send_message_supervisor: yup.string().trim(),
  // society_type: yup.string().trim().max(100, messages.society_type.max),
  // society_gender: yup.string().trim().max(100, messages.society_gender.max),
  // proposed_date: yup.string().trim(),
  // registration_date: yup.string().trim(),
  registration_no: yup.string().trim().max(100, messages.registration_no.max),
});

export default editOtherSchema;
