import plantConstants from 'constants/plantConstants';

const initialState = [];

const plantReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case plantConstants.SET_PLANTS:
      return payload;
    default:
      return state;
  }
};

export default plantReducer;
