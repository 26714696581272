import React, { useState, useEffect } from 'react';
import moment from 'helpers/moment';
import {
  Center,
  Flex,
  Box,
  Heading,
  Button,
  useColorModeValue,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Icon,
  Badge,
} from '@chakra-ui/react';
import { RepeatIcon, CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { BsStopwatch } from 'react-icons/bs';
import dashboardService from 'services/dashboardService';

function MilkUploadStatus() {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  const refreshData = async () => {
    setLoading(true);
    const query = { filters: { fields: 'milkfilepload' } };
    const resData = await dashboardService.getAll(query);
    if (resData && resData?.data?.milkfilepload) {
      setData(resData?.data?.milkfilepload);
      setStatus(resData?.data?.milkfilepload?.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Box
      p={2}
      h="full"
      w="full"
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      bg={useColorModeValue('gray.100', 'gray.700')}
      color={useColorModeValue('gray.800', 'gray.200')}
    >
      <Flex
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        h="full"
        w="full"
      >
        <Box height="15%" mb="4">
          <Flex justifyContent={'space-between'} alignItems={'center'} mb="4">
            <Heading
              fontSize="xl"
              color={useColorModeValue('orange.500', 'orange.200')}
            >
              MILK FILE UPLOAD STATUS
            </Heading>
            <Button
              variant="ghost"
              isLoading={loading}
              loadingText="Refreshing..."
              onClick={refreshData}
              outline="none"
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'transperent',
                scale: '50',
                color: 'teal.500',
                size: 'xs',
              }}
            >
              <RepeatIcon w={8} h={8} color="green.500" />
            </Button>
          </Flex>
        </Box>

        {loading && (
          <Box minH="200px" w="full">
            <Center>
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
              />
            </Center>
          </Box>
        )}

        {!loading && (
          <>
            <Box flex="1" p="2" w="full" h="full">
              <Table variant="simple" fontSize="md">
                <Thead>
                  <Tr>
                    <Th textAlign="left" fontSize="md">
                      DATE/SHIFT
                    </Th>
                    {data?.dates.length &&
                      data?.dates.map(d => (
                        <Th
                          key={d}
                          colSpan={2}
                          textAlign="center"
                          fontSize="md"
                        >
                          <Badge
                            fontSize="md"
                            variant="outline"
                            colorScheme="blue"
                            px="1"
                            py="1"
                          >
                            {d}
                          </Badge>
                        </Th>
                      ))}
                  </Tr>

                  <Tr>
                    <Th colSpan={1} textAlign="left" fontSize="md">
                      PLANT
                    </Th>

                    {['M', 'E', 'M', 'E', 'M', 'E'].map((shift, i) => (
                      <Th key={i} colSpan={1} textAlign="center" fontSize="md">
                        <Badge
                          fontSize="md"
                          variant="outline"
                          colorScheme={shift === 'M' ? 'purple' : 'pink'}
                          px="2"
                          py="1"
                        >
                          {shift}
                        </Badge>
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {status.length
                    ? status.map(plant => {
                        const date1 = data.dates[0];
                        const date2 = data.dates[1];
                        const date3 = data.dates[2];
                        return (
                          <Tr key={plant.plant_code}>
                            <Td
                              fontWeight="extrabold"
                              color="orange.600"
                              textAlign="left"
                            >
                              {plant.plant_code}
                            </Td>

                            <Td fontWeight="bold" textAlign="center">
                              <Icon
                                w={6}
                                h={6}
                                as={
                                  plant[date1]['M']
                                    ? CheckCircleIcon
                                    : WarningTwoIcon
                                }
                                color={
                                  plant[date1]['M'] ? 'green.500' : 'red.500'
                                }
                              />
                            </Td>

                            <Td fontWeight="bold" textAlign="center">
                              <Icon
                                w={6}
                                h={6}
                                as={
                                  plant[date1]['E']
                                    ? CheckCircleIcon
                                    : WarningTwoIcon
                                }
                                color={
                                  plant[date1]['E'] ? 'green.500' : 'red.500'
                                }
                              />
                            </Td>

                            <Td fontWeight="bold" textAlign="center">
                              <Icon
                                w={6}
                                h={6}
                                as={
                                  plant[date2]['M']
                                    ? CheckCircleIcon
                                    : WarningTwoIcon
                                }
                                color={
                                  plant[date2]['M'] ? 'green.500' : 'red.500'
                                }
                              />
                            </Td>

                            <Td fontWeight="bold" textAlign="center">
                              <Icon
                                w={6}
                                h={6}
                                as={
                                  plant[date2]['E']
                                    ? CheckCircleIcon
                                    : WarningTwoIcon
                                }
                                color={
                                  plant[date2]['E'] ? 'green.500' : 'red.500'
                                }
                              />
                            </Td>

                            <Td fontWeight="bold" textAlign="center">
                              <Icon
                                w={6}
                                h={6}
                                as={
                                  plant[date3]['M']
                                    ? CheckCircleIcon
                                    : WarningTwoIcon
                                }
                                color={
                                  plant[date3]['M'] ? 'green.500' : 'red.500'
                                }
                              />
                            </Td>

                            <Td fontWeight="bold" textAlign="center">
                              <Icon
                                w={6}
                                h={6}
                                as={
                                  plant[date3]['E']
                                    ? CheckCircleIcon
                                    : WarningTwoIcon
                                }
                                color={
                                  plant[date3]['E'] ? 'green.500' : 'red.500'
                                }
                              />
                            </Td>
                          </Tr>
                        );
                      })
                    : null}
                </Tbody>
              </Table>
            </Box>
            <Box height="10%" m="4">
              <Flex justifyContent={'end'} alignItems={'center'} mt="6">
                <BsStopwatch w={4} h={4} color="green.500" />
                <Text
                  fontSize="md"
                  fontWeight="semibold"
                  ml={4}
                  color="gray.500"
                >
                  {moment(data?.last_updated_at).fromNow()}
                </Text>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default MilkUploadStatus;
