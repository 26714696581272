import React, { useState, useEffect } from 'react';
import { Flex, Button } from '@chakra-ui/react';

import { Link, useLocation } from 'react-router-dom';

export default function EditButtons() {
  const [btnData, setBtnData] = useState(null);
  const [curPath, setcurPath] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setBtnData(location?.state);
    setcurPath(location.pathname);
  }, [location]);

  return btnData ? (
    <Flex direction={['column', 'row']} justifyContent="flex-end">
      <Button
        as={Link}
        to={{
          pathname: `/societies/edit/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="orange"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={curPath && curPath === `/societies/edit/${btnData?._id}`}
      >
        SOCIETY
      </Button>
      <Button
        as={Link}
        to={{
          pathname: `/societies/details/plant/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="yellow"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={curPath && curPath.startsWith(`/societies/details/plant`)}
      >
        PLANT
      </Button>
      <Button
        as={Link}
        to={{
          pathname: `/societies/details/supervisor/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="yellow"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={
          curPath && curPath.startsWith(`/societies/details/supervisor`)
        }
      >
        SUPERVISOR
      </Button>
      <Button
        as={Link}
        to={{
          pathname: `/societies/details/bank/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="yellow"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={curPath && curPath.startsWith(`/societies/details/bank`)}
      >
        BANK
      </Button>
      <Button
        as={Link}
        to={{
          pathname: `/societies/edit/other/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="blue"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={curPath && curPath.startsWith(`/societies/edit/other`)}
      >
        OTHER
      </Button>
      <Button
        as={Link}
        to={{
          pathname: `/societies/edit/incometax/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="blue"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={curPath && curPath.startsWith(`/societies/edit/incometax`)}
      >
        INCOME TAX
      </Button>
      <Button
        as={Link}
        to={{
          pathname: `/societies/edit/chairman/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="blue"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={curPath && curPath.startsWith(`/societies/edit/chairman`)}
      >
        CHAIRMAN
      </Button>
      <Button
        as={Link}
        to={{
          pathname: `/societies/edit/secretary/${btnData?._id}`,
          state: btnData,
        }}
        type="button"
        colorScheme="blue"
        size="sm"
        px="8"
        mt={[2, 0]}
        mr={[0, 2]}
        disabled={curPath && curPath.startsWith(`/societies/edit/secretary`)}
      >
        SECRETARY
      </Button>
    </Flex>
  ) : null;
}
