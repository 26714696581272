import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import schema from './schema';

import SelectField from 'components/form/SelectField';
import RenderUserOption from 'components/form/RenderUserOption';

import userService from 'services/userService';
import authorizationService from 'services/authorizationService';

const FormTitle = 'AUTHORIZE USER PLANT';

const ROLES_EXCLUDE = [
  'superadmin',
  'admin',
  'md',
  'hradmin',
  'hruser',
  'accountadmin',
  'supervisor',
];

const defaultValues = {
  user: '',
  plant: '',
};

const IndexPage = () => {
  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  const fetchIdRef = useRef(0);
  const fetchIdUserRef = useRef(0);
  const fetchIdUserDetailsRef = useRef(0);

  // We'll start our table without any data
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    // register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    getValues,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    handlePlantChange('clear', null);
    handleUserChange('clear', null);
  };

  const watchPlant = watch('plant', null);
  const watchUser = watch('user', null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      const responseData = await authorizationService.userPlantAuthorize(
        formData.user?._id,
        formData.plant ? formData.plant?._id : null
      );

      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  const fetchUsers = async () => {
    const fetchIdUser = ++fetchIdUserRef.current;

    if (fetchIdUser === fetchIdUserRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'username';
      query.filters = {};
      query.fields =
        '-societies -createdAt -updatedAt -email_verified -force_password_change -last_login_timestamp -last_password_change -mobile_verified';
      const responseData = await userService.getAll(query);

      if (responseData?.data) {
        const userOptions = [];

        _.forEach(responseData?.data, u => {
          const IS_ALLOWED = _.intersection(ROLES_EXCLUDE, u.role);
          if (IS_ALLOWED.length <= 0) {
            userOptions.push(u);
          }
        });
        setUsers(userOptions);
      }
    }
  };

  const getUserDetails = async () => {
    const fetchIdUserDetails = ++fetchIdUserDetailsRef.current;

    const user = getValues('user');

    if (fetchIdUserDetails === fetchIdUserDetailsRef.current && user?._id) {
      const responseData = await userService.getById(user._id);

      if (responseData) {
        const plant_id = responseData?.data?.plant_id?._id;

        if (plant_id) {
          const pl = plants.find(plant => plant?._id === plant_id);
          setValue('plant', pl);
        }
      }
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant', null);
      return;
    }
    return setValue('plant', selected);
  };

  const handleUserChange = (selected, action) => {
    handlePlantChange(null, 'clear');
    if (action === 'clear') {
      setValue('user', null);
      return;
    }
    setValue('user', selected);
    getUserDetails();
    return;
  };

  const customUserFilter = (option, searchText) => {
    if (option.data.username.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      id="121231"
      w="full"
      h="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize={['sm', 'lg']} color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
        </Flex>
      </Box>

      {/* Form */}
      <Box w="full" h="full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="user"
                    label="User"
                    labelKey="username"
                    valueKey="_id"
                    options={users}
                    placeholder="Select User"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => (
                      <RenderUserOption user={option} avatarSize={40} />
                    )}
                    getOptionValue={option => option?._id}
                    handleChange={handleUserChange}
                    error={errors.user}
                    control={control}
                    defaultValue={watchUser}
                    filterOption={customUserFilter}
                  />
                </Box>
                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="plant"
                    label="Plant"
                    labelKey="plant_code"
                    valueKey="_id"
                    options={plants}
                    placeholder="Select Plant"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option =>
                      `${option['plant_code']}-${option['plant_name']}`
                    }
                    getOptionValue={option => option?._id}
                    handleChange={handlePlantChange}
                    error={errors.plant}
                    control={control}
                    defaultValue={watchPlant}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ASSIGN PLANT
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const AuthorizeUserPlant = {
  routeProps: {
    path: '/authorize/plant',
    component: IndexPage,
    exact: true,
  },
  name: 'Authorize Plant',
  title: 'Authorize Plant',
};

export default AuthorizeUserPlant;
