import plantConstants from 'constants/plantConstants';
import plantService from 'services/plantService';

const getAll = () => {
  return async dispatch => {
    try {
      const plants = await plantService.getAll({ sortby: 'plant_code' });

      if (plants && plants.data) {
        dispatch({
          type: plantConstants.SET_PLANTS,
          payload: plants.data,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
};

const getAllSuperadmin = () => {
  return async dispatch => {
    try {
      const plants = await plantService.getAllForSuperadmin({
        sortby: 'plant_code',
      });

      if (plants && plants.data) {
        dispatch({
          type: plantConstants.SET_PLANTS_SUPERADMIN,
          payload: plants.data,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
};

const plantActions = {
  getAll,
  getAllSuperadmin,
};

export default plantActions;
