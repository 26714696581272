import { Badge, Center } from '@chakra-ui/react';
import { MILKFILE_UPLOAD_STATUS } from 'configs/milkFileUploadStatus';

const getBadgeColor = status => {
  if (status === MILKFILE_UPLOAD_STATUS.uploaded) {
    return 'blue';
  } else if (status === MILKFILE_UPLOAD_STATUS.completed) {
    return 'green';
  } else if (
    status === MILKFILE_UPLOAD_STATUS.failed ||
    status === MILKFILE_UPLOAD_STATUS.canceled
  ) {
    return 'red';
  } else {
    return 'yellow';
  }
};

const renderMilkFileUploadStatus = cell => {
  if (cell.value) {
    return (
      <Center>
        <Badge variant="solid" colorScheme={getBadgeColor(cell.value)}>
          {cell.value}
        </Badge>
      </Center>
    );
  } else {
    return <Center>-</Center>;
  }
};

export default renderMilkFileUploadStatus;
