import React from 'react';
import TableActionColumn from 'components/tables/TableActionColumn';

const hasEdit = ['superadmin'];

export default function ActionColumn({ row }) {
  return (
    <TableActionColumn
      row={row}
      hasEdit={hasEdit}
      editLink={`/plants/edit/${row._id}`}
    />
  );
}
