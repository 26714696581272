import React from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';

// Dashboard Components
import SocietyMilkAnalysis from 'modules/DashboardSocietyAnalysis/Components/SocietyMilkAnalysis';

const IndexPage = props => {
  return (
    <Box h="full" w="full">
      <Flex
        direction="column"
        justifyContent="start"
        alignItems="start"
        w="full"
        h="full"
      >
        {/* Row 1 */}
        <Box
          w="full"
          h="full"
          mt={{ sm: 8, md: 0 }}
          shadow="2xl"
          borderWidth="1px"
          borderRadius="md"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          bg={useColorModeValue('gray.100', 'gray.700')}
          // color={useColorModeValue('gray.800', 'gray.200')}
        >
          <SocietyMilkAnalysis />
        </Box>

        {/* Row 2 */}
        {/* <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          Row 2
        </Flex> */}
      </Flex>
    </Box>
  );
};

const DashboardSocietyAnalysis = {
  routeProps: {
    path: '/dashboardsocietyanalysis',
    component: IndexPage,
    exact: true,
  },
  name: 'Society Analysis',
  title: 'Society Analysis',
};

export default DashboardSocietyAnalysis;
