import * as yup from 'yup';
import messages from './messages';

const schema = yup.object().shape({
  firstname: yup
    .string()
    .nullable()
    // .min(2, messages.firstname.min)
    .max(100, messages.firstname.max),
  middlename: yup
    .string()
    .nullable()
    // .min(1, messages.middlename.min)
    .max(100, messages.middlename.max),
  lastname: yup
    .string()
    .nullable()
    // .min(2, messages.lastname.min)
    .max(100, messages.lastname.max),
});

export default schema;
