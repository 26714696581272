const fields = {
  names: ['mobile'],
  mobile: {
    default: '',
    disabled: false,
    readonly: false,
  },
};

export default fields;
