import { Badge, Center } from '@chakra-ui/react';
import { MESSAGE_STATUS } from 'configs/messageStatus';

const getBadgeColor = status => {
  if (status === MESSAGE_STATUS.sent) {
    return 'blue';
  } else if (status === MESSAGE_STATUS.delivered) {
    return 'green';
  } else {
    return 'red';
  }
};

const renderMessageStatus = cell => {
  if (cell.value) {
    return (
      <Center>
        <Badge variant="solid" colorScheme={getBadgeColor(cell.value)}>
          {cell.value}
        </Badge>
      </Center>
    );
  } else {
    return <Center>-</Center>;
  }
};

export default renderMessageStatus;
