import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import renderDate from 'components/tables/helpers/renderDate';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderMilkEntryType from 'components/tables/helpers/renderMilkEntryType';
import renderApproveReject from 'components/tables/helpers/renderApproveReject';
import renderMilkEntryTrType from 'components/tables/helpers/renderMilkEntryTrType';
import MilktransactioneditApproveColumn from './MilktransactioneditApproveColumn';
import renderIndiaFormat from 'components/tables/helpers/renderIndiaFormat';

import ActionColumn from './ActionColumn';

const columnsAll = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'tr_type',
    accessor: 'tr_type',
    Header: 'TR Type',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderMilkEntryTrType(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'proc_date',
    accessor: 'proc_date',
    Header: 'Proc Date',
    type: 'date',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDate(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.date.maxWidth,
    minWidth: tblConsts.date.minWidth,
  },
  {
    id: 'shift',
    accessor: 'shift',
    Header: 'Shift',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'plant_code',
    accessor: 'plant_code',
    Header: 'Plant Code',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'plant_name',
    accessor: 'plant_name',
    Header: 'Name',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },
  {
    id: 'society_code',
    accessor: 'society_code',
    Header: 'Soc Code',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'society_name',
    accessor: 'society_name',
    Header: 'Name',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: cell => cell.value,
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.societyname.maxWidth,
    minWidth: tblConsts.societyname.minWidth,
  },
  {
    id: 'city',
    accessor: 'city',
    Header: 'City',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'milk_catagory',
    accessor: 'milk_catagory',
    Header: 'Milk Catagory',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'milk_type',
    accessor: 'milk_type',
    Header: 'Milk Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cans',
    accessor: 'cans',
    Header: 'Cans',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => <div style={{ textAlign: 'right' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'weight_kg',
    accessor: 'weight_kg',
    Header: 'Wight(Kg)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'fat',
    accessor: 'fat',
    Header: 'FAT',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'snf',
    accessor: 'snf',
    Header: 'SNF',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'amount',
    accessor: 'amount',
    Header: 'Amount',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'entry_type',
    accessor: 'entry_type',
    Header: 'Entry Type',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderMilkEntryType(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'created_by',
    accessor: 'created_by',
    Header: 'Created By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'created_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'updated_by',
    accessor: 'updated_by',
    Header: 'Updated By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'updated_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'approved_by',
    accessor: 'approved_by',
    Header: 'Approved By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'approved_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'note',
    accessor: 'note',
    Header: 'Note',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'approve_note',
    accessor: 'approve_note',
    Header: 'Approve Note',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'approval_status',
    accessor: 'approval_status',
    Header: 'Status',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderApproveReject(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'cemter',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'approve',
    accessor: 'approve',
    Header: 'Approve/Reject',
    hidden: false,
    headerAlign: 'cemter',
    Cell: ({ row }) => <MilktransactioneditApproveColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columnsAll;
