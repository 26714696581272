import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderLogLevel from 'components/tables/helpers/renderLogLevel';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'timestamp',
    accessor: 'timestamp',
    Header: 'Timestamp',
    type: 'datetime',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'level',
    accessor: 'level',
    Header: 'Level',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderLogLevel(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'message',
    accessor: 'message',
    Header: 'message',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'meta',
    accessor: 'meta',
    Header: 'Meta',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    disableHideColumn: true,
  },
];

export default columns;
