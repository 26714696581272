const messages = {
  username: {
    required: 'Username is required',
    min: 'Username is too short',
    max: 'Username length exceeded',
  },
  password: {
    required: 'Password is required',
    min: 'Password length too short',
    max: 'Password length exceeded',
    match: 'Password and confirm password do not match',
  },
};

export default messages;
