import React, { useState, useEffect, forwardRef, useRef } from 'react';
import _ from 'lodash';
import {
  useTable,
  useSortBy,
  usePagination,
  useExpanded,
  useRowSelect,
  useRowState,
} from 'react-table';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Stack,
  Flex,
  Heading,
  Button,
  Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa';

import ColumnHide from 'components/tables/ColumnHide';
import FilterDrawer from 'components/tables/FilterDrawer';
import TablePagination from 'components/tables/TablePagination';
import TableDetails from 'components/tables/TableDetails';
import TableExport from 'components/tables/TableExport';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

export default function TableSelectComp({
  columns,
  data,
  fetchData,
  filters,
  loading,
  pageCount: controlledPageCount,
  resultCount = 0,
  tblHeader,
  showColumnHide = false,
  defaultSortBy = [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filterForm,
  isExpandable = false,
  ExpandedComponent = null,
  addLink,
  allowAdd = [],
  enableExport = false,
  exportFilename,
  onExport,
  enableSelect,
  selected,
  handleOnSelect,
  handleOnSelectAll,
  getSelectDisabledState,
  showSelectAll = false,
  getRowId,
}) {
  const bodyBgColor = useColorModeValue('gray.50', 'gray.800');
  const bodyTextColor = useColorModeValue('gray.700', 'whiteAlpha.700');
  const headerBgColor = useColorModeValue('orange.300', 'orange.700');
  const headerTextColor = useColorModeValue('gray.700', 'whiteAlpha.800');
  const rowBgColor = useColorModeValue('orange.200', 'orange.900');
  const rowTextColor = useColorModeValue('gray.900', 'gray.100');

  // Css Vars
  let headerTextAlignment = 'left';

  const user = useSelector(state => state.auth.user);
  const [exportHeaders, setExportHeaders] = useState([]);
  const [visibles, setVisibles] = useState([]);
  const [disabledVis, setDisabledVis] = useState(true);

  const getDisableState = row => {
    if (!getSelectDisabledState) return false;
    return getSelectDisabledState(row);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    toggleHideAllColumns,
    visibleColumns,
    selectedFlatRows,
    // toggleAllRowsSelected,
    // toggleRowSelected,
    isAllRowsSelected,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      //  selectedRowIds
    },
  } = useTable(
    {
      columns,
      data,
      pageCount: controlledPageCount,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        sortBy: defaultSortBy,
        hiddenColumns: columns
          .filter(column => column?.hidden)
          .map(column => column.accessor),
        selectedRowIds: selected,
        // selectedRowIds: { 0: true, 1: true },
      },
      autoResetSelectedRows: true,
      getRowId: getRowId ? getRowId : row => row._id,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useRowState,
    hooks => {
      enableSelect &&
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            disableSortBy: true,
            exportable: false,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) =>
              showSelectAll ? (
                <div>
                  <IndeterminateCheckbox
                    {...getToggleAllPageRowsSelectedProps()}
                  />
                </div>
              ) : null,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const currentState = row.getToggleRowSelectedProps();
              return (
                <div>
                  <IndeterminateCheckbox
                    {...currentState}
                    onClick={() => {
                      row.toggleRowSelected();
                      if (handleOnSelect) {
                        handleOnSelect(row, !row.isSelected);
                      }
                    }}
                    disabled={getDisableState(row.original)}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
    }
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters });
    // eslint-disable-next-line
  }, [fetchData, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    gotoPage(0);
    fetchData({ pageIndex: 0, filters });
    // eslint-disable-next-line
  }, [filters]);

  // Pre Select Rows
  useEffect(() => {
    if (handleOnSelectAll) {
      if (isAllRowsSelected) {
        // const selectedIds = selectedFlatRows.map(obj => {
        //   return obj.original._id;
        // });
        handleOnSelectAll(selectedFlatRows);
      } else {
        handleOnSelectAll([]);
      }
    }
    // eslint-disable-next-line
  }, [isAllRowsSelected]);

  useEffect(() => {
    if (enableExport) {
      const exp = [];
      if (columns.length > 0) {
        for (let i = 0; i < columns.length; i++) {
          const col = columns[i];
          let head;
          if (col.exportable) {
            if (col.exportHeader) {
              const k = String(col?.exportHeader);
              head = { label: col?.Header, key: k };
            } else {
              head = { label: col?.Header, key: col?.id };
            }
            if (head?.key) {
              exp.push(head);
            }
          }
        }
        setExportHeaders(exp);
      }
    }
    // eslint-disable-next-line
  }, [columns]);

  useEffect(() => {
    if (allColumns.length > 0) {
      const visibles = allColumns.filter(column => !column.disableHideColumn);
      setVisibles(visibles);

      if (visibles.length !== allColumns.length) {
        setDisabledVis(false);
      }
    }
    // eslint-disable-next-line
  }, [allColumns]);

  const renderHeader = () => {
    if (!tblHeader) {
      return;
    }
    if (typeof tblHeader === 'string') {
      return (
        <Heading size="md" mb={{ base: '2', md: '0' }} color="orange.500">
          {tblHeader}
        </Heading>
      );
    }
    return (
      <Heading size="md" mb={{ base: '2', md: '0' }} color="orange.500">
        {tblHeader}
      </Heading>
    );
  };

  const showAdd = _.intersection(allowAdd, user.role).length > 0;

  const addLinkRender = showAdd ? (
    <Button
      variant="outline"
      colorScheme="orange"
      size="sm"
      as={Link}
      to={addLink}
    >
      <FaPlus />
    </Button>
  ) : (
    ''
  );

  const csvExportRender = enableExport ? (
    <TableExport
      exportHeaders={exportHeaders}
      data={data}
      exportFilename={exportFilename}
      onExport={onExport}
    />
  ) : (
    ''
  );

  return (
    <Box display={{ md: 'flex' }} w="full" h="full">
      <Stack p="2" width="full">
        {/* <pre> <code>{JSON.stringify(pageIndex, null, 4)} </code></pre> */}

        <Flex
          direction={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'space-between', md: 'space-between' }}
          alignItems={{ base: 'center', md: 'space-between' }}
        >
          <Flex px={{ base: 1, md: 1 }}> {renderHeader()}</Flex>
          <Flex
            direction={{ base: 'row', md: 'row' }}
            justifyContent={{ base: 'space-between', md: 'space-between' }}
          >
            <Flex px={{ base: 1, md: 1 }}>
              {addLink && (
                <Flex px={{ base: 1, md: 1 }}>
                  <div className="px-2">{addLinkRender}</div>
                </Flex>
              )}

              {showColumnHide && (
                <Flex px={{ base: 1, md: 1 }}>
                  <ColumnHide
                    columnToggleProps={{ ...getToggleHideAllColumnsProps() }}
                    allColumns={visibles}
                    toggleHideAllColumns={toggleHideAllColumns}
                    disabledVis={disabledVis}
                  />
                </Flex>
              )}

              {filterForm && (
                <Flex px={{ base: 1, md: 1 }}>
                  <FilterDrawer children={filterForm} />
                </Flex>
              )}
              {enableExport && (
                <Flex px={{ base: 1, md: 1 }}>{csvExportRender}</Flex>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Table variant="simple" size="sm" {...getTableProps()} shadow="lg">
          <Thead bg={headerBgColor}>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    color={headerTextColor}
                    py={{ base: '1', md: '2' }}
                    px={{ base: '1', md: '2' }}
                    textAlign={
                      column.headerAlign
                        ? column.headerAlign
                        : headerTextAlignment
                    }
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody
            {...getTableBodyProps()}
            bg={bodyBgColor}
            color={bodyTextColor}
          >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <Tr
                    {...row.getRowProps()}
                    _hover={{ bg: rowBgColor, color: rowTextColor }}
                  >
                    {row.cells.map(cell => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          py={{ base: '1', md: '1' }}
                          px={{ base: '1', md: '2' }}
                          textAlign={`left`}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>

                  {isExpandable &&
                    (row.isExpanded ? (
                      <Tr>
                        <Td colSpan={visibleColumns.length}>
                          {ExpandedComponent ? (
                            <ExpandedComponent columns={columns} row={row} />
                          ) : (
                            <TableDetails columns={columns} data={row} />
                          )}
                        </Td>
                      </Tr>
                    ) : null)}
                </React.Fragment>
              );
            })}
          </Tbody>

          {/* Footer */}
          <Tfoot bg={headerBgColor}>
            <Tr>
              <Th colSpan={visibleColumns.length}>
                {loading
                  ? 'Loading...'
                  : `Showing ${page.length} of ${resultCount}
                  results`}
              </Th>
            </Tr>
          </Tfoot>
        </Table>

        {/* Pagination */}
        <TablePagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={pageCount}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Stack>
    </Box>
  );
}
