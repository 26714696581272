import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Flex, VStack, Text, Button, SimpleGrid } from '@chakra-ui/react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UpdateStatus from './UpdateStatus';
import UpdateRole from './UpdateRole';
import UpdatePassword from './UpdatePassword';
import UpdateMobile from './UpdateMobile';
import UpdateEmail from './UpdateEmail';
import UpdateUser from './UpdateUser';

const allowedRoles = ['superadmin'];

const EditPage = () => {
  const user = useSelector(state => state?.auth?.user);
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setData(location?.state);
    if (_.intersection(allowedRoles, user.role).length > 0) {
      setShow(true);
    }
  }, [location, user]);

  const FormTitle = `EDIT USER - ${data?.username}`;
  const IndexPageTitle = 'USERS';
  const IndexPagePath = '/users';

  return (
    <VStack spacing="2" align="stretch" h="full" w="full">
      <Box h="20px" mb={[4, 4]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>
      <SimpleGrid minChildWidth="350px" spacingX="10px" spacingY="10px">
        {show && <UpdateStatus data={data} />}
        {show && <UpdateRole data={data} />}
        {show && <UpdatePassword data={data} />}
        {show && <UpdateMobile data={data} />}
        {show && <UpdateEmail data={data} />}
        <UpdateUser data={data} />
      </SimpleGrid>
    </VStack>
  );
};

// Exports

const UserEdit = {
  routeProps: {
    path: '/users/edit/:_id',
    component: EditPage,
    exact: true,
  },
  name: 'Edit User',
  title: 'Edit User',
};

export default UserEdit;
