import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  // Get All
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/milk/transactionedit?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Get By ID
const getById = async _id => {
  try {
    const response = await API.get(`/milk/transactionedit/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Mark Create
const markCreate = async (formData = {}) => {
  try {
    const response = await API.post(`/milk/transactionedit/create`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Mark Edit
const markUpdate = async (_id, formData = {}) => {
  try {
    const response = await API.post(
      `/milk/transactionedit/edit/${_id}`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Mark Delete
const markDelete = async (_id, formData = {}) => {
  try {
    const response = await API.post(
      `/milk/transactionedit/delete/${_id}`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Delete Edit Entry
const remove = async (_id, formData = {}) => {
  try {
    const response = await API.delete(
      `/milk/transactionedit/delete/${_id}`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Force Delete Edit Entry
const forceRemove = async (_id, formData = {}) => {
  try {
    const response = await API.delete(
      `/milk/transactionedit/forcedelete/${_id}`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const milktransactionEditService = {
  getAll,
  getById,
  markCreate,
  markUpdate,
  markDelete,
  remove,
  forceRemove,
};

export default milktransactionEditService;
