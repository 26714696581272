const messages = {
  plant_code: {
    required: 'Plant Code is required',
    invalid: 'Invalid Plant Code',
  },
  plant_name: {
    required: 'Plant Name is required',
    invalid: 'Invalid Plant Name',
    max: 'Plant name length exceeded',
  },
  is_active: {
    required: 'Status is required',
    invalid: 'Invalid Status',
  },
  proc_type: {
    required: 'Please choose a Proc Type',
    invalid: 'Invalid Proc Type',
  },
  city: {
    required: 'City is required',
    invalid: 'Invalid City',
    max: 'City length exceeded',
  },
  gu_plant_name: {
    required: 'Plant Name (GUJ) is required',
    invalid: 'Invalid Plant Name (GUJ)',
    max: 'Plant Name (GUJ) length exceeded',
  },
  gu_city: {
    required: 'City (GUJ) is required',
    invalid: 'Invalid City (GUJ)',
    max: 'Plant Name (GUJ) length exceeded',
  },
};

export default messages;
