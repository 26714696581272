import React, { Component } from 'react';
import _500 from 'pages/_500';

const Error500 = _500?.routeProps?.component;

export default class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state to show the fallback UI during the next render phase
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // logging the error details
    console.log(`Cause: ${error}.\nStackTrace: ${info.componentStack}`);
  }

  render() {
    if (this.state.hasError) {
      // Return the fallback UI
      return <Error500 />;
    }

    return this.props.children;
  }
}
