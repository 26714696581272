import { Badge, Center } from '@chakra-ui/react';

const getBadgeColor = type => {
  if (type === 'C') {
    return 'yellow';
  } else if (type === 'U') {
    return 'cyan';
  } else {
    return 'pink';
  }
};

const renderMilkEntryTrType = cell => {
  if (cell.value) {
    return (
      <Center>
        <Badge variant="solid" colorScheme={getBadgeColor(cell.value)}>
          {cell.value}
        </Badge>
      </Center>
    );
  } else {
    return <Center>-</Center>;
  }
};

export default renderMilkEntryTrType;
