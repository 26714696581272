import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Flex, Button } from '@chakra-ui/react';
import TableActionColumn from 'components/tables/TableActionColumn';
import { FaRev, FaEye } from 'react-icons/fa';

const hasCustom = ['superadmin', 'storeadmin', 'storeuser'];

export default function ActionColumn({ row }) {
  const history = useHistory();

  const customAction = () => {
    history.push({
      pathname: `/store/consumptions/return/${row._id}`,
      state: row,
    });
  };

  const customButton = (
    <Flex>
      <Button
        type="button"
        size="xs"
        mr="2"
        colorScheme="purple"
        as={Link}
        target="_blank"
        to={{
          pathname: `/documents/${row?.tr_type?.toLowerCase()}/${row._id}`,
          // state: { ...row },
        }}
      >
        <FaEye size="16" className="text-white" />
      </Button>
      {row?.tr_type === 'CONSUMPTION' && !row?.return_id && (
        <Button
          type="button"
          size="xs"
          colorScheme="red"
          onClick={customAction}
          // disabled={row.return_id}
        >
          <FaRev size="16" className="text-white" />
        </Button>
      )}
    </Flex>
  );

  return (
    <TableActionColumn
      row={row}
      hasCustom={hasCustom}
      customComponent={customButton}
    />
  );
}
