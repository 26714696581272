const messages = {
  username: {
    required: 'Username is required',
    min: 'Username is too short',
    max: 'Username length exceeded',
  },
  mobile: {
    required: 'Mobile is required',
    invalid: 'Invalid Mobile number',
  },
};

export default messages;
