import React from 'react';
import { Link } from 'react-router-dom';
import { Center, Box, Heading, Text, Button } from '@chakra-ui/react';

function _Error500() {
  return (
    <Center>
      <Box textAlign="center" py={10} px={6} h="100vh" w="100vw">
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, red.400, red.600)"
          backgroundClip="text"
        >
          500
        </Heading>
        <Text fontSize="18px" mt={3} mb={2} color="red" fontWeight="bold">
          Server Error
        </Text>
        <Text color={'gray.500'} mb={6}>
          There is something wrong on our end. Please try again later. If issue
          persists, please contact us.
        </Text>

        <Button
          as={Link}
          to="/dashboard"
          colorScheme="orange"
          bgGradient="linear(to-r, orange.400, orange.500, orange.600)"
          color="white"
          variant="solid"
        >
          GO TO HOME
        </Button>
      </Box>
    </Center>
  );
}

const _500 = {
  routeProps: {
    path: '/error',
    component: _Error500,
    exact: true,
  },
  name: 'Unexpected Error',
  title: 'Unexpected Error',
};

export default _500;
