import URLS from 'configs/urls';

const fields = {
  names: ['firstname', 'middlename', 'lastname', 'mobile', 'email'],
  firstname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  middlename: {
    default: '',
    disabled: false,
    readonly: false,
  },
  lastname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  mobile: {
    default: '',
    disabled: true,
    readonly: false,
  },
  email: {
    default: '',
    disabled: true,
    readonly: false,
  },
  profile_picture: {
    default: `${URLS.profileImage}/default.jpeg`,
    disabled: false,
    readonly: false,
  },
  employee_code: {
    default: '',
    disabled: true,
    readonly: true,
  },
};

export default fields;
