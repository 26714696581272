import _CONSTATNTS from 'constants/_Constants';

const initialState = {
  currentPage: 1,
  limit: 10,
  nextPage: null,
  previousPage: null,
  resultCount: 0,
  totalPages: 1,
};

const metaReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case _CONSTATNTS.SET_META:
      return {
        currentPage: payload.currentPage,
        limit: payload.limit,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        resultCount: payload.resultCount,
        totalPages: payload.totalPages,
      };
    case _CONSTATNTS.UNSET_META:
      return {};
    default:
      return state;
  }
};

export default metaReducer;
