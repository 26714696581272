import API from '../helpers/API';

const getOwnProfile = async () => {
  try {
    const response = await API.get('/users/profile/me');
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateOwnProfile = async formData => {
  try {
    const response = await API.patch('/users/update/me', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const forgetPassword = async formData => {
  try {
    const response = await API.post('/auth/forgetpassword/mobile', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const resetPassword = async formData => {
  try {
    const response = await API.patch('/auth/resetpassword/mobile', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const verifyMobile = async formData => {
  try {
    const response = await API.post('/users/verify/mobile', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const resendMobileVerificationOtp = async formData => {
  try {
    const response = await API.post('/users/resend/mobileotp', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const verifyEmail = async formData => {
  try {
    const response = await API.post('/users/verify/email', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const resendEmailVerificationToken = async formData => {
  try {
    const response = await API.post('/users/resend/emailcode', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const changeOwnPassword = async formData => {
  try {
    const response = await API.patch('/auth/changepassword', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const changeOwnEmail = async formData => {
  try {
    const response = await API.patch('/auth/change/email', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const changeOwnMobile = async formData => {
  try {
    const response = await API.patch('/auth/change/mobile', formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const profileService = {
  getOwnProfile,
  updateOwnProfile,
  forgetPassword,
  resetPassword,
  verifyMobile,
  resendMobileVerificationOtp,
  verifyEmail,
  resendEmailVerificationToken,
  changeOwnPassword,
  changeOwnEmail,
  changeOwnMobile,
};

export default profileService;
