import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@chakra-ui/react';
import { FaFileCsv } from 'react-icons/fa';
import { CSVLink } from 'react-csv';

export default function TableExport({
  exportHeaders,
  data,
  exportFilename = `SD_ExportedFile`,
  onExport,
}) {
  const [expData, setExpData] = useState([]);

  useEffect(() => {
    setExpData(data);
  }, [data]);

  return (
    <Button
      variant="outline"
      colorScheme="orange"
      size="sm"
      as={CSVLink}
      headers={exportHeaders}
      data={expData}
      filename={exportFilename}
      onClick={async () => {
        if (onExport) {
          data = await onExport(expData);
          setExpData(data);
        }
        toast.success(
          `Exporeted the file ${exportFilename}.csv with ${
            data?.length || 'some'
          } records.`
        );
        return expData.length > 0;
      }}
    >
      <FaFileCsv size="14" />
    </Button>
  );
}
