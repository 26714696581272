import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import dashboardService from 'services/dashboardService';

// Dashboard Components
import SocietyTotalStats from 'modules/DashboardSociety/Components/SocietyTotalStats';
import SocietyDistrictStats from 'modules/DashboardSociety/Components/SocietyDistrictStats';
import SocietyOtherStats from 'modules/DashboardSociety/Components/SocietyOtherStats';
import SocietyPlantStats from 'modules/DashboardSociety/Components/SocietyPlantStats';
import SocietyQtyTop from 'modules/DashboardSociety/Components/SocietyQtyTop';
import SocietyQtyBottom from 'modules/DashboardSociety/Components/SocietyQtyBottom';
import SocietyAvgFatTop from 'modules/DashboardSociety/Components/SocietyAvgFatTop';
import SocietyAvgFatBottom from 'modules/DashboardSociety/Components/SocietyAvgFatBottom';
import SocietyAvgSnfTop from 'modules/DashboardSociety/Components/SocietyAvgSnfTop';
import SocietyAvgSnfBottom from 'modules/DashboardSociety/Components/SocietyAvgSnfBottom';
import SocietyAdultrationsTop from './Components/SocietyAdultrationsTop';

const societyTotalStatsRoles = ['*'];
const societyOtherStatsRoles = ['superadmin', 'admin', 'md'];
const societyDistrictWiseStatsRoles = ['superadmin', 'admin', 'md'];
const societyPlantStatsRoles = ['superadmin', 'admin', 'md'];

const IndexPage = props => {
  const [societyData, setSocietyData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector(state => state.auth.user);

  const isSupervisor = _.intersection(['supervisor'], user.role).length > 0;

  const isAllowed = (allowedRoles = []) => {
    return _.intersection(allowedRoles, ['*', ...user.role]).length > 0;
  };

  const refreshSocietyData = async () => {
    setLoading(true);
    const query = { filters: { fields: 'society' } };
    const resData = await dashboardService.getAll(query);
    if (resData && resData?.data?.society) {
      setSocietyData(resData?.data?.society);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshSocietyData();
  }, []);

  useEffect(() => {
    if (societyData?.district?.length) {
      setDistrictData(societyData?.district);
    }
    if (societyData?.plants?.length) {
      setPlantData(societyData?.plants);
    }
  }, [societyData]);

  return (
    <Box h="full" w="full" p={4} mt={{ sm: 24, md: 12, lg: 2 }}>
      <Flex
        direction="column"
        justifyContent="start"
        alignItems="start"
        w="full"
        h="full"
      >
        {/* Row 1 */}
        {!isSupervisor && (
          <Flex
            direction={['column', 'row']}
            justifyContent="start"
            alignItems="start"
            w="full"
            h="800px"
          >
            <Flex
              direction={['column', 'column']}
              justifyContent="start"
              alignItems="start"
              w="full"
              h="full"
            >
              <Flex
                direction={['column', 'row']}
                justifyContent="start"
                alignItems="start"
                w="full"
                h="50%"
                p="2"
              >
                {isAllowed(societyTotalStatsRoles) && (
                  <Box height="full" width={{ sm: 'full', md: '50%' }} p="4">
                    <SocietyTotalStats
                      loading={loading}
                      data={societyData}
                      refreshData={refreshSocietyData}
                    />
                  </Box>
                )}
                {isAllowed(societyOtherStatsRoles) && (
                  <Box height="full" width={{ sm: 'full', md: '50%' }} p="4">
                    <SocietyOtherStats
                      loading={loading}
                      data={societyData}
                      refreshData={refreshSocietyData}
                    />
                  </Box>
                )}
              </Flex>

              <Flex
                direction={['column', 'row']}
                justifyContent="start"
                alignItems="start"
                w="full"
                h="50%"
              >
                {isAllowed(societyDistrictWiseStatsRoles) && (
                  <Box height="full" width={{ sm: 'full', md: 'full' }} p="4">
                    <SocietyDistrictStats
                      loading={loading}
                      data={districtData}
                      refreshData={refreshSocietyData}
                    />
                  </Box>
                )}
              </Flex>
            </Flex>

            <Flex
              direction={['column', 'column']}
              justifyContent="start"
              alignItems="start"
              w="full"
              h="full"
              p="6"
            >
              {isAllowed(societyPlantStatsRoles) && (
                <SocietyPlantStats
                  loading={loading}
                  data={plantData}
                  refreshData={refreshSocietyData}
                />
              )}
            </Flex>
          </Flex>
        )}

        {/* Row 2 */}
        <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          <Box h="full" w={['full', '34%']} p={4}>
            <SocietyAdultrationsTop />
          </Box>
        </Flex>

        {/* Row 3 */}
        <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          <Box h="full" w={['full', '34%']} p={4}>
            <SocietyQtyTop milkType="all" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyQtyTop milkType="buff" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyQtyTop milkType="cow" />
          </Box>
        </Flex>

        {/* Row 4 */}
        <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          <Box h="full" w={['full', '34%']} p={4}>
            <SocietyQtyBottom milkType="all" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyQtyBottom milkType="buff" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyQtyBottom milkType="cow" />
          </Box>
        </Flex>

        {/* Row 5 */}
        <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          <Box h="full" w={['full', '34%']} p={4}>
            <SocietyAvgFatTop milkType="all" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgFatTop milkType="buff" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgFatTop milkType="cow" />
          </Box>
        </Flex>

        {/* Row 6 */}
        <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          <Box h="full" w={['full', '34%']} p={4}>
            <SocietyAvgSnfTop milkType="all" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgSnfTop milkType="buff" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgSnfTop milkType="cow" />
          </Box>
        </Flex>

        {/* Row 7 */}
        <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          <Box h="full" w={['full', '34%']} p={4}>
            <SocietyAvgFatBottom milkType="all" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgFatBottom milkType="buff" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgFatBottom milkType="cow" />
          </Box>
        </Flex>

        {/* Row 8 */}
        <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          <Box h="full" w={['full', '34%']} p={4}>
            <SocietyAvgSnfBottom milkType="all" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgSnfBottom milkType="buff" />
          </Box>
          <Box h="full" w={['full', '33%']} p={4}>
            <SocietyAvgSnfBottom milkType="cow" />
          </Box>
        </Flex>

        {/* Row 9 */}
        {/* <Flex
          direction={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
          w="full"
          h="full"
        >
          Row 9
        </Flex> */}
      </Flex>
    </Box>
  );
};

const DashboardSociety = {
  routeProps: {
    path: '/dashboardsociety',
    component: IndexPage,
    exact: true,
  },
  name: 'Society Dashboard',
  title: 'Society Dashboard',
};

export default DashboardSociety;
