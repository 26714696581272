import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/society?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async _id => {
  try {
    const response = await API.get(`/society/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const create = async (formData = {}) => {
  try {
    const response = await API.post(`/society/create`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const update = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/society/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateSocietyOtherDetails = async (society_id, formData = {}) => {
  try {
    const response = await API.patch(`/society/other/${society_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateSocietyIncometaxDetails = async (society_id, formData = {}) => {
  try {
    const response = await API.patch(
      `/society/incometax/${society_id}`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateSocietyChairmanDetails = async (society_id, formData = {}) => {
  try {
    const response = await API.patch(
      `/society/chairman/${society_id}`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateSocietySecretaryDetails = async (society_id, formData = {}) => {
  try {
    const response = await API.patch(
      `/society/secretary/${society_id}`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateSocietyPlant = async (society_id, plant_id) => {
  try {
    const response = await API.patch(`/society/changeplant/${society_id}`, {
      plant_id: plant_id,
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateSocietyBank = async (society_id, formData = {}) => {
  try {
    const response = await API.patch(`/society/bank/${society_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const societyService = {
  getAll,
  getById,
  create,
  update,
  updateSocietyPlant,
  updateSocietyBank,
  updateSocietyOtherDetails,
  updateSocietyIncometaxDetails,
  updateSocietyChairmanDetails,
  updateSocietySecretaryDetails,
};

export default societyService;
