import React from 'react';
import moment from 'helpers/moment';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  List,
  ListItem,
  ListIcon,
  Tooltip,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import renderDataCell from 'components/tables/helpers/renderDataCell';

const renderData = (col, row) => {
  if (col.id === 'jobs') {
    const jobs = row.original?.jobs;

    if (jobs.length > 0) {
      return (
        <List spacing={3}>
          {jobs.map(job => {
            return (
              <Tooltip
                key={job._id}
                label={
                  job.lastFinishedAt
                    ? `FINISHED AT : ${moment(job.lastFinishedAt).format(
                        moment.defaultDateTimeFormat
                      )}`
                    : '-'
                }
                fontSize="md"
                placement="top-start"
              >
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  {job.name}
                </ListItem>
              </Tooltip>
            );
          })}
        </List>
      );
    } else {
      <div>-</div>;
    }
  } else {
    return renderDataCell(col, row);
  }
};

export default function TableDetails({ columns, row }) {
  const user = useSelector(state => state?.auth?.user);

  const showId = col => {
    if (user?.role.length && user?.role[0] === 'superadmin') {
      if (col.hidden) {
        return true;
      } else {
        return false;
      }
    } else if (col.hidden && col?.id !== '_id') {
      return true;
    }
    return false;
  };

  return (
    <Box ml={8} mr={8}>
      <Table size="sm" variant="simple" colorScheme="telegram">
        <Tbody>
          {columns.map(col => {
            if (showId(col)) {
              return (
                <Tr key={col.id}>
                  <Th align="left">{col.Header}</Th>
                  <Td align="left"> {renderData(col, row)} </Td>
                </Tr>
              );
            } else {
              return undefined;
            }
          })}
        </Tbody>
      </Table>
    </Box>
  );
}
