import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import addSchema from './addSchema';
import userService from 'services/userService';
import roleService from 'services/roleService';
import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';

const AddPage = () => {
  const FormTitle = 'ADD USER';
  const IndexPageTitle = 'USERS';
  const IndexPagePath = '/users';

  const defaultValues = {
    username: '',
    mobile: '',
    role: ['user'],
    password: '',
    password_confirmation: '',
    employee_code: '',
  };

  const apiError = useSelector(state => state.error);
  const [roles, setRoles] = useState([]);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  // Get User Roles
  const fetchRoles = useCallback(async () => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const query = {};
      const resData = await roleService.getAll(query);
      if (resData) {
        setRoles(resData?.data);
      }
    }
  }, []);

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const setDefaultRoles = () => {
    const selectedRoles = [];
    defaultValues.role.forEach(r => {
      selectedRoles.push(roles.find(option => option?.role === r));
    });
    setValue('role', selectedRoles);
  };

  const onReset = () => {
    reset();
    handleRoleChange(null, 'clear');
  };

  const watchRole = watch('role');

  useEffect(() => {
    setDefaultRoles();
    // eslint-disable-next-line
  }, [roles]);

  const handleRoleChange = (selected, action) => {
    if (action === 'clear') {
      setDefaultRoles();
      return;
    }
    return setValue('role', selected);
  };

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        username: formData.username,
        mobile: formData.mobile,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        employee_code: formData.employee_code,
      };

      if (!formDataSend.employee_code || formDataSend.employee_code === '') {
        delete formDataSend.employee_code;
      }

      if (formData?.role) {
        const roles_send = [];
        formData?.role.forEach(role => {
          roles_send.push(role.role);
        });
        formDataSend.role = roles_send;
      }
      const responseData = await userService.create(formDataSend);

      if (responseData) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="username"
                    id="username"
                    label="Username"
                    placeholder="Username"
                    error={errors.username}
                    register={register('username')}
                  />
                </Box>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="mobile"
                    id="mobile"
                    label="Mobile"
                    placeholder="Mobile"
                    error={errors.mobile}
                    register={register('mobile')}
                  />
                </Box>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={true}
                    isClearable={true}
                    name="role"
                    label="Roles"
                    // register={register('role')}
                    labelKey="role"
                    valueKey="role"
                    options={roles}
                    placeholder="Select Role"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.role}`}
                    getOptionValue={option => option?.role}
                    handleChange={handleRoleChange}
                    error={errors.role}
                    control={control}
                    defaultValue={watchRole}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="password"
                    name="password"
                    id="password"
                    label="Password"
                    placeholder="Password"
                    error={errors.password}
                    register={register('password')}
                  />
                </Box>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    label="Confirmation Password"
                    placeholder="Confirmation Password"
                    error={errors.password_confirmation}
                    register={register('password_confirmation')}
                  />
                </Box>
                <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="employee_code"
                    id="employee_code"
                    label="Employee Code"
                    placeholder="Employee Code"
                    error={errors.employee_code}
                    register={register('employee_code')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const UserAdd = {
  routeProps: {
    path: '/users/add',
    component: AddPage,
    exact: true,
  },
  name: 'Add User',
  title: 'Add User',
};

export default UserAdd;
