import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Center } from '@chakra-ui/react';
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa';
import {
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

export default function TableActionColumn({
  row,
  hasShow = [],
  hasEdit = [],
  editLink,
  hasDelete = [],
  hasCustom = [],
  showHeading,
  handleDelete,
  showContent,
  customComponent = '',
}) {
  let showShow = false;
  let showEdit = false;
  let showDelete = false;
  let showCustom = false;

  const user = useSelector(state => state.auth.user);

  if (_.intersection(hasShow, user.role).length > 0) {
    showShow = true;
  }

  if (_.intersection(hasEdit, user.role).length > 0) {
    showEdit = true;
  }

  if (_.intersection(hasDelete, user.role).length > 0) {
    showDelete = true;
  }

  if (_.intersection(hasCustom, user.role).length > 0) {
    showCustom = true;
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue('gray.200', 'black');
  // const textColor = useColorModeValue('gray.300', 'gray.300');

  const ModelShow = ({ row }) => {
    return (
      <>
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          isCentered
          size="full"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent bg={bgColor} p="8">
            <ModalHeader>{showHeading ? showHeading : 'Show'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{showContent ? showContent : null}</ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Center>
      <ModelShow row={row} />
      <ButtonGroup variant="outline" spacing="2">
        {showShow && (
          <Button type="button" size="xs" colorScheme="blue" onClick={onOpen}>
            <FaRegEye />
          </Button>
        )}

        {showEdit && (
          <Button
            type="button"
            size="xs"
            colorScheme="teal"
            as={Link}
            to={{
              pathname: editLink || '#',
              state: row,
            }}
          >
            <FaEdit />
          </Button>
        )}

        {showDelete && (
          <Button
            type="button"
            size="xs"
            colorScheme="red"
            onClick={handleDelete}
          >
            <FaTrash />
          </Button>
        )}

        {showCustom && customComponent}
      </ButtonGroup>
    </Center>
  );
}
