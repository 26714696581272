import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import renderDate from 'components/tables/helpers/renderDate';
import renderStatus from 'components/tables/helpers/renderStatus';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';

import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'society_code',
    accessor: 'society_code',
    Header: 'Code',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'sap_code',
    accessor: 'sap_code',
    Header: 'SAP Code',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'society_name',
    accessor: 'society_name',
    Header: 'Name',
    type: 'string',
    hidden: false,
    // headerAlign: 'center',
    Cell: cell => <div style={{ minWidth: '300px' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.societyname.maxWidth,
    minWidth: tblConsts.societyname.minWidth,
  },
  {
    id: 'city',
    accessor: 'city',
    Header: 'City',
    type: 'string',
    hidden: false,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'taluka',
    accessor: 'taluka',
    Header: 'Taluka',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'district',
    accessor: 'district',
    Header: 'Dist',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'state',
    accessor: 'state',
    Header: 'State',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'country',
    accessor: 'country',
    Header: 'Country',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'bank_ifsc',
    accessor: 'bank_ifsc',
    Header: 'IFSC',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'bank_account_number',
    accessor: 'bank_account_number',
    Header: 'AC No',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.shorttext.maxWidth,
    minWidth: tblConsts.shorttext.minWidth,
  },
  {
    id: 'bank_name',
    accessor: 'bank_name',
    Header: 'Bank',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.mediumtext.maxWidth,
    minWidth: tblConsts.mediumtext.minWidth,
  },
  {
    id: 'bank_branch',
    accessor: 'bank_branch',
    Header: 'Branch',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'bank_city',
    accessor: 'bank_city',
    Header: 'Bank City',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'pan',
    accessor: 'pan',
    Header: 'PAN',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.shorttext.maxWidth,
    minWidth: tblConsts.shorttext.minWidth,
  },
  {
    id: 'pan_name',
    accessor: 'pan_name',
    Header: 'PAN Name',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'gst',
    accessor: 'gst',
    Header: 'GST',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.shorttext.maxWidth,
    minWidth: tblConsts.shorttext.minWidth,
  },
  {
    id: 'gst_name',
    accessor: 'gst_name',
    Header: 'GST Name',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },

  // Chairman Details
  {
    id: 'chairman_fname',
    accessor: 'chairman_fname',
    Header: 'Chairman Firstname',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_mname',
    accessor: 'chairman_mname',
    Header: 'Chairman Middlename',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_lname',
    accessor: 'chairman_lname',
    Header: 'Chairman Lastname',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_address1',
    accessor: 'chairman_address1',
    Header: 'Chairman Address 1',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_address2',
    accessor: 'chairman_address2',
    Header: 'Chairman Address 2',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_address3',
    accessor: 'chairman_address3',
    Header: 'Chairman Address 3',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_city',
    accessor: 'chairman_city',
    Header: 'Chairman City',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_pin',
    accessor: 'chairman_pin',
    Header: 'Chairman Pin',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_mobile',
    accessor: 'chairman_mobile',
    Header: 'Chairman Mobile',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_email',
    accessor: 'chairman_email',
    Header: 'Chairman E-Mail',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_pan',
    accessor: 'chairman_pan',
    Header: 'Chairman PAN',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_pan_name',
    accessor: 'chairman_pan_name',
    Header: 'Chairman PAN Name',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'chairman_adhar',
    accessor: 'chairman_adhar',
    Header: 'Chairman Adhar',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  // Secratery Details
  {
    id: 'secretary_fname',
    accessor: 'secretary_fname',
    Header: 'Secretary Firstname',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_mname',
    accessor: 'secretary_mname',
    Header: 'Secretary Middlename',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_lname',
    accessor: 'secretary_lname',
    Header: 'Secretary Lastname',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_address1',
    accessor: 'secretary_address1',
    Header: 'Secretary Address 1',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_address2',
    accessor: 'secretary_address2',
    Header: 'Secretary Address 2',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_address3',
    accessor: 'secretary_address3',
    Header: 'Secretary Address 3',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_city',
    accessor: 'secretary_city',
    Header: 'Secretary City',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_pin',
    accessor: 'secretary_pin',
    Header: 'Secretary Pin',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_mobile',
    accessor: 'secretary_mobile',
    Header: 'Secretary Mobile',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_email',
    accessor: 'secretary_email',
    Header: 'Secretary E-Mail',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_pan',
    accessor: 'secretary_pan',
    Header: 'Secretary PAN',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_pan_name',
    accessor: 'secretary_pan_name',
    Header: 'Secretary PAN Name',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'secretary_adhar',
    accessor: 'secretary_adhar',
    Header: 'Secretary Adhar',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  //
  {
    id: 'society_type',
    accessor: 'society_type',
    Header: 'Soc Type',
    type: 'string',
    hidden: false,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'society_gender',
    accessor: 'society_gender',
    Header: 'Soc Gender',
    type: 'string',
    hidden: false,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'proposed_date',
    accessor: 'proposed_date',
    Header: 'Propose Date',
    type: 'date',
    hidden: true,
    // headerAlign: 'center',
    Cell: ({ cell }) => renderDate(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.date.maxWidth,
    minWidth: tblConsts.date.minWidth,
  },
  {
    id: 'registration_date',
    accessor: 'registration_date',
    Header: 'Registration Date',
    type: 'date',
    hidden: true,
    // headerAlign: 'center',
    Cell: ({ cell }) => renderDate(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.date.maxWidth,
    minWidth: tblConsts.date.minWidth,
  },
  {
    id: 'registration_no',
    accessor: 'registration_no',
    Header: 'Registration No',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.mediumtext.maxWidth,
    minWidth: tblConsts.mediumtext.minWidth,
  },
  {
    id: 'is_active',
    accessor: 'is_active',
    Header: 'Status',
    type: 'bool',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },

  // *** Gujarati Data ***

  {
    id: 'gu_society_name',
    accessor: 'gu_society_name',
    Header: 'નામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_city',
    accessor: 'gu_city',
    Header: 'ગામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_taluka',
    accessor: 'gu_taluka',
    Header: 'તાલુકા',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_district',
    accessor: 'gu_district',
    Header: 'જિલ્લો',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_state',
    accessor: 'gu_state',
    Header: 'રાજ્ય',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_country',
    accessor: 'gu_country',
    Header: 'દેશ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_chairman_fname',
    accessor: 'gu_chairman_fname',
    Header: 'ચેરમેન નામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_chairman_mname',
    accessor: 'gu_chairman_mname',
    Header: 'ચેરમેન પીતા નું નામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_chairman_lname',
    accessor: 'gu_chairman_lname',
    Header: 'ચેરમેન અટક',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_chairman_address1',
    accessor: 'gu_chairman_address1',
    Header: 'ચેરમેન સરનામું ૧',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_chairman_address2',
    accessor: 'gu_chairman_address2',
    Header: 'ચેરમેન સરનામું ૨',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_chairman_address3',
    accessor: 'gu_chairman_address3',
    Header: 'ચેરમેન સરનામું ૩',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_chairman_city',
    accessor: 'gu_chairman_city',
    Header: 'ચેરમેન ગામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_secretary_fname',
    accessor: 'gu_secretary_fname',
    Header: 'સેક્રેટરી નામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_secretary_mname',
    accessor: 'gu_secretary_mname',
    Header: 'સેક્રેટરી પીતા નું નામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_secretary_lname',
    accessor: 'gu_secretary_lname',
    Header: 'સેક્રેટરી અટક',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_secretary_address1',
    accessor: 'gu_secretary_address1',
    Header: 'સેક્રેટરી સરનામું ૧',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_secretary_address2',
    accessor: 'gu_secretary_address2',
    Header: 'સેક્રેટરી સરનામું ૨',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'gu_secretary_address3',
    accessor: 'gu_secretary_address3',
    Header: 'સેક્રેટરી સરનામું ૩',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_secretary_city',
    accessor: 'gu_secretary_city',
    Header: 'સેક્રેટરી ગામ',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_society_type',
    accessor: 'gu_society_type',
    Header: 'મંડળી નો પ્રકાર',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_society_gender',
    accessor: 'gu_society_gender',
    Header: 'પુરુષ/મહિલા',
    type: 'string',
    hidden: true,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },
  // ** Gujarati Data End **

  {
    id: 'plant_code',
    accessor: 'plant_id.plant_code',
    Header: 'Plant Code',
    type: 'string',
    hidden: false,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
  },

  {
    id: 'plant_name',
    accessor: 'plant_id.plant_name',
    Header: 'Plant Name',
    type: 'string',
    hidden: false,
    // headerAlign: 'center',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },

  {
    id: 'supervisor_username',
    accessor: 'supervisor',
    Header: 'Supervisor Username',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'supervisor_username',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },

  // {
  //   id: 'supervisor_mobile',
  //   accessor: 'supervisor',
  //   Header: 'Supervisor Mobile',
  //   type: 'object',
  //   hidden: true,
  //   headerAlign: 'left',
  //   Cell: ({ cell }) => {
  //     return <div>{cell.value ? cell.value?.mobile : '-'}</div>;
  //   },
  //   exportable: true,
  //   disableSortBy: true,
  //   exportHeader: 'supervisor_mobile',
  //   expandfunction: value => {
  //     return <div>{value ? value?.mobile : '-'}</div>;
  //   },
  // },

  // {
  //   id: 'supervisor_email',
  //   accessor: 'supervisor',
  //   Header: 'Supervisor E-Mail',
  //   type: 'object',
  //   hidden: true,
  //   headerAlign: 'left',
  //   Cell: ({ cell }) => {
  //     return <div>{cell.value ? cell.value?.email : '-'}</div>;
  //   },
  //   exportable: true,
  //   disableSortBy: true,
  //   exportHeader: 'supervisor_email',
  //   expandfunction: value => {
  //     return <div>{value ? value?.email : '-'}</div>;
  //   },
  //   maxWidth: tblConsts.mediumtext.maxWidth,
  //   minWidth: tblConsts.mediumtext.minWidth,
  // },

  {
    id: 'send_message',
    accessor: 'send_message',
    Header: 'Send Message',
    type: 'bool',
    hidden: false,
    // headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: false,
    disableSortBy: false,
  },

  {
    id: 'send_message_supervisor',
    accessor: 'send_message_supervisor',
    Header: 'Supervisor Message',
    type: 'bool',
    hidden: false,
    // headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: false,
    disableSortBy: true,
  },

  //
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: false,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: false,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'cemter',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columns;
