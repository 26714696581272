import React, { useState, useEffect, useRef } from 'react';
import {
  Flex,
  Box,
  Center,
  Text,
  VStack,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { toast } from 'react-toastify';

import userService from 'services/userService';

export default function UpdateStatus({ data }) {
  const fetchIdRef = useRef(0);
  const [status, setstatus] = useState(data?._is_active);

  useEffect(() => setstatus(data?.is_active), [data]);

  const updateUserStatus = async () => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const responseData = await userService.updateStatus(data._id, {
        is_active: !status,
      });

      if (responseData) {
        setstatus(!status);
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <form>
      <Box
        maxW={'350px'}
        minW={'350px'}
        w={'full'}
        h={'full'}
        bg={useColorModeValue('white', 'gray.800')}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
        p={4}
      >
        <Flex
          direction={['column', 'column']}
          justifyContent="space-between"
          h="full"
          w="full"
        >
          <Stack
            textAlign={'center'}
            p={6}
            color={useColorModeValue('gray.800', 'white')}
            align={'center'}
          >
            <Text
              fontSize={'sm'}
              fontWeight={500}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              px={3}
              color={'blue.500'}
              rounded={'full'}
            >
              USER STATUS
            </Text>
          </Stack>
          <Center>
            <VStack spacing={6} align={'center'} h="full" w="full">
              <Box w="64" mr={[0, 4]} mb={[1, 0]}>
                <Center>
                  {status ? (
                    <VStack spacing="8">
                      <UnlockIcon w={16} h={16} color="green.500" />
                      <Text
                        fontSize="xl"
                        fontWeight="extrabold"
                        color="green.500"
                      >
                        ACTIVE
                      </Text>
                    </VStack>
                  ) : (
                    <VStack spacing="8">
                      <LockIcon w={16} h={16} color="red.500" />
                      <Text
                        fontSize="xl"
                        fontWeight="extrabold"
                        color="red.500"
                      >
                        INACTIVE
                      </Text>
                    </VStack>
                  )}
                </Center>
              </Box>
            </VStack>
          </Center>
          <Box>
            <Button
              mt={10}
              w={'full'}
              bg={status ? 'green.400' : 'red.400'}
              color={'white'}
              rounded={'xl'}
              boxShadow={'0 5px 20px 0px rgb(72 187 120 / 43%)'}
              onClick={updateUserStatus}
            >
              {status ? 'LOCK' : 'UNLOCK'}
            </Button>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
