import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Button,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
} from '@chakra-ui/react';

import { Link, useParams } from 'react-router-dom';

import EditButtons from './EditButtons';
import societyService from 'services/societyService';

const ShowBankDetailsPage = () => {
  const FormTitle = 'BANK DETAILS';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const [data, setData] = useState(null);
  const [details, setDetails] = useState({
    bank_account_number: null,
    bank_ifsc: null,
    bank_name: null,
    bank_branch: null,
    bank_city: null,
  });

  const { _id } = useParams();

  useEffect(() => {
    const fetchSociety = async () => {
      const responseData = await societyService.getById(_id);
      if (responseData && responseData.data) {
        setData(responseData.data);
      }
    };
    fetchSociety();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setDetails({
        bank_account_number: data.bank_account_number,
        bank_ifsc: data.bank_ifsc,
        bank_name: data.bank_name,
        bank_branch: data.bank_branch,
        bank_city: data.bank_city,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      <Box h="20px" mb={[4, 4]}>
        <EditButtons _id={data?._id} />
      </Box>

      <Box>
        {!details.bank_account_number ? (
          <Heading as="h4" size="md">
            Society does not have any bank details
          </Heading>
        ) : (
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Th>Bank A/C Number</Th>
                <Td>{details?.bank_account_number}</Td>
              </Tr>
              <Tr>
                <Th>Bank IFSC</Th>
                <Td>{details?.bank_ifsc}</Td>
              </Tr>
              <Tr>
                <Th>Bank Name</Th>
                <Td>{details?.bank_name}</Td>
              </Tr>
              <Tr>
                <Th>Bank Branch</Th>
                <Td>{details?.bank_branch}</Td>
              </Tr>
              <Tr>
                <Th>Bank City</Th>
                <Td>{details?.bank_city}</Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Box>
    </VStack>
  );
};

// Exports

const ShowBankDetails = {
  routeProps: {
    path: '/societies/details/bank/:_id',
    component: ShowBankDetailsPage,
    exact: true,
  },
  name: 'Bank Details',
  title: 'Bank Details',
};

export default ShowBankDetails;
