// Test
import Test from 'modules/Test';

// Pre Auth
import ChangeMobile from 'modules/ChangeMobile';
import ChangeEmail from 'modules/ChangeEmail';
import ChangePassword from 'modules/ChangePassword';

// Dashboards & Profile
import Dashboard from 'modules/Dashboard';
import DashboardMilk from 'modules/DashboardMilk';
import DashboardSociety from 'modules/DashboardSociety';
import DashboardSocietyAnalysis from 'modules/DashboardSocietyAnalysis';
import Profile from 'modules/Profile';

// Users
import Users from 'modules/Users';
import UserAdd from 'modules/Users/Add';
import UserEdit from 'modules/Users/Edit';

// Authorizations
import AuthorizeUserPlant from 'modules/AuthorizeUserPlant';
import AuthorizeUserSociety from 'modules/AuthorizeUserSociety';
import SocietyMove from 'modules/SocietyMove';
import SocietyBank from 'modules/SocietyBank';

// Plants
import Plants from 'modules/Plants';
import PlantAdd from 'modules/Plants/Add';
import PlantEdit from 'modules/Plants/Edit';

// Fiscal Years
import FiscalYears from 'modules/FiscalYears';
import FiscalYearAdd from 'modules/FiscalYears/Add';

// Cities
import Cities from 'modules/Cities';
import CityAdd from 'modules/Cities/Add';
import CityEdit from 'modules/Cities/Edit';

// Societies
import Societies from 'modules/Societies';
import SocietyAdd from 'modules/Societies/Add';
import SocietyEdit from 'modules/Societies/Edit';
import SocietyOtherDetails from 'modules/Societies/EditOtherDetails';
import SocietyIncometaxDetails from 'modules/Societies/EditIncometaxDetails';
import SocietyChairmanDetails from 'modules/Societies/EditChairmanDetails';
import SocietySecretaryDetails from 'modules/Societies/EditSecretaryDetails';
import ShowPlantDetails from 'modules/Societies/ShowPlantDetails';
import ShowSupervisorDetails from 'modules/Societies/ShowSupervisorDetails';
import ShowBankDetails from 'modules/Societies/ShowBankDetails';

// Milk File Uploads
import MilkFileUploads from 'modules/MilkFileUploads';
import MilkFileUpload from 'modules/MilkFileUploads/Add';
import MilkFileDelete from 'modules/MilkFileUploads/Delete';
import MilkFileFinalize from 'modules/MilkFileUploads/Finalize';

// Milk Transactions
import MilkTransactions from 'modules/MilkTransactions';

// Milk Transaction Edits
import MilkTransactionedits from 'modules/MilkTransactionedits';
import MilkTransactionMarkAdd from 'modules/MilkTransactionedits/MarkAdd';
import MilkTransactionMarkEdit from 'modules/MilkTransactionedits/MarkEdit';
import MilkTransactionMarkDelete from 'modules/MilkTransactionedits/MarkDelete';
import MilkTransactionEditDelete from 'modules/MilkTransactionedits/Delete';
import MilkTransactionEditForceDelete from 'modules/MilkTransactionedits/DeleteForce';
import MilkTransactionApproveReject from 'modules/MilkTransactionedits/ApproveReject';
import MilkTransactionApproveRejectBulk from 'modules/MilkTransactionedits/ApproveRejectBulk';
import MilkTransactioneditHistories from 'modules/MilkTransactioneditHistories';

// Milk Adultrations
import MilkAdulterationTypes from 'modules/MilkAdulterationTypes';
import MilkAdulterationTypeAdd from 'modules/MilkAdulterationTypes/Add';
import MilkAdulterationTypeEdit from 'modules/MilkAdulterationTypes/Edit';
import MilkAdulterations from 'modules/MilkAdulterations';
import MilkAdulterationAdd from 'modules/MilkAdulterations/Add';
import MilkAdulterationDelete from 'modules/MilkAdulterations/Delete';

// Maintenance Jobs
import Jobs from 'modules/Jobs';
import JobSchedules from 'modules/JobSchedules';

// Maintenance Histories and Logs
import Histories from 'modules/Histories';
import Messages from 'modules/Messages';
import LogsApp from 'modules/LogsApp';
import LogsServer from 'modules/LogsServer';
import UserActivities from 'modules/UserActivities';

// Materials Masters
import Units from 'modules/Units';
import UnitAdd from 'modules/Units/Add';
import MaterialCatagories from 'modules/MaterialCatagories';
import MaterialCatagoryAdd from 'modules/MaterialCatagories/Add';
import Materials from 'modules/Materials';
import MaterialAdd from 'modules/Materials/Add';
import MaterialEdit from 'modules/Materials/Edit';
import MaterialDiscontinue from 'modules/Materials/MaterialDiscontinue';
import Stock from 'modules/Stock';
import StockHistories from 'modules/StockHistories';

// Third Parties
import Vendors from 'modules/Vendors';
import VendorAdd from 'modules/Vendors/Add';
import VendorEdit from 'modules/Vendors/Edit';
import Customers from 'modules/Customers';
import CustomerAdd from 'modules/Customers/Add';
import CustomerEdit from 'modules/Customers/Edit';

// Store Operations
import Sales from 'modules/Sales';
import SalesAdd from 'modules/Sales/Add';
import SalesRet from 'modules/Sales/Return';
import SalesSociety from 'modules/SalesSociety';
import SalesSocietyAdd from 'modules/SalesSociety/Add';
import SalesSocietyRet from 'modules/SalesSociety/Return';
import Purchases from 'modules/Purchases';
import PurchaseAdd from 'modules/Purchases/Add';
import PurchaseRet from 'modules/Purchases/Return';
import StockTransfers from 'modules/StockTransfers';
import StockTransferAdd from 'modules/StockTransfers/Add';
import Consumtions from 'modules/Consumtions';
import ConsumtionAdd from 'modules/Consumtions/Add';
import ConsumtionRet from 'modules/Consumtions/Return';
import Scraps from 'modules/Scraps';
import ScrapAdd from 'modules/Scraps/Add';
import ScrapRet from 'modules/Scraps/Return';

// Reports
import ReportMilkPurchSummary from 'modules/RptMilkSummary';

const modules = [
  // Test
  Test,

  // Pre Auth
  ChangeMobile,
  ChangeEmail,
  ChangePassword,

  // Dashboards & Profile
  Dashboard,
  DashboardMilk,
  DashboardSociety,
  DashboardSocietyAnalysis,
  Profile,

  // Users
  Users,
  UserAdd,
  UserEdit,

  // Authorize
  AuthorizeUserPlant,
  AuthorizeUserSociety,
  SocietyMove,
  SocietyBank,

  // Plants
  Plants,
  PlantAdd,
  PlantEdit,

  // Fiscal Years
  FiscalYears,
  FiscalYearAdd,

  // Cities
  Cities,
  CityAdd,
  CityEdit,

  // Societies
  Societies,
  SocietyAdd,
  SocietyEdit,
  SocietyOtherDetails,
  SocietyIncometaxDetails,
  SocietyChairmanDetails,
  SocietySecretaryDetails,
  ShowPlantDetails,
  ShowSupervisorDetails,
  ShowBankDetails,

  // Milk File Uploads
  MilkFileUploads,
  MilkFileUpload,
  MilkFileDelete,
  MilkFileFinalize,

  // Milk Transactions
  MilkTransactions,

  // Milk Transaction Edits
  MilkTransactionedits,
  MilkTransactionMarkAdd,
  MilkTransactionMarkEdit,
  MilkTransactionMarkDelete,
  MilkTransactionEditDelete,
  MilkTransactionEditForceDelete,
  MilkTransactionApproveReject,
  MilkTransactionApproveRejectBulk,
  MilkTransactioneditHistories,

  // Milk Adultrations
  MilkAdulterationTypes,
  MilkAdulterationTypeAdd,
  MilkAdulterationTypeEdit,
  MilkAdulterations,
  MilkAdulterationAdd,
  MilkAdulterationDelete,

  // Third Parties
  Vendors,
  VendorAdd,
  VendorEdit,
  Customers,
  CustomerAdd,
  CustomerEdit,

  // Store Operations
  Sales,
  SalesAdd,
  SalesRet,
  SalesSociety,
  SalesSocietyAdd,
  SalesSocietyRet,
  Purchases,
  PurchaseAdd,
  PurchaseRet,
  StockTransfers,
  StockTransferAdd,
  Consumtions,
  ConsumtionAdd,
  ConsumtionRet,
  Scraps,
  ScrapAdd,
  ScrapRet,
  Stock,
  StockHistories,

  // Materials Masters
  Units,
  UnitAdd,
  MaterialCatagories,
  MaterialCatagoryAdd,
  Materials,
  MaterialAdd,
  MaterialEdit,
  MaterialDiscontinue,

  // Maintenance Jobs
  Jobs,
  JobSchedules,

  // Maintenance Histories
  Histories,
  Messages,
  LogsApp,
  LogsServer,
  UserActivities,

  // Reports
  ReportMilkPurchSummary,
];

export default modules;
