import React from 'react';
import { Text, Flex, Image } from '@chakra-ui/react';
import { stringTruncate } from 'helpers/genHelpers';

const getName = (firstname, lastname) => {
  let fullname = '';
  if (firstname && firstname !== '') {
    fullname = fullname + ' ' + firstname;
  }
  if (lastname && lastname !== '') {
    fullname = fullname + ' ' + lastname;
  }
  if (fullname === '') {
    return 'User';
  }
  return stringTruncate(fullname.toUpperCase(), 30);
};

const RenderUserOption = ({ user, avatarSize }) => {
  let imageSize;
  let fullNameTextSize = 'ms';
  let userNameTextSize = 'sm';

  if (!avatarSize) {
    imageSize = '40px';
  } else if (avatarSize < 30 || avatarSize > 60) {
    imageSize = '40px';
  } else {
    imageSize = avatarSize + 'px';
  }
  return (
    <Flex alignItems={'center'}>
      <Image
        borderRadius="full"
        boxSize={imageSize}
        src={user.profile_image_url}
        alt="Dan Abramov"
        fit="cover"
        loading="lazy"
        mr={2}
      />
      <Flex direction={'column'}>
        <Text
          fontSize={fullNameTextSize}
          fontWeight={'bolder'}
          color="orange.700"
        >
          {getName(user.firstname, user.lastname)}
        </Text>
        <Text fontSize={userNameTextSize} fontWeight={'bold'} color="gray.700">
          {user.username}
        </Text>
      </Flex>
    </Flex>
  );
};

export default RenderUserOption;
