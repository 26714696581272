import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Heading,
  Button,
  useColorModeValue,
  Select,
  FormControl,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import dashboardSocietyService from 'services/dashboardSocietyService';

import TopBottomCommon from 'modules/DashboardSociety/Components/TopBottomCommon';

const DAY_OPTIONS = [1, 5, 10];

function SocietyQtyTop({ milkType = 'all' }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [milk_type] = useState(milkType);
  const [type] = useState('top');
  const [limit] = useState(10);
  const [selectedDays, setSelectedDays] = useState(DAY_OPTIONS[0]);

  const refreshData = async () => {
    setLoading(true);
    setData([]);
    const resData = await dashboardSocietyService.getSocietyQty(
      milk_type,
      type,
      limit,
      selectedDays
    );
    if (resData && resData?.data) {
      setData(resData?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [selectedDays]);

  return (
    <Flex
      direction={['column', 'column']}
      justifyContent="space-between"
      alignItems="center"
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      w="full"
      h="full"
      p={4}
    >
      <Flex
        h="50px"
        w={['full', 'full']}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb="4"
      >
        <Heading
          fontSize="xl"
          color={useColorModeValue('green.500', 'green.200')}
        >
          TOP {limit} WEIGHT {`${milkType.toUpperCase()}`}
        </Heading>

        <Box>
          <FormControl>
            <InputGroup size="sm">
              <InputLeftAddon children="Days" />
              <Select
                size="sm"
                varient="filled"
                colorScheme="orange"
                focusBorderColor="orange.500"
                onChange={e => setSelectedDays(e.target.value)}
                defaultValue={selectedDays}
              >
                {DAY_OPTIONS.map(day => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>
        </Box>

        <Button
          variant="ghost"
          isLoading={loading}
          loadingText="Refreshing..."
          onClick={refreshData}
          outline="none"
          _focus={{ boxShadow: 'none' }}
          _hover={{
            bg: 'transperent',
            scale: '50',
            color: 'teal.500',
            size: 'xs',
          }}
        >
          <RepeatIcon w={8} h={8} color="green.500" />
        </Button>
      </Flex>

      <Box h="full" w={['full', 'full']} minW="full">
        <TopBottomCommon
          isTop={true}
          data={data}
          loading={loading}
          dataKey="weight_kg"
          dataLabel="WEIGHT"
        />
      </Box>
    </Flex>
  );
}

export default SocietyQtyTop;
