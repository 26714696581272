import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Heading,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import milktransactionEditService from 'services/milktransactionEditService';

const DeleteForcePage = ({ match }) => {
  const { _id } = match.params;

  const [data, setData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setData(location?.state);
  }, [location]);

  const FormTitle = 'DELETE MILK TRANSACTION EDIT ENTRY (FORCEFULLY)';
  const IndexPageTitle = 'MILK TRANSACTION EDITS';
  const IndexPagePath = '/milktransactionedits';

  const history = useHistory();

  const onSubmit = async () => {
    const resData = await milktransactionEditService.forceRemove(_id);
    if (resData) {
      toast.success(`${resData.message}`);
      history.push(`/milktransactionedits`);
    }
  };
  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[4, 0]}>
        <form>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <Heading size="lg" color="red.500">
                    Are You Sure you want to delete ?
                  </Heading>
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <Table size="sm">
                    <Tbody>
                      <Tr>
                        <Th>Date</Th>
                        <Td>
                          {moment(data?.proc_date).format(
                            moment.defaultDateFormat
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>Shift</Th>
                        <Td>{data?.shift}</Td>
                      </Tr>
                      <Tr>
                        <Th>Plant Code</Th>
                        <Td>{data?.plant_code}</Td>
                      </Tr>
                      <Tr>
                        <Th>Plant Name</Th>
                        <Td>{data?.plant_name}</Td>
                      </Tr>
                      <Tr>
                        <Th>Proc Type</Th>
                        <Td>{data?.proc_type}</Td>
                      </Tr>
                      <Tr>
                        <Th>Status</Th>
                        <Td>{data?.progress}</Td>
                      </Tr>
                      <Tr>
                        <Th>Message</Th>
                        <Td>{data?.messages}</Td>
                      </Tr>
                      <Tr>
                        <Th>Uploaded By</Th>
                        <Td>{data?.username_create}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="button"
                  colorScheme="red"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  onClick={onSubmit}
                >
                  DELETE
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  as={Link}
                  to={`/milktransactionedits`}
                >
                  CANCEL
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MilkTransactionEditForceDelete = {
  routeProps: {
    path: '/milktransactionedits/force/delete/:_id',
    component: DeleteForcePage,
    exact: true,
  },
  name: 'Milk Transaction Edit Force Delete',
  title: 'Milk Transaction Edit Force Delete',
};

export default MilkTransactionEditForceDelete;
