// import { toast } from "react-toastify";
import _CONSTATNTS from 'constants/_Constants';

export const setError = err => {
  return async dispatch => {
    if (err) {
      dispatch(setErrorState(err));
      // toast.error(`${err.message}`);
    } else {
      // toast.error(`Something went wrong. Unknown Error.`);
    }
  };
};

export const hideError = () => {
  return async dispatch => {
    dispatch(hideErrorState());
  };
};

export const setErrorState = err => {
  return {
    type: _CONSTATNTS.SET_ERROR,
    error: err,
  };
};

export const hideErrorState = () => {
  return {
    type: _CONSTATNTS.HIDE_ERROR,
  };
};
