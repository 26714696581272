import * as yup from 'yup';

const filterSchema = yup.object().shape({
  code: yup.string().trim().max(6).nullable(true).label('Code'),
  name: yup.string().trim().max(10).nullable(true).label('Material'),
  catagory: yup.object().nullable(true).label('Catagory'),
  tax_type: yup.object().nullable(true).label('Tax Type'),
  tradable: yup.object().nullable(true).label('Tradable'),
  consumable: yup.object().nullable(true).label('Consumable'),
  is_active: yup.object().nullable(true).label('Status'),
  is_discontinued: yup.object().nullable(true).label('Discontinued'),
});

export default filterSchema;
