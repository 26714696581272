const messages = {
  proc_date: {
    required: 'Procurement Date is required',
    invalid: 'Invalid Procurement Date',
  },
  shift: {
    required: 'Please Select a Shift',
    invalid: 'Inavldi Shift selected',
  },
  plant_id: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant selected',
  },
  plant_code: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant selected',
  },
  society_id: {
    required: 'Please select a society',
    invalid: 'Invalid Society selected',
  },
  society_code: {
    required: 'Society Code is required',
    invalid: 'Invalid Society Code',
  },
};

export default messages;
