import authConstants from 'constants/authConstants';

import authService from 'services/authService';
import { isTokenExpired, decodeToken } from 'helpers/authHelper';
import { resetAll } from 'actions/_Actions';
import profileActions from 'actions/api/profileActions';
import plantActions from 'actions/api/plantActions';
import fiscalyearActions from 'actions/api/fiscalyearActions';

export const setAuthStatus = () => {
  return {
    type: authConstants.SET_AUTH_STATUS,
  };
};

export const unSetAuthStatus = () => {
  return {
    type: authConstants.UNSET_AUTH_STATUS,
  };
};

export const setUser = payload => {
  return {
    type: authConstants.SET_USER,
    payload: payload,
  };
};

export const unSetUser = () => {
  return {
    type: authConstants.UNSET_USER,
  };
};

const login = formData => {
  return async dispatch => {
    try {
      const authResponse = await authService.login(formData);
      if (authResponse && authResponse.code === 200) {
        const { data } = authResponse;

        const decodedToken = await decodeToken(data.token);

        if (!decodedToken) {
          dispatch({
            type: authConstants.LOGOUT,
          });
          localStorage.removeItem('user');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('token');
        }
        localStorage.setItem('token', data?.token);
        localStorage.setItem('refresh_token', data?.refresh_token);
        let user = {
          _id: decodedToken._id,
          username: decodedToken.username,
          role: decodedToken.role,
          is_active: decodedToken.is_active,
          plant: decodedToken.plant,
        };
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthStatus());
        dispatch(setUser(user));
        dispatch(profileActions.getProfile());
        dispatch(plantActions.getAll());
        dispatch(fiscalyearActions.getAll());
        dispatch(fiscalyearActions.getCurrent());
        if (user.role === 'superadmin') {
          dispatch(plantActions.getAllSuperadmin());
        }
      }
    } catch (err) {
      dispatch(unSetAuthStatus());
      dispatch(unSetUser());
    }
  };
};

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token');
  return async dispatch => {
    dispatch(unSetAuthStatus());
    dispatch(unSetUser());
    dispatch(resetAll());
  };
};

const checkAuth = () => {
  return async dispatch => {
    const token = localStorage.getItem('token');
    const expired = isTokenExpired(token);
    if (expired) {
      const refreshToken = localStorage.getItem('refresh_token');
      const expiredRefreshToken = isTokenExpired(refreshToken);
      if (expiredRefreshToken) {
        return dispatch(logout());
      } else {
        // Get New Access Token
        return await authService.getNewAccessToken();
      }
    }
  };
};

const authActions = {
  login,
  logout,
  checkAuth,
};

export default authActions;
