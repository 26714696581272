import _ from 'lodash';
import fiscalyearConstants from 'constants/fiscalyearConstants';
import fiscalyearService from 'services/fiscalyearService';

const getAll = () => {
  return async dispatch => {
    try {
      const fiscalyears = await fiscalyearService.getAll({
        sortby: 'createdAt',
      });

      if (fiscalyears && fiscalyears.data) {
        const years = [];
        _.forEach(fiscalyears.data, function (value, i) {
          const fis = _.pick(value, 'fiscal_year');
          years.push(fis);
        });
        dispatch({
          type: fiscalyearConstants.SET_FISCALYEARS,
          payload: years,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
};

const getCurrent = () => {
  return async dispatch => {
    try {
      const fiscalyears = await fiscalyearService.getCurrent();

      if (fiscalyears && fiscalyears.data) {
        dispatch({
          type: fiscalyearConstants.SET_CURRENT_FISCALYEAR,
          payload: fiscalyears.data,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
};

const fiscalyearActions = {
  getAll,
  getCurrent,
};

export default fiscalyearActions;
