import moment from 'helpers/moment';
import { Center } from '@chakra-ui/react';

const renderDate = cell => {
  let value = cell.value;
  if (!value) {
    value = '-';
  } else {
    value = moment(cell?.value).format(moment.defaultDateFormat);
  }
  return <Center minWidth={'70px'}>{value}</Center>;
};

export default renderDate;
