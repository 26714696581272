import cronstrue from 'cronstrue';

const renderCron = cell => {
  if (!cell.value) return '-';
  if (cell.value.includes('*')) {
    return cronstrue.toString(cell.value);
  }
  return cell.value;
};

export default renderCron;
