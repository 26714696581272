import fiscalyearConstants from 'constants/fiscalyearConstants';

const initialState = null;

const fiscalyearCurrentReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case fiscalyearConstants.SET_CURRENT_FISCALYEAR:
      return payload;
    default:
      return state;
  }
};

export default fiscalyearCurrentReducer;
