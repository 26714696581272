const messages = {
  proc_date: {
    required: 'Proc Date required',
    invalid: 'Invalid Proc Date value',
  },
  plant_code: {
    required: 'Plant Code required',
    invalid: 'Invalid Plant Code value',
  },
};

export default messages;
