import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderCron from 'components/tables/helpers/renderCron';
import renderActive from 'components/tables/helpers/renderActive';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'type',
    accessor: 'type',
    Header: 'Type',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'disabled',
    accessor: 'disabled',
    Header: 'Active',
    type: 'bool',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderActive(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'priority',
    accessor: 'priority',
    Header: 'Priority',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => <div align="center">{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'nextRunAt',
    accessor: 'nextRunAt',
    Header: 'Next Run',
    type: 'datetime',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'lastModifiedBy',
    accessor: 'lastModifiedBy',
    Header: 'Last Modified By',
    type: 'string',
    hidden: true,
    headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'lockedAt',
    accessor: 'lockedAt',
    Header: 'Locked',
    type: 'datetime',
    hidden: true,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'lastRunAt',
    accessor: 'lastRunAt',
    Header: 'Last Run',
    type: 'datetime',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'lastFinishedAt',
    accessor: 'lastFinishedAt',
    Header: 'Last Finished',
    type: 'datetime',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'startDate',
    accessor: 'startDate',
    Header: 'Start',
    type: 'datetime',
    hidden: true,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'endDate',
    accessor: 'endDate',
    Header: 'End',
    type: 'datetime',
    hidden: true,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'repeatInterval',
    accessor: 'repeatInterval',
    Header: 'Repeat',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => renderCron(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'repeatTimezone',
    accessor: 'repeatTimezone',
    Header: 'Repeat TZ',
    type: 'string',
    hidden: true,
    headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'skipDays',
    accessor: 'skipDays',
    Header: 'Skip Days',
    type: 'string',
    hidden: true,
    headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'failCount',
    accessor: 'failCount',
    Header: 'Fail Count',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => <div align="center">{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'failReason',
    accessor: 'failReason',
    Header: 'Fail Reason',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'data',
    accessor: 'data',
    Header: 'Data',
    type: 'object',
    hidden: true,
    headerAlign: 'center',
    exportable: false,
    disableSortBy: false,
  },
];

export default columns;
