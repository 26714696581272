import React, { useEffect } from 'react';
import { Link as RLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Flex, Box, Button, Text, Spacer, Heading } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';

import profileService from 'services/profileService';
import authActions from 'actions/api/authActions';

import InputField from 'components/form/InputField';
import schema from './schema';
import fields from './fields';

const IndexPage = () => {
  const dispatch = useDispatch();

  const apiError = useSelector(state => state.error);
  const user = useSelector(state => state.auth?.user);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    // reset,
    setValue,
  } = useForm(formOptions);

  const onSubmit = async formData => {
    const formDataSend = {
      email: formData.email,
    };

    const resData = await profileService.changeOwnEmail(formDataSend);

    if (resData) {
      toast.success(`${resData.message}`);
      dispatch(authActions.logout());
    }
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const setAllValues = () => {
    for (let i = 0; i < fields?.names.length; i++) {
      const field = fields?.names[i];
      setValue(field, user[field]);
    }
  };

  useEffect(() => {
    setAllValues();
    // eslint-disable-next-line
  }, [user]);

  const renderVerified = () => {
    return (
      <Flex alignItems="center">
        <Text>E-Mail</Text>
        <Text ml="2" color={user?.email ? 'green' : 'red'} fontSize="xs">
          {user?.email ? 'Verified' : 'Unverified'}
          {user?.email ? <CheckCircleIcon ml="1" /> : <WarningIcon ml="1" />}
        </Text>
      </Flex>
    );
  };

  return (
    <Box w="full" h="full">
      <Box display={{ md: 'flex' }} w="full" mb="4">
        <Heading size="md" color="orange">
          CHANGE E-MAIL
        </Heading>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display={{ md: 'flex' }} w="full">
          <InputField
            type="email"
            name="email"
            id="email"
            label={renderVerified('email')}
            placeholder="E-Mail"
            error={errors.email}
            register={register('email')}
            disabled={fields.email.disabled}
            readOnly={fields.email.readonly}
          />
        </Box>

        <Box justify="center" align="end">
          <Flex mt={4}>
            <Spacer />
            <Button
              colorScheme="orange"
              size="sm"
              w={{ base: 'full', md: '15%' }}
              mr={{ base: 2, md: 2 }}
              mb={{ base: 2, md: 0 }}
              type="submit"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              CHANGE
            </Button>
            <Button
              as={RLink}
              to="/profile"
              colorScheme="blue"
              size="sm"
              w={{ base: 'full', md: '15%' }}
              mr={{ base: 2, md: 2 }}
              mb={{ base: 2, md: 0 }}
              type="button"
            >
              GO TO PROFILE
            </Button>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

const ChangeEmail = {
  routeProps: {
    path: '/change-email',
    component: IndexPage,
    exact: true,
  },
  name: 'ChangeEmail',
  title: 'ChangeEmail',
};

export default ChangeEmail;
