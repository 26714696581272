import * as yup from 'yup';
import messages from './messages';

const schema = yup.object().shape({
  username: yup.string().required(messages.username.required),
  current_password: yup.string().required(messages.current_password.required),
  password: yup
    .string()
    .required(messages.password.required)
    .test('passwords-match', messages.password.match, function (value) {
      return this.parent.password_confirmation === value;
    }),
  password_confirmation: yup
    .string()
    .required(messages.password_confirmation.required)
    .test(
      'passwords-match',
      messages.password_confirmation.match,
      function (value) {
        return this.parent.password === value;
      }
    ),
});

export default schema;
