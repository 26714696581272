import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import addSchema from './addSchema';
import fields from 'modules/Cities/fields';
import cityService from 'services/cityService';
import countryService from 'services/countryService';
import stateService from 'services/stateService';
import districtService from 'services/districtService';

import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';

const AddPage = () => {
  const FormTitle = 'ADD CITY';
  const IndexPageTitle = 'CITIES';
  const IndexPagePath = '/cities';

  const defaultValues = {
    city: fields?.city?.default,
    taluka: fields?.taluka?.default,
    district: fields?.district?.default,
    state: fields?.state?.default,
    country: fields?.country?.default,
    gu_city: fields?.gu_city?.default,
    gu_taluka: fields?.gu_taluka?.default,
    gu_district: fields?.gu_district?.default,
    gu_state: fields?.gu_state?.default,
    gu_country: fields?.gu_country?.default,
  };

  const apiError = useSelector(state => state.error);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);

  const fetchIdRef = useRef(0);
  const fetchIdCountryRef = useRef(0);
  const fetchIdStateRef = useRef(0);
  const fetchIdDistrictRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    getValues,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const watchCountry = watch('country', null);
  const watchState = watch('state', null);
  const watchDistrict = watch('district', null);
  const watchTaluka = watch('taluka', null);

  const onReset = () => {
    reset();
    // handleCountryChange('clear', null);
    // handleStateChange('clear', null);
    handleDistrictChange('clear', null);
    handleTalukaChange('clear', null);
    setValue('country', countries[0]);
    setValue('gu_country', countries[0]?.gu_name);
    setValue('state', states[0]);
    setValue('gu_state', states[0]?.gu_name);
  };

  const handleCountryChange = (selected, action) => {
    if (action === 'clear') {
      setValue('country', null);
      setValue('gu_country', null);
      handleStateChange('clear', null);
      return;
    }
    setValue('country', selected);
    setValue('gu_country', selected?.gu_name);
  };

  const handleStateChange = (selected, action) => {
    handleDistrictChange('clear', null);
    if (action === 'clear') {
      setValue('state', null);
      return;
    }
    setValue('state', selected);
    setValue('gu_state', selected?.gu_name);
  };

  const handleDistrictChange = (selected, action) => {
    handleTalukaChange('clear', null);
    if (action === 'clear') {
      setValue('district', null);
      setValue('gu_district', null);
      return;
    }
    setValue('district', selected);
    setValue('gu_district', selected.gu_name);
  };

  const handleTalukaChange = (selected, action) => {
    if (action === 'clear') {
      setValue('taluka', null);
      setValue('gu_taluka', null);
      return;
    }
    setValue('taluka', selected);
    setValue('gu_taluka', selected?.gu_name);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const query = {};
      query.pagination = false;
      query.sortby = 'name';

      const responseData = await countryService.getAll(query);
      if (responseData && responseData.data) {
        setCountries(responseData.data);
      }
    };

    const fetchIdCountry = ++fetchIdCountryRef.current;

    if (fetchIdCountry === fetchIdCountryRef.current) {
      fetchCountries();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      const query = {};
      query.pagination = false;
      query.sortby = 'name';

      const responseData = await stateService.getAll(query);
      if (responseData && responseData.data) {
        setStates(responseData.data);
      }
    };

    const fetchIdState = ++fetchIdStateRef.current;

    if (fetchIdState === fetchIdStateRef.current) {
      fetchStates();
      setValue('state', states[0]);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      const st = getValues('state');
      const query = {};
      query.filters = {};

      if (st) {
        query.filters.state = watchState?.name;
      }
      query.pagination = false;
      query.sortby = 'name';

      const responseData = await districtService.getAll(query);
      if (responseData && responseData.data) {
        setDistricts(responseData.data);
      }
    };

    const fetchIdDistrict = ++fetchIdDistrictRef.current;

    if (fetchIdDistrict === fetchIdDistrictRef.current) {
      fetchDistricts();
    }
    // eslint-disable-next-line
  }, [watchState]);

  useEffect(() => {
    handleTalukaChange('clear', null);
    const tal = _.find(districts, { name: watchDistrict?.name });
    if (tal) {
      setTalukas(tal.talukas);
    }
    // eslint-disable-next-line
  }, [watchDistrict]);

  useEffect(() => {
    setValue('country', countries[0]);
    setValue('gu_country', countries[0]?.gu_name);
    setValue('state', states[0]);
    setValue('gu_state', states[0]?.gu_name);
    // eslint-disable-next-line
  }, [countries, states]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        city: formData?.city,
        taluka: formData?.taluka?.name,
        district: formData?.district?.name,
        state: formData?.state?.name,
        country: formData?.country?.name,
        gu_city: formData?.gu_city,
        gu_taluka: formData?.gu_taluka,
        gu_district: formData?.gu_district,
        gu_state: formData?.gu_state,
        gu_country: formData?.gu_country,
      };
      const responseData = await cityService.create(formDataSend);
      if (responseData) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      w="full"
      h="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="country"
                    label="Country"
                    labelKey="name"
                    valueKey="name"
                    options={countries}
                    placeholder="Select Country"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => option?.name}
                    handleChange={handleCountryChange}
                    error={errors.country}
                    control={control}
                    defaultValue={watchCountry}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="state"
                    label="state"
                    labelKey="name"
                    valueKey="name"
                    options={states}
                    placeholder="Select State"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => option?.name}
                    handleChange={handleStateChange}
                    error={errors.state}
                    control={control}
                    defaultValue={watchState}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="District"
                    label="District"
                    labelKey="district"
                    valueKey="district"
                    options={districts}
                    placeholder="Select District"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => option?.name}
                    handleChange={handleDistrictChange}
                    error={errors.district}
                    control={control}
                    defaultValue={watchDistrict}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_country"
                    id="gu_country"
                    label="દેશ"
                    placeholder="દેશ"
                    error={errors.gu_country}
                    register={register('gu_country')}
                    readonly={fields?.gu_country?.disabled}
                  />
                </Box>

                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_state"
                    id="gu_state"
                    label="રાજ્ય"
                    placeholder="રાજ્ય"
                    error={errors.gu_state}
                    register={register('gu_state')}
                    readonly={fields?.gu_state?.disabled}
                  />
                </Box>

                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_district"
                    id="gu_district"
                    label="જિલ્લો"
                    placeholder="જિલ્લો"
                    error={errors.gu_district}
                    register={register('gu_district')}
                    readonly={fields?.gu_district?.disabled}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="Taluka"
                    label="Taluka"
                    labelKey="taluka"
                    valueKey="taluka"
                    options={talukas}
                    placeholder="Select Taluka"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => option?.name}
                    handleChange={handleTalukaChange}
                    error={errors.taluka}
                    control={control}
                    defaultValue={watchTaluka}
                  />
                </Box>

                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_taluka"
                    id="gu_taluka"
                    label="તાલુકા"
                    placeholder="તાલુકા"
                    error={errors.gu_taluka}
                    register={register('gu_taluka')}
                    readonly={fields?.gu_taluka?.disabled}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="city"
                    id="city"
                    label="City"
                    placeholder="City"
                    error={errors.city}
                    register={register('city')}
                  />
                </Box>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_city"
                    id="gu_city"
                    label="ગામ"
                    placeholder="ગામ"
                    error={errors.gu_city}
                    register={register('gu_city')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const CityAdd = {
  routeProps: {
    path: '/cities/add',
    component: AddPage,
    exact: true,
  },
  name: 'Add City',
  title: 'Add City',
};

export default CityAdd;
