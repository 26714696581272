import React from 'react';
import TableActionColumn from 'components/tables/TableActionColumn';

const hasEdit = ['superadmin', 'admin'];

export default function ActionColumn({ row }) {
  return (
    <TableActionColumn
      row={row}
      hasEdit={hasEdit}
      editLink={`/milkadulterationtypes/edit/${row._id}`}
    />
  );
}
