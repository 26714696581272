import * as yup from 'yup';
import messages from './messages';

const filterSchema = yup.object().shape({
  city: yup.string().max(10, messages.city.max).trim(),
  taluka: yup.string().max(10, messages.taluka.max).trim(),
  district: yup.string().max(10, messages.district.max).trim(),
});

export default filterSchema;
