import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import 'styles/custom.css';

import pages from 'pages';
import store from 'helpers/store';

import {
  ChakraProvider,
  ColorModeScript,
  Spinner,
  Center,
  Box,
} from '@chakra-ui/react';
import { myTheme } from 'styles/theme';

import _404 from 'pages/_404';
import modules from 'modules';
import ErrorBoundary from 'components/reusables/ErrorBoundary';
import Banner from 'components/notifications/Banner';

// Components
const Layout = lazy(() => import('layouts/Layout'));
const LayoutPage = lazy(() => import('layouts/LayoutPage'));
const ProtectedPage = lazy(() => import('layouts/ProtectedPage'));
const Dashboard = lazy(() => import('modules/Dashboard'));
const Toast = lazy(() => import('components/notifications/Toast'));

window.addEventListener('error', e => {
  // prompt user to confirm refresh
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload();
  }
});

const spinner = (
  <Box position="fixed" zIndex="10000">
    <Center minH={'100vh'} minW={'100vw'}>
      <Spinner
        thickness="6px"
        speed="0.95s"
        emptyColor="gray.300"
        color="orange.500"
        size="xl"
      />
    </Center>
  </Box>
);

function App() {
  let history = useHistory();

  <ColorModeScript />;

  return (
    <ErrorBoundary>
      <ChakraProvider theme={myTheme}>
        <Provider store={store}>
          <Router history={history} basename="/">
            <Suspense fallback={spinner}>
              {process.env.REACT_APP_MONITOR_API_HEALTH === 'true' && (
                <div>Online Detector</div>
              )}
              <Banner />
              <Toast />
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <ProtectedPage
                  exact={true}
                  path="/"
                  name="Home"
                  title="Home"
                  render={props => <Layout module={Dashboard} {...props} />}
                />
                {pages.map(page => (
                  <LayoutPage
                    key={page.routeProps.path}
                    page={page}
                    exact={page.routeProps.exact}
                    path={page.routeProps.path}
                    name={page.name}
                    title={page.title}
                  />
                ))}

                {modules.map(module => (
                  <ProtectedPage
                    key={module.name}
                    exact={module.routeProps.exact}
                    path={module.routeProps.path}
                    name={module.name}
                    title={module.title}
                    render={props => <Layout module={module} {...props} />}
                  />
                ))}
                {/* All Not Found Routes */}
                <Route component={_404?.routeProps?.component} />
              </Switch>
            </Suspense>
          </Router>
        </Provider>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default App;
