import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import schema from './schema';

import SelectField from 'components/form/SelectField';
import AsyncSelectField from 'components/form/AsyncSelectField';
import RenderSocietyOption from 'components/form/RenderSocietyOption';

import societyService from 'services/societyService';

const FormTitle = 'MOVE SOCIETY';

const defaultValues = {
  plant: '',
  society: '',
};

const IndexPage = () => {
  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);

  const [societies, setSocieties] = useState([]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    handleSocietyChange('clear', null);
    handlePlantChange('clear', null);
  };

  const watchSociety = watch('society', null);
  const watchPlant = watch('plant', null);

  useEffect(() => {
    fetchSocieties();
  }, []);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const responseData = await societyService.updateSocietyPlant(
        formData.society?._id,
        formData.plant?._id || null
      );

      if (responseData) {
        onReset();
        fetchSocieties();
        toast.success(`${responseData.message}`);
      }
    }
  };

  const fetchSocieties = async () => {
    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'society_code';
      query.fields = '_id,society_code,society_name,is_active,city,district';
      const responseData = await societyService.getAll(query);

      if (responseData) {
        setSocieties(responseData?.data);
      }
    }
  };

  const handleSocietyChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society', null);
      setValue('plant', null);
      return;
    }
    setValue('society', selected);
    if (selected && selected?.plant_id) {
      setValue('plant', selected?.plant_id);
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant', null);
      return;
    }
    return setValue('plant', selected);
  };

  const filterValues = (inputValue = '') => {
    const activeOnly = true;
    if (!activeOnly) {
      if (inputValue.startsWith('*')) {
        return societies.filter(i =>
          i?.society_name
            ?.toLowerCase()
            ?.startsWith(inputValue.toLowerCase().slice(1))
        );
      } else {
        return societies.filter(i =>
          i?.society_code?.toString()?.startsWith(inputValue)
        );
      }
    }

    // Return Active Only
    const activeSocieties = _.filter(societies, { is_active: true });

    if (inputValue.startsWith('*')) {
      return activeSocieties.filter(i =>
        i?.society_name
          ?.toLowerCase()
          ?.startsWith(inputValue.toLowerCase().slice(1))
      );
    } else {
      return activeSocieties.filter(i => {
        return i?.society_code?.toString()?.startsWith(inputValue);
      });
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      w="full"
      h="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
        </Flex>
      </Box>

      {/* Form */}
      <Box w="full" h="full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px" w="full" h="full">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <AsyncSelectField
                    isMulti={false}
                    isClearable={true}
                    name="society"
                    label="Society"
                    labelKey="society_code"
                    valueKey="_id"
                    options={societies}
                    placeholder="Select Society"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => (
                      <RenderSocietyOption
                        society={option}
                        showCity={true}
                        showDistrict={true}
                        showPlant={true}
                        showSupervisor={true}
                      />
                    )}
                    getOptionValue={option => option?._id}
                    handleChange={handleSocietyChange}
                    error={errors.society}
                    control={control}
                    defaultValue={watchSociety}
                    filterValues={filterValues}
                  />
                </Box>
                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="plant"
                    label="Plant"
                    labelKey="plant_code"
                    valueKey="_id"
                    options={plants}
                    placeholder="Select Plant"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option =>
                      `${option['plant_code']}-${option['plant_name']}`
                    }
                    getOptionValue={option => option?._id}
                    handleChange={handlePlantChange}
                    error={errors.plant}
                    control={control}
                    defaultValue={watchPlant}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  MOVE SOCIETY
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const SocietyMove = {
  routeProps: {
    path: '/society/move',
    component: IndexPage,
    exact: true,
  },
  name: 'Authorize Society',
  title: 'Authorize Society',
};

export default SocietyMove;
