import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'helpers/moment';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Heading,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import SelectField from 'components/form/SelectField';
import TextAreaField from 'components/form/TextAreaField';
import renderMilkEntryTrType from 'components/tables/helpers/renderMilkEntryTrType';
import renderMilkEntryType from 'components/tables/helpers/renderMilkEntryType';

import columns from './columns';
import approveRejectSchema from './approveRejectSchema';
import milktransactionEditService from 'services/milktransactionEditService';
import milktransactionApproveRejectService from 'services/milktransactionApproveRejectService';

const APPROVE_OPTIONS = [
  { name: 'APPROVE', value: true },
  { name: 'REJECT', value: false },
];

const CHNAGABLE_FIELDS = ['cans', 'weight_kg', 'fat', 'snf', 'amount'];

const defaultValues = {
  ids: [],
  approve_note: 'Approve/Reject',
  is_approved: { name: 'APPROVE', value: true },
};

const ApproveReject = ({ match }) => {
  const FormTitle = 'APPROVE/REJECT MILK TRANSACTION';
  const IndexPageTitle = 'MILK TRANSACTION EDITS';
  const IndexPagePath = '/milktransactionedits';

  const { _id } = match.params;
  const history = useHistory();
  const apiError = useSelector(state => state.error);

  const [transaction, setTransaction] = useState({});
  const [transactionOri, setTransactionOri] = useState({});
  const [labels, setLabels] = useState([]);
  const [ids, setIds] = useState(defaultValues.ids);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(approveRejectSchema),
    defaultValues: defaultValues,
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    control,
    watch,
  } = useForm(formOptions);

  const watchIsApproved = watch('is_approved', null);

  useEffect(() => {
    setValue('is_approved', defaultValues.is_approved);
    setValue('approve_note', defaultValues.approve_note);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIds([_id]);
    // eslint-disable-next-line
  }, [_id]);

  useEffect(() => {
    const newLables = [];
    _.forEach(columns, trn => {
      newLables[trn['id']] = trn['Header'];
    });
    setLabels(newLables);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getById = async () => {
      const responseData = await milktransactionEditService.getById(_id);
      if (responseData && responseData.data) {
        setTransactionOri(responseData?.data?.tr_id);
        delete responseData?.data?.tr_id;
        setTransaction(responseData.data);
      }
    };
    getById();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async formData => {
    return watchIsApproved?.value ? approve(formData) : reject(formData);
  };

  const approve = async formData => {
    const formDataSend = {
      ids: ids,
      approve_note: formData.approve_note,
    };
    const resData = await milktransactionApproveRejectService.approve(
      formDataSend
    );

    if (resData) {
      history.push('/milktransactionedits');
      toast.success(`${resData.message}`);
    }
  };

  const reject = async formData => {
    const formDataSend = {
      ids: ids,
      approve_note: formData.approve_note,
    };
    const resData = await milktransactionApproveRejectService.reject(
      formDataSend
    );

    if (resData) {
      history.push('/milktransactionedits');
      toast.success(`${resData.message}`);
    }
  };

  const handleApproveChange = (selected, action) => {
    if (action === 'clear') {
      setValue('is_approved', null);
      return;
    }
    return setValue('is_approved', selected);
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[4, 2]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="is_approved"
                    label="APPROVE OR REJECT"
                    labelKey="name"
                    valueKey="value"
                    options={APPROVE_OPTIONS}
                    placeholder="Select Status"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleApproveChange}
                    error={errors.is_approved}
                    control={control}
                    defaultValue={watchIsApproved}
                  />
                </Box>
                <Box w={['full', 4 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="approve_note"
                    id="approve_note"
                    label="Approve Note"
                    placeholder="Write Your Remark For The Edit Entry"
                    error={errors.approve_note}
                    register={register('approve_note')}
                  />
                </Box>
              </Flex>

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme={watchIsApproved?.value ? 'green' : 'red'}
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid}
                >
                  {watchIsApproved?.value ? 'APPROVE' : 'REJECT'}
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  as={Link}
                  to={`/milktransactionedits`}
                >
                  CANCEL
                </Button>
              </Flex>
              {/* Form Contents */}
            </VStack>
          </Stack>
        </form>
      </Box>
      <Box mt={[4, 2]}>
        <Stack direction={['column', 'row']} spacing="24px">
          <VStack spacing={6} align="stretch" h="full" w="full">
            <Flex direction={['column', 'row']}>
              <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Th colSpan="2">
                        <Heading fontSize="lg" color="orange">
                          DETAILS
                        </Heading>
                      </Th>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Transaction Type
                      </Th>
                      <Td>
                        {renderMilkEntryTrType({ value: transaction?.tr_type })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Entry Type
                      </Th>
                      <Td>
                        {renderMilkEntryType({
                          value: transaction?.entry_type,
                        })}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Procurement Date
                      </Th>
                      <Td>
                        {moment(transaction?.proc_date).format(
                          moment.defaultDateFormat
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Shift
                      </Th>
                      <Td>{transaction?.shift}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Society Code
                      </Th>
                      <Td>{transaction?.society_code}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Society Name
                      </Th>
                      <Td>{transaction?.society_name}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Society City
                      </Th>
                      <Td>{transaction?.city}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Plant Code
                      </Th>
                      <Td>{transaction?.plant_code}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Plant Name
                      </Th>
                      <Td>{transaction?.plant_name}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Milk Catagory
                      </Th>
                      <Td>{transaction?.milk_catagory}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Milk Type
                      </Th>
                      <Td>{transaction?.milk_note}</Td>
                    </Tr>
                    <Tr>
                      <Th color="teal.500" fontWeight="extrabold">
                        Entry Note
                      </Th>
                      <Td>{transaction?.note}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </VStack>
        </Stack>
      </Box>
      <Box mt={[4, 2]}>
        <Stack direction={['column', 'row']} spacing="24px">
          <VStack spacing={6} align="stretch" h="full" w="full">
            <Flex direction={['column', 'row']}>
              <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Th colSpan="3">
                        <Heading fontSize="lg" color="orange">
                          CHANGES
                        </Heading>
                      </Th>
                    </Tr>

                    <Tr>
                      <Th>
                        <Heading fontSize="md" color="blue.500">
                          PARAMETER
                        </Heading>
                      </Th>
                      <Th>
                        <Heading fontSize="md" color="cyan.400">
                          OLD VALUE
                        </Heading>
                      </Th>
                      <Th>
                        <Heading fontSize="md" color="green.300">
                          NEW VALUE
                        </Heading>
                      </Th>
                    </Tr>

                    {transaction['tr_type'] === 'C' &&
                      CHNAGABLE_FIELDS.map((value, i) => {
                        return (
                          <Tr key={i}>
                            <Th color="teal.500" fontWeight="extrabold">
                              {labels[value]}
                            </Th>
                            <Td>-</Td>
                            <Td>{transaction[value]}</Td>
                          </Tr>
                        );
                      })}

                    {transaction['tr_type'] === 'U' &&
                      CHNAGABLE_FIELDS.map((value, i) => {
                        return (
                          <Tr key={i}>
                            <Th
                              color={
                                transactionOri[value] === transaction[value]
                                  ? 'teal.500'
                                  : 'red.400'
                              }
                              fontWeight="extrabold"
                            >
                              {labels[value]}
                            </Th>
                            <Td
                              color={
                                transactionOri[value] === transaction[value]
                                  ? ''
                                  : 'red.400'
                              }
                              fontWeight={
                                transactionOri[value] === transaction[value]
                                  ? 'normal'
                                  : 'extrabold'
                              }
                            >
                              {transactionOri[value]}
                            </Td>
                            <Td
                              color={
                                transactionOri[value] === transaction[value]
                                  ? ''
                                  : 'red.400'
                              }
                              fontWeight={
                                transactionOri[value] === transaction[value]
                                  ? 'normal'
                                  : 'extrabold'
                              }
                            >
                              {transaction[value]}
                            </Td>
                          </Tr>
                        );
                      })}

                    {transaction['tr_type'] === 'D' &&
                      CHNAGABLE_FIELDS.map((value, i) => {
                        return (
                          <Tr key={i}>
                            <Th color="teal.500" fontWeight="extrabold">
                              {labels[value]}
                            </Th>
                            <Td>{transaction[value]}</Td>
                            <Td>-</Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </VStack>
        </Stack>
      </Box>
    </VStack>
  );
};

// Exports

const MilkTransactionApproveReject = {
  routeProps: {
    path: '/milktransactionedits/approve/:_id',
    component: ApproveReject,
    exact: true,
  },
  name: 'Milk Transaction Approve/Reject',
  title: 'Milk Transaction Approve/Reject',
};

export default MilkTransactionApproveReject;
