import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Table, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import renderDataCell from 'components/tables/helpers/renderDataCell';

export default function TableDetails({ columns, data }) {
  const user = useSelector(state => state?.auth?.user);

  const [cols, setCols] = useState([]);
  const [record, setRecord] = useState(null);

  useEffect(() => setCols(columns), [columns]);
  useEffect(() => setRecord(data), [data]);

  const showId = col => {
    if (user?.role.length && user?.role[0] === 'superadmin') {
      if (col.hidden) {
        return true;
      } else {
        return false;
      }
    } else if (col.hidden && col?.id !== '_id') {
      return true;
    }
    return false;
  };

  return (
    <Box ml={8} mr={8}>
      <Table size="sm" variant="simple" colorScheme="telegram">
        <Tbody>
          {cols.map(col => {
            if (showId(col)) {
              return (
                <Tr key={col.id}>
                  <Th align="left">{col.Header}</Th>
                  <Td align="left"> {renderDataCell(col, record)} </Td>
                </Tr>
              );
            } else {
              return undefined;
            }
          })}
        </Tbody>
      </Table>
    </Box>
  );
}
