import React from 'react';
import {
  Flex,
  Box,
  Stack,
  Heading,
  useColorModeValue,
  Image,
  Avatar,
  Tag,
  StatGroup,
  Stat,
  StatLabel,
  StatHelpText,
  Badge,
  Text,
} from '@chakra-ui/react';

const ActiveUser = (
  <Badge variant="solid" colorScheme="whatsapp" py="1" px="2" ml="2">
    Active
  </Badge>
);

const InActiveUser = (
  <Badge variant="solid" colorScheme="red" py="1" px="2" ml="2">
    InActive
  </Badge>
);

export default function ProfileImageCard({ profile, photoname }) {
  return (
    <Box
      // maxW={'270px'}
      w={['full', 'full', '320px']}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'2xl'}
      rounded={'md'}
      overflow={'hidden'}
    >
      <Image h={'120px'} w={'full'} src={photoname} objectFit={'cover'} />
      <Flex justify={'center'} mt={-12}>
        <Avatar
          size={'xl'}
          src={`${photoname}`}
          alt={'Author'}
          css={{
            border: '2px solid white',
          }}
        />
      </Flex>

      <Box p={6}>
        <Stack spacing={0} align={'center'} mb={5}>
          <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
            {profile?.username}
            {profile?.is_active ? ActiveUser : InActiveUser}
          </Heading>
          <Text color={'gray.500'}>
            Employee Code : {profile?.employee_code || '-'}
          </Text>
        </Stack>

        <Stack direction={'row'} justify={'center'} mb={5} spacing={1}>
          {profile?.role.map(r => (
            <Tag key={r} size={`sm`} variant="solid" colorScheme="purple">
              {r}
            </Tag>
          ))}
        </Stack>

        {profile?.plant && (
          <Stack direction={'row'} justify={'start'} mb={5} spacing={6}>
            <Stack spacing={0} align={'center'}>
              <StatGroup>
                <Stat mr="4">
                  <StatLabel>{profile?.plant?.plant_code}</StatLabel>
                  <StatHelpText>{profile?.plant?.plant_name}</StatHelpText>
                </Stat>
              </StatGroup>
            </Stack>
          </Stack>
        )}

        <Stack direction={'row'} justify={'start'} mb={5} spacing={6}>
          <Stack spacing={0} align={'center'}>
            <StatGroup>
              <Stat mr="4">
                <StatLabel>{`Last Password Changed`}</StatLabel>
                <StatHelpText>
                  {profile?.last_password_change
                    ? profile?.last_password_change
                    : 'Never'}
                </StatHelpText>
              </Stat>
            </StatGroup>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
