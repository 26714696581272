const tblConsts = {
  date: {
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
  datetime: {
    width: 160,
    minWidth: 160,
    maxWidth: 160,
  },
  longtext: {
    width: 500,
    minWidth: 500,
    maxWidth: 500,
  },
  mediumtext: {
    width: 250,
    minWidth: 250,
    maxWidth: 250,
  },
  shorttext: {
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
  plantname: {
    width: 160,
    minWidth: 160,
    maxWidth: 160,
  },
  societyname: {
    width: 260,
    minWidth: 260,
    maxWidth: 260,
  },
};

export default tblConsts;
