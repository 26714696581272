import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Divider,
} from '@chakra-ui/react';

import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import EditButtons from './EditButtons';
import editIncometaxSchema from './editIncometaxSchema';
import fields from './fields';
import societyService from 'services/societyService';

import InputField from 'components/form/InputField';

const EditIncometaxPage = () => {
  const FormTitle = 'SOCIETY INCOME TAX DETAILS';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const apiError = useSelector(state => state.error);

  const [data, setData] = useState(null);

  const { _id } = useParams();

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);

  useEffect(() => {
    const fetchSociety = async () => {
      const responseData = await societyService.getById(_id);
      if (responseData && responseData.data) {
        setData(responseData.data);
      }
    };

    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      fetchSociety();
    }
    // eslint-disable-next-line
  }, []);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(editIncometaxSchema),
    defaultValues: {
      pan: fields.pan.default,
      pan_name: fields.pan_name.default,
      gst: fields.gst.default,
      gst_name: fields.gst_name.default,
    },
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
  } = useForm(formOptions);

  const filedsUsed = ['pan', 'pan_name', 'gst', 'gst_name'];

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        if (filedsUsed.includes(key)) {
          setValue(key, value);
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        pan: formData.pan,
        pan_name: formData.pan_name,
        gst: formData.gst,
        gst_name: formData.gst_name,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (
          value === null ||
          value === undefined ||
          formDataSend[key] === data[key]
        ) {
          delete formDataSend[key];
        }
      }
      const responseData = await societyService.updateSocietyIncometaxDetails(
        data?._id,
        formDataSend
      );
      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              <Box h="20px" mb={[4, 4]}>
                <EditButtons _id={data?._id} />
              </Box>
              <Divider orientation="horizontal" />
              {/* Form Contents */}

              {/* Start Row 2 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="pan"
                    id="pan"
                    label="PAN"
                    placeholder="PAN"
                    error={errors.pan}
                    register={register('pan')}
                    disabled={fields?.pan?.disabled}
                    readonly={fields?.pan?.readonly}
                  />
                </Box>
                <Box w={['full', 6 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="pan_name"
                    id="pan_name"
                    label="PAN Name"
                    placeholder="PAN Name"
                    error={errors.pan_name}
                    register={register('pan_name')}
                    disabled={fields?.pan_name?.disabled}
                    readonly={fields?.pan_name?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 2 */}

              {/* Start Row 2 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gst"
                    id="gst"
                    label="GST"
                    placeholder="GST"
                    error={errors.gst}
                    register={register('gst')}
                    disabled={fields?.gst?.disabled}
                    readonly={fields?.gst?.readonly}
                  />
                </Box>
                <Box w={['full', 6 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gst_name"
                    id="gst_name"
                    label="GST Name"
                    placeholder="GST Name"
                    error={errors.gst_name}
                    register={register('gst_name')}
                    disabled={fields?.gst_name?.disabled}
                    readonly={fields?.gst_name?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 2 */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const EditIncometaxDetails = {
  routeProps: {
    path: '/societies/edit/incometax/:_id',
    component: EditIncometaxPage,
    exact: true,
  },
  name: 'Society Income Tax Details',
  title: 'Society Income Tax Details',
};

export default EditIncometaxDetails;
