import React from 'react';
import {
  Center,
  Flex,
  Box,
  Heading,
  Button,
  useColorModeValue,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

function SocietyPlantStats({ loading, data, refreshData }) {
  return (
    <Box
      p={2}
      h="full"
      w="full"
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      bg={useColorModeValue('gray.100', 'gray.700')}
      color={useColorModeValue('gray.800', 'gray.200')}
    >
      <Flex
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        h="full"
        w="full"
      >
        <Box height="50px">
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Heading
              fontSize="xl"
              color={useColorModeValue('orange.500', 'orange.200')}
            >
              SOCIETY PLANT WISE
            </Heading>
            <Button
              variant="ghost"
              isLoading={loading}
              loadingText="Refreshing..."
              onClick={refreshData}
              outline="none"
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'transperent',
                scale: '50',
                color: 'teal.500',
                size: 'xs',
              }}
            >
              <RepeatIcon w={8} h={8} color="green.500" />
            </Button>
          </Flex>
        </Box>

        {loading && (
          <Box minH="200px" w="full">
            <Center>
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
              />
            </Center>
          </Box>
        )}

        {!loading && (
          <>
            <Box flex="1" p="2" w="full" h="full">
              <Table variant="simple" fontSize="md">
                <Thead>
                  <Tr>
                    <Th fontSize="md">PLANT</Th>
                    <Th fontSize="md" isNumeric>
                      TOTAL
                    </Th>
                    <Th fontSize="md" isNumeric>
                      ACTIVE
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map(plant => (
                    <Tr key={plant.plant_code}>
                      <Td>
                        <Text fontWeight="extrabold" color="orange.600">
                          {plant?.plant_code}
                        </Text>
                      </Td>
                      <Td isNumeric>
                        <Text fontWeight="bold" color="blue.600">
                          {plant?.total}
                        </Text>
                      </Td>
                      <Td isNumeric>
                        <Text fontWeight="bold" color="green.600">
                          {plant?.active}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default SocietyPlantStats;
