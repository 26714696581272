import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import societyService from 'services/societyService';

import filterSchema from './filterSchema';
import { checkDatePeriod } from 'helpers/genHelpers';

import DateField from 'components/form/DateField';
import SelectField from 'components/form/SelectField';
import AsyncSelectField from 'components/form/AsyncSelectField';

const TR_TYPES = [
  { name: 'SALES', value: 'SALES' },
  { name: 'SALES RETURN', value: 'SALES_RET' },
];

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  const [societies, setSocieties] = useState([]);

  const fetchIdSocietyRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    tr_type: '',
    tr_date_from: null,
    tr_date_to: null,
    plant_id: '',
    society_id: '',
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    // register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    register,
    watch,
  } = useForm(formOptions);

  const watchPlant = watch('plant_id', '');
  const watchSociety = watch('society_id', '');
  const watchTrType = watch('tr_type', '');

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'tr_date' && value) {
        const from = moment(value.from).format(moment.defaultDateDb);
        const to = moment(value.to).format(moment.defaultDateDb);
        setValue('tr_date_from', from);
        setValue('tr_date_to', to);
      } else if (key === 'plant_id') {
        value = _.find(plants, { _id: value });
        setValue('plant_id', value);
      } else if (key === 'society_id') {
        value = _.find(societies, { _id: value });
        setValue('society_id', value);
      } else if (key === 'tr_type') {
        value = _.find(TR_TYPES, { value: value });
        setValue('tr_type', value);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [defaultFilters]);

  const fetchSocieties = async () => {
    const fetchIdCustomer = ++fetchIdSocietyRef.current;

    if (fetchIdCustomer === fetchIdSocietyRef.current) {
      const query = {};
      query.filters = {};
      query.pagination = false;
      query.sortby = 'society_code';
      query.fields = '_id,society_code,society_name,is_active';
      query.filters.is_active = true;
      const responseData = await societyService.getAll(query);

      if (responseData) {
        setSocieties(responseData?.data);
      }
    }
  };

  useEffect(() => {
    fetchSocieties();
    // eslint-disable-next-line
  }, []);

  const filterValues = (inputValue = '') => {
    if (inputValue.startsWith('*')) {
      return societies.filter(i =>
        i?.society_name
          ?.toLowerCase()
          ?.startsWith(inputValue.toLowerCase().slice(1))
      );
    } else {
      return societies.filter(i =>
        i?.society_code?.toString()?.startsWith(inputValue)
      );
    }
  };

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      reset(defaultValues);
      handleSocietyChange('clear', null);
      handlePlantChange('clear', null);
      setFilters({});
    }
  };

  const onSubmit = formData => {
    for (const key in formData) {
      if (formData[key] === '' || formData[key] === null) {
        delete formData[key];
      }
    }

    if (formData.tr_date_from && formData.tr_date_to) {
      const valPeriod = checkDatePeriod(
        formData.tr_date_from,
        formData.tr_date_to,
        31
      );

      delete formData['tr_date_from'];
      delete formData['tr_date_to'];

      if (!valPeriod) {
        setError('tr_date', {
          type: 'manual',
          message: `Invalid Date range or Date period exceeded`,
        });
        return;
      }

      formData.tr_date = valPeriod;
    }

    if (formData.plant_id) {
      formData.plant_id = formData.plant_id?._id;
    }

    if (formData.society_id) {
      formData.society_id = formData.society_id?._id;
    }

    if (formData.tr_type) {
      formData.tr_type = formData.tr_type?.value;
    }

    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant_id', null);
      return;
    }
    setValue('plant_id', selected);
  };

  const handleSocietyChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society_id', null);
      return;
    }
    setValue('society_id', selected);
  };

  const handleTrTypeChange = (selected, action) => {
    if (action === 'clear') {
      setValue('tr_type', null);
      return;
    }
    setValue('tr_type', selected);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}
        <SelectField
          isMulti={false}
          isClearable={true}
          name="tr_type"
          label="Transaction Type"
          labelKey="name"
          valueKey="value"
          options={TR_TYPES}
          placeholder="Select Transaction Type"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.value}
          handleChange={handleTrTypeChange}
          error={errors.tr_type}
          control={control}
          defaultValue={watchTrType}
        />

        <Box border="1px" borderColor="gray.200" p={2} mb={4}>
          <DateField
            name="tr_date_from"
            label="Date From"
            error={errors.tr_date}
            register={register('tr_date_from')}
          />
          <DateField
            name="tr_date_to"
            label="Date To"
            error={errors.tr_date}
            register={register('tr_date_to')}
          />
        </Box>

        <SelectField
          isMulti={false}
          isClearable={true}
          name="plant_id"
          label="Plant"
          labelKey="plant_id"
          valueKey="_id"
          options={plants}
          placeholder="Select Plant"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option =>
            `${option['plant_code']}-${option['plant_name']}`
          }
          getOptionValue={option => option?._id}
          handleChange={handlePlantChange}
          error={errors.plant_id}
          control={control}
          defaultValue={watchPlant}
        />
        <AsyncSelectField
          isMulti={false}
          isClearable={true}
          name="society"
          label="Society"
          labelKey="society_code"
          valueKey="_id"
          options={societies}
          placeholder="Select Society"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option =>
            `${option['society_code']} - ${option['society_name']}`
          }
          getOptionValue={option => option?._id}
          handleChange={handleSocietyChange}
          error={errors.society}
          control={control}
          defaultValue={watchSociety}
          filterValues={filterValues}
        />

        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
