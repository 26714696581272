const messages = {
  name: {
    required: 'Catagory is required',
    invalid: 'Catagory has invalid value',
  },
  description: {
    required: 'Description is required',
    invalid: 'Description has invalid value',
  },
};

export default messages;
