import * as yup from 'yup';
import validationRegex from 'configs/validationRegex';
import messages from './messages';

const schema = yup.object().shape({
  mobile: yup
    .string()
    .required(messages.mobile.required)
    .matches(validationRegex.mobile, messages.mobile.invalid),
  password_reset_otp: yup
    .string()
    .required(messages.password_reset_otp.required)
    .matches(validationRegex.mobileOtp, messages.password_reset_otp.invalid),
  password: yup
    .string()
    .required(messages.password.required)
    .test('passwords-match', messages.password.match, function (value) {
      return this.parent.password_confirmation === value;
    }),
  password_confirmation: yup
    .string()
    .required(messages.password_confirmation.required)
    .test(
      'passwords-match',
      messages.password_confirmation.match,
      function (value) {
        return this.parent.password === value;
      }
    ),
});

export default schema;
