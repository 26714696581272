import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Box } from '@chakra-ui/react';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import unitService from 'services/unitService';
import columns from './columns';
import Filters from './Filters';

// Table
import TableComp from 'components/tables/TableComp';

const allowAdd = ['superadmin'];

const TableHeader = 'UNITS';

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  const tblColumns = useMemo(() => columns, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [filters, setFilters] = useState({});

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await unitService.getAll(query);
        if (resData) {
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  return (
    <Box display={{ md: 'flex' }} w="full" h="full" mb="4">
      <TableComp
        columns={tblColumns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        defaultSortBy={defaultSortBy}
        //
        tblHeader={TableHeader}
        showColumnHide={true}
        isExpandable={true}
        // ExpandedComponent={ExpandRow}
        addLink="/units/add"
        allowAdd={allowAdd}
        enableExport={true}
        exportFilename={`Units`}
        filters={filters}
        filterForm={
          <Filters
            onFilter={onFilterChange}
            defaultFilters={filters}
            setFilters={setFilters}
          />
        }
      />
    </Box>
  );
};

// Exports

const Units = {
  routeProps: {
    path: '/units',
    component: IndexPage,
    exact: true,
  },
  name: 'Units',
  title: 'Units',
};

export default Units;
