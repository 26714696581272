import _CONSTATNTS from 'constants/_Constants';

const initialState = {};

const successReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case _CONSTATNTS.SET_DATA:
      return {
        code: payload.code,
        status: payload.status,
        isOperational: payload.isOperational,
        message: payload.message,
        errors: payload.errors,
      };
    case _CONSTATNTS.UNSET_DATA:
      return {};
    default:
      return state;
  }
};

export default successReducer;
