import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { SHIFT_OPTIONS } from 'configs/ShiftOptions';
import { MILK_CATAGORY_OPTIONS } from 'configs/MilkCatagoryOptions';
import { MILK_TYPE_OPTIONS } from 'configs/MilkTypeOptions';

import markAddSchema from './markAddSchema';
import fields from './fields';
import milktransactionEditService from 'services/milktransactionEditService';
import societyService from 'services/societyService';
import DateField from 'components/form/DateField';
import SelectField from 'components/form/SelectField';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';
import AsyncSelectField from 'components/form/AsyncSelectField';
import RenderSocietyOption from 'components/form/RenderSocietyOption';

const MarkAddPage = () => {
  const FormTitle = 'ADD MILK TRANSACTION';
  const IndexPageTitle = 'MILK TRANSACTION EDITS';
  const IndexPagePath = '/milktransactionedits';

  const defaultValues = {
    proc_date: fields?.proc_date?.default,
    shift: fields?.shift?.default,
    plant_id: fields?.plant_id?.default,
    society_id: fields?.society_id?.default,
    milk_catagory: fields?.milk_catagory?.default,
    milk_type: fields?.milk_type?.default,
    cans: fields?.cans?.default,
    weight_kg: fields?.weight_kg?.default,
    fat: fields?.fat?.default,
    snf: fields?.snf?.default,
    amount: fields?.amount?.default,
    note: fields?.note?.default,
  };

  const apiError = useSelector(state => state.error);
  const [societies, setSocieties] = useState([]);

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(markAddSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    handleSocietyChange('clear', null);
    handleShiftChange('clear', null);
    handleMilkCatagoryChange('clear', null);
    handleMilkTypeChange('clear', null);
  };

  const watchShift = watch('shift', null);
  const watchSociety = watch('society_id', null);
  const watchMilkCatagory = watch('milk_catagory', null);
  const watchMilkType = watch('milk_type', null);

  const onSubmit = async formData => {
    const formDataSend = {
      proc_date: formData?.proc_date,
      shift: formData?.shift?.value,
      plant_id: formData?.society_id?.plant_id?._id,
      society_id: formData?.society_id?._id,
      milk_catagory: formData?.milk_catagory?.value,
      milk_type: formData?.milk_type?.value,
      cans: formData?.cans,
      weight_kg: formData?.weight_kg,
      fat: formData?.fat,
      snf: formData?.snf,
      amount: formData?.amount,
      note: formData?.note,
    };

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const responseData = await milktransactionEditService.markCreate(
        formDataSend
      );

      if (responseData) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  const fetchSocieties = async () => {
    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      const query = { is_active: true };
      query.pagination = false;
      query.sortby = 'society_code';
      query.fields =
        '_id,society_code,society_name,is_active,plant_id,city,district,supervisor';
      const responseData = await societyService.getAll(query);

      if (responseData) {
        setSocieties(responseData?.data);
      }
    }
  };

  const filterValues = (inputValue = '') => {
    if (inputValue.startsWith('*')) {
      return societies.filter(i =>
        i?.society_name
          ?.toLowerCase()
          ?.startsWith(inputValue.toLowerCase().slice(1))
      );
    } else {
      return societies.filter(i =>
        i?.society_code?.toString()?.startsWith(inputValue)
      );
    }
  };

  useEffect(() => {
    fetchSocieties();
  }, []);

  const handleSocietyChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society_id', null);
      setValue('plant_code', '');
      setValue('plant_name', '');
      return;
    }
    setValue('society_id', selected);
    setValue('plant_code', selected?.plant_id?.plant_code);
    setValue('plant_name', selected?.plant_id?.plant_name);
  };

  const handleShiftChange = (selected, action) => {
    if (action === 'clear') {
      setValue('shift', null);
      return;
    }
    return setValue('shift', selected);
  };

  const handleMilkCatagoryChange = (selected, action) => {
    if (action === 'clear') {
      setValue('milk_catagory', null);
      return;
    }
    return setValue('milk_catagory', selected);
  };

  const handleMilkTypeChange = (selected, action) => {
    if (action === 'clear') {
      setValue('milk_type', null);
      return;
    }
    return setValue('milk_type', selected);
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Flex direction={['column', 'row']} justifyContent="space-between">
            <Text fontSize="xl" color="orange.500" fontWeight="bold">
              {FormTitle}
            </Text>
          </Flex>
          <Flex direction={['column', 'row']} justifyContent="space-between">
            <Button
              as={Link}
              to={IndexPagePath}
              type="button"
              colorScheme="orange"
              size="sm"
              px="8"
              mt={[2, 0]}
              mr={[0, 2]}
            >
              {IndexPageTitle}
            </Button>
            <Button
              as={Link}
              to={`/milktransactions`}
              type="button"
              colorScheme="orange"
              size="sm"
              px="8"
              mt={[2, 0]}
            >
              MILK TRANSACTIONS
            </Button>
          </Flex>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[12, 0]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <AsyncSelectField
                    isMulti={false}
                    isClearable={true}
                    name="society_id"
                    label="Society"
                    labelKey="society_code"
                    valueKey="_id"
                    options={societies}
                    placeholder="Select Society"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => (
                      <RenderSocietyOption
                        society={option}
                        showCity={true}
                        showDistrict={true}
                        showPlant={true}
                        showSupervisor={true}
                      />
                    )}
                    getOptionValue={option => option?._id}
                    handleChange={handleSocietyChange}
                    error={errors.society}
                    control={control}
                    defaultValue={watchSociety}
                    filterValues={filterValues}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_code"
                    id="plant_code"
                    label="Plant Code"
                    placeholder="Plant Code"
                    error={errors.plant_code}
                    disabled={true}
                    register={register('plant_code')}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_name"
                    id="plant_name"
                    label="Plant Name"
                    placeholder="Plant Name"
                    error={errors.plant_name}
                    disabled={true}
                    register={register('plant_name')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="proc_date"
                    label="Proc Date"
                    placeholder="DD-MM-YYYY"
                    error={errors.proc_date}
                    register={register('proc_date')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="Shift"
                    label="Shift"
                    labelKey="name"
                    valueKey="value"
                    options={SHIFT_OPTIONS}
                    placeholder="Select Shift"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleShiftChange}
                    error={errors.shift}
                    control={control}
                    defaultValue={watchShift}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="Milk Catagory"
                    label="Milk Catagory"
                    labelKey="name"
                    valueKey="value"
                    options={MILK_CATAGORY_OPTIONS}
                    placeholder="Select Milk Catagory"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleMilkCatagoryChange}
                    error={errors.milk_catagory}
                    control={control}
                    defaultValue={watchMilkCatagory}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="Milk Type"
                    label="Milk Type"
                    labelKey="name"
                    valueKey="value"
                    options={MILK_TYPE_OPTIONS}
                    placeholder="Select Milk Type"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleMilkTypeChange}
                    error={errors.milk_type}
                    control={control}
                    defaultValue={watchMilkType}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    name="cans"
                    id="cans"
                    label="Cans"
                    placeholder="Cans"
                    error={errors.cans}
                    register={register('cans')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.1"
                    name="weight_kg"
                    id="weight_kg"
                    label="Weight (KG)"
                    placeholder="Weight (KG)"
                    error={errors.weight_kg}
                    register={register('weight_kg')}
                  />
                </Box>
                <Box w={['full', 1 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.01"
                    name="fat"
                    id="fat"
                    label="FAT"
                    placeholder="FAT"
                    error={errors.fat}
                    register={register('fat')}
                  />
                </Box>
                <Box w={['full', 1 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.01"
                    name="snf"
                    id="snf"
                    label="SNF"
                    placeholder="SNF"
                    error={errors.snf}
                    register={register('snf')}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.01"
                    name="amount"
                    id="amount"
                    label="Amount"
                    placeholder="Amount"
                    error={errors.amount}
                    register={register('amount')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="note"
                    id="note"
                    label="Note"
                    placeholder="Write Your Remark For The Edit Entry"
                    error={errors.note}
                    register={register('note')}
                  />
                </Box>
              </Flex>

              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MilkTransactionMarkAdd = {
  routeProps: {
    path: '/milktransactions/add',
    component: MarkAddPage,
    exact: true,
  },
  name: 'Milk Transaction Add',
  title: 'Milk Transaction Add',
};

export default MilkTransactionMarkAdd;
