import axios from 'axios';

const BASE_URL = 'https://ifsc.razorpay.com';

const headers = {
  // Accept: "application/json",
  // "Access-Control-Allow-Origin": "*",
  // "Content-Type": "application/json",
};

const Client = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  responseType: 'json',
  headers,
});

const serach = async ifsc => {
  try {
    const response = await Client.get(`/${ifsc}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const bankService = {
  serach,
};

export default bankService;
