const messages = {
  username: {
    required: 'Please select a user',
    invalid: 'Invalid Username',
  },
  plant: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant',
  },
};

export default messages;
