const styles = {
  none: {},
  document: {
    fontSize: '10px',
    // position: 'absolute',
    padding: 0,
    // width: '210mm',
    // minHeight: '297mm',
    width: '190mm',
    height: '267mm',
    minHeight: '267mm',
    margin: '10mm auto',
    border: '1px #D3D3D3 solid',
    borderRadius: '5px',
    background: 'white',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
    // fontsize: '8px',
  },
  page: {
    position: 'relative',
    padding: '10mm',
    // margin: '10mm',
    // background: 'gray',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: '1.1em',
  },
  heading: {
    height: '8mm',
    marginTop: '1mm',
    marginBottom: '1mm',
    // backgroundColor: '#787d82',
    // color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.8em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    // textDecoration: 'underline',
    border: '1px #D3D3D3 solid',
  },
  partyDetails: {
    height: '25mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px #D3D3D3 solid',
    marginBottom: '2mm',
    margin: '0 2mm',
  },
  detailsLeft: {
    width: '50%',
  },
  detailsRight: {
    width: '50%',
  },
  name: {
    fontSize: '1.0em',
    fontWeight: 'semibold',
    marginLeft: '1mm',
  },
  secDetails: {
    color: '#5d6975',
    marginLeft: '1mm',
  },
  companyDetails: {
    height: '20mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageDiv: {
    width: '20mm',
  },
  logoImage: {
    width: '80px',
  },
  companyDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  companyDummyDiv: {
    width: '20mm',
  },
  companyName: {
    fontSize: '1.6em',
    fontWeight: 'bold',
  },
  companyAddress: {
    color: '#5d6975',
    lineHeight: '0.7em',
    textAlign: 'center',
  },
  docDetails: {
    height: '15mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // borderBottom: '1px #D3D3D3 solid',
    marginBottom: '2mm',
    color: '#5d6975',
  },
  docDetailsLeft: {
    width: '50%',
  },
  docDetailsRight: {
    width: '50%',
  },
  detailsP: {
    marginTop: '1mm',
  },
  detailsHeaders: {
    fontWeight: 'bold',
    margin: '0 2mm',
    color: '#000',
  },
  note: {
    height: '10mm',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  footer: {
    height: '3mm',
    color: '#5d6975',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px #D3D3D3 solid',
    paddingTop: '3mm',
  },
  items: {
    flex: 1,
  },
  table: {
    width: '100%',
  },
  thead: {
    fontSize: '1.1em',
    textAlign: 'left',
  },
  thLeft: {
    textAlign: 'left',
    paddingLeft: '2mm',
    paddingRight: '2mm',
    borderTop: '1px #D3D3D3 solid',
    borderBottom: '1px #D3D3D3 solid',
  },
  thCenter: {
    textAlign: 'center',
    paddingLeft: '2mm',
    paddingRight: '2mm',
    borderTop: '1px #D3D3D3 solid',
    borderBottom: '1px #D3D3D3 solid',
  },
  thRight: {
    textAlign: 'right',
    paddingLeft: '2mm',
    paddingRight: '2mm',
    borderTop: '1px #D3D3D3 solid',
    borderBottom: '1px #D3D3D3 solid',
  },
  tr: {},
  tdString: { padding: '2mm 2mm', textAlign: 'left' },
  tdNumber: { padding: '2mm 2mm', textAlign: 'right' },
  tdAmount: { padding: '2mm 2mm', textAlign: 'right' },
  subTotal: { textAlign: 'right', fontSize: '1.0em', fontWeight: 'bold' },
  grandTotal: {
    textAlign: 'right',
    fontSize: '1.1em',
    fontWeight: 'bold',
    borderTop: '1px #D3D3D3 solid',
    borderBottom: '1px #D3D3D3 solid',
  },

  // Placeholders
  placeholder_container: {
    width: '160mm',
    height: '230mm',
    margin: '10mm auto',
    border: '1px #D3D3D3 solid',
    borderRadius: '5px',
    backgroundColor: '#eee',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
    // fontsize: '8px',
  },
  placeholder: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  tfoot: {
    borderTop: '1px #D3D3D3 solid',
  },
  tfootTr: {},
  tfootTh: {
    textAlign: 'right',
    fontSize: '1.2em',
    paddingTop: '2mm',
    paddingBottom: '2mm',
    borderBottom: '1px #D3D3D3 solid',
  },
  tfootTd: {
    textAlign: 'right',
    fontSize: '1.2em',
    borderBottom: '1px #D3D3D3 solid',
  },
};

export default styles;
