import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import schema from './schema';

import AsyncSelectField from 'components/form/AsyncSelectField';
import InputField from 'components/form/InputField';
import RenderSocietyOption from 'components/form/RenderSocietyOption';

import societyService from 'services/societyService';
import bankService from 'services/external/bankService';

const FormTitle = 'SOCIETY BANK';

const defaultValues = {
  society: '',
};

const IndexPage = () => {
  const apiError = useSelector(state => state.error);

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);

  const [societies, setSocieties] = useState([]);
  const [old_bank_ifsc, setOldBankIfsc] = useState('');
  const [old_bank_name, setOldBankName] = useState('');
  const [old_bank_branch, setOldBankBranch] = useState('');
  const [old_bank_city, setOldBankCity] = useState('');
  const [old_bank_account_number, setOldBankAccountNumber] = useState('');

  const [bank_name, setBankName] = useState('');
  const [bank_branch, setBankBranch] = useState('');
  const [bank_city, setBankCity] = useState('');

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    handleSocietyChange('clear', null);
    setBankName('');
    setBankBranch('');
    setBankCity('');
    setOldBankIfsc('');
    setOldBankName('');
    setOldBankBranch('');
    setOldBankCity('');
    setOldBankAccountNumber('');
  };

  const watchSociety = watch('society', null);
  const watchAccountNumber = watch('bank_account_number', '');
  const watchIfsc = watch('bank_ifsc', '');

  useEffect(() => {
    fetchSocieties();
  }, []);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        bank_ifsc: watchIfsc,
        bank_name: bank_name,
        bank_branch: bank_branch,
        bank_city: bank_city,
        bank_account_number: watchAccountNumber,
      };

      const responseData = await societyService.updateSocietyBank(
        formData.society?._id,
        formDataSend
      );

      if (responseData) {
        onReset();
        fetchSocieties();
        toast.success(`${responseData.message}`);
      }
    }
  };

  const fetchSocieties = async () => {
    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'society_code';
      query.fields = '_id,society_code,society_name,is_active,city,district';
      const responseData = await societyService.getAll(query);

      if (responseData) {
        setSocieties(responseData?.data);
      }
    }
  };

  const onSearch = async () => {
    if (watchIfsc.length !== 11) {
      return toast.warning(`IFSC Code length should be 11`);
    }
    const response = await bankService.serach(watchIfsc);

    if (response === undefined) {
      return toast.error(`Somthingwent wrong. Failed to find Bank for IFSC`);
    }

    if (response === null) {
      return toast.error(`IFSC Code invalid or not found`);
    }

    if (response) {
      setBankName(response?.BANK);
      setBankBranch(response?.BRANCH);
      setBankCity(response?.CITY);
      toast.success(`Bank Details Found`);
    }
  };

  const handleSocietyChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society', null);
      return;
    }
    setValue('society', selected);

    if (selected) {
      setOldBankIfsc(selected?.bank_ifsc);
      setOldBankName(selected?.bank_name);
      setOldBankBranch(selected?.bank_branch);
      setOldBankCity(selected?.bank_city);
      setOldBankAccountNumber(selected?.bank_account_number);
    }
  };

  const filterValues = (inputValue = '') => {
    const activeOnly = true;
    if (!activeOnly) {
      if (inputValue.startsWith('*')) {
        return societies.filter(i =>
          i?.society_name
            ?.toLowerCase()
            ?.startsWith(inputValue.toLowerCase().slice(1))
        );
      } else {
        return societies.filter(i =>
          i?.society_code?.toString()?.startsWith(inputValue)
        );
      }
    }

    // Return Active Only
    const activeSocieties = _.filter(societies, { is_active: true });

    if (inputValue.startsWith('*')) {
      return activeSocieties.filter(i =>
        i?.society_name
          ?.toLowerCase()
          ?.startsWith(inputValue.toLowerCase().slice(1))
      );
    } else {
      return activeSocieties.filter(i => {
        return i?.society_code?.toString()?.startsWith(inputValue);
      });
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      w="full"
      h="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
        </Flex>
      </Box>

      {/* Form */}
      <Box w="full" h="full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px" w="full" h="full">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <AsyncSelectField
                    isMulti={false}
                    isClearable={true}
                    name="society"
                    label="Society"
                    labelKey="society_code"
                    valueKey="_id"
                    options={societies}
                    placeholder="Select Society"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => (
                      <RenderSocietyOption
                        society={option}
                        showCity={true}
                        showDistrict={true}
                        showPlant={true}
                        showSupervisor={true}
                      />
                    )}
                    getOptionValue={option => option?._id}
                    handleChange={handleSocietyChange}
                    error={errors.society}
                    control={control}
                    defaultValue={watchSociety}
                    filterValues={filterValues}
                  />
                </Box>
              </Flex>

              <Flex direction={['column', 'row']} w="full" h="full">
                <Flex direction={['column', 'row']} w="full" h="full">
                  <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                    <InputField
                      type="text"
                      name="bank_ifsc"
                      id="bank_ifsc"
                      label="IFSC"
                      placeholder="IFSC"
                      error={errors.bank_ifsc}
                      register={register('bank_ifsc')}
                    />
                  </Box>
                  <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                    <Button
                      type="button"
                      colorScheme="blue"
                      size="sm"
                      px="8"
                      onClick={onSearch}
                    >
                      SEARCH IFSC
                    </Button>
                  </Box>
                </Flex>
              </Flex>

              <Flex direction={['column', 'row']}>
                <Box w={['full', '1/2']} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="bank_account_number"
                    id="bank_account_number"
                    label="Account Number"
                    placeholder="Account Number"
                    error={errors.bank_account_number}
                    register={register('bank_account_number')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE SOCIETY BANK
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-start"
              >
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th colSpan="3">Bank Details</Th>
                    </Tr>
                    <Tr>
                      <Th colSpan="1"></Th>
                      <Th colSpan="1">Current Bank Details</Th>
                      <Th colSpan="1">Updated Bank Details</Th>
                    </Tr>
                    <Tr>
                      <Th>IFSC</Th>
                      <Td>{old_bank_ifsc ? old_bank_ifsc : '-'}</Td>
                      <Td>{watchIfsc ? watchIfsc : '-'}</Td>
                    </Tr>
                    <Tr>
                      <Th>Bank</Th>
                      <Td>{old_bank_name ? old_bank_name : '-'}</Td>
                      <Td>{bank_name ? bank_name : '-'}</Td>
                    </Tr>
                    <Tr>
                      <Th>Branch</Th>
                      <Td>{old_bank_branch ? old_bank_branch : '-'}</Td>
                      <Td>{bank_branch ? bank_branch : '-'}</Td>
                    </Tr>
                    <Tr>
                      <Th>City</Th>
                      <Td>{old_bank_city ? old_bank_city : '-'}</Td>
                      <Td>{bank_city ? bank_city : '-'}</Td>
                    </Tr>
                    <Tr>
                      <Th>Account Number</Th>
                      <Td>
                        {old_bank_account_number
                          ? old_bank_account_number
                          : '-'}
                      </Td>
                      <Td>{watchAccountNumber ? watchAccountNumber : '-'}</Td>
                    </Tr>
                  </Thead>
                  <Tbody></Tbody>
                </Table>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const SocietyBank = {
  routeProps: {
    path: '/society/bank',
    component: IndexPage,
    exact: true,
  },
  name: 'Society Bank',
  title: 'Society Bank',
};

export default SocietyBank;
