const fields = {
  names: [
    'society_code',
    'sap_code',
    'society_name',
    'city',
    'taluka',
    'district',
    'state',
    'country',
    'pan',
    'pan_name',
    'gst',
    'gst_name',
    'is_active',
    'society_type',
    'society_gender',
    'proposed_date',
    'registration_date',
    'registration_no',
    'bank_ifsc',
    'bank_name',
    'bank_branch',
    'bank_city',
    'bank_account_number',

    'chairman_fname',
    'chairman_mname',
    'chairman_lname',
    'chairman_address1',
    'chairman_address2',
    'chairman_address3',
    'chairman_city',
    'chairman_pin',
    'chairman_mobile',
    'chairman_email',
    'chairman_pan',
    'chairman_pan_name',
    'chairman_adhar',

    'secretary_fname',
    'secretary_mname',
    'secretary_lname',
    'secretary_address1',
    'secretary_address2',
    'secretary_address3',
    'secretary_city',
    'secretary_pin',
    'secretary_mobile',
    'secretary_email',
    'secretary_pan',
    'secretary_pan_name',
    'secretary_adhar',

    'gu_society_name',
    'gu_city',
    'gu_taluka',
    'gu_district',
    'gu_state',
    'gu_country',
    'gu_society_type',
    'gu_society_gender',

    'gu_chairman_fname',
    'gu_chairman_mname',
    'gu_chairman_lname',
    'gu_chairman_address1',
    'gu_chairman_address2',
    'gu_chairman_address3',
    'gu_chairman_city',

    'gu_secretary_fname',
    'gu_secretary_mname',
    'gu_secretary_lname',
    'gu_secretary_address1',
    'gu_secretary_address2',
    'gu_secretary_address3',
    'gu_secretary_city',

    'plant',
    'supervisor',
    'supervisor_mobile',
    'supervisor_email',
    'send_sms',
    'send_sms_supervisor',
  ],
  society_code: {
    default: '',
    disabled: false,
    readonly: false,
  },
  sap_code: {
    default: '',
    disabled: true,
    readonly: true,
  },
  society_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  city: {
    default: '',
    disabled: false,
    readonly: false,
  },
  taluka: {
    default: '',
    disabled: false,
    readonly: false,
  },
  district: {
    default: '',
    disabled: false,
    readonly: false,
  },
  state: {
    default: 'GUJARAT',
    disabled: true,
    readonly: true,
  },
  country: {
    default: 'INDIA',
    disabled: true,
    readonly: true,
  },

  pan: {
    default: '',
    disabled: false,
    readonly: false,
  },
  pan_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gst: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gst_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  is_active: {
    default: false,
    disabled: false,
    readonly: false,
  },
  society_type: {
    default: '',
    disabled: false,
    readonly: false,
  },
  society_gender: {
    default: null,
    disabled: false,
    readonly: false,
  },
  proposed_date: {
    default: null,
    disabled: false,
    readonly: false,
  },
  registration_date: {
    default: null,
    disabled: false,
    readonly: false,
  },
  registration_no: {
    default: '',
    disabled: false,
    readonly: false,
  },
  bank_ifsc: {
    default: '',
    disabled: true,
    readonly: true,
  },
  bank_name: {
    default: '',
    disabled: true,
    readonly: true,
  },
  bank_branch: {
    default: '',
    disabled: true,
    readonly: true,
  },
  bank_city: {
    default: '',
    disabled: true,
    readonly: true,
  },
  bank_account_number: {
    default: '',
    disabled: true,
    readonly: true,
  },

  chairman_fname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_mname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_lname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_address1: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_address2: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_address3: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_city: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_pin: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_mobile: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_email: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_pan: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_pan_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  chairman_adhar: {
    default: '',
    disabled: false,
    readonly: false,
  },

  secretary_fname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_mname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_lname: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_address1: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_address2: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_address3: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_city: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_pin: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_mobile: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_email: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_pan: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_pan_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  secretary_adhar: {
    default: '',
    disabled: false,
    readonly: false,
  },

  gu_society_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gu_city: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_taluka: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_district: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_state: {
    default: 'ગુજરાત',
    disabled: true,
    readonly: true,
  },
  gu_country: {
    default: 'ભારત',
    disabled: true,
    readonly: true,
  },
  gu_society_type: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_society_gender: {
    default: null,
    disabled: true,
    readonly: true,
  },

  gu_chairman_fname: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_chairman_mname: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_chairman_lname: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_chairman_address1: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_chairman_address2: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_chairman_address3: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_chairman_city: {
    default: '',
    disabled: true,
    readonly: true,
  },

  gu_secretary_fname: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_secretary_mname: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_secretary_lname: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_secretary_address1: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_secretary_address2: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_secretary_address3: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_secretary_city: {
    default: '',
    disabled: true,
    readonly: true,
  },

  plant: {
    default: '',
    disabled: true,
    readonly: true,
  },
  supervisor: {
    default: null,
    disabled: true,
    readonly: true,
  },
  supervisor_mobile: {
    default: '',
    disabled: true,
    readonly: true,
  },
  supervisor_email: {
    default: '',
    disabled: true,
    readonly: true,
  },
  send_message: {
    default: false,
    disabled: true,
    readonly: true,
  },
  send_message_supervisor: {
    default: null,
    disabled: true,
    readonly: true,
  },
};

export default fields;
