import { Center, Avatar } from '@chakra-ui/react';

const renderAvatar = (cell, row) => {
  return (
    <Center>
      <Avatar size="sm" src={`${cell.value}`} />
    </Center>
  );
};

export default renderAvatar;
