import React, { useState, useEffect } from 'react';
import {
  Center,
  Box,
  useColorModeValue,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { toIndianFormat } from 'helpers/genHelpers';

function TopBottomCommon({
  isTop,
  data,
  loading,
  dataKey,
  dataLabel,
  altDataLabel,
  altDataKey,
}) {
  const [results, setResults] = useState([]);

  const tblTopBg = useColorModeValue('green.800', 'green.200');
  const tblTopColor = useColorModeValue('white', 'black');

  const tblBottomBg = useColorModeValue('red.800', 'red.200');
  const tblBottomColor = useColorModeValue('white', 'black');

  const tblBodyTextColor = useColorModeValue('gray.900', 'gray.100');

  useEffect(() => {
    if (data && data?.results && data?.results.length) {
      setResults(data?.results);
    }
  }, [data]);

  return (
    <Box h="full" w="full">
      {loading && (
        <Box minH="200px" w="full">
          <Center>
            <Spinner
              thickness="6px"
              speed="0.65s"
              emptyColor="gray.200"
              color="orange.500"
              size="xl"
            />
          </Center>
        </Box>
      )}

      {!loading && (
        <Table
          size="sm"
          varient="striped"
          colorScheme={isTop ? tblTopBg : tblBottomBg}
        >
          <Thead>
            <Tr bg={isTop ? tblTopBg : tblBottomBg}>
              <Th
                isNumeric
                py="2"
                fontSize="md"
                fontWeight="bold"
                color={isTop ? tblTopColor : tblBottomColor}
                w="2%"
              >
                #
              </Th>
              <Th
                py="2"
                fontSize="md"
                fontWeight="bold"
                textAlign="center"
                color={isTop ? tblTopColor : tblBottomColor}
                w="5%"
              >
                CODE
              </Th>
              <Th
                py="2"
                fontSize="md"
                fontWeight="bold"
                textAlign="left"
                color={isTop ? tblTopColor : tblBottomColor}
                w="auto"
              >
                NAME
              </Th>
              <Th
                isNumeric
                py="2"
                fontSize="md"
                fontWeight="bold"
                color={isTop ? tblTopColor : tblBottomColor}
                w="10%"
              >
                {dataLabel}
              </Th>
              {altDataLabel && (
                <Th
                  isNumeric
                  py="2"
                  fontSize="md"
                  fontWeight="bold"
                  color={isTop ? tblTopColor : tblBottomColor}
                  w="10%"
                >
                  {altDataLabel}
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody color={tblBodyTextColor} fontWeight="bold">
            {results.map((result, i) => (
              <Tr key={i}>
                <Th isNumeric fontWeight="bold">
                  {i + 1}
                </Th>
                <Td textAlign="center">{result.society_code.pad(6)}</Td>
                <Td textAlign="left">{result.society_name}</Td>
                <Td isNumeric>{toIndianFormat(result[dataKey])}</Td>
                {altDataKey && (
                  <Td isNumeric>{toIndianFormat(result[altDataKey])}</Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

export default TopBottomCommon;
