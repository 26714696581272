const messages = {
  type: {
    required: 'Adultration Type is required',
    invalid: 'Adultration Type has invalid value',
  },
  amount: {
    required: 'Amount is required',
    invalid: 'Invalid Amount value',
    // min: 'Invalid Amount value. Lower limit exceeded.',
    // max: 'Invalid Amount value. Upper limit exceeded.',
  },
  is_active: {
    required: 'Status is required',
    invalid: 'Invalid Status',
  },
  note: {
    required: 'Note is required',
    invalid: 'Invalid Note value',
  },
};

export default messages;
