import { toIndianFormat } from 'helpers/genHelpers';

const renderIndiaFormat = cell => {
  let value = cell.value;
  if (!value) {
    value = '-';
  } else {
    value = toIndianFormat(cell?.value);
  }
  return <div style={{ textAlign: 'right' }}>{value}</div>;
};

export default renderIndiaFormat;
