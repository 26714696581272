import { combineReducers } from 'redux';

import authReducer from 'reducers/authReducer';
import errorReducer from 'reducers/errorReducer';
import successReducer from 'reducers/successReducer';
import metaReducer from 'reducers/metaReducer';
import bannerReducer from 'reducers/bannerReducer';
import loadingReducer from 'reducers/loadingReducer';

import plantReducer from 'reducers/plantReducer';
import fiscalyearReducer from 'reducers/fiscalyearReducer';
import fiscalyearCurrentReducer from 'reducers/fiscalyearCurrentReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  success: successReducer,
  meta: metaReducer,
  showBanner: bannerReducer,
  loading: loadingReducer,

  plants: plantReducer,
  fiscalyears: fiscalyearReducer,
  fiscalyearObj: fiscalyearCurrentReducer,
});

export default rootReducer;
