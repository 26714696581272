import * as yup from 'yup';
import messages from './messages';

const editSchema = yup.object().shape({
  name: yup.string().trim().min(2).max(200).label('Name'),
  description: yup.string().trim().max(500).label('Description'),
  catagory: yup.object().label('Catagory'),
  conversion: yup
    .number(messages.conversion.invalid)
    .min(1)
    .positive(messages.conversion.invalid)
    .typeError(messages.conversion.invalid)
    .label('Conversion Factor'),
  consuption_price: yup
    .number(messages.consuption_price.invalid)
    .positive(messages.consuption_price.invalid)
    .min(0)
    .required(messages.consuption_price.required)
    .typeError(messages.consuption_price.invalid)
    .label('Consumtion Price'),
  sales_price: yup
    .number(messages.sales_price.invalid)
    .positive(messages.sales_price.invalid)
    .min(0)
    .typeError(messages.sales_price.invalid)
    .label('Sales Price'),
  tax_type: yup.object().nullable(true).label('Tax Type'),
  hsn: yup.string().trim().nullable(true).label('HSN'),
  sgst: yup.object().nullable(true).label('SGST'),
  cgst: yup.object().nullable(true).label('CGST'),
  igst: yup.object().nullable(true).label('IGST'),
  tradable: yup.boolean().nullable(true).label('Tradable'),
  consumable: yup.boolean().nullable(true).label('Consumable'),
  is_active: yup.boolean().nullable(true).label('Status'),
  is_discontinued: yup.boolean().nullable(true).label('Discontinued'),
});

export default editSchema;
