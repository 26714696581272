import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Divider,
} from '@chakra-ui/react';

import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import EditButtons from './EditButtons';
import editOtherSchema from './editOtherSchema';
import fields from './fields';
import societyService from 'services/societyService';
import { societyTypes } from 'configs/societyType';
import { societyGenders } from 'configs/societyGenders';

import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';
import SwitchField from 'components/form/SwitchField';
import DateField from 'components/form/DateField';

const EditOtherPage = () => {
  const FormTitle = 'EDIT SOCIETY OTHER DETAILS';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const DATE_FIELDS = [
    'proposed_date',
    'registration_date',
    'send_message_supervisor',
  ];

  const apiError = useSelector(state => state.error);

  const [data, setData] = useState(null);

  const { _id } = useParams();

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);

  useEffect(() => {
    const fetchSociety = async () => {
      const responseData = await societyService.getById(_id);
      if (responseData && responseData.data) {
        setData(responseData.data);
        // console.log(data);
      }
    };

    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      fetchSociety();
    }
    // eslint-disable-next-line
  }, []);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(editOtherSchema),
    defaultValues: {
      society_type: fields.society_type.default,
      proposed_date: fields.proposed_date.default,
      registration_date: fields.registration_date.default,
      registration_no: fields.registration_no.default,
      send_message: fields.send_message.default,
      send_message_supervisor: fields.send_message_supervisor.default,
      gu_society_type: fields.gu_society_type.default,
      gu_society_gender: fields.gu_society_gender.default,
    },
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    control,
    watch,
    // getValues,
  } = useForm(formOptions);

  const watchSocietyType = watch('society_type', null);
  const watchSocietyGender = watch('society_gender', null);
  const watchIsSendSms = watch('send_message', false);

  const filedsUsed = [
    'society_type',
    'society_gender',
    'send_message',
    'send_message_supervisor',
    'proposed_date',
    'registration_date',
    'registration_no',
    'gu_society_type',
    'gu_society_gender',
  ];

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        if (filedsUsed.includes(key)) {
          if (key === 'society_type') {
            value = _.find(societyTypes, {
              key: data['society_type'],
            });
            setValue(key, value);
          } else if (key === 'society_gender') {
            value = _.find(societyGenders, {
              key: data['society_gender'],
            });
            setValue(key, value);
          } else if (DATE_FIELDS.includes(key) && value) {
            value = moment(value).format(moment.defaultDateDb);
            setValue(key, value);
          } else {
            setValue(key, value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const handleSocietyTypeChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society_type', null);
      setValue('gu_society_type', null);
      return;
    }
    setValue('society_type', selected);
    setValue('gu_society_type', selected?.gu_name);

    if (selected?.key !== 'REG') {
      setValue('registration_date', null);
      setValue('registration_no', null);
    }
  };

  const handleSocietyGenderChange = (selected, action) => {
    if (action === 'clear') {
      setValue('society_gender', null);
      setValue('gu_society_gender', null);
      return;
    }
    setValue('society_gender', selected);
    setValue('gu_society_gender', selected?.gu_name);
  };

  const onSubmit = async formData => {
    // console.log(`FORMDATA => `, formData);
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        society_type: formData.society_type?.key,
        society_gender: formData.society_gender?.key,

        proposed_date: formData.proposed_date,
        registration_date: formData.registration_date,
        registration_no: formData.registration_no,

        send_message: formData.send_message,
        send_message_supervisor: formData.send_message_supervisor,

        gu_society_type: formData.gu_society_type,
        gu_society_gender: formData.gu_society_gender,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (key === 'society_type' && value !== 'REG') {
          formDataSend['registration_date'] = null;
          formDataSend['registration_no'] = null;
        } else if (DATE_FIELDS.includes(key) && value === '') {
          formDataSend[key] = null;
        } else if (
          value === null ||
          value === undefined ||
          formDataSend[key] === data[key]
        ) {
          delete formDataSend[key];
        }
      }
      const responseData = await societyService.updateSocietyOtherDetails(
        data?._id,
        formDataSend
      );
      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              <Box h="20px" mb={[4, 4]}>
                <EditButtons _id={data?._id} />
              </Box>
              <Divider orientation="horizontal" />
              {/* Form Contents */}

              {/* Start Row 2 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="society_type"
                    label="Society Type"
                    labelKey="name"
                    valueKey="key"
                    options={societyTypes}
                    placeholder="Select Socity Type"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option['name']}`}
                    getOptionValue={option => option?.key}
                    handleChange={handleSocietyTypeChange}
                    error={errors.society_type}
                    control={control}
                    defaultValue={watchSocietyType}
                    disabled={fields?.society_type?.disabled}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_society_type"
                    id="gu_society_type"
                    label="મંડળી નો પ્રકાર"
                    placeholder="મંડળી નો પ્રકાર"
                    error={errors.gu_society_type}
                    register={register('gu_society_type')}
                    readonly={fields?.gu_society_type?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="society_gender"
                    label="Society Gender"
                    labelKey="name"
                    valueKey="key"
                    options={societyGenders}
                    placeholder="Select Socity Gender"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option['name']}`}
                    getOptionValue={option => option?.key}
                    handleChange={handleSocietyGenderChange}
                    error={errors.society_gender}
                    control={control}
                    defaultValue={watchSocietyGender}
                    disabled={fields?.society_gender?.disabled}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_society_gender"
                    id="gu_society_gender"
                    label="પુરુષ/મહિલા"
                    placeholder="પુરુષ/મહિલા"
                    error={errors.gu_society_gender}
                    register={register('gu_society_gender')}
                    // disabled={fields?.gu_society_gender?.disabled}
                    readonly={fields?.gu_society_gender?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 2 */}

              {/* Start Row 2 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="proposed_date"
                    label="Proposed Date"
                    placeholder="DD-MM-YYYY"
                    error={errors.proposed_date}
                    register={register('proposed_date')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="registration_date"
                    label="Registration Date"
                    placeholder="DD-MM-YYYY"
                    error={errors.registration_date}
                    register={register('registration_date')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="registration_no"
                    id="registration_no"
                    label="Registration No"
                    placeholder="Registration No"
                    error={errors.registration_no}
                    register={register('registration_no')}
                    disabled={watchSocietyType?.key !== 'REG'}
                    readonly={watchSocietyType?.key !== 'REG'}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}></Box>
              </Flex>
              {/* End Row 2 */}

              {/* Start Row 2 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="send_message_supervisor"
                    label="Send SMS Supervisor Till"
                    placeholder="DD-MM-YYYY"
                    error={errors.send_message_supervisor}
                    register={register('send_message_supervisor')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} ml={[0, 4]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="send_message"
                    id="send_message"
                    label="Send SMS ?"
                    error={errors.send_message}
                    register={register('send_message')}
                    defaultChecked={watchIsSendSms}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}></Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}></Box>
              </Flex>
              {/* End Row 2 */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const EditOtherDetails = {
  routeProps: {
    path: '/societies/edit/other/:_id',
    component: EditOtherPage,
    exact: true,
  },
  name: 'Edit Society Other Details',
  title: 'Edit Society Other Details',
};

export default EditOtherDetails;
