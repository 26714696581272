import React, { useState, useEffect } from 'react';
import {
  Center,
  Flex,
  Box,
  Heading,
  Button,
  useColorModeValue,
  Spinner,
  Select,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Stack,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import dashboardMilkService from 'services/dashboardMilkService';

const DAY_OPTIONS = [1, 5, 10];

function TotalMilkPlant() {
  const chartYAxisColor = useColorModeValue('#50586C', '#D8D8D8');
  const chartXAxisColor = useColorModeValue('#50586C', '#D8D8D8');
  const goodLineColor = useColorModeValue('#1B998B', '#85FFF1');
  const sourLineColor = useColorModeValue('#F72C3D', '#FFB7BD');

  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDays, setSelectedDays] = useState(DAY_OPTIONS[0]);

  const [property, setProperty] = useState('weight');
  const [type, setType] = useState('total');

  const refreshData = async () => {
    setLoading(true);
    setData([]);
    const resData = await dashboardMilkService.getPlantQty('top', selectedDays);
    if (resData && resData?.data) {
      setData(resData?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [selectedDays]);

  useEffect(() => {
    setResults(data?.results);
    // eslint-disable-next-line
  }, [data]);

  return (
    <Box
      h="full"
      w="full"
      p={2}
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      bg={useColorModeValue('gray.50', 'gray.700')}
      color={useColorModeValue('gray.900', 'gray.200')}
    >
      <Flex
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        h="full"
        w="full"
      >
        <Box height="15%" mb="4">
          <Flex justifyContent={'space-between'} alignItems={'center'} mb="4">
            <Heading
              fontSize="md"
              color={useColorModeValue('orange.500', 'orange.200')}
            >
              MILK PLANT WISE SUMMARY : {selectedDays}{' '}
              {selectedDays > 1 ? 'Days' : 'Day'}
            </Heading>

            <Box mx="2" my="2">
              <FormControl>
                <InputGroup size="sm">
                  <InputLeftAddon children="Days" />
                  <Select
                    size="sm"
                    varient="filled"
                    colorScheme="orange"
                    focusBorderColor="orange.500"
                    onChange={e => setSelectedDays(e.target.value)}
                    defaultValue={selectedDays}
                  >
                    {DAY_OPTIONS.map(day => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </FormControl>
            </Box>

            <Button
              variant="ghost"
              isLoading={loading}
              loadingText="Refreshing..."
              onClick={refreshData}
              outline="none"
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'transperent',
                scale: '50',
                color: 'teal.500',
                size: 'xs',
              }}
            >
              <RepeatIcon w={8} h={8} color="green.500" />
            </Button>
          </Flex>
        </Box>

        <Box height="15%" mb="2" px="16">
          <Flex justifyContent={'start'} alignItems={'center'} mb="4">
            <RadioGroup onChange={setType} value={type} mr="24">
              <Stack direction="row">
                <Radio value="total" size="lg">
                  TOTAL
                </Radio>
                <Radio value="buff" size="lg">
                  BUFF
                </Radio>
                <Radio value="cow" size="lg">
                  COW
                </Radio>
              </Stack>
            </RadioGroup>

            <RadioGroup onChange={setProperty} value={property}>
              <Stack direction="row">
                <Radio value="weight" size="lg">
                  Weight
                </Radio>
                <Radio value="kgfat" size="lg">
                  KgFat
                </Radio>
                <Radio value="kgsnf" size="lg">
                  KgSnf
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </Box>

        {loading && (
          <Box minH="200px" w="full">
            <Center>
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
              />
            </Center>
          </Box>
        )}

        {!loading && (
          <ResponsiveContainer
            width="100%"
            height="100%"
            // minWidth="100%"
            // minHeight="300px"
            // aspect={undefined}
            debounce={1}
          >
            <BarChart
              width={500}
              height={300}
              data={results}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              cursor="pointer"
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis
                dataKey="plant_code"
                label={{
                  value: 'PLANTS',
                  offset: 0,
                  position: 'insideBottomLeft',
                  fill: chartXAxisColor,
                }}
                type="category"
                tick={{ stroke: chartXAxisColor, strokeWidth: 1 }}
              />
              <YAxis
                label={{
                  value: property.toUpperCase(),
                  angle: -90,
                  position: 'insideLeft',
                  fill: chartYAxisColor,
                }}
                type="number"
                domain={[
                  0,
                  dataMax => Math.round(dataMax / 10000) * 10000 + 10000,
                ]}
                interval={`preserveStartEnd`}
                tickCount={20}
                tick={{ stroke: chartYAxisColor, strokeWidth: 1 }}
              />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={`good.${type}.${property}`}
                stackId="a"
                fill={goodLineColor}
                name={`GOOD ${type.toUpperCase()} ${property.toUpperCase()}`}
              >
                <LabelList dataKey="plant_name" position="end" angle="-90" />
              </Bar>
              <Bar
                dataKey={`sour.${type}.${property}`}
                stackId="a"
                fill={sourLineColor}
                name={`SOUR ${type.toUpperCase()} ${property.toUpperCase()}`}
              ></Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Flex>
    </Box>
  );
}

export default TotalMilkPlant;
