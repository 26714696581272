import API from 'helpers/API';

const userPlantAuthorize = async (user_id, plant_id) => {
  try {
    const response = await API.post(`/authorize/plant/${user_id}`, {
      plant_id: plant_id,
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const userSocietyAuthorize = async (user_id, society_id) => {
  try {
    const response = await API.post(
      `/authorize/society/${society_id}/${user_id}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const authorizationService = {
  userPlantAuthorize,
  userSocietyAuthorize,
};

export default authorizationService;
