import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { FaSync } from 'react-icons/fa';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import userService from 'services/userService';
import societyService from 'services/societyService';
import columns from './columns';
import Filters from './Filters';

import TableComp from 'components/tables/TableComp';

const allowAdd = ['superadmin', 'admin'];

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'society_code',
      desc: false,
    },
  ];

  const tblColumns = useMemo(() => columns, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [filters, setFilters] = useState({});
  const fetchIdRef = useRef(0);
  const fetchIdUserDetailsRef = useRef(0);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const fetchIdUsers = ++fetchIdUserDetailsRef.current;

    if (fetchIdUsers === fetchIdUserDetailsRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'username';
      query.filters = {};
      query.filters.role = 'supervisor';
      query.fields =
        '-societies -createdAt -updatedAt -email_verified -force_password_change -last_login_timestamp -last_password_change -mobile_verified';
      const responseData = await userService.getAll(query);

      if (responseData && responseData?.data) {
        setUsers(responseData?.data);
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await societyService.getAll(query);
        if (resData) {
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [filters]
  );

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  const refreshData = async () => {
    await fetchData({
      pageSize: PAGINATION_OPTIONS.limit,
      pageIndex: 0,
      sortBy: defaultSortBy,
    });
  };

  const TableHeader = (
    <Flex direction={['column', 'row']} justifyContent="space-between">
      <Text mr={[4, 4]}>SOCIETIES</Text>
      <Button
        type="button"
        variant="outline"
        colorScheme="orange"
        size="sm"
        onClick={refreshData}
      >
        <FaSync />
      </Button>
    </Flex>
  );

  const handleOnExport = data => {
    const newData = [];

    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const newD = { ...d };
      if (d.plant_id) {
        newD.plant_code = d.plant_id.plant_code;
        newD.plant_name = d.plant_id.plant_name;
      }
      if (d.supervisor) {
        newD.supervisor_username = d.supervisor.username;
      }
      newData.push(newD);
    }
    return newData;
  };

  return (
    <Box display={{ md: 'flex' }} w="full" h="full" mb="4">
      <TableComp
        columns={tblColumns}
        data={data}
        fetchData={fetchData}
        filters={filters}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        defaultSortBy={defaultSortBy}
        //
        tblHeader={TableHeader}
        showColumnHide={true}
        filterForm={
          <Filters
            onFilter={onFilterChange}
            defaultFilters={filters}
            setFilters={setFilters}
            users={users}
          />
        }
        isExpandable={true}
        onExport={handleOnExport}
        addLink="/societies/add"
        allowAdd={allowAdd}
        enableExport={true}
        exportFilename={`Societies`}
      />
    </Box>
  );
};

// Exports

const Societies = {
  routeProps: {
    path: '/societies',
    component: IndexPage,
    exact: true,
  },
  name: 'Societies',
  title: 'Societies',
};

export default Societies;
