import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import renderDate from 'components/tables/helpers/renderDate';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderMessages from 'components/tables/helpers/renderMessages';
import renderMilkEntryType from 'components/tables/helpers/renderMilkEntryType';
import renderStatus from 'components/tables/helpers/renderStatus';
import renderIndiaFormat from 'components/tables/helpers/renderIndiaFormat';

import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'proc_date',
    accessor: 'proc_date',
    Header: 'Proc Date',
    type: 'date',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDate(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.date.maxWidth,
    minWidth: tblConsts.date.minWidth,
  },
  {
    id: 'shift',
    accessor: 'shift',
    Header: 'Shift',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'plant_code',
    accessor: 'plant_code',
    Header: 'Plant Code',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'plant_name',
    accessor: 'plant_name',
    Header: 'Name',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },
  {
    id: 'society_code',
    accessor: 'society_code',
    Header: 'Soc Code',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'society_name',
    accessor: 'society_name',
    Header: 'Name',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    // Cell: cell => cell.value,
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.societyname.maxWidth,
    minWidth: tblConsts.societyname.minWidth,
  },
  {
    id: 'city',
    accessor: 'city',
    Header: 'City',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'taluka',
    accessor: 'taluka',
    Header: 'Taluka',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'district',
    accessor: 'district',
    Header: 'District',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  // {
  //   id: 'vehicle_no',
  //   accessor: 'vehicle_no',
  //   Header: 'Vehicle No',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'left',
  //   exportable: true,
  //   disableSortBy: true,
  // },
  {
    id: 'society_type',
    accessor: 'society_type',
    Header: 'Soc Type',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'milk_catagory',
    accessor: 'milk_catagory',
    Header: 'Milk Catagory',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'milk_type',
    accessor: 'milk_type',
    Header: 'Milk Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  // {
  //   id: 'dock_id',
  //   accessor: 'dock_id',
  //   Header: 'Dock',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'left',
  //   exportable: true,
  //   disableSortBy: true,
  // },
  {
    id: 'cans',
    accessor: 'cans',
    Header: 'Cans',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => <div style={{ textAlign: 'right' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'weight_kg',
    accessor: 'weight_kg',
    Header: 'Wight(Kg)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  // {
  //   id: 'weight_ltr',
  //   accessor: 'weight_ltr',
  //   Header: 'Wight(Ltr)',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'right',
  //   Cell: ({ cell }) => renderIndiaFormat(cell),
  //   exportable: true,
  //   disableSortBy: true,
  // },
  {
    id: 'sample_no',
    accessor: 'sample_no',
    Header: 'Sample',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'fat',
    accessor: 'fat',
    Header: 'FAT',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'snf',
    accessor: 'snf',
    Header: 'SNF',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'fat_kg',
    accessor: 'fat_kg',
    Header: 'FAT(Kg)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'snf_kg',
    accessor: 'snf_kg',
    Header: 'SNF(Kg)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  // {
  //   id: 'eq_fat',
  //   accessor: 'eq_fat',
  //   Header: 'Eq FAT',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'right',
  //   Cell: ({ cell }) => renderIndiaFormat(cell),
  //   exportable: true,
  //   disableSortBy: true,
  // },
  // {
  //   id: 'eq_fat_kg',
  //   accessor: 'eq_fat_kg',
  //   Header: 'Eq FAT(KG)',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'right',
  //   Cell: ({ cell }) => renderIndiaFormat(cell),
  //   exportable: true,
  //   disableSortBy: true,
  // },
  // {
  //   id: 'po_number',
  //   accessor: 'po_number',
  //   Header: 'PO',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'left',
  //   exportable: true,
  //   disableSortBy: true,
  // },
  // {
  //   id: 'po_line_number',
  //   accessor: 'po_line_number',
  //   Header: 'PO Line',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'left',
  //   exportable: true,
  //   disableSortBy: true,
  // },
  {
    id: 'rate',
    accessor: 'rate',
    Header: 'Rate',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: true,
  },
  // {
  //   id: 'gr_no',
  //   accessor: 'gr_no',
  //   Header: 'GR',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'left',
  //   exportable: true,
  //   disableSortBy: true,
  // },
  {
    id: 'amount',
    accessor: 'amount',
    Header: 'Amount',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'gu_plant_name',
    accessor: 'gu_plant_name',
    Header: 'પ્લાન્ટ',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_society_name',
    accessor: 'gu_society_name',
    Header: 'મંડળી નું નામ',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_city',
    accessor: 'gu_city',
    Header: 'ગામ',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_taluka',
    accessor: 'gu_taluka',
    Header: 'તાલુકા',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_district',
    accessor: 'gu_district',
    Header: 'જિલ્લો',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'gu_society_type',
    accessor: 'gu_society_type',
    Header: 'મંડળી પ્રકાર',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'send_sms_mobiles',
    accessor: 'send_sms_mobiles',
    Header: 'SMS Mobiles',
    type: 'array',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      if (cell.value && cell.value.length > 0) {
        const mobiles = cell.value.map(item => item).join(', ');
        return <div>{mobiles}</div>;
      }
      return <div>-</div>;
    },
    exportable: true,
    disableSortBy: true,
    expandfunction: value => {
      if (value && value.length > 0) {
        const mobiles = value.map(item => item).join(', ');
        return <div>{mobiles}</div>;
      }
      return <div>-</div>;
    },
  },
  {
    id: 'fiscal_year',
    accessor: 'fiscal_year',
    Header: 'Fiscal Year',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'entry_type',
    accessor: 'entry_type',
    Header: 'Entry Type',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderMilkEntryType(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'created_by',
    accessor: 'created_by',
    Header: 'Created By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'created_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'updated_by',
    accessor: 'updated_by',
    Header: 'Updated By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'updated_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'approved_by',
    accessor: 'approved_by',
    Header: 'Approved By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'approved_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'message_sent',
    accessor: 'message_sent',
    Header: 'Msg Count',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'message_success',
    accessor: 'message_success',
    Header: 'Msg Success',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'messages',
    accessor: 'messages',
    Header: 'Messages',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => renderMessages(cell),
    exportable: true,
    disableHideColumn: true,
    disableSortBy: true,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'cemter',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columns;
