const messages = {
  proc_date: {
    required: 'Procurement Date is required',
    invalid: 'Invalid Procurement Date',
  },
  shift: {
    required: 'Shift is required',
    invalid: 'Inavldi Shift value',
  },
  plant_code: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant Value',
  },
};

export default messages;
