import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';

import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    width: 300,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'material_id',
    accessor: 'material_id',
    Header: 'Material ID',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return (
        <div style={{ textAlign: 'left' }}>
          {cell.value ? cell.value?._id : '-'}
        </div>
      );
    },
    exportable: false,
    disableSortBy: true,
    expandfunction: value => {
      return <div>{value ? value?._id : '-'}</div>;
    },
  },
  {
    id: 'material_code',
    accessor: 'material_id',
    Header: 'Code',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return (
        <div style={{ textAlign: 'left' }}>
          {cell.value ? cell.value?.code : '-'}
        </div>
      );
    },
    exportable: true,
    disableSortBy: true,
    expandfunction: value => {
      return <div>{value ? value?.code : '-'}</div>;
    },
  },
  {
    id: 'material_name',
    accessor: 'material_id',
    Header: 'Material',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return (
        <div style={{ textAlign: 'left' }}>
          {cell.value ? cell.value?.name : '-'}
        </div>
      );
    },
    exportable: true,
    disableSortBy: true,
    expandfunction: value => {
      return <div>{value ? value?.name : '-'}</div>;
    },
    width: 300,
  },
  {
    id: 'plant_code',
    accessor: 'plant_code',
    Header: 'Plant Code',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'stock_in',
    accessor: 'stock_in',
    Header: 'StockIn',
    type: 'number',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => {
      return <div style={{ textAlign: 'right' }}>{cell.value}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value : '-'}</div>;
    },
  },
  {
    id: 'stock_out',
    accessor: 'stock_out',
    Header: 'StockOut',
    type: 'number',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => {
      return <div style={{ textAlign: 'right' }}>{cell.value}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value : '-'}</div>;
    },
  },
  {
    id: 'stock_before',
    accessor: 'stock_before',
    Header: 'StockBefore',
    type: 'number',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => {
      return <div style={{ textAlign: 'right' }}>{cell.value}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value : '-'}</div>;
    },
  },
  {
    id: 'stock_after',
    accessor: 'stock_after',
    Header: 'StockAfter',
    type: 'number',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => {
      return <div style={{ textAlign: 'right' }}>{cell.value}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value : '-'}</div>;
    },
  },
  {
    id: 'who',
    accessor: 'who',
    Header: 'PostedBy',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'materialorder_id',
    accessor: 'materialorder_id',
    Header: 'Order ID',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div style={{ textAlign: 'left' }}>{cell.value?._id}</div>;
    },
    exportable: false,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?._id : '-'}</div>;
    },
  },
  {
    id: 'tr_type',
    accessor: 'materialorder_id.tr_type',
    Header: 'Transaction Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div style={{ textAlign: 'left' }}>{cell.value}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value : '-'}</div>;
    },
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columns;
