import * as yup from 'yup';
import messages from './messages';

const editSchema = yup.object().shape({
  society_name: yup.string().trim().max(100, messages.society_name.max),
  gu_society_name: yup.string().trim().max(100, messages.gu_society_name.max),
  gu_city: yup.string().trim().max(100, messages.gu_city.max),
  is_active: yup.bool(messages.is_active.invalid),
});

export default editSchema;
