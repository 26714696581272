import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';
import { checkDatePeriod } from 'helpers/genHelpers';

import InputField from 'components/form/InputField';
import DateField from 'components/form/DateField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    timestamp_from: null,
    timestamp_to: null,
    level: '',
    message: '',
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
  } = useForm(formOptions);

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'timestamp' && value) {
        const from = moment(value.from).format(moment.defaultDateDb);
        const to = moment(value.to).format(moment.defaultDateDb);
        setValue('timestamp_from', from);
        setValue('timestamp_to', to);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [defaultFilters]);

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      reset(defaultValues);
    }
    setFilters({});
  };

  const onSubmit = formData => {
    for (const key in formData) {
      if (formData[key] === '') {
        delete formData[key];
      }
    }

    if (formData.timestamp_from && formData.timestamp_to) {
      const valPeriod = checkDatePeriod(
        formData.timestamp_from,
        formData.timestamp_to,
        31
      );

      delete formData['timestamp_from'];
      delete formData['timestamp_to'];

      if (!valPeriod) {
        setError('timestamp', {
          type: 'manual',
          message: `Invalid Date range or Date period exceeded`,
        });
        return;
      }

      formData.timestamp = valPeriod;
    }

    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}

        <Box border="1px" borderColor="gray.200" p={2} mb={4}>
          <DateField
            name="timestamp_from"
            label="Timestamp From"
            error={errors.timestamp}
            register={register('timestamp_from')}
          />
          <DateField
            name="timestamp_to"
            label="Timestamp To"
            error={errors.timestamp}
            register={register('timestamp_to')}
          />
        </Box>

        <InputField
          type="text"
          name="level"
          id="level"
          label="Level"
          placeholder="Level"
          error={errors.level}
          register={register('level')}
        />

        <InputField
          type="text"
          name="message"
          id="message"
          label="Message"
          placeholder="Message"
          error={errors.message}
          register={register('message')}
        />

        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
