import React, { forwardRef, useRef, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Checkbox,
  Text,
  Stack,
  Box,
} from '@chakra-ui/react';
import { FaEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';

export default function ColumnHide({
  columnToggleProps,
  allColumns,
  disabledVis,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state?.auth?.user);
  const [showAll, setShowAll] = useState(true);
  const [visibleCols, setVisibleCols] = useState([]);

  useEffect(() => {
    setShowAll(disabledVis);
  }, [disabledVis]);

  useEffect(() => {
    const vis = [];

    for (let i = 0; i < allColumns.length; i++) {
      const c = allColumns[i];
      if (
        c.id === 'expander' ||
        c.id === 'selection' ||
        c.id === 'actions' ||
        c.id === 'approve'
      ) {
        continue;
      }

      if (
        !(
          user?.role.length &&
          user?.role[0] !== 'superadmin' &&
          c?.id === '_id'
        )
      ) {
        vis.push(c);
      }
    }
    setVisibleCols(vis);
  }, [allColumns, user]);

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return <input type="checkbox" ref={resolvedRef} {...rest} />;
    }
  );

  return (
    <>
      <Button
        variant="outline"
        colorScheme="orange"
        size="sm"
        onClick={onOpen}
        mb={{ base: '2', md: '0' }}
      >
        <Text fontWeight="thin" fontSize="md">
          <FaEyeSlash />
        </Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Columns Hide/Show</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {showAll && (
                <Box mb={4}>
                  <IndeterminateCheckbox {...columnToggleProps} /> Toggle All
                </Box>
              )}
              <Stack spacing={4} direction="column">
                {visibleCols.map(column => {
                  return (
                    <Checkbox
                      size="sm"
                      colorScheme="orange"
                      key={column.id}
                      {...column.getToggleHiddenProps()}
                      defaultChecked={!column.hidden}
                    >
                      {column.Header}
                    </Checkbox>
                  );
                })}
              </Stack>
              <br />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
