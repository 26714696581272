import _CONSTATNTS from 'constants/_Constants';

const initialState = {
  isOpen: false,
  status: 'info',
  message: '',
  timeOut: 3000,
};

const bannerReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case _CONSTATNTS.SHOW_BANNER:
      return {
        // ...state,
        isOpen: true,
        title: payload?.title ? payload?.title : state.title,
        status: payload?.status ? payload?.status : state.status,
        message: payload?.message ? payload?.message : state.message,
        timeOut: payload?.timeOut ? payload?.timeOut : state.timeOut,
      };
    case _CONSTATNTS.HIDE_BANNER:
      return initialState;
    default:
      return initialState;
  }
};

export default bannerReducer;
