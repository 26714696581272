import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  username: yup.string().label('Username').required(messages.username.required),
  mobile: yup
    .string()
    .label('Mobile')
    .required(messages.mobile.required)
    .min(0, messages.mobile.invalid)
    .max(10, messages.mobile.invalid),
  password: yup.string().label('Password').required(messages.password.required),
  password_confirmation: yup
    .string()
    .label('Confirm Password')
    .test(
      'passwords-match',
      messages.password_confirmation.match,
      function (value) {
        return this.parent.password === value;
      }
    ),
  employee_code: yup.string().trim().nullable(true).label('Employee Code'),
});

export default addSchema;
