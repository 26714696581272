const messages = {
  plant: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant',
  },
  society: {
    required: 'Please select a society',
    invalid: 'Invalid society',
  },
};

export default messages;
