const fields = {
  names: [
    'city',
    'taluka',
    'district',
    'state',
    'country',
    'gu_city',
    'gu_taluka',
    'gu_district',
    'gu_state',
    'gu_country',
  ],
  city: {
    default: '',
    disabled: false,
    readonly: false,
  },
  taluka: {
    default: '',
    disabled: false,
    readonly: false,
  },
  district: {
    default: '',
    disabled: false,
    readonly: false,
  },
  state: {
    default: '',
    disabled: false,
    readonly: false,
  },
  country: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gu_city: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gu_taluka: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_district: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_state: {
    default: '',
    disabled: true,
    readonly: true,
  },
  gu_country: {
    default: '',
    disabled: true,
    readonly: true,
  },
};

export default fields;
