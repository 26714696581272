import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/jobs?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Get By ID
const getById = async _id => {
  try {
    const response = await API.get(`/jobs/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Delete Edit Entry
const remove = async _id => {
  try {
    const response = await API.delete(`/jobs/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const enable = async _id => {
  try {
    const response = await API.post(`/jobs/enable/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const disable = async _id => {
  try {
    const response = await API.post(`/jobs/disable/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const jobService = {
  getAll,
  getById,
  remove,
  enable,
  disable,
};

export default jobService;
