import * as yup from 'yup';
import messages from './messages';

const editSecretarySchema = yup.object().shape({
  secretary_fname: yup.string().trim().max(100, messages.secretary_fname.max),
  secretary_mname: yup.string().trim().max(100, messages.secretary_mname.max),
  secretary_lname: yup.string().trim().max(100, messages.secretary_lname.max),
  secretary_address1: yup
    .string()
    .trim()
    .max(100, messages.secretary_address1.max),
  secretary_address2: yup
    .string()
    .trim()
    .max(100, messages.secretary_address2.max),
  secretary_address3: yup
    .string()
    .trim()
    .max(100, messages.secretary_address3.max),
  secretary_pin: yup
    .string()
    .trim()
    // .length(6, messages.secretary_pin.invalid)
    .nullable()
    .notRequired(),
  secretary_mobile: yup.string().trim().max(100, messages.secretary_mobile.max),
  secretary_email: yup.string().trim().max(100, messages.secretary_email.max),
  secretary_pan: yup.string().trim().max(100, messages.secretary_pan.invalid),
  secretary_pan_name: yup
    .string()
    .trim()
    .max(100, messages.secretary_pan_name.max),
  secretary_adhar: yup
    .string()
    .trim()
    // .length(12, messages.secretary_adhar.invalid)
    .nullable()
    .notRequired(),
});

export default editSecretarySchema;
