import React, { useEffect } from 'react';
import { hideBanner } from 'actions/_Actions';
import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Slide,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

export default function Banner() {
  const dispatch = useDispatch();
  const showBanner = useSelector(state => state.showBanner);

  const closeBanner = () => {
    dispatch(hideBanner());
  };

  useEffect(() => {
    if (showBanner?.isOpen) {
      setTimeout(() => {
        closeBanner();
      }, process.env.REACT_APP_BANNER_TIMEOUT * 1000 || 5000);
    }
    // eslint-disable-next-line
  }, [showBanner?.isOpen]);

  return !showBanner?.isOpen ? (
    ''
  ) : (
    <Slide
      direction="top"
      in={showBanner?.isOpen}
      unmountOnExit={true}
      style={{ zIndex: 10 }}
    >
      <Box mt="0" shadow="md">
        <Alert status={showBanner?.status} variant="solid" fontSize="sm">
          <AlertIcon />
          <AlertTitle mr={2}>{showBanner?.title}</AlertTitle>
          <AlertDescription>{showBanner.message}</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={closeBanner}
          />
        </Alert>
      </Box>
    </Slide>
  );
}
