import React from 'react';
import moment from 'helpers/moment';
import {
  Center,
  Flex,
  Box,
  Heading,
  Text,
  Stack,
  List,
  ListItem,
  ListIcon,
  Button,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import { CheckIcon, RepeatIcon } from '@chakra-ui/icons';
import { BsStopwatch } from 'react-icons/bs';

function SocietyTotalStats({ loading, data, refreshData }) {
  const mainTextColor = useColorModeValue('orange.500', 'orange.200');
  const subTextColor = useColorModeValue('orange.800', 'orange.50');
  const listIconColor = useColorModeValue('green.800', 'green.200');

  return (
    <Box
      h="full"
      w="full"
      p={2}
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      bg={useColorModeValue('gray.100', 'gray.700')}
      color={useColorModeValue('gray.800', 'gray.200')}
    >
      <Flex
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        h="full"
        w="full"
      >
        <Box height="15%" mb="4">
          <Flex justifyContent={'space-between'} alignItems={'center'} mb="4">
            <Heading
              fontSize="xl"
              color={useColorModeValue('orange.500', 'orange.200')}
            >
              SOCIETIES
            </Heading>
            <Button
              variant="ghost"
              isLoading={loading}
              loadingText="Refreshing..."
              onClick={refreshData}
              outline="none"
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'transperent',
                scale: '50',
                color: 'teal.500',
                size: 'xs',
              }}
            >
              <RepeatIcon w={8} h={8} color="green.500" />
            </Button>
          </Flex>
        </Box>

        {loading && (
          <Box minH="200px" w="full">
            <Center>
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
              />
            </Center>
          </Box>
        )}

        {!loading && (
          <>
            <Box height="20%" mb="6">
              <Stack direction={'row'} align={'center'} justify={'center'}>
                <Text fontSize={'6xl'} fontWeight={800} color={mainTextColor}>
                  {data?.total}
                </Text>
                <Text fontSize={'md'} color={subTextColor}>
                  TOTAL
                </Text>
              </Stack>
            </Box>
            <Box flex="1" p="2">
              <List spacing={3} mt="4" flex="1">
                <ListItem fontSize={'md'} fontWeight="bold">
                  <ListIcon as={CheckIcon} color={listIconColor} />
                  {`${data?.active} Active Societies`}
                </ListItem>
              </List>
            </Box>
            <Box height="10%" m="4">
              <Flex justifyContent={'end'} alignItems={'center'} mt="6">
                <BsStopwatch w={4} h={4} color="green.500" />
                <Text
                  fontSize="md"
                  fontWeight="semibold"
                  ml={4}
                  color="gray.500"
                >
                  {moment(data?.last_updated_at).fromNow()}
                </Text>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}

export default SocietyTotalStats;
