const messages = {
  type: {
    required: 'Adultration Type is required',
    invalid: 'Adultration Type has invalid value',
  },
  proc_date: {
    required: 'Procurement Date is required',
    invalid: 'Invalid Procurement Date',
  },
  shift: {
    required: 'Please Select a Shift',
    invalid: 'Inavldi Shift selected',
  },
  society_id: {
    required: 'Please select a society',
    invalid: 'Invalid Society selected',
  },
  society_code: {
    required: 'Society code is reqired',
    invalid: 'Invalid Society code',
  },
  cans: {
    required: 'Can is required',
    invalid: 'Invalid Can value',
    // min: 'Invalid Can value. Lower limit exceeded.',
    // max: 'Invalid Can value. Upper limit exceeded.',
  },
  weight_kg: {
    required: 'Weight is required',
    invalid: 'Invalid Weight value',
    // min: 'Invalid Weight value. Lower limit exceeded.',
    // max: 'Invalid Weight value. Upper limit exceeded.',
  },
  amount: {
    required: 'Amount is required',
    invalid: 'Invalid Amount value',
    min: 'Invalid Amount value. Lower limit exceeded.',
    max: 'Invalid Amount value. Upper limit exceeded.',
  },
  note: {
    required: 'Note is required',
    invalid: 'Invalid Note value',
  },
  sample_no: {
    required: 'Sample Number is required',
    invalid: 'Invalid Sample Number value',
  },
};

export default messages;
