import API from 'helpers/API';

// Mark Create
const approve = async (formData = {}) => {
  try {
    const response = await API.post(`/milk/approve/accept`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

// Mark Edit
const reject = async (formData = {}) => {
  try {
    const response = await API.post(`/milk/approve/reject`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const milktransactionApproveRejectService = {
  approve,
  reject,
};

export default milktransactionApproveRejectService;
