// import moment from "moment";
import moment from 'moment-timezone';

const defaultDateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
const defaultDateFormat = 'DD-MM-YYYY';

const defaultDateTimeDb = 'YYYY-MM-DD HH:mm:ss';
const defaultDateDb = 'YYYY-MM-DD';

// moment.tz.setDefault("Etc/UTC");
moment.tz.setDefault('Asia/Kolkata');

moment.defaultDateTimeFormat = defaultDateTimeFormat;
moment.defaultDateFormat = defaultDateFormat;

moment.defaultDateTimeDb = defaultDateTimeDb;
moment.defaultDateDb = defaultDateDb;

// moment().utcOffset("+05:30").format();

// moment.defaultFormat = defaultDateFormat;
// moment.format.setDefault(defaultDateTimeFormat);

export default moment;
