import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import materialPurchaseService from 'services/materialPurchaseService';
import returnSchema from './returnSchema';
import fields from 'modules/FiscalYears/fields';
import TextAreaField from 'components/form/TextAreaField';
import DateField from 'components/form/DateField';

const ReturnPage = () => {
  const FormTitle = 'PURCHASE RETURN';
  const IndexPageTitle = 'PURCHASES';
  const IndexPagePath = '/store/purchases';

  const defaultValues = {
    tr_date: fields?.tr_date?.default,
    note: fields?.note?.default,
  };

  const location = useLocation();

  const apiError = useSelector(state => state.error);
  const [data, setData] = useState(null);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(returnSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    reset,
    setValue,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    setValue('tr_date', null);
  };

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        tr_date: formData.tr_date,
        note: formData?.note,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (value === null || value === '' || value === undefined) {
          delete formDataSend[key];
        }
      }

      const responseData = await materialPurchaseService.reverse(
        data?._id,
        formDataSend
      );

      if (responseData?.code === 201) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  useEffect(() => {
    setData(location?.state);
  }, [location]);

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="tr_date"
                    label="Date"
                    placeholder="DD-MM-YYYY"
                    error={errors.tr_date}
                    register={register('tr_date')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 6 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="note"
                    id="note"
                    label="Note"
                    placeholder="Write Your Note"
                    error={errors.note}
                    register={register('note')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  RETURN
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const PurchaseReturn = {
  routeProps: {
    path: '/store/purchases/return/:_id',
    component: ReturnPage,
    exact: true,
  },
  name: 'Purchase Return',
  title: 'Purchase Return',
};

export default PurchaseReturn;
