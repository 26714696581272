import React, { useEffect } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';
import fields from './fields';
import { STATUS_OPTIONS } from 'configs/statusOptions';

import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    code: fields?.code?.default,
    name: fields?.name?.default,
    is_active: fields?.is_active?.default,
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const watchStatus = watch('is_active', '');

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'is_active') {
        value = _.find(STATUS_OPTIONS, { value: value });
        setValue('is_active', value);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [defaultFilters]);

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      handleStatusChange(null, 'clear');
      reset(defaultValues);
    }
    setFilters({});
  };

  const onSubmit = formData => {
    // console.log(formData);
    const formDataSend = {};
    for (const key in formData) {
      if (formData[key] === undefined || formData[key] === '') {
        delete formData[key];
      } else if (['is_active'].includes(key) && formData[key]) {
        formDataSend[key] = formData[key].value;
      } else if (formData[key] !== null) {
        formDataSend[key] = formData[key];
      }
    }

    if (!_.isEmpty(formDataSend)) {
      onFilter(formDataSend);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  const handleStatusChange = (selected, action) => {
    if (action === 'clear') {
      setValue('is_active', null);
      return;
    }
    setValue('is_active', selected);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}
        <InputField
          type="text"
          name="code"
          id="code"
          label="Code"
          placeholder="Code"
          error={errors.code}
          register={register('code')}
        />
        <InputField
          type="text"
          name="name"
          id="name"
          label="Vendor"
          placeholder="Vendor"
          error={errors.name}
          register={register('name')}
        />

        <SelectField
          isMulti={false}
          isClearable={true}
          name="is_active"
          label="Status"
          labelKey="name"
          valueKey="value"
          options={STATUS_OPTIONS}
          placeholder="Status ?"
          closeMenuOnSelect={false}
          size="sm"
          getOptionLabel={option => option.name}
          getOptionValue={option => option?.value}
          handleChange={handleStatusChange}
          error={errors.status}
          control={control}
          defaultValue={watchStatus}
        />

        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
