import { Center } from '@chakra-ui/react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const renderActive = cell => {
  let status;

  if (!cell.value) {
    status = <FaCheckCircle color="green" size={20} />;
  } else {
    status = <FaTimesCircle color="red" size={20} />;
  }

  return <Center>{status}</Center>;
};

export default renderActive;
