import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import materialSalesService from 'services/materialSalesService';
import customerService from 'services/customerService';
import addSchema from './addSchema';
import fields from 'modules/FiscalYears/fields';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';
import SelectField from 'components/form/SelectField';
import DateField from 'components/form/DateField';

import MaterialSalesCart from 'components/cart/MaterialSalesCart';

const AddPage = () => {
  const FormTitle = 'ADD SALES';
  const IndexPageTitle = 'SALES';
  const IndexPagePath = '/store/sales';

  const defaultValues = {
    tr_date: fields?.tr_date?.default,
    referance: fields?.referance?.default,
    plant_id: fields?.plant_id?.default,
    cust_id: fields?.cust_id?.default,
    materials: fields?.materials?.default,
    note: fields?.note?.default,
  };

  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  const [customers, setCustomers] = useState([]);
  const [cart, setCart] = useState([]);

  const fetchIdRef = useRef(0);
  const fetchIdCustomerRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    clearErrors,
    reset,
    control,
    watch,
    setValue,
  } = useForm(formOptions);

  const watchCustomer = watch('cust_id', '');
  const watchPlant = watch('plant_id', '');

  const fetchCustomers = async () => {
    const fetchIdCustomer = ++fetchIdCustomerRef.current;

    if (fetchIdCustomer === fetchIdCustomerRef.current) {
      const query = {};
      query.filters = {};
      query.pagination = false;
      query.sortby = 'name';
      query.fields = '_id,name';
      query.filters.is_active = true;
      const responseData = await customerService.getAll(query);

      if (responseData) {
        setCustomers(responseData?.data);
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue('materials', cart);
    // eslint-disable-next-line
  }, [cart]);

  const onReset = () => {
    handleCustomerChange('clear', null);
    handlePlantChange('clear', null);
    setValue('tr_date', null);
    setCart([]);
    reset();
  };

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        is_society_sales: false,
        tr_date: formData.tr_date,
        referance: formData?.referance,
        plant_id: formData?.plant_id?._id,
        cust_id: formData?.cust_id?._id,
        materials: cart,
        note: formData?.note,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (value === null || value === '' || value === undefined) {
          delete formDataSend[key];
        }
      }

      const responseData = await materialSalesService.create(formDataSend);

      if (responseData?.code === 201) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant_id', null);
      return;
    }
    return setValue('plant_id', selected);
  };

  const handleCustomerChange = (selected, action) => {
    if (action === 'clear') {
      setValue('cust_id', null);
      return;
    }
    setValue('cust_id', selected);
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="tr_date"
                    label="Date"
                    placeholder="DD-MM-YYYY"
                    error={errors.tr_date}
                    register={register('tr_date')}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="plant_id"
                    label="Plant"
                    labelKey="plant_id"
                    valueKey="_id"
                    options={plants}
                    placeholder="Select Plant"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option =>
                      `${option['plant_code']}-${option['plant_name']}`
                    }
                    getOptionValue={option => option?._id}
                    handleChange={handlePlantChange}
                    error={errors.plant_id}
                    control={control}
                    defaultValue={watchPlant}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="cust_id"
                    label="Customer"
                    labelKey="name"
                    valueKey="name"
                    options={customers}
                    placeholder="Select Customer"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option?.name}
                    handleChange={handleCustomerChange}
                    error={errors.cust_id}
                    control={control}
                    defaultValue={watchCustomer}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="referance"
                    id="referance"
                    label="Referance"
                    placeholder="Referance"
                    error={errors.referance}
                    register={register('referance')}
                  />
                </Box>
                <Box w={['full', 6 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="note"
                    id="note"
                    label="Note"
                    placeholder="Write Your Note"
                    error={errors.note}
                    register={register('note')}
                  />
                </Box>
              </Flex>
              <Flex
                direction={['column', 'row']}
                w={['full', 'full']}
                h={['full', 'full']}
              >
                <MaterialSalesCart
                  cart={cart}
                  setCart={setCart}
                  error={errors.materials}
                  clearErrors={clearErrors}
                  selectedPlant={watchPlant}
                  tradable={true}
                  consumable={false}
                  tr_type={`SALES`}
                />
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const SalesAdd = {
  routeProps: {
    path: '/store/sales/add',
    component: AddPage,
    exact: true,
  },
  name: 'Add Sales',
  title: 'Add Sales',
};

export default SalesAdd;
