import * as yup from 'yup';
import messages from './messages';

const materialDiscontinueSchema = yup.object().shape({
  is_discontinued: yup
    .boolean()
    .required(messages.is_discontinued.required)
    .nullable(true)
    .label('Discontinued'),
});

export default materialDiscontinueSchema;
