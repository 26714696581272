import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Progress,
} from '@chakra-ui/react';

import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { SHIFT_OPTIONS } from 'configs/ShiftOptions';

import addSchema from './addSchema';
import fields from 'modules/FiscalYears/fields';
import milkuploadService from 'services/milkuploadService';
import DateField from 'components/form/DateField';
import SelectField from 'components/form/SelectField';
import FileUploadField from 'components/form/FileUploadField';

const AddPage = () => {
  const FormTitle = 'UPLOAD MILK TRANSACTIONS FILE';
  const IndexPageTitle = 'MILK FILE UPLOADS';
  const IndexPagePath = '/milkfileuploads';

  const defaultValues = {
    fiscal_year: fields?.fiscal_year?.default,
  };

  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [progress, setProgress] = useState(0);

  const fetchIdRef = useRef(0);
  const history = useHistory();

  useEffect(() => {
    if (apiError.errors) {
      setProgress(0);
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
    setFilename(null);
  };

  const watchPlant = watch('plant_code', null);
  const watchShift = watch('shift', null);

  const validateFiles = value => {
    if (value.length < 1) {
      return 'Files is required';
    }
    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024);
      const MAX_FILE_SIZE = 10;
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size 10mb';
      }
    }
    return true;
  };

  const onSubmit = async formData => {
    setProgress(5);
    const formDataSend = new FormData();
    formDataSend.append('proc_date', formData.proc_date);
    formDataSend.append('shift', formData.shift?.value);
    formDataSend.append('plant_code', formData.plant_code?.plant_code);
    formDataSend.append('milk_file', file);

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    };

    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const responseData = await milkuploadService.upload(
        formDataSend,
        options
      );

      if (responseData) {
        history.push('/milkfileuploads');
        toast.success(`${responseData.message}`);
      }
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant_code', null);
      return;
    }
    return setValue('plant_code', selected);
  };

  const handleShiftChange = (selected, action) => {
    if (action === 'clear') {
      setValue('shift', null);
      return;
    }
    return setValue('shift', selected);
  };

  const handleFileChange = async event => {
    setProgress(0);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const onUploadProgress = progressEvent => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);

    if (percent < 100) {
      setProgress(percent);
    }
  };

  useEffect(() => {
    if (file) {
      setFilename(file?.name);
    }
    // eslint-disable-next-line
  }, [file]);

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[4, 0]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <DateField
                    name="proc_date"
                    label="Procurement Date"
                    placeholder="DD-MM-YYYY"
                    error={errors.proc_date}
                    register={register('proc_date')}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="shift"
                    label="Shift"
                    labelKey="name"
                    valueKey="value"
                    options={SHIFT_OPTIONS}
                    placeholder="Select Shift"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => option?.value}
                    handleChange={handleShiftChange}
                    error={errors.shift}
                    control={control}
                    defaultValue={watchShift}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="plant_code"
                    label="Plant"
                    labelKey="plant_code"
                    valueKey="_id"
                    options={plants}
                    placeholder="Select Plant"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option =>
                      `${option['plant_code']}-${option['plant_name']}`
                    }
                    getOptionValue={option => option?._id}
                    handleChange={handlePlantChange}
                    error={errors.plant_code}
                    control={control}
                    defaultValue={watchPlant}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 4 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <FileUploadField
                    id="milk_file"
                    name="milk_file"
                    label={`Milk File ${filename ? ' ( ' : ''} ${
                      filename ? filename : ''
                    }${filename ? ' ) ' : ''} `}
                    register={register('milk_file', {
                      validate: validateFiles,
                    })}
                    onChange={handleFileChange}
                    error={errors.milk_file}
                  />
                </Box>
              </Flex>
              {progress > 0 && (
                <Flex direction={['column', 'row']}>
                  <Box w={['full', 4 / 6]} mr={[0, 4]} mb={[1, 0]}>
                    <Progress hasStripe value={progress} />
                  </Box>
                </Flex>
              )}
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  UPLOAD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MilkFileUpload = {
  routeProps: {
    path: '/milkfileuploads/upload',
    component: AddPage,
    exact: true,
  },
  name: 'Milk File Upload',
  title: 'Milk File Upload',
};

export default MilkFileUpload;
