const fields = {
  names: ['tr_date', 'referance', 'plant_id', 'materials', 'note'],
  tr_date: {
    default: null,
    disabled: false,
    readonly: false,
  },
  referance: {
    default: '',
    disabled: false,
    readonly: false,
  },
  plant_from_id: {
    default: '',
    disabled: false,
    readonly: false,
  },
  plant_to_id: {
    default: '',
    disabled: false,
    readonly: false,
  },
  materials: {
    default: [],
    disabled: false,
    readonly: false,
  },
  note: {
    default: '',
    disabled: false,
    readonly: false,
  },
};

export default fields;
