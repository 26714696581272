import React, { useState, useEffect } from 'react';
import { FormErrorMessage, FormControl, FormLabel } from '@chakra-ui/react';
import Select from 'components/form/chakra-react-select';

export default function SelectFieldSimple(props) {
  const [selected, setSelected] = useState([]);

  const {
    id,
    error,
    label,
    name,
    options,
    value,
    // defaultValue = null,
    placeholder,
    getOptionLabel,
    getOptionValue,
    handleChange,
    disabled = false,
  } = props;

  useEffect(() => {
    setSelected(value);
  }, [options, value]);

  return (
    <FormControl id={id} isInvalid={error} py="2">
      {label && (
        <FormLabel
          htmlFor={name}
          color={disabled ? 'brand.light' : error ? 'red.500' : 'brand.default'}
        >
          {label}
        </FormLabel>
      )}
      <Select
        {...props}
        options={options}
        placeholder={placeholder ? placeholder : 'Select'}
        closeMenuOnSelect={false}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={(selected, { action }) => {
          handleChange(selected, action);
        }}
        value={selected}
        error={error}
        isDisabled={disabled ? true : false}
      />

      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}
