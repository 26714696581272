import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);

  try {
    const response = await API.get(`/material?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async _id => {
  try {
    const response = await API.get(`/material/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const create = async (formData = {}) => {
  try {
    const response = await API.post(`/material/create`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const update = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/material/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const discontinue = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/material/discontinue/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const materialService = {
  getAll,
  getById,
  create,
  update,
  discontinue,
};

export default materialService;
