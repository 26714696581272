import * as yup from 'yup';
import messages from './messages';

const updateMobileSchema = yup.object().shape({
  mobile: yup
    .string()
    .label('Mobile')
    .required(messages.mobile.required)
    .min(0, messages.mobile.invalid)
    .max(10, messages.mobile.invalid),
});

export default updateMobileSchema;
