import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import renderDate from 'components/tables/helpers/renderDate';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderStatus from 'components/tables/helpers/renderStatus';
import renderMaterialTrType from 'components/tables/helpers/renderMaterialTrType';

import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'tr_date',
    accessor: 'tr_date',
    Header: 'Date',
    type: 'string',
    hidden: false,
    Cell: ({ cell }) => renderDate(cell),
    headerAlign: 'center',
    exportable: true,
    disableSortBy: false,
    minWidth: 100,
  },
  {
    id: 'tr_type',
    accessor: 'tr_type',
    Header: 'TR Type',
    type: 'string',
    hidden: false,
    Cell: ({ cell, row }) =>
      renderMaterialTrType(cell, row?.original?.return_id),
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'referance',
    accessor: 'referance',
    Header: 'Referance',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'Consumer',
    accessor: 'consumer_id',
    Header: 'Consumer',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'plant_code',
    accessor: 'plant_id',
    Header: 'Plant Code',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.plant_code : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.plant_code : '-'}</div>;
    },
  },
  {
    id: 'plant_name',
    accessor: 'plant_id',
    Header: 'Plant Name',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.plant_name : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.plant_name : '-'}</div>;
    },
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },
  {
    id: 'note',
    accessor: 'note',
    Header: 'Note',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'posted_by',
    accessor: 'posted_by.username',
    Header: 'Posted By',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'return_id',
    accessor: 'return_id',
    Header: 'Returned',
    type: 'object',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: false,
    disableSortBy: true,
    expandfunction: value => {
      return <div>{value ? 'TRUE' : 'FALSE'}</div>;
    },
  },
  {
    id: 'materialtransactions',
    accessor: 'materialtransactions',
    Header: 'Order Items',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return (
        <div style={{ textAlign: 'center' }}>
          {cell.value ? cell.value?.length : '-'}
        </div>
      );
    },
    exportable: false,
    disableSortBy: true,
    expandfunction: value => {
      return <div>{value ? value?.plant_name : '-'}</div>;
    },
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columns;
