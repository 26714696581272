import * as yup from 'yup';
import messages from './messages';

const updateUserSchema = yup.object().shape({
  firstname: yup
    .string()
    .nullable(true)
    .min(2, messages.firstname.min)
    .max(100, messages.firstname.max)
    .transform((v, o) => (o === '' ? null : v))
    .label('Firstname'),
  middlename: yup
    .string()
    .nullable(true)
    .min(1, messages.middlename.min)
    .max(100, messages.middlename.max)
    .transform((v, o) => (o === '' ? null : v))
    .label('Middlename'),
  lastname: yup
    .string()
    .nullable(true)
    .min(2, messages.lastname.min)
    .max(100, messages.lastname.max)
    .transform((v, o) => (o === '' ? null : v))
    .label('Lastname'),
  employee_code: yup
    .string()
    .trim()
    .length(8, messages.employee_code.invalid)
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v))
    .label('Employee Code'),
});

export default updateUserSchema;
