import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import editSecretarySchema from './editSecretarySchema';
import fields from './fields';
import societyService from 'services/societyService';

import InputField from 'components/form/InputField';
import EditButtons from './EditButtons';

const EditSecretaryPage = () => {
  const FormTitle = 'EDIT SOCIETY SECRETARY DETAILS';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const apiError = useSelector(state => state.error);

  const [data, setData] = useState(null);

  const { _id } = useParams();

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);

  useEffect(() => {
    const fetchSociety = async () => {
      const responseData = await societyService.getById(_id);
      if (responseData && responseData.data) {
        setData(responseData.data);
        // console.log(data);
      }
    };

    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      fetchSociety();
    }
    // eslint-disable-next-line
  }, []);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(editSecretarySchema),
    defaultValues: {
      secretary_fname: fields.secretary_fname.default,
      secretary_mname: fields.secretary_mname.default,
      secretary_lname: fields.secretary_lname.default,
      secretary_address1: fields.secretary_address1.default,
      secretary_address2: fields.secretary_address2.default,
      secretary_address3: fields.secretary_address3.default,
      secretary_city: fields.secretary_city.default,
      secretary_mobile: fields.secretary_mobile.default,
      secretary_email: fields.secretary_email.default,
      secretary_pan: fields.secretary_pan.default,
      secretary_pan_name: fields.secretary_pan_name.default,
      secretary_adhar: fields.secretary_adhar.default,

      gu_secretary_fname: fields.gu_secretary_fname.default,
      gu_secretary_mname: fields.gu_secretary_mname.default,
      gu_secretary_lname: fields.gu_secretary_lname.default,
      gu_secretary_address1: fields.gu_secretary_address1.default,
      gu_secretary_address2: fields.gu_secretary_address2.default,
      gu_secretary_address3: fields.gu_secretary_address3.default,
      gu_secretary_city: fields.gu_secretary_city.default,
    },
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
  } = useForm(formOptions);

  const fieldsUsed = [
    'secretary_fname',
    'secretary_mname',
    'secretary_lname',
    'secretary_address1',
    'secretary_address2',
    'secretary_address3',
    'secretary_city',
    'secretary_pin',
    'secretary_mobile',
    'secretary_email',
    'secretary_pan',
    'secretary_pan_name',
    'secretary_adhar',

    'gu_secretary_fname',
    'gu_secretary_mname',
    'gu_secretary_lname',
    'gu_secretary_address1',
    'gu_secretary_address2',
    'gu_secretary_address3',
    'gu_secretary_city',
  ];

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        if (fieldsUsed.includes(key)) {
          setValue(key, value);
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        secretary_fname: formData.secretary_fname,
        secretary_mname: formData.secretary_mname,
        secretary_lname: formData.secretary_lname,
        secretary_address1: formData.secretary_address1,
        secretary_address2: formData.secretary_address2,
        secretary_address3: formData.secretary_address3,
        secretary_city: formData.secretary_city,
        secretary_pin: formData.secretary_pin,
        secretary_mobile: formData.secretary_mobile,
        secretary_email: formData.secretary_email,
        secretary_pan: formData.secretary_pan,
        secretary_pan_name: formData.secretary_pan_name,
        secretary_adhar: formData.secretary_adhar,

        gu_secretary_fname: formData.gu_secretary_fname,
        gu_secretary_mname: formData.gu_secretary_mname,
        gu_secretary_lname: formData.gu_secretary_lname,
        gu_secretary_address1: formData.gu_secretary_address1,
        gu_secretary_address2: formData.gu_secretary_address2,
        gu_secretary_address3: formData.gu_secretary_address3,
        gu_secretary_city: formData.gu_secretary_city,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (
          value === null ||
          value === undefined ||
          formDataSend[key] === data[key]
        ) {
          delete formDataSend[key];
        }
      }
      const responseData = await societyService.updateSocietySecretaryDetails(
        data?._id,
        formDataSend
      );
      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <EditButtons _id={data?._id} />

              {/* Start Row 12 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_fname"
                    id="secretary_fname"
                    label="Secretary Firstname"
                    placeholder="Secretary Firstname"
                    error={errors.secretary_fname}
                    register={register('secretary_fname')}
                    disabled={fields?.secretary_fname?.disabled}
                    readonly={fields?.secretary_fname?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_mname"
                    id="secretary_mname"
                    label="Secretary Middlename"
                    placeholder="Secretary Middlename"
                    error={errors.secretary_mname}
                    register={register('secretary_mname')}
                    disabled={fields?.secretary_mname?.disabled}
                    readonly={fields?.secretary_mname?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_lname"
                    id="secretary_lname"
                    label="Secretary Lastname"
                    placeholder="Secretary Lastname"
                    error={errors.secretary_lname}
                    register={register('secretary_lname')}
                    disabled={fields?.secretary_lname?.disabled}
                    readonly={fields?.secretary_lname?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_mobile"
                    id="secretary_mobile"
                    label="Secretary Mobile"
                    placeholder="Secretary Mobile"
                    error={errors.secretary_mobile}
                    register={register('secretary_mobile')}
                    disabled={fields?.secretary_mobile?.disabled}
                    readonly={fields?.secretary_mobile?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="email"
                    name="secretary_email"
                    id="secretary_email"
                    label="Secretary E-Mail"
                    placeholder="Secretary E-Mail"
                    error={errors.secretary_email}
                    register={register('secretary_email')}
                    disabled={fields?.secretary_email?.disabled}
                    readonly={fields?.secretary_email?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 12 */}

              {/* Start Row 13 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_address1"
                    id="secretary_address1"
                    label="Secretary Address 1"
                    placeholder="Secretary Address 1"
                    error={errors.secretary_address1}
                    register={register('secretary_address1')}
                    disabled={fields?.secretary_address1?.disabled}
                    readonly={fields?.secretary_address1?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_address2"
                    id="secretary_address2"
                    label="Secretary Address 2"
                    placeholder="Secretary Address 2"
                    error={errors.secretary_address2}
                    register={register('secretary_address2')}
                    disabled={fields?.secretary_address2?.disabled}
                    readonly={fields?.secretary_address2?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_address3"
                    id="secretary_address3"
                    label="Secretary Address 3"
                    placeholder="Secretary Address 3"
                    error={errors.secretary_address3}
                    register={register('secretary_address3')}
                    disabled={fields?.secretary_address3?.disabled}
                    readonly={fields?.secretary_address3?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 13 */}

              {/* Start Row 14 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_city"
                    id="secretary_city"
                    label="Secretary City"
                    placeholder="Secretary City"
                    error={errors.secretary_city}
                    register={register('secretary_city')}
                    disabled={fields?.secretary_city?.disabled}
                    readonly={fields?.secretary_city?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_pin"
                    id="secretary_pin"
                    label="Secretary Pin"
                    placeholder="Secretary Pin"
                    error={errors.secretary_pin}
                    register={register('secretary_pin')}
                    disabled={fields?.secretary_pin?.disabled}
                    readonly={fields?.secretary_pin?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_adhar"
                    id="secretary_adhar"
                    label="Secretary Adhar"
                    placeholder="Secretary Adhar"
                    error={errors.secretary_adhar}
                    register={register('secretary_adhar')}
                    disabled={fields?.secretary_adhar?.disabled}
                    readonly={fields?.secretary_adhar?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_pan"
                    id="secretary_pan"
                    label="Secretary PAN"
                    placeholder="Secretary PAN"
                    error={errors.secretary_pan}
                    register={register('secretary_pan')}
                    disabled={fields?.secretary_pan?.disabled}
                    readonly={fields?.secretary_pan?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="secretary_pan_name"
                    id="secretary_pan_name"
                    label="Secretary PAN Name"
                    placeholder="Secretary PAN Name"
                    error={errors.secretary_pan_name}
                    register={register('secretary_pan_name')}
                    disabled={fields?.secretary_pan_name?.disabled}
                    readonly={fields?.secretary_pan_name?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 14 */}

              {/* Start Row 23 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_secretary_fname"
                    id="gu_secretary_fname"
                    label="સેક્રેટરી નામ"
                    placeholder="સેક્રેટરી નામ"
                    error={errors.gu_secretary_fname}
                    register={register('gu_secretary_fname')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_secretary_mname"
                    id="gu_secretary_mname"
                    label="સેક્રેટરી પીતા નું નામ"
                    placeholder="સેક્રેટરી પીતા નું નામ"
                    error={errors.gu_secretary_mname}
                    register={register('gu_secretary_mname')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_secretary_lname"
                    id="gu_secretary_lname"
                    label="સેક્રેટરી અટક"
                    placeholder="સેક્રેટરી અટક"
                    error={errors.gu_secretary_lname}
                    register={register('gu_secretary_lname')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_secretary_city"
                    id="gu_secretary_city"
                    label="સેક્રેટરી ગામ"
                    placeholder="સેક્રેટરી ગામ"
                    error={errors.gu_secretary_city}
                    register={register('gu_secretary_city')}
                  />
                </Box>
              </Flex>
              {/* End Row 23 */}

              {/* Start Row 24 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_secretary_address1"
                    id="gu_secretary_address1"
                    label="સેક્રેટરી સરનામું ૧"
                    placeholder="સેક્રેટરી સરનામું ૧"
                    error={errors.gu_secretary_address1}
                    register={register('gu_secretary_address1')}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_secretary_address2"
                    id="gu_secretary_address2"
                    label="સેક્રેટરી સરનામું ૨"
                    placeholder="સેક્રેટરી સરનામું ૨"
                    error={errors.gu_secretary_address2}
                    register={register('gu_secretary_address2')}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_secretary_address3"
                    id="gu_secretary_address3"
                    label="સેક્રેટરી સરનામું ૩"
                    placeholder="સેક્રેટરી સરનામું ૩"
                    error={errors.gu_secretary_address3}
                    register={register('gu_secretary_address3')}
                  />
                </Box>
              </Flex>
              {/* End Row 24 */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const SocietyEditSecretary = {
  routeProps: {
    path: '/societies/edit/secretary/:_id',
    component: EditSecretaryPage,
    exact: true,
  },
  name: 'Edit Society Secretary Details',
  title: 'Edit Society Secretary Details',
};

export default SocietyEditSecretary;
