import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  type: yup
    .string()
    .required(messages.type.required)
    .trim()
    .min(2)
    .max(30)
    .label('Adultration Type'),
  amount: yup
    .number(messages.amount.invalid)
    .min(0, messages.amount.min)
    .max(10000, messages.amount.max)
    .required(messages.amount.required)
    .positive(messages.amount.invalid)
    .typeError(messages.amount.invalid)
    .label('Penalty Amount'),
  note: yup.string(messages.note.invalid).trim().label('Note'),
  is_active: yup
    .boolean()
    .label('Status')
    .required(messages.is_active.required),
});

export default addSchema;
