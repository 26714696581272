import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/milk/getalluploads?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async _id => {
  try {
    const response = await API.get(`/milk/upload/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const upload = async (formData = {}, options = null) => {
  try {
    const response = await API.post(`/milk/upload`, formData, options);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const download = async (_id, options = null) => {
  try {
    options = { responseType: 'blob' };
    const response = await API.get(`/milk/download/${_id}`, options);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const remove = async _id => {
  try {
    const response = await API.delete(`/milk/upload/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const finalize = async (_id, formData) => {
  try {
    const response = await API.post(`/milk/finalize/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const milkuploadService = {
  getAll,
  getById,
  upload,
  download,
  remove,
  finalize,
};

export default milkuploadService;
