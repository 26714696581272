import * as yup from 'yup';
import messages from './messages';

const editSchema = yup.object().shape({
  name: yup.string().trim().min(2).max(200).label('Name'),
  address: yup.string().trim().max(500).label('Address'),
  city: yup
    .string()
    .required(messages.city.required)
    .trim()
    .min(2)
    .max(20)
    .label('City'),
  state: yup.string().trim().min(2).max(20).label('City'),
  pan: yup.string().trim().min(2).max(20).label('PAN'),
  pan_name: yup.string().trim().min(2).max(20).label('Name as in PAN'),
  gst: yup.string().trim().min(2).max(20).label('GST'),
  gst_name: yup.string().trim().min(2).max(20).label('Name as in GST'),
  mobile: yup
    .string()
    .label('Mobile')
    .min(0, messages.mobile.invalid)
    .max(10, messages.mobile.invalid),
  email: yup.string().email(messages.email.invalid).label('E-Mail'),
  is_active: yup.boolean().nullable(true).label('Status'),
});

export default editSchema;
