const messages = {
  tr_date: {
    required: 'Date is required',
    invalid: 'Date has invalid value',
  },
  referance: {
    required: 'Referance is required',
    invalid: 'Referance has invalid value',
  },
  note: {
    required: 'Note is required',
    invalid: 'Note has invalid value',
  },
  catagory: {
    required: 'Catagory is required',
    invalid: 'Catagory has invalid value',
  },
  plant_id: {
    required: 'Plant is required',
    invalid: 'Plant has invalid value',
  },
  materials: {
    required: 'Cart can not be empty',
    invalid: 'Invalid Cart Items',
  },
};

export default messages;
