import moment from 'helpers/moment';

export default function renderDataCell(col, row) {
  if (!col.hidden) return;
  const value = row.original[col.id];
  if (!value) return <span>-</span>;
  if (!col.type) return <span> {JSON.stringify(value, null, ' ')} </span>;
  if (col.type === 'string') return <span> {value} </span>;
  if (col.type === 'boolean')
    return <span> {JSON.stringify(value, null, ' ')} </span>;
  if (col.type === 'date') {
    return (
      <span style={{ color: '#000' }}>
        {moment(value).format(moment.defaultDateFormat)}
      </span>
    );
  }
  if (col.type === 'datetime') {
    return (
      <span style={{ color: '#000' }}>
        {moment(value).format(moment.defaultDateTimeFormat)}
      </span>
    );
  }
  if ((col.type === 'object' || col.type === 'array') && col.expandfunction) {
    const fun = col.expandfunction;
    return fun(value);
  }
  if (
    value instanceof Object ||
    value instanceof Array ||
    col.type === 'object' ||
    col.type === 'array'
  ) {
    return (
      <span className="text-sm">
        <code>
          <pre style={{ fontSize: '12px', color: '#000' }}>
            {JSON.stringify(value, null, ' ')}
          </pre>
        </code>
      </span>
    );
  }
}
