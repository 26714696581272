import React from 'react';
import TableActionColumn from 'components/tables/TableActionColumn';
import { useHistory } from 'react-router';
import milktransactionService from 'services/milktransactionService';
import { toast } from 'react-toastify';
import { Button } from '@chakra-ui/react';
import { FaSms } from 'react-icons/fa';
import moment from 'helpers/moment';
import config from 'configs/config';

const hasEdit = ['superadmin', 'admin', 'plantincharge', 'operator'];
const hasDelete = ['superadmin', 'admin', 'plantincharge', 'operator'];
const hasCustom = ['superadmin'];

export default function ActionColumn({ row }) {
  const history = useHistory();

  const last_day = moment()
    .startOf('day')
    .subtract(config.milk_message_single_send_test_max_days, 'days');
  const proc_date = moment(row?.proc_date).startOf('day');
  const allowSendTestSms = proc_date.isSameOrAfter(last_day);

  const deleteRecord = () => {
    history.push({
      pathname: `/milktransactions/delete/${row._id}/${row.fiscal_year}`,
      state: row,
    });
  };

  const customAction = async () => {
    const formData = { fiscal_year: row.fiscal_year };
    const responseData = await milktransactionService.sendSingleMessage(
      row._id,
      formData
    );

    if (responseData) {
      toast.success(responseData?.message || `All Messages Sent Successfully`);
    }
  };

  const customButton = allowSendTestSms && (
    <Button type="button" size="xs" colorScheme="blue" onClick={customAction}>
      <FaSms className="text-white" />
    </Button>
  );

  return (
    <TableActionColumn
      row={row}
      hasEdit={hasEdit}
      editLink={`/milktransactions/edit/${row._id}/${row.fiscal_year}`}
      hasDelete={hasDelete}
      handleDelete={deleteRecord}
      hasCustom={hasCustom}
      customComponent={customButton}
    />
  );
}
