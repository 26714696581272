import React, { useState, useEffect } from 'react';
import { FormErrorMessage, FormControl, FormLabel } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import Select from 'components/form/chakra-react-select';

export default function SelectField(props) {
  const [selected, setSelected] = useState([]);

  const {
    id,
    error,
    label,
    name,
    options,
    value,
    defaultValue = null,
    control,
    placeholder,
    getOptionLabel,
    getOptionValue,
    handleChange,
    disabled = false,
    filterOption,
  } = props;

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue, options, value]);

  return (
    <FormControl id={id} isInvalid={error} py="2">
      {label && (
        <FormLabel
          htmlFor={name}
          color={disabled ? 'brand.light' : error ? 'red.500' : 'brand.default'}
        >
          {label}
        </FormLabel>
      )}

      <Controller
        render={({ field }) => {
          return (
            <Select
              {...props}
              {...field}
              options={options}
              placeholder={placeholder ? placeholder : 'Select'}
              closeMenuOnSelect={false}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChange={(selected, { action }) => {
                handleChange(selected, action);
              }}
              value={selected}
              error={error}
              isDisabled={disabled ? true : false}
              filterOption={filterOption}
            />
          );
        }}
        control={control}
        name={name}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}
