import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderStatus from 'components/tables/helpers/renderStatus';

import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'code',
    accessor: 'code',
    Header: 'Code',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Material',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.mediumtext.maxWidth,
    minWidth: tblConsts.mediumtext.minWidth,
  },
  {
    id: 'address',
    accessor: 'address',
    Header: 'Address',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
    disableHiding: true,
  },
  {
    id: 'city',
    accessor: 'city',
    Header: 'City',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'state',
    accessor: 'state',
    Header: 'State',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'pan',
    accessor: 'pan',
    Header: 'PAN',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.shorttext.maxWidth,
    minWidth: tblConsts.shorttext.minWidth,
  },
  {
    id: 'pan_name',
    accessor: 'pan_name',
    Header: 'Name (PAN)',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'gst',
    accessor: 'gst',
    Header: 'GST',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.shorttext.maxWidth,
    minWidth: tblConsts.shorttext.minWidth,
  },
  {
    id: 'gst_name',
    accessor: 'gst_name',
    Header: 'Name (GST)',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'mobile',
    accessor: 'mobile',
    Header: 'Mobile',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'email',
    accessor: 'email',
    Header: 'E-Mail',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.mediumtext.maxWidth,
    minWidth: tblConsts.mediumtext.minWidth,
  },
  {
    id: 'is_active',
    accessor: 'is_active',
    Header: 'Status',
    type: 'boolean',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columns;
