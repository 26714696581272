import jwtDecode from 'jwt-decode';
import moment from 'helpers/moment';

export const isTokenExpired = token => {
  if (!token) return true;
  const decodedToken = jwtDecode(token);
  // console.log(moment().toISOString());
  // console.log(moment.unix(decodedToken.exp).utc().toISOString());
  return moment().isSameOrAfter(moment.unix(decodedToken.exp).utc());
};

export const decodeToken = token => {
  if (!token) return null;
  const decodedToken = jwtDecode(token);
  return decodedToken;
};
