import * as yup from 'yup';

const messages = {
  current_password: {
    required: 'Current Password is required',
    invalid: 'Invalid Current Password',
  },
  password: {
    required: 'Password is required',
    invalid: 'Invalid Password',
    match: 'Password and Confirmation Password do not match',
  },
  password_confirmation: {
    required: 'Confirm Password is required',
    invalid: 'Invalid Confirm Password',
    match: 'Password and Confirmation Password do not match',
  },
};

const schema = yup.object().shape({
  current_password: yup.string().required(messages.current_password.required),
  password: yup
    .string()
    .required()
    .test('passwords-match', messages.password.match, function (value) {
      return this.parent.password_confirmation === value;
    }),
  password_confirmation: yup
    .string()
    .required()
    .test('passwords-match', messages.password.match, function (value) {
      return this.parent.password === value;
    }),
});

export default schema;
