import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Badge,
} from '@chakra-ui/react';

import SelectField from 'components/form/SelectField';
import TextAreaField from 'components/form/TextAreaField';

import approveRejectBulkSchema from './approveRejectBulkSchema';
import milktransactionApproveRejectService from 'services/milktransactionApproveRejectService';

const APPROVE_OPTIONS = [
  { name: 'APPROVE', value: true },
  { name: 'REJECT', value: false },
];

const defaultValues = {
  ids: [],
  approve_note: 'Approve/Reject',
  is_approved: { name: 'APPROVE', value: true },
};

const ApproveRejectBulk = () => {
  const FormTitle = 'BULK APPROVE/REJECT MILK TRANSACTIONS';
  const IndexPageTitle = 'MILK TRANSACTION EDITS';
  const IndexPagePath = '/milktransactionedits';

  const history = useHistory();
  const location = useLocation();
  const apiError = useSelector(state => state.error);

  const [ids, setIds] = useState(defaultValues.ids);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(approveRejectBulkSchema),
    defaultValues: defaultValues,
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    control,
    watch,
  } = useForm(formOptions);

  const watchIsApproved = watch('is_approved', null);

  useEffect(() => {
    setValue('is_approved', defaultValues.is_approved);
    setValue('approve_note', defaultValues.approve_note);
    setValue('ids', ids);
    // eslint-disable-next-line
  }, [ids]);

  useEffect(() => {
    setIds(location?.state);
    // eslint-disable-next-line
  }, [location]);

  const onSubmit = async formData => {
    return watchIsApproved?.value ? approve(formData) : reject(formData);
  };

  const approve = async formData => {
    const formDataSend = {
      ids: ids,
      approve_note: formData.approve_note,
    };
    const resData = await milktransactionApproveRejectService.approve(
      formDataSend
    );

    if (resData) {
      history.push('/milktransactionedits');
      toast.success(`${resData.message}`);
    }
  };

  const reject = async formData => {
    const formDataSend = {
      ids: ids,
      approve_note: formData.approve_note,
    };
    const resData = await milktransactionApproveRejectService.reject(
      formDataSend
    );

    if (resData) {
      history.push('/milktransactionedits');
      toast.success(`${resData.message}`);
    }
  };

  const handleApproveChange = (selected, action) => {
    if (action === 'clear') {
      setValue('is_approved', null);
      return;
    }
    return setValue('is_approved', selected);
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[4, 2]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="is_approved"
                    label="APPROVE OR REJECT"
                    labelKey="name"
                    valueKey="value"
                    options={APPROVE_OPTIONS}
                    placeholder="Select Status"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleApproveChange}
                    error={errors.is_approved}
                    control={control}
                    defaultValue={watchIsApproved}
                  />
                </Box>
                <Box w={['full', 4 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="approve_note"
                    id="approve_note"
                    label="Approve Note"
                    placeholder="Write Your Remark For The Edit Entry"
                    error={errors.approve_note}
                    register={register('approve_note')}
                  />
                </Box>
              </Flex>

              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <Text fontSize="xl" fontWeight="bold">
                    <Badge ml="4" px="4" fontSize="1.2em" colorScheme="orange">
                      SELECTED TRANSACTIONS :
                    </Badge>
                    <Badge ml="4" px="4" fontSize="1.2em" colorScheme="teal">
                      {ids.length}
                    </Badge>
                  </Text>
                </Box>
              </Flex>

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme={watchIsApproved?.value ? 'green' : 'red'}
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || ids.length === 0}
                >
                  {watchIsApproved?.value ? 'APPROVE' : 'REJECT'}
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  as={Link}
                  to={`/milktransactionedits`}
                >
                  CANCEL
                </Button>
              </Flex>
              {/* Form Contents */}
            </VStack>
          </Stack>
        </form>
      </Box>
    </VStack>
  );
};

// Exports

const MilkTransactionApproveRejectBulk = {
  routeProps: {
    path: '/milktransactionedits/bulkapprove',
    component: ApproveRejectBulk,
    exact: true,
  },
  name: 'Milk Transaction Approve/Reject Bulk',
  title: 'Milk Transaction Approve/Reject Bulk',
};

export default MilkTransactionApproveRejectBulk;
