import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';

// import ActionColumn from './ActionColumn';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Unit',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
  },
  // {
  //   id: 'actions',
  //   accessor: 'actions',
  //   Header: 'Actions',
  //   hidden: true,
  //   headerAlign: 'cemter',
  //   Cell: ({ row }) => <ActionColumn row={row?.values} />,
  //   exportable: false,
  //   disableSortBy: true,
  // },
];

export default columns;
