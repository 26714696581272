import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Heading,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import SwitchFieldSimple from 'components/form/SwitchFieldSimple';

import milkuploadService from 'services/milkuploadService';

const FinalizePage = ({ match }) => {
  const { _id } = match.params;

  const [data, setData] = useState(null);
  const [status, setStatus] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setData(location?.state);
  }, [location]);

  useEffect(() => {
    setStatus(data ? data.is_finalized : false);
  }, [data]);

  const FormTitle = 'Finalize Milk Transactions Data';
  const IndexPageTitle = 'MILK FILE UPLOADS';
  const IndexPagePath = '/milkfileuploads';

  const history = useHistory();

  const onSubmit = async () => {
    const resData = await milkuploadService.finalize(_id, {
      is_finalized: status,
    });

    if (resData) {
      toast.success(`${resData.message}`);
      history.push(`/milkfileuploads`);
    }
  };
  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[4, 0]}>
        <form>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <Heading size="lg" color="red.500">
                    Are You Sure you want to Change Finalize Status ?
                  </Heading>
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchFieldSimple
                    name="is_finalized"
                    id="is_finalized"
                    label="Finalize"
                    size="lg"
                    value={status}
                    defaultChecked={status}
                    handleChange={(e, switchStatus) => {
                      setStatus(switchStatus);
                    }}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <Table size="sm">
                    <Tbody>
                      <Tr>
                        <Th>Date</Th>
                        <Td>
                          {moment(data?.proc_date).format(
                            moment.defaultDateFormat
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Th>Shift</Th>
                        <Td>{data?.shift}</Td>
                      </Tr>
                      <Tr>
                        <Th>Plant Code</Th>
                        <Td>{data?.plant_code}</Td>
                      </Tr>
                      <Tr>
                        <Th>Plant Name</Th>
                        <Td>{data?.plant_name}</Td>
                      </Tr>
                      <Tr>
                        <Th>Proc Type</Th>
                        <Td>{data?.proc_type}</Td>
                      </Tr>
                      <Tr>
                        <Th>Status</Th>
                        <Td>{data?.progress}</Td>
                      </Tr>
                      <Tr>
                        <Th>Message</Th>
                        <Td>{data?.messages}</Td>
                      </Tr>
                      <Tr>
                        <Th>Uploaded By</Th>
                        <Td>{data?.username_create}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="button"
                  colorScheme="red"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  onClick={onSubmit}
                >
                  CHANGE FINALIZE STATUS
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  as={Link}
                  to={`/milkfileuploads`}
                >
                  CANCEL
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MilkFileFinalize = {
  routeProps: {
    path: '/milkfileuploads/finalize/:_id',
    component: FinalizePage,
    exact: true,
  },
  name: 'Milk Data Finalize',
  title: 'Milk Data Finalize',
};

export default MilkFileFinalize;
