import React, { useState } from 'react';
import {
  useColorModeValue,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

const SwitchFieldSimple = ({
  name,
  id,
  label,
  error,
  disabled = false,
  readonly = false,
  size = 'sm',
  colorScheme = 'orange',
  defaultChecked = false,
  handleChange,
  value = false,
}) => {
  const [status, setStatus] = useState(value);

  const handleLocalChange = e => {
    handleChange(e, status);
    setStatus(!status);
  };
  return (
    <FormControl id={id} isInvalid={error} py="2">
      {label && (
        <FormLabel
          htmlFor={name}
          color={disabled ? 'brand.light' : error ? 'red.500' : 'brand.default'}
        >
          {label}
        </FormLabel>
      )}
      <Switch
        colorScheme={colorScheme}
        focusBorderColor="orange.400"
        _disabled={{ color: useColorModeValue('gray.500', 'gray.400') }}
        width="full"
        name={name}
        isDisabled={disabled === true ? true : false}
        isReadOnly={readonly === true ? true : false}
        size={size}
        defaultChecked={defaultChecked ? true : false}
        isChecked={defaultChecked}
        onChange={handleLocalChange}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

export default SwitchFieldSimple;
