const fields = {
  names: [
    'code',
    'name',
    'address',
    'city',
    'state',
    'pan',
    'pan_name',
    'gst',
    'gst_name',
    'mobile',
    'email',
    'is_active',
  ],
  code: {
    default: '',
    disabled: false,
    readonly: false,
  },
  name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  address: {
    default: '',
    disabled: false,
    readonly: false,
  },
  city: {
    default: '',
    disabled: false,
    readonly: false,
  },
  state: {
    default: '',
    disabled: false,
    readonly: false,
  },
  pan: {
    default: '',
    disabled: false,
    readonly: false,
  },
  pan_name: {
    default: 1,
    disabled: false,
    readonly: false,
  },
  gst: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gst_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  monile: {
    default: '',
    disabled: false,
    readonly: false,
  },
  email: {
    default: '',
    disabled: false,
    readonly: false,
  },
  sgst: {
    default: '',
    disabled: false,
    readonly: false,
  },
  is_active: {
    default: true,
    disabled: false,
    readonly: false,
  },
};

export default fields;
