import * as yup from 'yup';
import messages from './messages';

const editChairmanSchema = yup.object().shape({
  chairman_fname: yup.string().trim().max(100, messages.chairman_fname.max),
  chairman_mname: yup.string().trim().max(100, messages.chairman_mname.max),
  chairman_lname: yup.string().trim().max(100, messages.chairman_lname.max),
  chairman_address1: yup
    .string()
    .trim()
    .max(100, messages.chairman_address1.max),
  chairman_address2: yup
    .string()
    .trim()
    .max(100, messages.chairman_address2.max),
  chairman_address3: yup
    .string()
    .trim()
    .max(100, messages.chairman_address3.max),
  chairman_pin: yup
    .string()
    .trim()
    // .length(6, messages.chairman_pin.invalid)
    .nullable()
    .notRequired(),
  chairman_mobile: yup.string().trim().max(100, messages.chairman_mobile.max),
  chairman_email: yup.string().trim().max(100, messages.chairman_email.max),
  chairman_pan: yup.string().trim().max(100, messages.chairman_pan.invalid),
  chairman_pan_name: yup
    .string()
    .trim()
    .max(100, messages.chairman_pan_name.max),
  chairman_adhar: yup
    .string()
    .trim()
    // .length(12, messages.chairman_adhar.invalid)
    .nullable()
    .notRequired(),
});

export default editChairmanSchema;
