import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/milk/transactions?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async (_id, fiscal_year) => {
  try {
    const response = await API.get(
      `/milk/transactions/${_id}?fiscal_year=${fiscal_year}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const sendSingleMessage = async (_id, formData = {}, options = null) => {
  try {
    const response = await API.post(
      `/milk/transactions/sendsms/${_id}`,
      formData,
      options
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const milktransactionService = {
  getAll,
  getById,
  sendSingleMessage,
};

export default milktransactionService;
