import * as yup from 'yup';
import messages from './messages';

const markDeleteSchema = yup.object().shape({
  note: yup
    .string(messages.note.invalid)
    .required(messages.note.required)
    .trim()
    .label('Note'),
});

export default markDeleteSchema;
