import React from 'react';
import TableActionColumn from 'components/tables/TableActionColumn';
import Show from './Show';

const hasShow = [];
const hasEdit = ['superadmin', 'hradmin'];

export default function ActionColumn({ row }) {
  return (
    <TableActionColumn
      row={row}
      hasEdit={hasEdit}
      editLink={`/users/edit/${row._id}`}
      hasShow={hasShow}
      showHeading={row.username}
      showContent={<Show row={row} />}
    />
  );
}
