import React from 'react';
import styles from './styles';

export default function PlaceholderA4() {
  return (
    <div style={styles.placeholder_container}>
      <div style={styles.placeholder}>
        <div className="placeholderA4"></div>
      </div>
    </div>
  );
}
