import * as yup from 'yup';
import messages from './messages';

const filterSchema = yup.object().shape({
  fiscal_year: yup
    .object()
    .required(messages.fiscal_year.required)
    .label('Fiscal Year'),
  proc_date: yup.string(messages.proc_date.invalid).nullable(true),
  plant_code: yup.object().label('Plant'),
  society_code: yup
    .number(messages.society_code.invalid)
    .integer(messages.society_code.invalid)
    .typeError(messages.society_code.invalid)
    .truncate(true)
    .nullable(true),
});

export default filterSchema;
