import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderLocation from 'components/tables/helpers/renderLocation';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: false,
    disableSortBy: false,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'username',
    accessor: 'username',
    Header: 'Username',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'type',
    accessor: 'type',
    Header: 'Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'message',
    accessor: 'message',
    Header: 'message',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.longtext.maxWidth,
    minWidth: tblConsts.longtext.minWidth,
  },
  {
    id: 'ip',
    accessor: 'ip',
    Header: 'IP',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.shorttext.maxWidth,
    minWidth: tblConsts.shorttext.minWidth,
  },
  {
    id: 'local_ip',
    accessor: 'local_ip',
    Header: 'Local IP',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.shorttext.maxWidth,
    minWidth: tblConsts.shorttext.minWidth,
  },
  {
    id: 'country',
    accessor: 'country',
    Header: 'Country',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'region',
    accessor: 'region',
    Header: 'Region',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'city',
    accessor: 'city',
    Header: 'City',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'timezone',
    accessor: 'timezone',
    Header: 'Timezone',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'location',
    accessor: 'location',
    Header: 'Location',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    Cell: ({ cell }) => renderLocation(cell),
    disableSortBy: true,
    expandfunction: value => renderLocation({ value: value }),
    disableHideColumn: true,
  },
  {
    id: 'area',
    accessor: 'area',
    Header: 'Area',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'device_vendor',
    accessor: 'device_vendor',
    Header: 'Device Vendor',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'device_model',
    accessor: 'device_model',
    Header: 'Device Model',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'device_type',
    accessor: 'device_type',
    Header: 'Device Type',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'browser',
    accessor: 'browser',
    Header: 'Browser',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'browser_version',
    accessor: 'browser_version',
    Header: 'Browser Version',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'os',
    accessor: 'os',
    Header: 'OS',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'os_version',
    accessor: 'os_version',
    Header: 'OS Version',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'platform',
    accessor: 'platform',
    Header: 'Platform',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cpu_architecture',
    accessor: 'cpu_architecture',
    Header: 'CPU Architecture',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'meta',
    accessor: 'meta',
    Header: 'Meta',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    disableHideColumn: true,
  },
];

export default columns;
