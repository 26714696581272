import React from 'react';
import { useHistory } from 'react-router';
import { FaCheckDouble, FaTimes } from 'react-icons/fa';
import { Button, Center } from '@chakra-ui/react';
import { MILKFILE_UPLOAD_STATUS } from 'configs/milkFileUploadStatus';

export default function FinalizeColumn({ row }) {
  const history = useHistory();

  const goToPage = () => {
    history.push({
      pathname: `/milkfileuploads/finalize/${row._id}`,
      state: row,
    });
  };

  return (
    <Center>
      <Button
        type="button"
        size="xs"
        colorScheme="blue"
        variant="outline"
        onClick={goToPage}
        isDisabled={row?.progress !== MILKFILE_UPLOAD_STATUS.completed}
      >
        {row.is_finalized ? (
          <FaTimes color="red" />
        ) : (
          <FaCheckDouble color="green" />
        )}
      </Button>
    </Center>
  );
}
