import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

// Dashboard Components
import YesterdayMilk from './Components/YesterdayMilk';
import TotalMilkForLastDays from './Components/TotalMilkForLastDays';
import TotalMilkPlant from './Components/TotalMilkPlant';
import TotalMilkMonth from './Components/TotalMilkMonth';

const IndexPage = props => {
  return (
    <Flex direction="column" justifyContent="start" alignItems="start">
      <Flex
        direction={{ sm: 'column', md: 'row' }}
        justifyContent="start"
        alignItems="center"
        w="full"
        h="230px"
        mb={4}
      >
        <Box height="full" width={{ sm: 'full', md: '280px' }} p={2}>
          <YesterdayMilk />
        </Box>
        <Box height="full" flex="1" p={2}>
          <TotalMilkForLastDays />
        </Box>
      </Flex>

      <Box w="full" height="600px" mb={4} p={2}>
        <TotalMilkPlant />
      </Box>

      <Flex
        direction={{ sm: 'column', md: 'row' }}
        justifyContent="start"
        alignItems="start"
        width="full"
        height="600px"
        // mb={4}
      >
        <TotalMilkMonth />
      </Flex>
    </Flex>
  );
};

const DashboardMilk = {
  routeProps: {
    path: '/dashboardmilk',
    component: IndexPage,
    exact: true,
  },
  name: 'Milk Dashboard',
  title: 'Milk Dashboard',
};

export default DashboardMilk;
