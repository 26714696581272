import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  proc_date: yup
    .string(messages.proc_date.invalid)
    .trim()
    .required(messages.proc_date.required)
    .nullable(true)
    .label('Proc Date'),
  society_id: yup
    .object()
    .label('Society')
    .required(messages.society_id.required)
    .nullable(true)
    .label('Society'),
  shift: yup
    .object()
    .required(messages.shift.required)
    .nullable(true)
    .label('Shift'),
  milk_catagory: yup
    .object()
    .required(messages.milk_catagory.required)
    .nullable(true)
    .label('Milk Catagory'),
  milk_type: yup
    .object()
    .required(messages.milk_type.required)
    .nullable(true)
    .label('Milk Type'),
  cans: yup
    .number(messages.cans.invalid)
    .min(1, messages.cans.min)
    .max(30, messages.cans.max)
    .required(messages.cans.required)
    .positive(messages.cans.invalid)
    .integer(messages.cans.invalid)
    .typeError(messages.cans.invalid)
    .label('Cans'),
  weight_kg: yup
    .number(messages.weight_kg.invalid)
    .min(1, messages.weight_kg.min)
    .max(1000, messages.weight_kg.max)
    .required(messages.weight_kg.required)
    .positive(messages.weight_kg.invalid)
    .typeError(messages.weight_kg.invalid)
    .label('Weight'),
  fat: yup
    .number(messages.fat.invalid)
    .min(3, messages.fat.min)
    .max(8, messages.fat.max)
    .required(messages.fat.required)
    .positive(messages.fat.invalid)
    .typeError(messages.fat.invalid)
    .label('FAT'),
  snf: yup
    .number(messages.snf.invalid)
    .min(8, messages.snf.min)
    .max(12, messages.snf.max)
    .required(messages.snf.required)
    .positive(messages.snf.invalid)
    .typeError(messages.snf.invalid)
    .label('SNF'),
  amount: yup
    .number(messages.amount.invalid)
    .min(1, messages.amount.min)
    .max(500000, messages.amount.max)
    .required(messages.amount.required)
    .positive(messages.amount.invalid)
    .typeError(messages.amount.invalid)
    .label('Amount'),
  note: yup
    .string(messages.note.invalid)
    .required(messages.note.required)
    .trim()
    .label('Note'),
});

export default addSchema;
