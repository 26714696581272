import React, { useEffect } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';
import fields from './fields';

import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';
import RenderUserOption from 'components/form/RenderUserOption';

export default function Filters({
  onFilter,
  defaultFilters,
  setFilters,
  users,
}) {
  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    society_code: fields?.society_code?.default,
    society_name: fields?.society_name?.default,
    city: fields?.city?.default,
    plant_id: fields?.plant?.default,
    supervisor: fields?.supervisor?.default,
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const watchPlant = watch('plant', '');
  const watchSupervisor = watch('supervisor', null);

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'plant_id') {
        value = _.find(plants, { _id: value });
        setValue('plant', value);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      handlePlantChange(null, 'clear');
      handleUserChange(null, 'clear');
      reset(defaultValues);
      setFilters({});
    }
  };

  const onSubmit = formData => {
    if (formData?.plant) {
      formData.plant_id = formData?.plant?._id;
      delete formData['plant'];
    }
    if (formData?.supervisor) {
      formData.supervisor = formData?.supervisor?._id;
    }

    for (const key in formData) {
      if (formData[key] === '') {
        delete formData[key];
      }
    }

    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant', null);
      return;
    }
    setValue('plant', selected);
  };

  const handleUserChange = (selected, action) => {
    if (action === 'clear') {
      setValue('supervisor', null);
      return;
    }
    setValue('supervisor', selected);
    return;
  };

  const customFilter = (option, searchText) => {
    if (option.data.username.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}

        <Box flex="1">
          <InputField
            type="text"
            name="society_code"
            id="society_code"
            label="Code"
            placeholder="Code"
            error={errors.society_code}
            register={register('society_code')}
          />
          <InputField
            type="text"
            name="society_name"
            id="society_name"
            label="Name"
            placeholder="Name"
            error={errors.society_name}
            register={register('society_name')}
          />
          <InputField
            type="text"
            name="city"
            id="city"
            label="City"
            placeholder="City"
            error={errors.city}
            register={register('city')}
          />

          <SelectField
            isMulti={false}
            isClearable={true}
            name="plant_id"
            label="Plant"
            labelKey="plant_code"
            valueKey="_id"
            options={plants}
            placeholder="Select Plant"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option =>
              `${option.plant_code} - ${option.plant_name}`
            }
            getOptionValue={option => option?.plant_code}
            handleChange={handlePlantChange}
            error={errors.plant_id}
            control={control}
            defaultValue={watchPlant}
          />
          <SelectField
            isMulti={false}
            isClearable={true}
            name="supervisor"
            label="Supervisor"
            labelKey="Supervisor"
            valueKey="_id"
            options={users}
            placeholder="Select Supervisor"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option => (
              <RenderUserOption user={option} avatarSize={40} />
            )}
            getOptionValue={option => option?._id}
            handleChange={handleUserChange}
            error={errors.supervisor}
            control={control}
            defaultValue={watchSupervisor}
            filterOption={customFilter}
          />
        </Box>
        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
