import React, { useState, useMemo, useCallback, useRef } from 'react';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import materialSalesService from 'services/materialSalesService';
import columns from './columns';
import Filters from './Filters';

// Table
import TableComp from 'components/tables/TableComp';

const allowAdd = ['superadmin', 'storeadmin', 'storeuser'];

const TableHeader = 'SALES';

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'tr_date',
      desc: false,
    },
  ];

  const tblColumns = useMemo(() => columns, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [filters, setFilters] = useState({});

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      filters.is_society_sales = false;
      filters.society_id = null;

      if (fetchId === fetchIdRef.current) {
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await materialSalesService.getAll(query);
        if (resData) {
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  const handleOnExport = data => {
    const newData = [];

    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const newD = { ...d };
      if (d.posted_by) {
        newD.posted_by = d.posted_by.username;
      }
      if (d.plant_id) {
        newD.plant_code = d.plant_id.plant_code;
        newD.plant_name = d.plant_id.plant_name;
      }
      if (d.cust_id) {
        newD.customer_code = d.cust_id.code;
        newD.customer_name = d.cust_id.name;
      }
      newData.push(newD);
    }
    return newData;
  };

  return (
    <TableComp
      columns={tblColumns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      resultCount={resultCount}
      defaultSortBy={defaultSortBy}
      //
      tblHeader={TableHeader}
      showColumnHide={true}
      isExpandable={true}
      // ExpandedComponent={ExpandRow}
      addLink="/store/sales/add"
      allowAdd={allowAdd}
      enableExport={true}
      exportFilename={`Sales`}
      onExport={handleOnExport}
      filters={filters}
      filterForm={
        <Filters
          onFilter={onFilterChange}
          defaultFilters={filters}
          setFilters={setFilters}
        />
      }
    />
  );
};

// Exports

const Sales = {
  routeProps: {
    path: '/store/sales',
    component: IndexPage,
    exact: true,
  },
  name: 'Sales',
  title: 'Sales',
};

export default Sales;
