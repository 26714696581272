import fields from './fields';

const defaultValues = {
  society_code: fields?.society_code?.default,
  sap_code: fields?.sap_code?.default,
  society_name: fields?.society_name?.default,
  city: fields?.city?.default,
  taluka: fields?.taluka?.default,
  district: fields?.district?.default,
  state: fields?.state?.default,
  country: fields?.country?.default,
  pan: fields?.pan?.default,
  pan_name: fields?.pan_name?.default,
  gst: fields?.gst?.default,
  gst_name: fields?.gst_name?.default,
  is_active: fields?.is_active?.default,
  society_type: fields?.society_type?.default,
  proposed_date: fields?.proposed_date?.default,
  registration_date: fields?.registration_date?.default,
  registration_no: fields?.registration_no?.default,
  bank_ifsc: fields?.bank_ifsc?.default,
  bank_name: fields?.bank_name?.default,
  bank_branch: fields?.bank_branch?.default,
  bank_city: fields?.bank_city?.default,
  bank_account_number: fields?.bank_account_number?.default,

  chairman_fname: fields?.chairman_fname?.default,
  chairman_mname: fields?.chairman_mname?.default,
  chairman_lname: fields?.chairman_lname?.default,
  chairman_address1: fields?.chairman_address1?.default,
  chairman_address2: fields?.chairman_address2?.default,
  chairman_address3: fields?.chairman_address3?.default,
  chairman_city: fields?.chairman_city?.default,
  chairman_pin: fields?.chairman_pin?.default,
  chairman_mobile: fields?.chairman_mobile?.default,
  chairman_email: fields?.chairman_email?.default,
  chairman_pan: fields?.chairman_pan?.default,
  chairman_pan_name: fields?.chairman_pan_name?.default,
  chairman_adhar: fields?.chairman_adhar?.default,

  secretary_fname: fields?.secretary_fname?.default,
  secretary_mname: fields?.secretary_mname?.default,
  secretary_lname: fields?.secretary_lname?.default,
  secretary_address1: fields?.secretary_address1?.default,
  secretary_address2: fields?.secretary_address2?.default,
  secretary_address3: fields?.secretary_address3?.default,
  secretary_city: fields?.secretary_city?.default,
  secretary_pin: fields?.secretary_pin?.default,
  secretary_mobile: fields?.secretary_mobile?.default,
  secretary_email: fields?.secretary_email?.default,
  secretary_pan: fields?.secretary_pan?.default,
  secretary_pan_name: fields?.secretary_pan_name?.default,
  secretary_adhar: fields?.secretary_adhar?.default,

  gu_society_name: fields?.gu_society_name?.default,
  gu_city: fields?.gu_city?.default,
  gu_taluka: fields?.gu_taluka?.default,
  gu_district: fields?.gu_district?.default,
  gu_state: fields?.gu_state?.default,
  gu_country: fields?.gu_country?.default,
  gu_society_type: fields?.gu_society_type?.default,

  gu_chairman_fname: fields?.gu_chairman_fname?.default,
  gu_chairman_mname: fields?.gu_chairman_mname?.default,
  gu_chairman_lname: fields?.gu_chairman_lname?.default,
  gu_chairman_address1: fields?.gu_chairman_address1?.default,
  gu_chairman_address2: fields?.gu_chairman_address2?.default,
  gu_chairman_address3: fields?.gu_chairman_address3?.default,
  gu_chairman_city: fields?.gu_chairman_city?.default,

  gu_secretary_fname: fields?.gu_secretary_fname?.default,
  gu_secretary_mname: fields?.gu_secretary_mname?.default,
  gu_secretary_lname: fields?.gu_secretary_lname?.default,
  gu_secretary_address1: fields?.gu_secretary_address1?.default,
  gu_secretary_address2: fields?.gu_secretary_address2?.default,
  gu_secretary_address3: fields?.gu_secretary_address3?.default,
  gu_secretary_city: fields?.gu_secretary_city?.default,

  plant: fields?.plant?.default,
  supervisor: fields?.supervisor?.default,
  supervisor_mobile: fields?.supervisor_mobile?.default,
  supervisor_email: fields?.supervisor_email?.default,
  send_message: fields?.send_message?.default,
  send_message_supervisor: fields?.send_message_supervisor?.default,
};

export default defaultValues;
