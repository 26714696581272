import * as yup from 'yup';
import messages from './messages';

const returnSchema = yup.object().shape({
  tr_date: yup
    .string(messages.tr_date.invalid)
    .trim()
    .required(messages.tr_date.required)
    .nullable(true)
    .label('Date'),
});

export default returnSchema;
