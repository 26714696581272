import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';
import { checkDatePeriod } from 'helpers/genHelpers';

import DateField from 'components/form/DateField';
import InputField from 'components/form/InputField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    material_id: '',
    materialorder_id: '',
    plant_code: '',
    who: '',
    createdAt_from: null,
    createdAt_to: null,
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
  } = useForm(formOptions);

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'createdAt' && value) {
        const from = moment(value.from).format(moment.defaultDateDb);
        const to = moment(value.to).format(moment.defaultDateDb);
        setValue('createdAt_from', from);
        setValue('createdAt_to', to);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [defaultFilters]);

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      reset(defaultValues);
    }
    setFilters({});
  };

  const onSubmit = formData => {
    for (const key in formData) {
      if (formData[key] === '') {
        delete formData[key];
      }
    }

    if (formData.createdAt_from && formData.createdAt_to) {
      const valPeriod = checkDatePeriod(
        formData.createdAt_from,
        formData.createdAt_to,
        31
      );

      delete formData['createdAt_from'];
      delete formData['createdAt_to'];

      if (!valPeriod) {
        setError('createdAt', {
          type: 'manual',
          message: `Invalid Date range or Date period exceeded`,
        });
        return;
      }

      formData.createdAt = valPeriod;
    }

    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}
        <InputField
          type="text"
          name="plant_code"
          id="plant_code"
          label="Plant Code"
          placeholder="Plant Code"
          error={errors.plant_code}
          register={register('plant_code')}
        />
        <InputField
          type="text"
          name="who"
          id="who"
          label="Posted By"
          placeholder="Posted By"
          error={errors.who}
          register={register('who')}
        />

        <Box border="1px" borderColor="gray.200" p={2} mb={4}>
          <DateField
            name="createdAt_from"
            label="Date From"
            error={errors.createdAt}
            register={register('createdAt_from')}
          />
          <DateField
            name="createdAt_to"
            label="Date To"
            error={errors.createdAt}
            register={register('createdAt_to')}
          />
        </Box>

        <InputField
          type="text"
          name="material_id"
          id="material_id"
          label="Material ID"
          placeholder="Material ID"
          error={errors.material_id}
          register={register('material_id')}
        />
        <InputField
          type="text"
          name="materialorder_id"
          id="materialorder_id"
          label="Order ID"
          placeholder="Order ID"
          error={errors.materialorder_id}
          register={register('materialorder_id')}
        />

        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
