import React from 'react';

export default function PrintButton() {
  const printDoc = () => {
    return window.print();
  };

  return (
    <div className="float_print_container">
      <button className="print-button" type="button" onClick={printDoc}>
        <span className="print-icon"></span>
      </button>
    </div>
  );
}
