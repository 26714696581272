import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Button,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
} from '@chakra-ui/react';

import { Link, useParams } from 'react-router-dom';

import EditButtons from './EditButtons';
import societyService from 'services/societyService';

const ShowPlantDetailsPage = () => {
  const FormTitle = 'PLANT DETAILS';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const [data, setData] = useState(null);
  const [details, setDetails] = useState(null);

  const { _id } = useParams();

  useEffect(() => {
    const fetchSociety = async () => {
      const responseData = await societyService.getById(_id);
      if (responseData && responseData.data) {
        setData(responseData.data);
      }
    };
    fetchSociety();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data && data.plant_id)) {
      setDetails(data.plant_id);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      <Box h="20px" mb={[4, 4]}>
        <EditButtons _id={data?._id} />
      </Box>

      <Box>
        {!details ? (
          <Heading as="h4" size="md">
            Society is not associated with any plant
          </Heading>
        ) : (
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Th>Plant Code</Th>
                <Td>{details?.plant_code}</Td>
              </Tr>
              <Tr>
                <Th>Plant Name</Th>
                <Td>{details?.plant_name}</Td>
              </Tr>
              <Tr>
                <Th>પ્લાન્ટ નું નામ</Th>
                <Td>{details?.gu_plant_name}</Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Box>
    </VStack>
  );
};

// Exports

const ShowPlantDetails = {
  routeProps: {
    path: '/societies/details/plant/:_id',
    component: ShowPlantDetailsPage,
    exact: true,
  },
  name: 'Plant Details',
  title: 'Plant Details',
};

export default ShowPlantDetails;
