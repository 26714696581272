const messages = {
  firstname: {
    required: 'Firstname is required',
    min: 'Firstname is too short',
    max: 'Firstname length exceeded',
  },
  middlename: {
    required: 'Middlename is required',
    min: 'Middlename is too short',
    max: 'Middlename length exceeded',
  },
  lastname: {
    required: 'Last is required',
    min: 'Last is too short',
    max: 'Last length exceeded',
  },
  mobile: {
    required: 'Mobile is required',
    invalid: 'Invalid Mobile number',
  },
  email: {
    required: 'E-Mail is required',
    invalid: 'Invalid E-Mail',
  },
  profile_picture: {
    required: 'Profile Image is required',
    invalid: 'Invalid Profile Image',
  },
};

export default messages;
