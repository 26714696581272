import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { Center, Icon } from '@chakra-ui/react';

const renderStatus = cell => {
  let status;

  if (cell.value) {
    status = <Icon as={FaCheckCircle} w={4} h={4} color="green.500" />;
  } else {
    status = <Icon as={FaTimesCircle} w={4} h={4} color="red.500" />;
  }

  return <Center>{status}</Center>;
};

export default renderStatus;
