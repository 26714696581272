import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Flex, Text, Button, Badge } from '@chakra-ui/react';
import { FaSync } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import moment from 'helpers/moment';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import milktransactionEditService from 'services/milktransactionEditService';
import columns from './columns';
import columnsAll from './columnsAll';

import TableSelectComp from 'components/tables/TableSelectComp';
import Filters from './Filters';

const allowApproveRoles = ['superadmin', 'admin', 'md'];

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'proc_date',
      desc: true,
    },
  ];

  const history = useHistory();

  const user = useSelector(state => state.auth.user);

  const allowApprove = _.intersection(allowApproveRoles, user.role).length > 0;

  const tblColumns = useMemo(() => {
    if (allowApprove) {
      return columnsAll;
    }
    return columns;
  }, [allowApprove]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [filters, setFilters] = useState({});
  const [nonSelectable, setNonSelectable] = useState([]);
  const [ids, setIds] = useState([]);
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await milktransactionEditService.getAll(query);
        if (resData) {
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const refreshData = async () => {
    await fetchData({
      pageSize: PAGINATION_OPTIONS.limit,
      pageIndex: 0,
      sortBy: defaultSortBy,
    });
  };

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  useEffect(() => {
    const nonSel = [];
    setIds([]);
    if (data.length > 0) {
      data.forEach(d => {
        if (d.approval_status !== 'P') {
          nonSel.push(d._id);
        }
      });
      setNonSelectable(nonSel);
    }
    // eslint-disable-next-line
  }, [data]);

  const bulkApproveReject = () => {
    if (ids.length > 0) {
      history.push({
        pathname: '/milktransactionedits/bulkapprove',
        state: ids,
      });
    } else {
      toast.dark(`No records selected for approve or reject`);
    }
  };

  const TableHeader = (
    <Flex direction={['column', 'row']} justifyContent="space-between">
      <Text mr={[4, 4]}>MILK TRANSACTION EDITS</Text>
      {allowApprove && (
        <Button
          type="button"
          colorScheme="orange"
          variant="outline"
          size="sm"
          mt={[2, 0]}
          mr={[0, 2]}
          onClick={bulkApproveReject}
          disabled={ids.length === 0}
        >
          BULK APPROVE/REJECT
          <Badge ml="2" px="2" fontSize="1.2em" colorScheme="blue">
            {ids.length}
          </Badge>
        </Button>
      )}
      <Button
        type="button"
        variant="outline"
        colorScheme="orange"
        size="sm"
        onClick={refreshData}
      >
        <FaSync />
      </Button>
    </Flex>
  );

  const handleOnExport = data => {
    const newData = [];

    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const newD = { ...d };
      if (d.proc_date) {
        newD.proc_date = moment(d.proc_date).format(moment.defaultDateFormat);
      }
      if (d.created_by) {
        newD.created_by = d.created_by.username;
      }
      if (d.updated_by) {
        newD.updated_by = d.updated_by.username;
      }
      if (d.approved_by) {
        newD.approved_by = d.approved_by.username;
      }
      newData.push(newD);
    }
    return newData;
  };

  const onSelect = (row, isSelect) => {
    if (!allowApprove) {
      // toast.warn(`You are not authorized to perform this action`);
      return;
    }
    if (row.original.approval_status === 'P') {
      if (isSelect) {
        addId(row?.original?._id);
      } else {
        removeId(row?.original?._id);
      }
    }
  };

  const OnSelectAll = selectedRows => {
    if (!allowApprove) {
      // toast.warn(`You are not authorized to perform this action`);
      return;
    }
    if (selectedRows.length > 0) {
      const selectedIds = selectedRows.map(obj => {
        if (obj.original.approval_status === 'P') {
          return obj.original._id;
        }
        return undefined;
      });
      setIds(selectedIds);
    } else {
      setIds([]);
    }
  };

  const addId = id => {
    setIds(oldState => {
      const newState = [...oldState, id];
      return newState;
    });
  };

  const removeId = id => {
    setIds(oldState => {
      const newState = [...oldState];
      const filteredAry = newState.filter(i => {
        return i !== id;
      });
      return filteredAry;
    });
  };

  const getSelectDisabledState = row => {
    if (!allowApprove) {
      return true;
    }
    return row.approval_status !== 'P';
  };

  return (
    <Box display={{ md: 'flex' }} w="full" h="full" mb="4">
      <TableSelectComp
        columns={tblColumns}
        data={data}
        getRowId={row => row._id}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        defaultSortBy={defaultSortBy}
        //
        tblHeader={TableHeader}
        showColumnHide={true}
        isExpandable={true}
        enableExport={true}
        exportFilename={`MilkTransactionEdits`}
        onExport={handleOnExport}
        filters={filters}
        filterForm={
          <Filters
            onFilter={onFilterChange}
            defaultFilters={filters}
            setFilters={setFilters}
          />
        }
        enableSelect={allowApprove}
        selected={ids}
        handleOnSelect={onSelect}
        handleOnSelectAll={OnSelectAll}
        nonSelectable={nonSelectable}
        getSelectDisabledState={getSelectDisabledState}
        showSelectAll={false}
      />
    </Box>
  );
};

// Exports

const MilkTransactionedits = {
  routeProps: {
    path: '/milktransactionedits',
    component: IndexPage,
    exact: true,
  },
  name: 'Milk Transaction Edits',
  title: 'Milk Transaction Edits',
};

export default MilkTransactionedits;
