import * as yup from 'yup';

const filterSchema = yup.object().shape({
  createdAt: yup.object().nullable(true).label('Created At'),
  username: yup.string().trim().max(10).nullable(true).label('Username'),
  type: yup.string().trim().max(10).nullable(true).label('Type'),
  message: yup.string().trim().max(20).nullable(true).label('Message'),
});

export default filterSchema;
