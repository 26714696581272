import React from 'react';
import { useHistory } from 'react-router';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import { Button, Center } from '@chakra-ui/react';

export default function MilktransactioneditApproveColumn({ row }) {
  const history = useHistory();

  const goToApproveReject = () => {
    history.push({
      pathname: `/milktransactionedits/approve/${row._id}`,
      state: row,
    });
  };

  return (
    <Center>
      <Button
        type="button"
        size="xs"
        colorScheme="blue"
        variant="outline"
        onClick={goToApproveReject}
        leftIcon={<FaRegThumbsUp color="green" />}
        disabled={row?.approval_status !== 'P'}
      >
        <FaRegThumbsDown color="red" />
      </Button>
    </Center>
  );
}
