const messages = {
  fiscal_year: {
    required: 'Fiscal Year required',
    invalid: 'Invalid Fiscal Year',
  },
  proc_date: {
    required: 'Procurement Date is required',
    invalid: 'Invalid Procurement Date',
  },
  shift: {
    required: 'Shift is required',
    invalid: 'Inavldi Shift value',
  },
  plant_code: {
    required: 'Please select a plant',
    invalid: 'Invalid Plant Value',
  },
  society_code: {
    required: 'Society Code is required',
    invalid: 'Invalid Society Code',
  },
};

export default messages;
