import { Badge } from '@chakra-ui/react';

const renderLocation = cell => {
  if (
    !cell ||
    !cell.value ||
    !cell.value?.coordinates ||
    cell.value?.coordinates.length === 0
  )
    return <div>-</div>;
  return (
    <>
      <Badge variant="outline" colorScheme="green" mr="2">
        Latitude : {cell.value?.coordinates[0]}
      </Badge>
      <Badge variant="outline" colorScheme="blue">
        Longitude : {cell.value?.coordinates[1]}
      </Badge>
    </>
  );
};

export default renderLocation;
