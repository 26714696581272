import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  // code: yup
  //   .string()
  //   .required(messages.code.required)
  //   .trim()
  //   .max(20)
  //   .label('Code'),
  name: yup
    .string()
    .required(messages.name.required)
    .trim()
    .max(200)
    .label('Name'),
  address: yup.string().trim().max(500).label('Address'),
  city: yup
    .string()
    .required(messages.city.required)
    .trim()
    .max(20)
    .label('City'),
  state: yup.string().trim().max(20).nullable(true).label('City'),
  pan: yup.string().trim().max(20).nullable(true).label('PAN'),
  pan_name: yup.string().trim().max(20).nullable(true).label('Name as in PAN'),
  gst: yup.string().trim().max(20).nullable(true).label('GST'),
  gst_name: yup.string().trim().max(20).nullable(true).label('Name as in GST'),
  mobile: yup
    .string()
    .label('Mobile')
    .min(0, messages.mobile.invalid)
    .max(10, messages.mobile.invalid)
    .nullable(true),
  email: yup
    .string()
    .email(messages.email.invalid)
    .label('E-Mail')
    .nullable(true),
  is_active: yup.boolean().nullable(true).label('Status'),
});

export default addSchema;
