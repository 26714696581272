import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  proc_date: yup
    .string(messages.proc_date.invalid)
    .trim()
    .required(messages.proc_date.required)
    .nullable(),
  shift: yup.object().label('Shift'),
  plant_code: yup.object().label('Plant'),
});

export default addSchema;
