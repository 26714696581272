import React, { useState, useMemo, useCallback, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import materialService from 'services/materialService';
import columns from './columns';
import Filters from './Filters';

// Table
import TableComp from 'components/tables/TableComp';

const TableHeader = 'MATERIALS STOCK';

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'name',
      desc: false,
    },
  ];

  const plants = useSelector(state => state.plants);

  const tblColumns = useMemo(() => {
    const columnTemplate = {
      accessor: 'stock',
      type: 'object',
      hidden: false,
      headerAlign: 'right',
      exportable: true,
      disableSortBy: true,
    };

    const cols = [];
    for (let i = 0; i < plants.length; i++) {
      const plant = plants[i];
      const plant_code = plant.plant_code;
      const cl = { ...columnTemplate };
      cl.id = plant_code;
      cl.Header = plant_code;
      cl.Cell = ({ cell }) => {
        return (
          <div style={{ textAlign: 'right' }}>
            {cell.value ? cell.value[plant_code] : '-'}
          </div>
        );
      };
      cl.expandfunction = value => {
        return <div>{value ? value[plant_code] : '-'}</div>;
      };
      const exist = _.find(cols, { id: plant_code });
      if (!exist) {
        cols.push(cl);
      }
    }
    return [...columns, ...cols];
  }, [plants]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [filters, setFilters] = useState({});

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await materialService.getAll(query);
        if (resData) {
          // console.log(resData?.data);
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  const handleOnExport = data => {
    const newData = [];

    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const newD = { ...d };
      if (d.stock) {
        for (const prop in d.stock) {
          newD[prop] = d.stock[prop];
        }
      }
      newData.push(newD);
    }
    return newData;
  };

  return (
    <TableComp
      columns={tblColumns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      resultCount={resultCount}
      defaultSortBy={defaultSortBy}
      //
      tblHeader={TableHeader}
      showColumnHide={true}
      isExpandable={true}
      // ExpandedComponent={ExpandRow}
      // addLink="/materials/add"
      // allowAdd={allowAdd}
      enableExport={true}
      exportFilename={`Stock`}
      onExport={handleOnExport}
      filters={filters}
      filterForm={
        <Filters
          onFilter={onFilterChange}
          defaultFilters={filters}
          setFilters={setFilters}
        />
      }
    />
  );
};

// Exports

const Stock = {
  routeProps: {
    path: '/stock',
    component: IndexPage,
    exact: true,
  },
  name: 'Stock',
  title: 'Stock',
};

export default Stock;
