import * as yup from 'yup';
import messages from './messages';

const editIncometaxSchema = yup.object().shape({
  pan: yup.string().trim().max(100, messages.pan.invalid),
  pan_name: yup.string().trim().max(100, messages.pan_name.invalid),
  gst: yup.string().trim().max(100, messages.gst.invalid),
  gst_name: yup.string().trim().max(100, messages.gst_name.invalid),
});

export default editIncometaxSchema;
