const authConstants = {
  SET_TOKEN: "SET_TOKEN",
  SET_TOKEN_EXP: "SET_TOKEN_EXP",
  UNSET_TOKEN: "UNSET_TOKEN",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  UNSET_REFRESH_TOKEN: "UNSET_REFRESH_TOKEN",
  SET_REFRESH_TOKEN_EXP: "SET_TOKEN_EXP",
  SET_USER: "SET_USER",
  UNSET_USER: "UNSET_USER",
  SET_AUTH_STATUS: "SET_AUTH_STATUS",
  UNSET_AUTH_STATUS: "UNSET_AUTH_STATUS",

  LOGOUT: "USERS_LOGOUT",
};

export default authConstants;
