import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import markEditSchema from './markEditSchema';
import fields from './fields';
import milktransactionEditService from 'services/milktransactionEditService';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';

const MarkEditPage = ({ match }) => {
  const { _id, fiscal_year } = match.params;

  const FormTitle = 'EDIT MILK TRANSACTION';
  const IndexPageTitle = 'MILK TRANSACTION EDITS';
  const IndexPagePath = '/milktransactionedits';

  const DATE_FIELDS = ['proc_date'];

  const defaultValues = {
    proc_date: fields?.proc_date?.default,
    shift: fields?.shift?.default,
    plant_code: fields?.plant_code?.default,
    plant_name: fields?.plant_name?.default,
    society_code: fields?.society_code?.default,
    society_name: fields?.society_name?.default,
    milk_catagory: fields?.milk_catagory?.default,
    milk_type: fields?.milk_type?.default,
    cans: fields?.cans?.default,
    weight_kg: fields?.weight_kg?.default,
    fat: fields?.fat?.default,
    snf: fields?.snf?.default,
    amount: fields?.amount?.default,
    note: fields?.note?.default,
  };

  const location = useLocation();
  const apiError = useSelector(state => state.error);
  const [data, setData] = useState(null);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  useEffect(() => {
    setData(location?.state);
  }, [location]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(markEditSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
  } = useForm(formOptions);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        if (key in defaultValues) {
          if (DATE_FIELDS.includes(key)) {
            value = moment(value).format(moment.defaultDateFormat);
            setValue(key, value);
          } else {
            setValue(key, value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = async formData => {
    const dirtyFields = ['cans', 'fat', 'snf', 'weight_kg', 'amount', 'note'];
    const formDataSend = {
      fiscal_year: fiscal_year,
    };

    for (let [key, value] of Object.entries(formData)) {
      if (dirtyFields.includes(key) && value !== data[key]) {
        formDataSend[key] = value;
      }
    }
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      const responseData = await milktransactionEditService.markUpdate(
        _id,
        formDataSend
      );

      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Flex direction={['column', 'row']} justifyContent="space-between">
            <Text fontSize="xl" color="orange.500" fontWeight="bold">
              {FormTitle}
            </Text>
          </Flex>
          <Flex direction={['column', 'row']} justifyContent="space-between">
            <Button
              as={Link}
              to={IndexPagePath}
              type="button"
              colorScheme="orange"
              size="sm"
              px="8"
              mt={[2, 0]}
              mr={[0, 2]}
            >
              {IndexPageTitle}
            </Button>
            <Button
              as={Link}
              to={`/milktransactions`}
              type="button"
              colorScheme="orange"
              size="sm"
              px="8"
              mt={[2, 0]}
            >
              MILK TRANSACTIONS
            </Button>
          </Flex>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[12, 0]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="society_code"
                    id="society_code"
                    label="Society Code"
                    placeholder="Society Code"
                    error={errors.society_code}
                    disabled={true}
                    register={register('society_code')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="society_name"
                    id="society_name"
                    label="Society Name"
                    placeholder="Society Name"
                    error={errors.society_name}
                    disabled={true}
                    register={register('society_name')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_code"
                    id="plant_code"
                    label="Plant Code"
                    placeholder="Plant Code"
                    error={errors.plant_code}
                    disabled={true}
                    register={register('plant_code')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="plant_name"
                    id="plant_name"
                    label="Plant Name"
                    placeholder="Plant Name"
                    error={errors.plant_name}
                    disabled={true}
                    register={register('plant_name')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="proc_date"
                    id="proc_date"
                    label="Proc Date"
                    placeholder="Proc Date"
                    error={errors.proc_date}
                    disabled={true}
                    register={register('proc_date')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="shift"
                    id="shift"
                    label="Shift"
                    placeholder="Shift"
                    error={errors.shift}
                    disabled={true}
                    register={register('shift')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="milk_catagory"
                    id="milk_catagory"
                    label="Milk Catagory"
                    placeholder="Milk Catagory"
                    error={errors.milk_catagory}
                    disabled={true}
                    register={register('milk_catagory')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="milk_type"
                    id="milk_type"
                    label="Milk Type"
                    placeholder="Milk Type"
                    error={errors.milk_type}
                    disabled={true}
                    register={register('milk_type')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    name="cans"
                    id="cans"
                    label="Cans"
                    placeholder="Cans"
                    error={errors.cans}
                    register={register('cans')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.1"
                    name="weight_kg"
                    id="weight_kg"
                    label="Weight (KG)"
                    placeholder="Weight (KG)"
                    error={errors.weight_kg}
                    register={register('weight_kg')}
                  />
                </Box>
                <Box w={['full', 1 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.01"
                    name="fat"
                    id="fat"
                    label="FAT"
                    placeholder="FAT"
                    error={errors.fat}
                    register={register('fat')}
                  />
                </Box>
                <Box w={['full', 1 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.01"
                    name="snf"
                    id="snf"
                    label="SNF"
                    placeholder="SNF"
                    error={errors.snf}
                    register={register('snf')}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    step="0.01"
                    name="amount"
                    id="amount"
                    label="Amount"
                    placeholder="Amount"
                    error={errors.amount}
                    register={register('amount')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="note"
                    id="note"
                    label="Note"
                    placeholder="Write Your Remark For The Edit Entry"
                    error={errors.note}
                    register={register('note')}
                  />
                </Box>
              </Flex>

              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MilkTransactionMarkEdit = {
  routeProps: {
    path: '/milktransactions/edit/:_id/:fiscal_year',
    component: MarkEditPage,
    exact: true,
  },
  name: 'Milk Transaction Edit',
  title: 'Milk Transaction Edit',
};

export default MilkTransactionMarkEdit;
