import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import materialOrederService from 'services/materialOrederService';

import PlaceholderA4 from 'components/printables/common/PlaceholderA4';
import PurchaseDoc from 'components/printables/PurchaseDoc';
import PurchaseRetDoc from 'components/printables/PurchaseRetDoc';
import ScrapDoc from 'components/printables/ScrapDoc';
import ScrapRetDoc from 'components/printables/ScrapRetDoc';
import ConsumptionDoc from 'components/printables/ConsumptionDoc';
import ConsumptionRetDoc from 'components/printables/ConsumptionRetDoc';
import StockTransferDoc from 'components/printables/StockTransferDoc';
import SalesDoc from 'components/printables/SalesDoc';
import SalesRetDoc from 'components/printables/SalesRetDoc';

const getDocuemntComponent = (type, data) => {
  switch (type) {
    case 'PURCHASE':
      return <PurchaseDoc data={data} />;
    case 'PURCHASE_RET':
      return <PurchaseRetDoc data={data} />;
    case 'TRF_IN':
      return <StockTransferDoc data={data} />;
    case 'TRF_OUT':
      return <StockTransferDoc data={data} />;
    case 'CONSUMPTION':
      return <ConsumptionDoc data={data} />;
    case 'CONSUMPTION_RET':
      return <ConsumptionRetDoc data={data} />;
    case 'SCRAP':
      return <ScrapDoc data={data} />;
    case 'SCRAP_RET':
      return <ScrapRetDoc data={data} />;
    case 'SALES':
      return <SalesDoc data={data} />;
    case 'SALES_RET':
      return <SalesRetDoc data={data} />;
    default:
      return null;
  }
};

const IndexPage = ({ match }) => {
  let { _id, type } = match.params;
  type = type.toUpperCase();
  const [data, setData] = useState(null);
  const loggedIn = useSelector(state => state.auth?.loggedIn);

  useEffect(() => {
    const fetchDocument = async () => {
      const responseData = await materialOrederService.getById(_id);
      if (responseData) {
        setData(responseData?.data);
      }
    };

    fetchDocument();
  }, [_id, type]);

  if (!loggedIn) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return (
    <>
      {/* <pre>
        <code>{JSON.stringify(data, null, 4)} </code>
      </pre> */}
      {data ? getDocuemntComponent(type, data) : <PlaceholderA4 />}
    </>
  );
};

// Exports

const Documents = {
  routeProps: {
    path: '/documents/:type/:_id',
    component: IndexPage,
    exact: true,
  },
  name: 'Document',
  title: 'Document',
};

export default Documents;
