import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/messages?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async _id => {
  try {
    const response = await API.get(`/messages/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const checkDeliveryById = async _id => {
  try {
    const response = await API.post(`/messages/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const messageService = {
  getAll,
  getById,
  checkDeliveryById,
};

export default messageService;
