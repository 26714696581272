// Errors
import _404 from 'pages/_404';
import _403 from 'pages/_403';
import _500 from 'pages/_500';

// Auth
import Login from 'pages/Login';
import ForcePasswordChange from 'pages/ForcePasswordChange';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import VerifyMobile from 'pages/VerifyMobile';
import ResendMobileOtp from 'pages/ResendMobileOtp';
// Others
import Documents from 'pages/Documents';

const Pages = [
  _404,
  _403,
  _500,
  Login,
  ForcePasswordChange,
  ForgotPassword,
  ResetPassword,
  VerifyMobile,
  ResendMobileOtp,

  // Others
  Documents,
];

// Exports
export default Pages;
