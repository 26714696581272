import * as yup from 'yup';
import messages from './messages';

const filterSchema = yup.object().shape({
  society_code: yup.string().trim(),
  society_name: yup.string().trim().max(100, messages.society_name.max),
  city: yup.string().trim(),
});

export default filterSchema;
