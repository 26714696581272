import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import '@fontsource/inter';

// import { ButtonStyles as Button } from 'styles/components/buttonStyles';

// Text - #1a202c

const themeOptions = {
  colors: {
    brand: {
      default: '#DD6B20',
      light: '#edad83',
      lightest: '#f5d3bc',
      dark: '#ad5419',
    },
  },
  fonts: {
    body: 'Inter',
  },
  fontSizes: {
    xs: '0.55rem',
    sm: '0.675rem',
    md: '0.8rem',
    lg: '1rem',
    xl: '1.15rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.15,
    short: 1.275,
    base: 1.3,
    tall: 1.5,
    taller: 1.625,
    3: '.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  space: {
    px: '1px',
    0.5: '0.115rem',
    1: '0.20rem',
    1.5: '0.365rem',
    2: '0.4rem',
    2.5: '0.525rem',
    3: '0.65rem',
    3.5: '0.775rem',
    4: '0.8rem',
    5: '1.0rem',
    6: '1.2rem',
    7: '1.5rem',
    8: '1.8rem',
    9: '2.0rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    60: '15rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
  },
  styles: {
    global: props => ({
      body: {
        fontSize: 'md',
      },
      '*::placeholder': {
        color: mode('brand.dark', 'brand.lightest')(props),
      },
      a: {
        color: '#DD6B20',
        // _hover: {
        //   textDecoration: 'underline',
        // },
      },
    }),
  },
  components: {
    // Button,
  },
};

export const myTheme = extendTheme(
  {
    ...themeOptions,
  },
  withDefaultColorScheme({ colorScheme: 'orange' })
);
