import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  type: yup.object().required(messages.type.required).label('Adultration Type'),
  proc_date: yup
    .string(messages.proc_date.invalid)
    .trim()
    .required(messages.proc_date.required)
    .nullable(true)
    .label('Proc Date'),
  shift: yup
    .object()
    .required(messages.shift.required)
    .nullable(true)
    .label('Shift'),
  society_id: yup
    .object()
    .label('Society')
    .required(messages.society_id.required)
    .nullable(true)
    .label('Society'),
  cans: yup
    .number(messages.cans.invalid)
    .min(1, messages.cans.min)
    .max(30, messages.cans.max)
    .required(messages.cans.required)
    .positive(messages.cans.invalid)
    .integer(messages.cans.invalid)
    .typeError(messages.cans.invalid)
    .label('Cans'),
  weight_kg: yup
    .number(messages.weight_kg.invalid)
    .min(1, messages.weight_kg.min)
    .max(1000, messages.weight_kg.max)
    .required(messages.weight_kg.required)
    .positive(messages.weight_kg.invalid)
    .typeError(messages.weight_kg.invalid)
    .label('Weight'),
  amount: yup
    .number(messages.amount.invalid)
    .min(1, messages.amount.min)
    .max(500000, messages.amount.max)
    .required(messages.amount.required)
    .positive(messages.amount.invalid)
    .typeError(messages.amount.invalid)
    .label('Amount'),
  sample_no: yup.string(messages.sample_no.invalid).trim().label('Sample No'),
  note: yup
    .string(messages.note.invalid)
    .required(messages.note.required)
    .trim()
    .label('Note'),
});

export default addSchema;
