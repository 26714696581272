const fields = {
  names: [
    'code',
    'name',
    'description',
    'catagory',
    'unit_in',
    'unit_out',
    'conversion',
    'consuption_price',
    'sales_price',
    'tax_type',
    'hsn',
    'sgst',
    'cgst',
    'igst',
    'tradable',
    'consumable',
    'is_active',
    'is_discontinued',
  ],
  code: {
    default: '',
    disabled: false,
    readonly: false,
  },
  name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  description: {
    default: '',
    disabled: false,
    readonly: false,
  },
  catagory: {
    default: '',
    disabled: false,
    readonly: false,
  },
  unit_in: {
    default: '',
    disabled: false,
    readonly: false,
  },
  unit_out: {
    default: '',
    disabled: false,
    readonly: false,
  },
  conversion: {
    default: 1,
    disabled: false,
    readonly: false,
  },
  consuption_price: {
    default: '',
    disabled: false,
    readonly: false,
  },
  sales_price: {
    default: '',
    disabled: false,
    readonly: false,
  },
  tax_type: {
    default: '',
    disabled: false,
    readonly: false,
  },
  hsn: {
    default: '',
    disabled: false,
    readonly: false,
  },
  sgst: {
    default: '',
    disabled: false,
    readonly: false,
  },
  cgst: {
    default: '',
    disabled: false,
    readonly: false,
  },
  igst: {
    default: '',
    disabled: false,
    readonly: false,
  },
  tradable: {
    default: false,
    disabled: false,
    readonly: false,
  },
  consumable: {
    default: false,
    disabled: false,
    readonly: false,
  },
  is_active: {
    default: true,
    disabled: false,
    readonly: false,
  },
  is_discontinued: {
    default: false,
    disabled: false,
    readonly: false,
  },
};

export default fields;
