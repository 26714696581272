import * as yup from 'yup';

const filterSchema = yup.object().shape({
  material_id: yup.string().trim().nullable(true).label('Material'),
  materialorder_id: yup.string().trim().nullable(true).label('Order'),
  plant_code: yup.string().trim().max(4).nullable(true).label('Plant'),
  who: yup.string().trim().max(20).nullable(true).label('Posted By'),
  createdAt: yup.object().nullable(true).label('TR Date'),
});

export default filterSchema;
