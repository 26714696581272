import { LOG_LEVEL } from 'configs/logLevel';
import { Center, Badge } from '@chakra-ui/react';

const getBadgeColor = status => {
  if (status === LOG_LEVEL.info) {
    return 'blue';
  } else if (status === LOG_LEVEL.error) {
    return 'red';
  } else if (status === LOG_LEVEL.success) {
    return 'green';
  } else if (status === LOG_LEVEL.warning) {
    return 'yellow';
  } else {
    return 'purple';
  }
};

const renderLogLevel = cell => {
  if (!cell.value) return <Center>-</Center>;
  return (
    <Center>
      <Badge variant="solid" colorScheme={getBadgeColor(cell.value)}>
        {cell.value}
      </Badge>
    </Center>
  );
};

export default renderLogLevel;
