import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import renderDate from 'components/tables/helpers/renderDate';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderMilkEntryTrType from 'components/tables/helpers/renderMilkEntryTrType';
import renderIndiaFormat from 'components/tables/helpers/renderIndiaFormat';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'tr_type',
    accessor: 'tr_type',
    Header: 'TR Type',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderMilkEntryTrType(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'proc_date',
    accessor: 'proc_date',
    Header: 'Proc Date',
    type: 'date',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDate(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.date.maxWidth,
    minWidth: tblConsts.date.minWidth,
  },
  {
    id: 'shift',
    accessor: 'shift',
    Header: 'Shift',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'plant_code',
    accessor: 'plant_code',
    Header: 'Plant Code',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'plant_name',
    accessor: 'plant_name',
    Header: 'Name',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },
  {
    id: 'society_code',
    accessor: 'society_code',
    Header: 'Soc Code',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'society_name',
    accessor: 'society_name',
    Header: 'Name',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: cell => cell.value,
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.societyname.maxWidth,
    minWidth: tblConsts.societyname.minWidth,
  },
  {
    id: 'society_type',
    accessor: 'society_type',
    Header: 'Soc Type',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'milk_catagory',
    accessor: 'milk_catagory',
    Header: 'Milk Cat',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'milk_type',
    accessor: 'milk_type',
    Header: 'Milk Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'old_cans',
    accessor: 'old_cans',
    Header: 'Cans (O)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: cell => (
      <div style={{ textAlign: 'right' }}>{cell.value ? cell.value : '-'}</div>
    ),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'new_cans',
    accessor: 'new_cans',
    Header: 'Cans (N)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: cell => (
      <div style={{ textAlign: 'right' }}>{cell.value ? cell.value : '-'}</div>
    ),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'old_weight_kg',
    accessor: 'old_weight_kg',
    Header: 'Wight Kg (O)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: cell => (
      <div style={{ textAlign: 'right' }}>{cell.value ? cell.value : '-'}</div>
    ),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'new_weight_kg',
    accessor: 'new_weight_kg',
    Header: 'Wight Kg (N)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'old_fat',
    accessor: 'old_fat',
    Header: 'FAT (O)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'new_fat',
    accessor: 'new_fat',
    Header: 'FAT (N)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'old_snf',
    accessor: 'old_snf',
    Header: 'SNF (O)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'new_snf',
    accessor: 'new_snf',
    Header: 'SNF (N)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'old_amount',
    accessor: 'old_amount',
    Header: 'Amount (O)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'new_amount',
    accessor: 'new_amount',
    Header: 'Amount (N)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'fiscal_year',
    accessor: 'fiscal_year',
    Header: 'Fiscal Year',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'created_by',
    accessor: 'created_by',
    Header: 'Created By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'created_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'updated_by',
    accessor: 'updated_by',
    Header: 'Updated By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'updated_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'approved_by',
    accessor: 'approved_by',
    Header: 'Approved By',
    type: 'object',
    hidden: true,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: true,
    exportHeader: 'approved_by',
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.datetime.maxWidth,
    minWidth: tblConsts.datetime.minWidth,
  },
];

export default columns;
