import tblConsts from 'components/tables/tblConsts';
import renderUserStatus from 'components/tables/helpers/renderUserStatus';
import renderAvatar from 'components/tables/helpers/renderAvatar';

import ActionColumn from './ActionColumn';

const columns = [
  // {
  //   id: '_id',
  //   accessor: '_id',
  //   Header: 'ID',
  //   type: 'string',
  //   hidden: true,
  //   headerAlign: 'left',
  //   exportable: false,
  //   disableSortBy: true,
  // },
  {
    id: 'profile_image_url',
    accessor: 'profile_image_url',
    Header: 'Image',
    // type: 'string',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderAvatar(cell),
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'username',
    accessor: 'username',
    Header: 'Username',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: cell => <div style={{ textAlign: 'left' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'mobile',
    accessor: 'mobile',
    Header: 'Mobile',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    Cell: cell => <div style={{ textAlign: 'right' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'email',
    accessor: 'email',
    Header: 'E-Mail',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'employee_code',
    accessor: 'employee_code',
    Header: 'EMP Code',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'firstname',
    accessor: 'firstname',
    Header: 'Firstname',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'middlename',
    accessor: 'middlename',
    Header: 'Middlename',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'lastname',
    accessor: 'lastname',
    Header: 'Lastname',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'is_active',
    accessor: 'is_active',
    Header: 'Status',
    type: 'boolean',
    hidden: false,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderUserStatus(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'plant_code',
    accessor: 'plant_id.plant_code',
    Header: 'Plant Code',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'plant_name',
    accessor: 'plant_id.plant_name',
    Header: 'Plant Name',
    type: 'string',
    hidden: false,
    // headerAlign: 'left',
    Cell: cell => <div style={{ textAlign: 'left' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    hidden: false,
    headerAlign: 'cemter',
    Cell: ({ row }) => <ActionColumn row={row?.values} />,
    exportable: false,
    disableSortBy: true,
  },
];

export default columns;
