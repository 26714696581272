import API from 'helpers/API';
// import getQueryString from 'helpers/queryHelper';

const getSocietyQty = async (milk_type, type, limit, days) => {
  try {
    const response = await API.get(
      `/dashboardsociety/society/qty/${milk_type}/${type}/${limit}/days/${days}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getSocietyKgFat = async (milk_type, type, limit, days) => {
  try {
    const response = await API.get(
      `/dashboardsociety/society/kgfat/${milk_type}/${type}/${limit}/days/${days}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getSocietyKgSnf = async (milk_type, type, limit, days) => {
  try {
    const response = await API.get(
      `/dashboardsociety/society/kgsnf/${milk_type}/${type}/${limit}/days/${days}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getSocietyAvgFatSnf = async (milk_type, sort, type, limit, days) => {
  try {
    const response = await API.get(
      `/dashboardsociety/society/avarage/${milk_type}/${sort}/${type}/${limit}/days/${days}`
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getSocietyAnalysisData = async (formData = {}) => {
  try {
    const response = await API.post(
      `/dashboardsociety/society/analysis`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getTopAdultrations = async (formData = {}) => {
  try {
    const response = await API.get(
      `/dashboardsociety/society/top/adultrations`,
      formData
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const dashboardSocietyService = {
  getSocietyQty,
  getSocietyKgFat,
  getSocietyKgSnf,
  getSocietyAvgFatSnf,
  getSocietyAnalysisData,
  getTopAdultrations,
};

export default dashboardSocietyService;
