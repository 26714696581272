const messages = {
  city: {
    required: 'City name required',
    min: 'City name length too short',
    max: 'City name length exceeded',
    exist: 'City already exist',
  },
  taluka: {
    required: 'Taluka name required',
    min: 'Taluka name length too short',
    max: 'Taluka name length exceeded',
  },
  district: {
    required: 'District name required',
    min: 'District name length too short',
    max: 'District name length exceeded',
  },
  state: {
    required: 'State name required',
  },
  country: {
    required: 'Country name required',
  },
  gu_city: {
    required: 'City name required',
    max: 'City name length exceeded',
  },
  gu_taluka: {
    required: 'Taluka name required',
  },
  gu_district: {
    required: 'District name required',
  },
  gu_state: {
    required: 'State name required',
  },
  gu_country: {
    required: 'Country name required',
  },
};

export default messages;
