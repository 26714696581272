import * as yup from 'yup';
import validationRegex from 'configs/validationRegex';
import messages from './messages';

const schema = yup.object().shape({
  username: yup.string().required(messages.username.required),
  mobile: yup
    .string()
    .required(messages.mobile.required)
    .matches(validationRegex.mobile, messages.mobile.invalid),
});

export default schema;
