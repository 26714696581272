import React from 'react';
import TableActionColumn from 'components/tables/TableActionColumn';
import { useHistory } from 'react-router';
import { Button } from '@chakra-ui/react';
import { FaDownload } from 'react-icons/fa';
import milkuploadService from 'services/milkuploadService';
import { toast } from 'react-toastify';
import { MILKFILE_UPLOAD_STATUS } from 'configs/milkFileUploadStatus';

const hasDelete = ['superadmin'];
const hasCustom = ['superadmin', 'admin', 'md'];

export default function ActionColumn({ row }) {
  const history = useHistory();

  const deleteRecord = () => {
    if (row.is_finalized) {
      toast.error(`Finalized records can not be Edited/Deleted`);
    } else {
      history.push({
        pathname: `/milkfileuploads/delete/${row._id}`,
        state: row,
      });
    }
  };

  const customAction = async () => {
    const responseData = await milkuploadService.download(row._id);

    if (responseData) {
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', row.filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      toast.success(`File Downloaded Successfully`);
    }
  };

  const customButton = row.progress === MILKFILE_UPLOAD_STATUS.completed && (
    <Button type="button" size="xs" colorScheme="blue" onClick={customAction}>
      <FaDownload className="text-white" />
    </Button>
  );

  return (
    <TableActionColumn
      row={row}
      hasDelete={hasDelete}
      handleDelete={deleteRecord}
      hasCustom={hasCustom}
      customComponent={customButton}
    />
  );
}
