import * as yup from 'yup';
import messages from './messages';

const filterSchema = yup.object().shape({
  proc_date: yup.object().nullable(true),
  plant_code: yup.object().label('Plant').nullable(true),
  society_code: yup
    .number(messages.society_code.invalid)
    .min(1, messages.society_code.min)
    .max(2000, messages.society_code.max)
    .positive(messages.society_code.invalid)
    .integer(messages.society_code.invalid)
    .typeError(messages.society_code.invalid)
    .nullable(true)
    .label('Cans'),
});

export default filterSchema;
