import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Heading,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

import SwitchField from 'components/form/SwitchField';
import materialService from 'services/materialService';
import materialDiscontinueSchema from './materialDiscontinueSchema';

const defaultValues = {
  is_discontinued: false,
};

const MaterialDiscontinuePage = ({ match }) => {
  const FormTitle = 'MATERIAL DISCONTINUE';
  const IndexPageTitle = 'MATERIALS';
  const IndexPagePath = '/materials';

  const { _id } = match.params;
  const history = useHistory();
  const location = useLocation();
  const apiError = useSelector(state => state.error);
  const [data, setData] = useState(null);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(materialDiscontinueSchema),
    defaultValues: defaultValues,
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  useEffect(() => {
    setData(location?.state);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setValue('is_discontinued', data?.is_discontinued);
    // eslint-disable-next-line
  }, [data]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    watch,
  } = useForm(formOptions);

  const watchDiscontinued = watch('is_discontinued', false);

  const onSubmit = async formData => {
    const resData = await materialService.discontinue(_id, {
      is_discontinued: formData.is_discontinued,
    });

    if (resData) {
      history.push('/materials');
      toast.success(`${resData.message}`);
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box mt={[4, 2]}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <Heading size="md" color="red.500">
                    {`Are You Sure You Want To ${
                      watchDiscontinued ? 'Discontinue' : 'Continue'
                    } The Selected Material ?`}
                  </Heading>
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 4 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="is_discontinued"
                    id="is_discontinued"
                    label="Discontinued"
                    error={errors.is_discontinued}
                    register={register('is_discontinued')}
                    defaultChecked={watchDiscontinued}
                  />
                </Box>
              </Flex>

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme={watchDiscontinued ? 'red' : 'green'}
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={
                    !isValid || watchDiscontinued === data?.is_discontinued
                  }
                >
                  {watchDiscontinued ? 'DISCONTINUE' : 'CONTINUE'}
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  as={Link}
                  to={`/materials`}
                >
                  CANCEL
                </Button>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <Table size="sm">
                    <Tbody>
                      <Tr>
                        <Th>Material Name</Th>
                        <Td>{data?.name}</Td>
                      </Tr>
                      <Tr>
                        <Th>Catagory</Th>
                        <Td>{data?.catagory}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
              {/* Form Contents */}
            </VStack>
          </Stack>
        </form>
      </Box>
    </VStack>
  );
};

// Exports

const MaterialDiscontinue = {
  routeProps: {
    path: '/materials/discontinue/:_id',
    component: MaterialDiscontinuePage,
    exact: true,
  },
  name: 'Material Discontinue',
  title: 'Material Discontinue',
};

export default MaterialDiscontinue;
