import React, { useEffect } from 'react';
import { Link as RLink, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Flex,
  Box,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import profileService from 'services/profileService';

import InputField from 'components/form/InputField';
import schema from './schema';

function VerifyMobilePage() {
  let history = useHistory();

  const apiError = useSelector(state => state.error);
  const loggedIn = useSelector(state => state.auth?.loggedIn);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    reset,
  } = useForm(formOptions);

  const onSubmit = async formData => {
    const resData = await profileService.verifyMobile(formData);
    if (resData) {
      onReset();
      history.push('/login');
      toast.success(`${resData.message}`);
    }
  };

  const onReset = () => {
    reset();
    return;
  };

  useEffect(() => {
    if (loggedIn) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={['4', '6']} mx={'auto'} maxW={['xs', 'lg']}>
        <Stack align={'center'} w={['xs', 'lg']}>
          <Heading
            fontSize={['2xl', '4xl']}
            color={useColorModeValue('orange.500', 'orange.400')}
          >
            Reset Password
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('gray.100', 'gray.700')}
          boxShadow={'2xl'}
          p={['4', '10']}
        >
          <Stack spacing={['2', '4']} align="stretch">
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                type="text"
                name="username"
                id="username"
                label="Username"
                placeholder="Username"
                error={errors.username}
                register={register('username')}
              />
              <InputField
                type="text"
                name="mobile"
                id="mobile"
                label="Mobile"
                placeholder="Mobile"
                error={errors.mobile}
                register={register('mobile')}
              />
              <InputField
                type="text"
                name="mobile_code"
                id="mobile_code"
                label="Mobile OTP"
                placeholder="Mobile OTP"
                error={errors.mobile_code}
                register={register('mobile_code')}
              />

              <Button
                colorScheme="orange"
                w="full"
                type="submit"
                mt={4}
                isLoading={isSubmitting}
                disabled={!isValid || !isDirty}
              >
                Verify Mobile
              </Button>
            </form>

            <Flex
              direction={{ base: 'column', sm: 'row' }}
              align={'center'}
              justifyContent="space-between"
              justifyItems="center"
              width="full"
            >
              <Link
                to="/login"
                as={RLink}
                color={'brand.default'}
                fontWeight="bold"
                my="1"
              >
                Login
              </Link>
              <Link
                to="/resend-mobile-otp"
                as={RLink}
                color={'brand.default'}
                fontWeight="bold"
                my="1"
              >
                Send OTP ?
              </Link>
            </Flex>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

const VerifyMobile = {
  routeProps: {
    path: '/verify-mobile',
    component: VerifyMobilePage,
    exact: true,
  },
  name: 'VerifyMobile',
  title: 'VerifyMobile',
};

export default VerifyMobile;
