import API from '../helpers/API';
import getQueryString from '../helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/users?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async _id => {
  try {
    const response = await API.get(`/users/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const create = async (formData = {}) => {
  try {
    const response = await API.post(`/users/create`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const update = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/users/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateRole = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/users/role/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateStatus = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/users/status/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateEmail = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/users/change/email/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateMobile = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/users/change/mobile/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updatePassword = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/users/changepassword/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const forcePasswordChange = async (formData = {}) => {
  try {
    const response = await API.patch(`/auth/forcepasswordchange`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const userService = {
  getAll,
  getById,
  create,
  update,
  updateRole,
  updateStatus,
  updateEmail,
  updateMobile,
  updatePassword,
  forcePasswordChange,
};

export default userService;
