import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';
import { checkDatePeriod } from 'helpers/genHelpers';

import InputField from 'components/form/InputField';
import DateField from 'components/form/DateField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    name: '',
    nextRunAt_from: null,
    nextRunAt_to: null,
    lastRunAt_from: null,
    lastRunAt_to: null,
    lastFinishedAt_from: null,
    lastFinishedAt_to: null,
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
  } = useForm(formOptions);

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'nextRunAt' && value) {
        const from = moment(value.from).format(moment.defaultDateDb);
        const to = moment(value.to).format(moment.defaultDateDb);
        setValue('nextRunAt_from', from);
        setValue('nextRunAt_to', to);
      } else if (key === 'lastRunAt' && value) {
        const from = moment(value.from).format(moment.defaultDateDb);
        const to = moment(value.to).format(moment.defaultDateDb);
        setValue('lastRunAt_from', from);
        setValue('lastRunAt_to', to);
      } else if (key === 'lastFinishedAt' && value) {
        const from = moment(value.from).format(moment.defaultDateDb);
        const to = moment(value.to).format(moment.defaultDateDb);
        setValue('lastFinishedAt_from', from);
        setValue('lastFinishedAt_to', to);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleClear = () => {
    if (!_.isEmpty(defaultFilters)) {
      reset(defaultValues);
    }
    setFilters({});
  };

  const onSubmit = formData => {
    for (const key in formData) {
      if (formData[key] === '') {
        delete formData[key];
      }
    }

    // NextrunAt Period
    if (formData.nextRunAt_from && formData.nextRunAt_to) {
      const valPeriodNextRun = checkDatePeriod(
        formData.nextRunAt_from,
        formData.nextRunAt_to,
        31
      );

      delete formData['nextRunAt_from'];
      delete formData['nextRunAt_to'];

      if (!valPeriodNextRun) {
        setError('nextRunAt', {
          type: 'manual',
          message: `Invalid Date range or Date period exceeded`,
        });
      } else {
        formData.nextRunAt = valPeriodNextRun;
      }
    }

    // LastrunAt Period
    if (formData.lastRunAt_from && formData.lastRunAt_to) {
      const valPeriodLastRun = checkDatePeriod(
        formData.lastRunAt_from,
        formData.lastRunAt_to,
        31
      );

      delete formData['lastRunAt_from'];
      delete formData['lastRunAt_to'];

      if (!valPeriodLastRun) {
        setError('lastRunAt', {
          type: 'manual',
          message: `Invalid Date range or Date period exceeded`,
        });
      } else {
        formData.lastRunAt = valPeriodLastRun;
      }
    }

    // lastFinishedAt Period
    if (formData.lastFinishedAt_from && formData.lastFinishedAt_to) {
      const valPeriodLastFinished = checkDatePeriod(
        formData.lastFinishedAt_from,
        formData.lastFinishedAt_to,
        31
      );

      delete formData['lastFinishedAt_from'];
      delete formData['lastFinishedAt_to'];

      if (!valPeriodLastFinished) {
        setError('lastFinishedAt', {
          type: 'manual',
          message: `Invalid Date range or Date period exceeded`,
        });
        return;
      } else {
        formData.lastFinishedAt = valPeriodLastFinished;
      }
    }
    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}
        <InputField
          type="text"
          name="name"
          id="name"
          label="Name"
          placeholder="Name"
          error={errors.name}
          register={register('name')}
        />

        <Box border="1px" borderColor="gray.200" p={2} mb={4}>
          <DateField
            name="nextRunAt_from"
            label="Next Run At From"
            error={errors.nextRunAt}
            register={register('nextRunAt_from')}
          />
          <DateField
            name="nextRunAt_to"
            label="Next Run At To"
            error={errors.nextRunAt}
            register={register('nextRunAt_to')}
          />
        </Box>

        <Box border="1px" borderColor="gray.200" p={2} mb={4}>
          <DateField
            name="lastRunAt_from"
            label="Last Run At From"
            error={errors.lastRunAt}
            register={register('lastRunAt_from')}
          />
          <DateField
            name="lastRunAt_to"
            label="Last Run At To"
            error={errors.lastRunAt}
            register={register('lastRunAt_to')}
          />
        </Box>

        <Box border="1px" borderColor="gray.200" p={2} mb={4}>
          <DateField
            name="lastFinishedAt_from"
            label="Last Finished At From"
            error={errors.lastFinishedAt}
            register={register('lastFinishedAt_from')}
          />
          <DateField
            name="lastFinishedAt_to"
            label="Last Finished At To"
            error={errors.lastFinishedAt}
            register={register('lastFinishedAt_to')}
          />
        </Box>

        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
