import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import editChairmanSchema from './editChairmanSchema';
import fields from './fields';
import societyService from 'services/societyService';

import InputField from 'components/form/InputField';
import EditButtons from './EditButtons';

const EditChairmanPage = () => {
  const FormTitle = 'EDIT SOCIETY CHAIRMAN DETAILS';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const apiError = useSelector(state => state.error);

  const [data, setData] = useState(null);

  const { _id } = useParams();

  const fetchIdRef = useRef(0);
  const fetchIdSocietyRef = useRef(0);

  useEffect(() => {
    const fetchSociety = async () => {
      const responseData = await societyService.getById(_id);
      if (responseData && responseData.data) {
        setData(responseData.data);
        // console.log(data);
      }
    };

    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      fetchSociety();
    }
    // eslint-disable-next-line
  }, []);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(editChairmanSchema),
    defaultValues: {
      chairman_fname: fields.chairman_fname.default,
      chairman_mname: fields.chairman_mname.default,
      chairman_lname: fields.chairman_lname.default,
      chairman_address1: fields.chairman_address1.default,
      chairman_address2: fields.chairman_address2.default,
      chairman_address3: fields.chairman_address3.default,
      chairman_city: fields.chairman_city.default,
      chairman_mobile: fields.chairman_mobile.default,
      chairman_email: fields.chairman_email.default,
      chairman_pan: fields.chairman_pan.default,
      chairman_pan_name: fields.chairman_pan_name.default,
      chairman_adhar: fields.chairman_adhar.default,

      gu_chairman_fname: fields.gu_chairman_fname.default,
      gu_chairman_mname: fields.gu_chairman_mname.default,
      gu_chairman_lname: fields.gu_chairman_lname.default,
      gu_chairman_address1: fields.gu_chairman_address1.default,
      gu_chairman_address2: fields.gu_chairman_address2.default,
      gu_chairman_address3: fields.gu_chairman_address3.default,
      gu_chairman_city: fields.gu_chairman_city.default,
    },
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
  } = useForm(formOptions);

  const fieldsUsed = [
    'chairman_fname',
    'chairman_mname',
    'chairman_lname',
    'chairman_address1',
    'chairman_address2',
    'chairman_address3',
    'chairman_city',
    'chairman_pin',
    'chairman_mobile',
    'chairman_email',
    'chairman_pan',
    'chairman_pan_name',
    'chairman_adhar',

    'gu_chairman_fname',
    'gu_chairman_mname',
    'gu_chairman_lname',
    'gu_chairman_address1',
    'gu_chairman_address2',
    'gu_chairman_address3',
    'gu_chairman_city',
  ];

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        if (fieldsUsed.includes(key)) {
          setValue(key, value);
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        chairman_fname: formData.chairman_fname,
        chairman_mname: formData.chairman_mname,
        chairman_lname: formData.chairman_lname,
        chairman_address1: formData.chairman_address1,
        chairman_address2: formData.chairman_address2,
        chairman_address3: formData.chairman_address3,
        chairman_city: formData.chairman_city,
        chairman_pin: formData.chairman_pin,
        chairman_mobile: formData.chairman_mobile,
        chairman_email: formData.chairman_email,
        chairman_pan: formData.chairman_pan,
        chairman_pan_name: formData.chairman_pan_name,
        chairman_adhar: formData.chairman_adhar,

        gu_chairman_fname: formData.gu_chairman_fname,
        gu_chairman_mname: formData.gu_chairman_mname,
        gu_chairman_lname: formData.gu_chairman_lname,
        gu_chairman_address1: formData.gu_chairman_address1,
        gu_chairman_address2: formData.gu_chairman_address2,
        gu_chairman_address3: formData.gu_chairman_address3,
        gu_chairman_city: formData.gu_chairman_city,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (
          value === null ||
          value === undefined ||
          formDataSend[key] === data[key]
        ) {
          delete formDataSend[key];
        }
      }

      const responseData = await societyService.updateSocietyChairmanDetails(
        data?._id,
        formDataSend
      );
      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <EditButtons _id={data?._id} />

              {/* Start Row 12 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_fname"
                    id="chairman_fname"
                    label="Chairman Firstname"
                    placeholder="Chairman Firstname"
                    error={errors.chairman_fname}
                    register={register('chairman_fname')}
                    disabled={fields?.chairman_fname?.disabled}
                    readonly={fields?.chairman_fname?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_mname"
                    id="chairman_mname"
                    label="Chairman Middlename"
                    placeholder="Chairman Middlename"
                    error={errors.chairman_mname}
                    register={register('chairman_mname')}
                    disabled={fields?.chairman_mname?.disabled}
                    readonly={fields?.chairman_mname?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_lname"
                    id="chairman_lname"
                    label="Chairman Lastname"
                    placeholder="Chairman Lastname"
                    error={errors.chairman_lname}
                    register={register('chairman_lname')}
                    disabled={fields?.chairman_lname?.disabled}
                    readonly={fields?.chairman_lname?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_mobile"
                    id="chairman_mobile"
                    label="Chairman Mobile"
                    placeholder="Chairman Mobile"
                    error={errors.chairman_mobile}
                    register={register('chairman_mobile')}
                    disabled={fields?.chairman_mobile?.disabled}
                    readonly={fields?.chairman_mobile?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="email"
                    name="chairman_email"
                    id="chairman_email"
                    label="Chairman E-Mail"
                    placeholder="Chairman E-Mail"
                    error={errors.chairman_email}
                    register={register('chairman_email')}
                    disabled={fields?.chairman_email?.disabled}
                    readonly={fields?.chairman_email?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 12 */}

              {/* Start Row 13 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_address1"
                    id="chairman_address1"
                    label="Chairman Address 1"
                    placeholder="Chairman Address 1"
                    error={errors.chairman_address1}
                    register={register('chairman_address1')}
                    disabled={fields?.chairman_address1?.disabled}
                    readonly={fields?.chairman_address1?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_address2"
                    id="chairman_address2"
                    label="Chairman Address 2"
                    placeholder="Chairman Address 2"
                    error={errors.chairman_address2}
                    register={register('chairman_address2')}
                    disabled={fields?.chairman_address2?.disabled}
                    readonly={fields?.chairman_address2?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_address3"
                    id="chairman_address3"
                    label="Chairman Address 3"
                    placeholder="Chairman Address 3"
                    error={errors.chairman_address3}
                    register={register('chairman_address3')}
                    disabled={fields?.chairman_address3?.disabled}
                    readonly={fields?.chairman_address3?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 13 */}

              {/* Start Row 14 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_city"
                    id="chairman_city"
                    label="Chairman City"
                    placeholder="Chairman City"
                    error={errors.chairman_city}
                    register={register('chairman_city')}
                    disabled={fields?.chairman_city?.disabled}
                    readonly={fields?.chairman_city?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_pin"
                    id="chairman_pin"
                    label="Chairman Pin"
                    placeholder="Chairman Pin"
                    error={errors.chairman_pin}
                    register={register('chairman_pin')}
                    disabled={fields?.chairman_pin?.disabled}
                    readonly={fields?.chairman_pin?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_adhar"
                    id="chairman_adhar"
                    label="Chairman Adhar"
                    placeholder="Chairman Adhar"
                    error={errors.chairman_adhar}
                    register={register('chairman_adhar')}
                    disabled={fields?.chairman_adhar?.disabled}
                    readonly={fields?.chairman_adhar?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_pan"
                    id="chairman_pan"
                    label="Chairman PAN"
                    placeholder="Chairman PAN"
                    error={errors.chairman_pan}
                    register={register('chairman_pan')}
                    disabled={fields?.chairman_pan?.disabled}
                    readonly={fields?.chairman_pan?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="chairman_pan_name"
                    id="chairman_pan_name"
                    label="Chairman PAN Name"
                    placeholder="Chairman PAN Name"
                    error={errors.chairman_pan_name}
                    register={register('chairman_pan_name')}
                    disabled={fields?.chairman_pan_name?.disabled}
                    readonly={fields?.chairman_pan_name?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 14 */}

              {/* Start Row 23 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_chairman_fname"
                    id="gu_chairman_fname"
                    label="ચેરમેન નામ"
                    placeholder="ચેરમેન નામ"
                    error={errors.gu_chairman_fname}
                    register={register('gu_chairman_fname')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_chairman_mname"
                    id="gu_chairman_mname"
                    label="ચેરમેન પીતા નું નામ"
                    placeholder="ચેરમેન પીતા નું નામ"
                    error={errors.gu_chairman_mname}
                    register={register('gu_chairman_mname')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_chairman_lname"
                    id="gu_chairman_lname"
                    label="ચેરમેન અટક"
                    placeholder="ચેરમેન અટક"
                    error={errors.gu_chairman_lname}
                    register={register('gu_chairman_lname')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_chairman_city"
                    id="gu_chairman_city"
                    label="ચેરમેન ગામ"
                    placeholder="ચેરમેન ગામ"
                    error={errors.gu_chairman_city}
                    register={register('gu_chairman_city')}
                  />
                </Box>
              </Flex>
              {/* End Row 23 */}

              {/* Start Row 24 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_chairman_address1"
                    id="gu_chairman_address1"
                    label="ચેરમેન સરનામું ૧"
                    placeholder="ચેરમેન સરનામું ૧"
                    error={errors.gu_chairman_address1}
                    register={register('gu_chairman_address1')}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_chairman_address2"
                    id="gu_chairman_address2"
                    label="ચેરમેન સરનામું ૨"
                    placeholder="ચેરમેન સરનામું ૨"
                    error={errors.gu_chairman_address2}
                    register={register('gu_chairman_address2')}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_chairman_address3"
                    id="gu_chairman_address3"
                    label="ચેરમેન સરનામું ૩"
                    placeholder="ચેરમેન સરનામું ૩"
                    error={errors.gu_chairman_address3}
                    register={register('gu_chairman_address3')}
                  />
                </Box>
              </Flex>
              {/* End Row 24 */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const SocietyEditChairman = {
  routeProps: {
    path: '/societies/edit/chairman/:_id',
    component: EditChairmanPage,
    exact: true,
  },
  name: 'Edit Society Chairman Details',
  title: 'Edit Society Chairman Details',
};

export default SocietyEditChairman;
