const fields = {
  names: ['fiscal_year'],
  fiscal_year: {
    default: '',
    disabled: true,
    readonly: false,
  },
};

export default fields;
