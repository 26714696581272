import API from 'helpers/API';

const getSmsCreditBalance = async () => {
  try {
    const response = await API.get(`/sms/creditbalance`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const updateSmsCreditBalance = async (formData = {}) => {
  try {
    const response = await API.post(`/sms/creditbalance`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const smsService = {
  getSmsCreditBalance,
  updateSmsCreditBalance,
};

export default smsService;
