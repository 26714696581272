import * as yup from 'yup';
import validationRegex from 'configs/validationRegex';

const messages = {
  mobile: {
    required: 'Mobile is required',
    invalid: 'Invalid Mobile number',
  },
};

const schema = yup.object().shape({
  mobile: yup
    .string()
    .required(messages.mobile.required)
    .matches(validationRegex.mobile, messages.mobile.invalid),
});

export default schema;
