import tblConsts from 'components/tables/tblConsts';
import renderDateTime from 'components/tables/helpers/renderDateTime';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderHistoryDetails from 'components/tables/helpers/renderHistoryDetails';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Created',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: false,
    disableSortBy: false,
  },
  {
    id: 'updatedAt',
    accessor: 'updatedAt',
    Header: 'Updated',
    type: 'datetime',
    hidden: true,
    // headerAlign: 'left',
    Cell: ({ cell }) => renderDateTime(cell),
    exportable: false,
    disableSortBy: false,
  },
  {
    id: 'hist_type',
    accessor: 'hist_type',
    Header: 'Type',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'society_code',
    accessor: 'society',
    Header: 'Soc Code',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.society_code : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.society_code : '-'}</div>;
    },
  },
  {
    id: 'society_name',
    accessor: 'society',
    Header: 'Soc Name',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.society_name : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.society_name : '-'}</div>;
    },
    maxWidth: tblConsts.societyname.maxWidth,
    minWidth: tblConsts.societyname.minWidth,
  },
  {
    id: 'who',
    accessor: 'who',
    Header: 'Edit By',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'what',
    accessor: 'what',
    Header: 'Edited Data',
    type: 'array',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: true,
    disableHideColumn: true,
    expandfunction: value => renderHistoryDetails(value),
  },
  {
    id: 'old_plant',
    accessor: 'old_plant',
    Header: 'Plant (O)',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.plant_code : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.plant_code : '-'}</div>;
    },
  },
  {
    id: 'new_plant',
    accessor: 'new_plant',
    Header: 'Plant (N)',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.plant_code : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.plant_code : '-'}</div>;
    },
  },
  {
    id: 'old_supervisor',
    accessor: 'old_supervisor',
    Header: 'SUpervisor (O)',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'new_supervisor',
    accessor: 'new_supervisor',
    Header: 'Supervisor (N)',
    type: 'object',
    hidden: false,
    headerAlign: 'left',
    Cell: ({ cell }) => {
      return <div>{cell.value ? cell.value?.username : '-'}</div>;
    },
    exportable: true,
    disableSortBy: false,
    expandfunction: value => {
      return <div>{value ? value?.username : '-'}</div>;
    },
  },
  {
    id: 'old_bank',
    accessor: 'old_bank',
    Header: 'Bank (O)',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'new_bank',
    accessor: 'new_bank',
    Header: 'Bank (N)',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
];

export default columns;
