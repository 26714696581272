import React, { useEffect, useRef } from 'react';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import addSchema from './addSchema';
import fields from 'modules/FiscalYears/fields';
import materialCatagoryService from 'services/materialCatagoryService';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';

const AddPage = () => {
  const FormTitle = 'ADD MATERIAL CATAGORY';
  const IndexPageTitle = 'MATERIAL CATAGORIES';
  const IndexPagePath = '/materialcatagories';

  const defaultValues = {
    name: fields?.name?.default,
    description: fields?.description?.default,
  };

  const apiError = useSelector(state => state.error);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    reset,
  } = useForm(formOptions);

  const onReset = () => {
    reset();
  };

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        name: formData?.name,
        description: formData?.description,
      };

      const responseData = await materialCatagoryService.create(formDataSend);

      if (responseData) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="name"
                    id="name"
                    label="Catagory"
                    placeholder="Catagory"
                    error={errors.name}
                    register={register('name')}
                  />
                </Box>
                <Box w={['full', 2 / 3]} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="description"
                    id="description"
                    label="Description"
                    placeholder="Write Your Description"
                    error={errors.description}
                    register={register('description')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MaterialCatagoryAdd = {
  routeProps: {
    path: '/materialcatagories/add',
    component: AddPage,
    exact: true,
  },
  name: 'Add Material Catagory',
  title: 'Add Material Catagory',
};

export default MaterialCatagoryAdd;
