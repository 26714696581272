import * as yup from 'yup';
import messages from './messages';

const PLANT_CODE_PATTERN = /[O][L,M][0-9][0-9]\b/i;

const addSchema = yup.object().shape({
  plant_code: yup
    .string()
    .test('plant_code_match', messages.plant_code.invalid, function (value) {
      return PLANT_CODE_PATTERN.test(value);
    }),
  plant_name: yup
    .string()
    .label('Code')
    .required(messages.plant_name.required)
    .trim()
    .max(50, messages.plant_name.max),
  city: yup
    .string()
    .label('City')
    .required(messages.city.required)
    .trim()
    .max(50, messages.city.max),
  is_active: yup
    .boolean()
    .label('Status')
    .required(messages.is_active.required),
  gu_plant_name: yup
    .string()
    .trim()
    .label('પ્લાન્ટ')
    .max(200, messages.gu_plant_name.max),
  gu_city: yup.string().trim().label('ગામ').max(100, messages.gu_city.max),
});

export default addSchema;
