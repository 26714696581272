import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
  Divider,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import addSchema from './addSchema';
import fields from './fields';
import defaultValues from './defaultValues';
import cityService from 'services/cityService';
import districtService from 'services/districtService';
import societyService from 'services/societyService';

import InputField from 'components/form/InputField';
import SelectField from 'components/form/SelectField';
import SwitchField from 'components/form/SwitchField';

const AddPage = () => {
  const FormTitle = 'ADD SOCIETY';
  const IndexPageTitle = 'SOCIETIES';
  const IndexPagePath = '/societies';

  const apiError = useSelector(state => state.error);

  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchIdRef = useRef(0);
  const fetchIdDistrictRef = useRef(0);
  const fetchIdCityRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(addSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    watch,
  } = useForm(formOptions);

  const watchDistrict = watch('district', null);
  const watchTaluka = watch('taluka', null);
  const watchCity = watch('city', null);
  const watchIsActive = watch('is_active', false);

  const onReset = () => {
    reset();
    handleDistrictChange('clear', null);
    handleTalukaChange('clear', null);
    handleCityChange('clear', null);
  };

  const handleDistrictChange = (selected, action) => {
    handleTalukaChange('clear', null);
    if (action === 'clear') {
      setValue('district', null);
      setValue('gu_district', null);
      return;
    }
    setValue('district', selected);
    setValue('gu_district', selected.gu_name);
  };

  const handleTalukaChange = (selected, action) => {
    handleCityChange('clear', null);
    if (action === 'clear') {
      setValue('taluka', null);
      setValue('gu_taluka', null);
      return;
    }
    setValue('taluka', selected);
    setValue('gu_taluka', selected?.gu_name);
  };

  const handleCityChange = (selected, action) => {
    if (action === 'clear') {
      setValue('city', null);
      setValue('gu_city', null);
      return;
    }
    setValue('city', selected);
    setValue('gu_city', selected?.gu_city);
  };

  useEffect(() => {
    const fetchDistricts = async () => {
      const query = {};
      const responseData = await districtService.getAll(query);
      if (responseData && responseData.data) {
        setDistricts(responseData.data);
      }
    };

    const fetchIdDistrict = ++fetchIdDistrictRef.current;

    if (fetchIdDistrict === fetchIdDistrictRef.current) {
      fetchDistricts();
    }
    // eslint-disable-next-line
  }, []);

  const fetchCities = async () => {
    const query = {};
    query.filters = {};

    if (watchTaluka?.name) {
      query.filters.taluka = watchTaluka?.name;
      query.pagination = false;
      query.sortby = 'name';

      const responseData = await cityService.getAll(query);
      if (responseData && responseData.data) {
        setCities(responseData.data);
      }
    }
  };

  useEffect(() => {
    const fetchIdCity = ++fetchIdCityRef.current;

    if (fetchIdCity === fetchIdCityRef.current) {
      fetchCities();
    }
    setValue('gu_taluka', watchTaluka?.gu_name);
    // eslint-disable-next-line
  }, [watchTaluka]);

  useEffect(() => {
    handleTalukaChange('clear', null);
    handleCityChange('clear', null);
    const tal = _.find(districts, { name: watchDistrict?.name });
    if (tal) {
      setTalukas(tal.talukas);
    }
    setValue('gu_district', watchDistrict?.gu_name);
    // eslint-disable-next-line
  }, [watchDistrict]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        society_code: formData.society_code,
        society_name: formData.society_name,
        city: formData.city?.city,
        taluka: formData.taluka?.name,
        district: formData.district?.name,
        is_active: formData.is_active,
        gu_society_name: formData.gu_society_name,
        gu_city: formData.gu_city,
        gu_taluka: formData.gu_taluka,
        gu_district: formData.gu_district,
      };

      const responseData = await societyService.create(formDataSend);

      if (responseData) {
        onReset();
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}

              {/* Start Row 1 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <Text
                    fontSize="lg"
                    fontWeight="extrabold"
                    as="em"
                    color="green.400"
                  >
                    Society Details
                  </Text>
                  <Divider orientation="horizontal" />
                </Box>
              </Flex>
              {/* End Row 1 */}

              {/* Start Row 2 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="society_code"
                    id="society_code"
                    label="Code"
                    placeholder="Code"
                    error={errors.society_code}
                    register={register('society_code')}
                    disabled={fields?.society_code?.disabled}
                    readonly={fields?.society_code?.readonly}
                  />
                </Box>
                <Box w={['full', 4 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="society_name"
                    id="society_name"
                    label="Name"
                    placeholder="Name"
                    error={errors.society_name}
                    register={register('society_name')}
                    disabled={fields?.society_name?.disabled}
                    readonly={fields?.society_name?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 2 */}

              {/* Start Row 2 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="sap_code"
                    id="sap_code"
                    label="SAP Code"
                    placeholder="SAP Code"
                    error={errors.sap_code}
                    register={register('sap_code')}
                    disabled={fields?.sap_code?.disabled}
                    readonly={fields?.sap_code?.readonly}
                  />
                </Box>
                <Box w={['full', 4 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_society_name"
                    id="gu_society_name"
                    label="નામ"
                    placeholder="નામ"
                    error={errors.gu_society_name}
                    register={register('gu_society_name')}
                    disabled={fields?.gu_society_name?.disabled}
                    readonly={fields?.gu_society_name?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 2 */}

              {/* Start Row 3 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="country"
                    id="country"
                    label="Country"
                    placeholder="Country"
                    error={errors.country}
                    register={register('country')}
                    // disabled={fields?.country?.disabled}
                    readonly={fields?.country?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="state"
                    id="state"
                    label="State"
                    placeholder="State"
                    error={errors.state}
                    register={register('state')}
                    // disabled={fields?.state?.disabled}
                    readonly={fields?.state?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="District"
                    label="District"
                    labelKey="district"
                    valueKey="district"
                    options={districts}
                    placeholder="Select District"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => option?.name}
                    handleChange={handleDistrictChange}
                    error={errors.district}
                    control={control}
                    defaultValue={watchDistrict}
                    disabled={fields?.district?.disabled}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="Taluka"
                    label="Taluka"
                    labelKey="taluka"
                    valueKey="taluka"
                    options={talukas}
                    placeholder="Select Taluka"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => option?.name}
                    handleChange={handleTalukaChange}
                    error={errors.taluka}
                    control={control}
                    defaultValue={watchTaluka}
                    disabled={fields?.taluka?.disabled}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="City"
                    label="City"
                    labelKey="city"
                    valueKey="city"
                    options={cities}
                    placeholder="Select City"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => `${option.city}`}
                    getOptionValue={option => option?.city}
                    handleChange={handleCityChange}
                    error={errors.city}
                    control={control}
                    defaultValue={watchCity}
                    disabled={fields?.city?.disabled}
                  />
                </Box>
              </Flex>
              {/* End Row 3 */}

              {/* Start Row 4 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="is_active"
                    id="is_active"
                    label="Status"
                    error={errors.is_active}
                    register={register('is_active')}
                    defaultChecked={watchIsActive}
                    disabled={fields?.is_active?.disabled}
                  />
                </Box>
              </Flex>
              {/* End Row 4*/}

              <hr></hr>
              {/* Start Row 5 */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_country"
                    id="gu_country"
                    label="દેશ"
                    placeholder="દેશ"
                    error={errors.gu_country}
                    register={register('gu_country')}
                    // disabled={fields?.gu_country?.disabled}
                    readonly={fields?.gu_country?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_state"
                    id="gu_state"
                    label="રાજ્ય"
                    placeholder="રાજ્ય"
                    error={errors.gu_state}
                    register={register('gu_state')}
                    // disabled={fields?.gu_state?.disabled}
                    readonly={fields?.gu_state?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_district"
                    id="gu_district"
                    label="જિલ્લો"
                    placeholder="જિલ્લો"
                    error={errors.gu_district}
                    register={register('gu_district')}
                    // disabled={fields?.gu_district?.disabled}
                    readonly={fields?.gu_district?.readonly}
                  />
                </Box>
                <Box w={['full', 1 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_taluka"
                    id="gu_taluka"
                    label="તાલુકા"
                    placeholder="તાલુકા"
                    error={errors.gu_taluka}
                    register={register('gu_taluka')}
                    // disabled={fields?.gu_taluka?.disabled}
                    readonly={fields?.gu_taluka?.readonly}
                  />
                </Box>
                <Box w={['full', 2 / 6]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gu_city"
                    id="gu_city"
                    label="ગામ"
                    placeholder="ગામ"
                    error={errors.gu_city}
                    register={register('gu_city')}
                    // disabled={fields?.gu_city?.disabled}
                    readonly={fields?.gu_city?.readonly}
                  />
                </Box>
              </Flex>
              {/* End Row 5 */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  ADD
                </Button>
                <Button
                  type="button"
                  colorScheme="yellow"
                  size="sm"
                  px="12"
                  onClick={onReset}
                >
                  RESET
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const SocietyAdd = {
  routeProps: {
    path: '/societies/add',
    component: AddPage,
    exact: true,
  },
  name: 'Add Society',
  title: 'Add Society',
};

export default SocietyAdd;
