import API from 'helpers/API';
import getQueryString from 'helpers/queryHelper';

const getAll = async (query = {}) => {
  const queryString = getQueryString(query);
  try {
    const response = await API.get(`/customer?${queryString}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const getById = async _id => {
  try {
    const response = await API.get(`/customer/${_id}`);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const create = async (formData = {}) => {
  try {
    const response = await API.post(`/customer/create`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const update = async (_id, formData = {}) => {
  try {
    const response = await API.patch(`/customer/${_id}`, formData);
    return response.data;
  } catch (err) {
    console.log(err.message);
  }
};

const customerService = {
  getAll,
  getById,
  create,
  update,
};

export default customerService;
