import authConstants from 'constants/authConstants';
import profileService from 'services/profileService';

const getProfile = () => {
  return async dispatch => {
    try {
      const profileResponse = await profileService.getOwnProfile();
      let user = JSON.parse(localStorage.getItem('user'));

      if (profileResponse && profileResponse.data) {
        user = {
          ...user,
          mobile: profileResponse.data.mobile,
          mobile_verified: profileResponse.data.mobile_verified,
          employee_code: profileResponse.data.employee_code,
          email: profileResponse.data.email,
          email_verified: profileResponse.data.email_verified,
          last_password_change: profileResponse.data.last_password_change,
          is_active: profileResponse.data.is_active,
          profile_picture: profileResponse.data.profile_picture,
          profile_image_url: profileResponse.data.profile_image_url,
          firstname: profileResponse.data.firstname,
          middlename: profileResponse.data.middlename,
          lastname: profileResponse.data.lastname,
          societies: profileResponse.data.societies,
          force_password_change: profileResponse.data.force_password_change,
        };

        dispatch({
          type: authConstants.SET_USER,
          payload: user,
        });
        localStorage.setItem('user', JSON.stringify(user));
      }
    } catch (err) {
      console.log(err.message);
    }
  };
};

const updateProfile = formData => {
  return async dispatch => {
    try {
      await profileService.updateOwnProfile(formData);
    } catch (err) {
      console.log(err.message);
    }
  };
};

const profileActions = {
  getProfile,
  updateProfile,
};

export default profileActions;
