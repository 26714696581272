import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Flex,
  Box,
  Heading,
  Button,
  useColorModeValue,
  Select,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Stack,
  Radio,
  RadioGroup,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { MonthOptions } from 'configs/MonthOptions';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import SelectFieldSimple from 'components/form/SelectFieldSimple';

import dashboardSocietyService from 'services/dashboardSocietyService';
import societyService from 'services/societyService';

function SocietyMilkAnalysis() {
  const fetchIdSocietyRef = useRef(0);

  const chartYAxisColor = useColorModeValue('#50586C', '#D8D8D8');
  const chartXAxisColor = useColorModeValue('#50586C', '#D8D8D8');
  const strokeDotColor = useColorModeValue('#383838', '#383838');
  const wightLineColor = useColorModeValue('#005555', '#3AEBDC');
  const kgFatLineColor = useColorModeValue('#533E85', '#71D2FF');
  const kgSnfLineColor = useColorModeValue('#6E0D25', '#FFFFB3');
  const avgFatLineColor = useColorModeValue('#3C153B', '#FF9DFC');
  const avgSnfLineColor = useColorModeValue('#B3281B', '#F3C5C1');

  const fiscalyears = useSelector(state => state.fiscalyears);
  const fiscalyearObj = useSelector(state => state.fiscalyearObj);

  const [data, setData] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fiscal_year, setFiscalyear] = useState(null);
  const [selectedMonth, setSelecteMonth] = useState('4');
  const [societies, setSocieties] = useState([]);
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [milkCatagory, setMilkCatagory] = useState('ALL');
  const [milkType, setMilkType] = useState('ALL');

  useEffect(() => {
    if (!fiscal_year) {
      const fy = _.find(fiscalyears, {
        fiscal_year: fiscalyearObj?.current?.fiscal_year,
      });
      handleFiscalYearChange(fy, 'select-option');
      if (fiscalyearObj?.now) {
        const mnth = _.find(MonthOptions, {
          value: fiscalyearObj?.now?.month * 1,
        });
        handleMonthChange(mnth, 'select-option');
      }
    }
    // eslint-disable-next-line
  }, [fiscalyears, fiscalyearObj]);

  const handleFiscalYearChange = (selected, action) => {
    if (action === 'clear') {
      setFiscalyear(null);
      return;
    }
    setFiscalyear(selected);
  };

  const handleMonthChange = (selected, action) => {
    if (action === 'clear') {
      setSelecteMonth(null);
      return;
    }
    setSelecteMonth(selected);
  };

  const refreshData = async () => {
    if (
      !fiscal_year ||
      !selectedMonth ||
      !selectedSociety ||
      !milkCatagory ||
      !milkType
    )
      return;
    setLoading(true);
    setData(null);
    setResults([]);

    const formData = {
      fiscal_year: fiscal_year?.fiscal_year,
      month: selectedMonth?.value,
      society_code: selectedSociety,
      milk_catagory: milkCatagory,
      milk_type: milkType,
    };

    const resData = await dashboardSocietyService.getSocietyAnalysisData(
      formData
    );

    if (resData && resData?.data) {
      setData(resData?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    setResults(data?.results);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (selectedSociety && selectedSociety !== 0) {
      refreshData();
    }
    // eslint-disable-next-line
  }, [fiscal_year, selectedMonth, selectedSociety, milkCatagory, milkType]);

  useEffect(() => {
    if (data && data?.results && data?.results.length) {
      setResults(data.results);
    }
    // eslint-disable-next-line
  }, [data]);

  const fetchSocieties = async () => {
    const fetchIdSociety = ++fetchIdSocietyRef.current;

    if (fetchIdSociety === fetchIdSocietyRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'society_code';
      query.fields = '_id,society_code,society_name';
      const responseData = await societyService.getAll(query);

      if (responseData && responseData?.data) {
        setSocieties([
          { _id: null, society_code: 0, society_name: 'Select Society' },
          ...responseData?.data,
        ]);
      }
    }
  };

  useEffect(() => {
    fetchSocieties();
  }, []);

  return (
    <Flex
      direction={['column', 'column']}
      justifyContent="space-between"
      alignItems="center"
      w="full"
      h="full"
    >
      <Box
        w="full"
        // h="full"
        position="sticky"
        top={0}
        bg={useColorModeValue('orange.100', 'orange.100')}
        color={useColorModeValue('gray.900', 'gray.900')}
        zIndex={10}
        p={4}
        mb="4"
        border="1px"
        borderColor={useColorModeValue('orange.300', 'orange.300')}
      >
        <Flex
          w={['full', 'full']}
          justifyContent={'space-between'}
          alignItems={'center'}
          // mb="4"
          direction={['column', 'row']}
        >
          <Heading
            fontSize="xl"
            color={useColorModeValue('orange.600', 'orange.600')}
          >
            SOCIETY MILK ANALYSIS
          </Heading>

          <Box>
            <SelectFieldSimple
              size="md"
              id="fiscal_year"
              name="fiscal_year"
              // label="Year"
              options={fiscalyears}
              value={fiscal_year}
              placeholder="Select Year"
              getOptionLabel={option => `${option?.fiscal_year}`}
              getOptionValue={option => option?.fiscal_year}
              handleChange={handleFiscalYearChange}
            />
          </Box>

          <Box>
            <SelectFieldSimple
              size="md"
              id="month"
              name="month"
              // label="Month"
              options={MonthOptions}
              value={selectedMonth}
              placeholder="Select Month"
              getOptionLabel={option => `${option?.name}`}
              getOptionValue={option => option?.value}
              handleChange={handleMonthChange}
            />
          </Box>

          <Box>
            <FormControl>
              <InputGroup size="md">
                <InputLeftAddon children="Society" />
                <Select
                  size="md"
                  varient="filled"
                  colorScheme="orange"
                  focusBorderColor="orange.500"
                  onChange={e => setSelectedSociety(e.target.value * 1)}
                  defaultValue={selectedSociety}
                >
                  {societies.map(society => (
                    <option
                      key={society.society_code}
                      value={society.society_code}
                    >
                      {`${society.society_code.pad(6)} - ${
                        society.society_name
                      }`}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </FormControl>
          </Box>

          <Button
            variant="ghost"
            isLoading={loading}
            loadingText="Refreshing..."
            onClick={refreshData}
            outline="none"
            _focus={{ boxShadow: 'none' }}
            _hover={{
              bg: 'transperent',
              scale: '50',
              color: 'teal.500',
              size: 'xs',
            }}
          >
            <RepeatIcon w={8} h={8} color="green.500" />
          </Button>
        </Flex>

        <Flex
          // h="50px"
          w={['full', 'full']}
          justifyContent={'space-between'}
          alignItems={'center'}
          my="1"
        >
          <Flex justifyContent={'start'} alignItems={'center'} mx="8">
            <RadioGroup onChange={setMilkCatagory} value={milkCatagory} mr="24">
              <Stack direction="row">
                <Radio value="ALL" size="lg">
                  TOTAL
                </Radio>
                <Radio value="GOOD" size="lg">
                  GOOD
                </Radio>
                <Radio value="SOUR" size="lg">
                  SOUR
                </Radio>
              </Stack>
            </RadioGroup>

            <RadioGroup onChange={setMilkType} value={milkType}>
              <Stack direction="row">
                <Radio value="ALL" size="lg">
                  TOTAL
                </Radio>
                <Radio value="BUFF" size="lg">
                  BUFF
                </Radio>
                <Radio value="COW" size="lg">
                  COW
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </Flex>
      </Box>

      {loading && (
        <Center h="full" w="full">
          <Spinner
            thickness="6px"
            speed="0.65s"
            emptyColor="gray.200"
            color="orange.500"
            size="xl"
          />
        </Center>
      )}

      <Box h="full" w={['full', 'full']} minW="full">
        {!loading && (
          <Flex
            direction={{ sm: 'column', md: 'row' }}
            // h={{ sm: '300px', md: '600px' }}
            h={['full', 'full']}
            w={['full', 'full']}
            justifyContent={{ sm: 'stretch', md: 'space-between' }}
            alignItems={'center'}
          >
            <Box h={{ sm: '300px', md: '600px' }} w={['full', 'full']} mb="16">
              <ResponsiveContainer
                width="100%"
                height="100%"
                // minWidth="100%"
                // minHeight="300px"
                // aspect={undefined}
                debounce={1}
              >
                <LineChart
                  width={500}
                  height={300}
                  data={results}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  cursor="pointer"
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis
                    dataKey="day"
                    label={{
                      value: 'DAY',
                      offset: 0,
                      position: 'insideBottomLeft',
                      fill: chartXAxisColor,
                    }}
                    tick={{ stroke: chartXAxisColor, strokeWidth: 1 }}
                    type="number"
                    domain={[1, 31]}
                    tickCount={31}
                  />
                  <YAxis
                    label={{
                      value: 'WEIGHT - KGFAT - KGSNF',
                      angle: -90,
                      position: 'insideLeft',
                      fill: chartYAxisColor,
                    }}
                    type="number"
                    domain={[0, dataMax => Math.round(dataMax / 10) * 10 + 10]}
                    interval={`preserveStartEnd`}
                    tickCount={50}
                    tick={{ stroke: chartYAxisColor, strokeWidth: 1 }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey={`weight_kg`}
                    // stroke="#05595B"
                    stroke={wightLineColor}
                    dot={{ stroke: strokeDotColor, strokeWidth: 2 }}
                    name={`WEIGHT : ${milkCatagory} ${milkType}`}
                    strokeWidth={3}
                  />
                  <Line
                    type="monotone"
                    dataKey={`fat_kg`}
                    stroke={kgFatLineColor}
                    dot={{ stroke: strokeDotColor, strokeWidth: 2 }}
                    name={`KGFAT : ${milkCatagory} ${milkType}`}
                    strokeWidth={3}
                  />
                  <Line
                    type="monotone"
                    dataKey={`snf_kg`}
                    stroke={kgSnfLineColor}
                    dot={{ stroke: strokeDotColor, strokeWidth: 2 }}
                    name={`KGSNF : ${milkCatagory} ${milkType}`}
                    strokeWidth={3}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Flex>
        )}

        {!loading && (
          <Flex
            direction={{ sm: 'column', md: 'row' }}
            // h={{ sm: '300px', md: '600px' }}
            h={['full', 'full']}
            w={['full', 'full']}
            justifyContent={{ sm: 'stretch', md: 'space-between' }}
            alignItems={'center'}
          >
            <Box h={{ sm: '300px', md: '600px' }} w={['full', '50%']} mb="16">
              <ResponsiveContainer
                width="100%"
                height="100%"
                // minWidth="100%"
                // minHeight="300px"
                // aspect={undefined}
                debounce={1}
              >
                <LineChart
                  width={500}
                  height={300}
                  data={results}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  cursor="pointer"
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis
                    dataKey="day"
                    label={{
                      value: 'DAY',
                      offset: 0,
                      position: 'insideBottomLeft',
                      fill: chartXAxisColor,
                    }}
                    tick={{ stroke: chartXAxisColor, strokeWidth: 1 }}
                    type="number"
                    domain={[1, 31]}
                    tickCount={31}
                  />
                  <YAxis
                    label={{
                      value: 'AVERAGE FAT',
                      angle: -90,
                      position: 'insideLeft',
                      fill: chartYAxisColor,
                    }}
                    type="number"
                    interval={`preserveStartEnd`}
                    tickCount={60}
                    domain={[0, 12]}
                    // domain={[
                    //   dataMin =>
                    //     0 - Math.abs(dataMin) > 0 ? 0 - Math.abs(dataMin) : 0,
                    //   dataMax => (dataMax < 12 ? 12 : dataMax + 1),
                    // ]}
                    tick={{ stroke: chartYAxisColor, strokeWidth: 1 }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey={`avgfat`}
                    stroke={avgFatLineColor}
                    dot={{ stroke: strokeDotColor, strokeWidth: 2 }}
                    name={`AVERAGE FAT : ${milkCatagory} ${milkType}`}
                    strokeWidth={3}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Box h={{ sm: '300px', md: '600px' }} w={['full', '50%']} mb="16">
              <ResponsiveContainer
                width="100%"
                height="100%"
                // minWidth="100%"
                // minHeight="300px"
                // aspect={undefined}
                debounce={1}
              >
                <LineChart
                  width={500}
                  height={300}
                  data={results}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  cursor="pointer"
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis
                    dataKey="day"
                    label={{
                      value: 'DAY',
                      offset: 0,
                      position: 'insideBottomLeft',
                      fill: chartXAxisColor,
                    }}
                    tick={{ stroke: chartXAxisColor, strokeWidth: 1 }}
                    type="number"
                    domain={[1, 31]}
                    tickCount={31}
                  />
                  <YAxis
                    label={{
                      value: 'AVERAGE SNF',
                      angle: -90,
                      position: 'insideLeft',
                      fill: chartYAxisColor,
                    }}
                    type="number"
                    domain={[0, 12]}
                    interval={`preserveStartEnd`}
                    tickCount={60}
                    tick={{ stroke: chartYAxisColor, strokeWidth: 1 }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey={`avgsnf`}
                    stroke={avgSnfLineColor}
                    dot={{ stroke: strokeDotColor, strokeWidth: 2 }}
                    name={`AVERAGE SNF : ${milkCatagory} ${milkType}`}
                    strokeWidth={3}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

export default SocietyMilkAnalysis;
