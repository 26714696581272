import * as yup from 'yup';
// import messages from './messages';

const filterSchema = yup.object().shape({
  tr_date: yup.object().nullable(true).label('Date'),
  referance: yup.string().trim().max(10).nullable(true).label('Referance'),
  plant_from_id: yup.object().nullable(true).label('Plant From'),
  plant_to_id: yup.object().nullable(true).label('Plant To'),
  tr_type: yup.object().nullable(true).label('Transaction Type'),
});

export default filterSchema;
