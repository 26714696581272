import { Badge, Center } from '@chakra-ui/react';

const getBadgeColor = (type, hasReturn) => {
  if (type.endsWith('_RET') || type.endsWith('_OUT')) {
    return 'red';
  } else if (hasReturn) {
    return 'yellow';
  } else {
    return 'green';
  }
};

const renderMaterialTrType = (cell, hasReturn) => {
  if (cell.value) {
    return (
      <Badge variant="solid" colorScheme={getBadgeColor(cell.value, hasReturn)}>
        {cell.value}
      </Badge>
    );
  } else {
    return <Center>-</Center>;
  }
};

export default renderMaterialTrType;
