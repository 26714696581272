const messages = {
  username: {
    required: 'Username is required',
    min: 'Username is too short',
    max: 'Username length exceeded',
  },
  current_password: {
    required: 'Current Password is required',
  },
  password: {
    required: 'Password is required',
    min: 'Password length too short',
    max: 'Password length exceeded',
    match: 'Password and confirm password do not match',
  },
  password_confirmation: {
    required: 'Confirm Password is required',
    min: 'Confirm Password length too short',
    max: 'Confirm Password length exceeded',
    match: 'Password and confirm password do not match',
  },
};
export default messages;
