const _CONSTATNTS = {
  // OLD
  SHOW_LOADER: 'SHOW_LOADER ',
  HIDE_LOADER: 'HIDE_LOADER',
  SET_ERROR: 'SET_ERROR ',
  HIDE_ERROR: 'HIDE_ERROR',
  SET_DATA: 'SET_DATA ',
  UNSET_DATA: 'UNSET_DATA ',
  SET_META: 'SET_META ',
  UNSET_META: 'UNSET_META ',
  SIDEBAR_TOGGLE: 'SIDEBAR_TOGGLE',
  SIDEBAR_OPEN: 'SIDEBAR_OPEN',
  SIDEBAR_CLOSE: 'SIDEBAR_CLOSE',
  USER_DROPDOWN_TOGGLE: 'USER_DROPDOWN_TOGGLE',
  SHOW_BANNER: 'SHOW_BANNER ',
  HIDE_BANNER: 'HIDE_BANNER',
};

export default _CONSTATNTS;
