const fields = {
  names: [
    'proc_date',
    'shift',
    'plant_Id',
    'society_id',
    'milk_catagory',
    'milk_type',
    'cans',
    'weight_kg',
    'fat',
    'snf',
    'amount',
    'note',
  ],
  proc_date: {
    default: null,
    disabled: false,
    readonly: false,
  },
  shift: {
    default: null,
    disabled: false,
    readonly: false,
  },
  plant_id: {
    default: null,
    disabled: false,
    readonly: false,
  },
  society_id: {
    default: null,
    disabled: false,
    readonly: false,
  },
  milk_catagory: {
    default: null,
    disabled: false,
    readonly: false,
  },
  milk_type: {
    default: null,
    disabled: false,
    readonly: false,
  },
  cans: {
    default: '',
    disabled: false,
    readonly: false,
  },
  weight_kg: {
    default: '',
    disabled: false,
    readonly: false,
  },
  fat: {
    default: '',
    disabled: false,
    readonly: false,
  },
  snf: {
    default: '',
    disabled: false,
    readonly: false,
  },
  amount: {
    default: '',
    disabled: false,
    readonly: false,
  },
  note: {
    default: '',
    disabled: false,
    readonly: false,
  },
};

export default fields;
