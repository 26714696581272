import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Box, Flex, Text, Badge, Button } from '@chakra-ui/react';
import { FaSync } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import moment from 'helpers/moment';

import { PAGINATION_OPTIONS } from 'configs/paginationOptions';
import milktransactionService from 'services/milktransactionService';
import columns from './columns';

import TableComp from 'components/tables/TableComp';
import Filters from './Filters';

const allowAdd = ['superadmin', 'admin', 'plantincharge', 'operator'];

const IndexPage = () => {
  const defaultSortBy = [
    {
      id: 'proc_date',
      desc: true,
    },
    {
      id: 'shift',
      desc: true,
    },
  ];

  const tblColumns = useMemo(() => columns, []);
  const fiscalyearObj = useSelector(state => state.fiscalyearObj);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [filters, setFilters] = useState({});
  const fetchIdRef = useRef(0);
  const [fiscal_year, setFiscalyear] = useState(null);

  useEffect(() => {
    setFiscalyear(fiscalyearObj?.current?.fiscal_year);

    const newFilters = { ...filters };
    newFilters.fiscal_year = fiscalyearObj?.current?.fiscal_year;
    setFilters(newFilters);
    // eslint-disable-next-line
  }, [fiscalyearObj]);

  useEffect(() => {
    if (filters?.fiscal_year) {
      setFiscalyear(filters?.fiscal_year);
    }
    // eslint-disable-next-line
  }, [filters]);

  const fetchData = useCallback(
    async ({
      pageSize = PAGINATION_OPTIONS.limit,
      pageIndex = 1,
      sortBy = defaultSortBy,
      filters = {},
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      if (fetchId === fetchIdRef.current && fiscal_year) {
        const query = {
          page: pageIndex + 1,
          limit: pageSize,
          filters: filters,
        };

        if (sortBy.length) {
          query.sortby = sortBy[0].id;
          query.sortorder = sortBy[0].desc ? 'desc' : 'asc';
        }

        const resData = await milktransactionService.getAll(query);
        if (resData) {
          setData(resData?.data);
        }
        setPageCount(resData?.meta?.totalPages);
        setResultCount(resData?.meta?.resultCount);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [fiscal_year]
  );

  const onFilterChange = (filters = {}) => {
    setFilters(filters);
  };

  const refreshData = async () => {
    await fetchData({
      pageSize: PAGINATION_OPTIONS.limit,
      pageIndex: 0,
      sortBy: defaultSortBy,
      filters: filters,
    });
  };

  const TableHeader = (
    <Flex>
      <Text fontSize={['xs', 'lg']} mr={[4, 4]}>
        MILK TRANSACTIONS
        <Badge
          variant="subtle"
          fontSize={['xs', 'lg']}
          colorScheme="green"
          py="1"
          m={[2, 2]}
          mt={[0, 0]}
        >
          {fiscal_year}
        </Badge>
        <Button
          type="button"
          variant="outline"
          colorScheme="orange"
          size="sm"
          onClick={refreshData}
        >
          <FaSync />
        </Button>
      </Text>
    </Flex>
  );

  const handleOnExport = data => {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const newD = { ...d };
      if (d.proc_date) {
        newD.proc_date = moment(d.proc_date).format(moment.defaultDateFormat);
      }
      if (d.created_by) {
        newD.created_by = d.created_by.username;
      }
      if (d.updated_by) {
        newD.updated_by = d.updated_by.username;
      }
      if (d.approved_by) {
        newD.approved_by = d.approved_by.username;
      }
      newData.push(newD);
    }
    return newData;
  };

  return (
    <Box display={{ md: 'flex' }} w="full" h="full" mb="4">
      <TableComp
        columns={tblColumns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        resultCount={resultCount}
        defaultSortBy={defaultSortBy}
        //
        tblHeader={TableHeader}
        showColumnHide={true}
        isExpandable={true}
        addLink="/milktransactions/add"
        allowAdd={allowAdd}
        enableExport={true}
        exportFilename={`MilkTransactions`}
        onExport={handleOnExport}
        filters={filters}
        filterForm={
          <Filters
            onFilter={onFilterChange}
            defaultFilters={filters}
            setFilters={setFilters}
          />
        }
      />
    </Box>
  );
};

// Exports

const MilkTransactions = {
  routeProps: {
    path: '/milktransactions',
    component: IndexPage,
    exact: true,
  },
  name: 'Milk Transactions',
  title: 'Milk Transactions',
};

export default MilkTransactions;
