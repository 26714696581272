import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import materialService from 'services/materialService';
import materialCatagoryService from 'services/materialCatagoryService';
import { TAX_TYPE_OPTIONS } from 'configs/taxTypeOptions';
import { TAX_RATE_OPTIONS } from 'configs/taxRateOptions';
import editSchema from './editSchema';
import fields from 'modules/FiscalYears/fields';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';
import SelectField from 'components/form/SelectField';
import SwitchField from 'components/form/SwitchField';

const EditPage = () => {
  const FormTitle = 'EDIT MATERIAL';
  const IndexPageTitle = 'MATERIALS';
  const IndexPagePath = '/materials';

  const defaultValues = {
    code: fields?.code?.default,
    name: fields?.name?.default,
    description: fields?.description?.default,
    catagory: fields?.catagory?.default,
    unit_in: fields?.unit_in?.default,
    unit_out: fields?.unit_out?.default,
    conversion: fields?.conversion?.default,
    consuption_price: fields?.consuption_price?.default,
    sales_price: fields?.sales_price?.default,
    tax_type: fields?.tax_type?.default,
    hsn: fields?.hsn?.default,
    sgst: fields?.sgst?.default,
    cgst: fields?.cgst?.default,
    igst: fields?.igst?.default,
    tradable: fields?.tradable?.default,
    consumable: fields?.consumable?.default,
    is_active: fields?.is_active?.default,
    is_discontinued: fields?.is_discontinued?.default,
  };

  const apiError = useSelector(state => state.error);
  const [data, setData] = useState(null);
  const [catagories, setCatagories] = useState([]);

  const location = useLocation();

  const fetchIdRef = useRef(0);
  const fetchIdCatagoryRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  useEffect(() => {
    setData(location?.state);
  }, [location]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(editSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    control,
    watch,
    setValue,
  } = useForm(formOptions);

  const watchCatagory = watch('catagory', '');
  const watchTaxType = watch('tax_type', '');
  const watchSgst = watch('sgst', '');
  const watchCgst = watch('cgst', '');
  const watchIgst = watch('igst', '');
  const watchDiscontinued = watch('is_discontinued', false);
  const watchTradable = watch('tradable', false);
  const watchConsumable = watch('consumable', false);
  const watchStatus = watch('is_active', true);

  const fetchCatagories = async () => {
    const fetchIdCatagory = ++fetchIdCatagoryRef.current;

    if (fetchIdCatagory === fetchIdCatagoryRef.current) {
      const query = {};
      query.pagination = false;
      query.sortby = 'name';
      query.fields = '_id,name';
      const responseData = await materialCatagoryService.getAll(query);

      if (responseData) {
        setCatagories(responseData?.data);
      }
    }
  };

  useEffect(() => {
    fetchCatagories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        if (key === 'catagory') {
          value = _.find(catagories, { name: data[key] });
          setValue(key, value);
        } else if (key === 'tax_type') {
          value = _.find(TAX_TYPE_OPTIONS, { value: data[key] });
          setValue(key, value);
        } else if (key === 'sgst') {
          value = _.find(TAX_RATE_OPTIONS.sgst, { value: data[key] });
          setValue(key, value);
        } else if (key === 'cgst') {
          value = _.find(TAX_RATE_OPTIONS.cgst, { value: data[key] });
          setValue(key, value);
        } else if (key === 'igst') {
          value = _.find(TAX_RATE_OPTIONS.igst, { value: data[key] });
          setValue(key, value);
        } else {
          setValue(key, data[key]);
        }
      }
    }
    // eslint-disable-next-line
  }, [data, catagories]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        name: formData?.name,
        description: formData?.description,
        catagory: formData?.catagory?.name,
        conversion: formData?.conversion,
        consuption_price: formData?.consuption_price,
        sales_price: formData?.sales_price,
        tax_type: formData?.tax_type?.value,
        hsn: formData?.hsn,
        sgst: formData?.sgst?.value,
        cgst: formData?.cgst?.value,
        igst: formData?.igst?.value,
        tradable: formData?.tradable,
        consumable: formData?.consumable,
        is_active: formData?.is_active,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (value === null || value === '' || value === undefined) {
          delete formDataSend[key];
        }
      }

      const responseData = await materialService.update(
        data?._id,
        formDataSend
      );

      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  const handleCatagoryChange = (selected, action) => {
    if (action === 'clear') {
      setValue('catagory', null);
      return;
    }
    setValue('catagory', selected);
  };

  const handleTaxTypeChange = (selected, action) => {
    if (selected?.value === 'TAX-FREE') {
      handleSgstChange(null, 'clear');
      handleCgstChange(null, 'clear');
      handleIgstChange(null, 'clear');
    } else if (selected?.value === 'IGST') {
      handleSgstChange(null, 'clear');
      handleCgstChange(null, 'clear');
    } else {
      handleCgstChange(null, 'clear');
      handleIgstChange(null, 'clear');
    }
    if (action === 'clear') {
      setValue('tax_type', null);
      return;
    }
    setValue('tax_type', selected);
  };

  const handleSgstChange = (selected, action) => {
    if (action === 'clear') {
      setValue('sgst', null);
      return;
    }
    setValue('sgst', selected);
  };

  const handleCgstChange = (selected, action) => {
    if (action === 'clear') {
      setValue('cgst', null);
      return;
    }
    setValue('cgst', selected);
  };

  const handleIgstChange = (selected, action) => {
    if (action === 'clear') {
      setValue('igst', null);
      return;
    }
    setValue('igst', selected);
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="code"
                    id="code"
                    label="Code"
                    placeholder="Code"
                    error={errors.code}
                    register={register('code')}
                    disabled={true}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="name"
                    id="name"
                    label="Material Name"
                    placeholder="Material Name"
                    error={errors.name}
                    register={register('name')}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="catagory"
                    label="Catagory"
                    labelKey="name"
                    valueKey="name"
                    options={catagories}
                    placeholder="Select Catagory"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option?.name}
                    handleChange={handleCatagoryChange}
                    error={errors.catagory}
                    control={control}
                    defaultValue={watchCatagory}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="unit_in"
                    id="unit_in"
                    label="Unit (IN)"
                    placeholder="Unit (IN)"
                    error={errors.unit_in}
                    register={register('unit_in')}
                    disabled={true}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="unit_out"
                    id="unit_out"
                    label="Unit (OUT)"
                    placeholder="Unit (OUT)"
                    error={errors.unit_out}
                    register={register('unit_out')}
                    disabled={true}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    setp="0.01"
                    name="conversion"
                    id="conversion"
                    label="Conversion Factor"
                    placeholder="Conversion Factor"
                    error={errors.conversion}
                    register={register('conversion')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    setp="0.01"
                    name="consuption_price"
                    id="consuption_price"
                    label="Consumption Price"
                    placeholder="Consumption Price"
                    error={errors.consuption_price}
                    register={register('consuption_price')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="number"
                    setp="0.01"
                    name="sales_price"
                    id="sales_price"
                    label="Sales Price"
                    placeholder="Sales Price"
                    error={errors.sales_price}
                    register={register('sales_price')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="hsn"
                    id="hsn"
                    label="HSN"
                    placeholder="HSN"
                    error={errors.hsn}
                    register={register('hsn')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="tax_type"
                    label="Tax Type"
                    labelKey="name"
                    valueKey="value"
                    options={TAX_TYPE_OPTIONS}
                    placeholder="Select Tax Type"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleTaxTypeChange}
                    error={errors.tax_type}
                    control={control}
                    defaultValue={watchTaxType}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="sgst"
                    label="SGST"
                    labelKey="name"
                    valueKey="value"
                    options={TAX_RATE_OPTIONS?.sgst || []}
                    placeholder="Select SGST"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleSgstChange}
                    error={errors.sgst}
                    control={control}
                    defaultValue={watchSgst}
                    disabled={
                      !watchTaxType ||
                      watchTaxType?.value === 'TAX-FREE' ||
                      watchTaxType?.value === 'IGST'
                    }
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="cgst"
                    label="CGST"
                    labelKey="name"
                    valueKey="value"
                    options={TAX_RATE_OPTIONS?.cgst || []}
                    placeholder="Select CGST"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleCgstChange}
                    error={errors.cgst}
                    control={control}
                    defaultValue={watchCgst}
                    disabled={
                      !watchTaxType ||
                      watchTaxType?.value === 'TAX-FREE' ||
                      watchTaxType?.value === 'IGST'
                    }
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SelectField
                    isMulti={false}
                    isClearable={true}
                    name="igst"
                    label="IGST"
                    labelKey="name"
                    valueKey="value"
                    options={TAX_RATE_OPTIONS?.igst || []}
                    placeholder="Select IGST"
                    closeMenuOnSelect={false}
                    size="sm"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option?.value}
                    handleChange={handleIgstChange}
                    error={errors.sgst}
                    control={control}
                    defaultValue={watchIgst}
                    disabled={
                      !watchTaxType ||
                      watchTaxType?.value === 'TAX-FREE' ||
                      watchTaxType?.value !== 'IGST'
                    }
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="is_discontinued"
                    id="is_discontinued"
                    label="Discontinued"
                    error={errors.is_discontinued}
                    register={register('is_discontinued')}
                    defaultChecked={watchDiscontinued}
                    disabled={true}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="tradable"
                    id="tradable"
                    label="Tradable"
                    error={errors.tradable}
                    register={register('tradable')}
                    defaultChecked={watchTradable}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="consumable"
                    id="consumable"
                    label="Consumable"
                    error={errors.consumable}
                    register={register('consumable')}
                    defaultChecked={watchConsumable}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="is_active"
                    id="is_active"
                    label="Status"
                    error={errors.is_active}
                    register={register('is_active')}
                    defaultChecked={watchStatus}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}></Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="description"
                    id="description"
                    label="Description"
                    placeholder="Write Your Description"
                    error={errors.description}
                    register={register('description')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const MaterialEdit = {
  routeProps: {
    path: '/materials/edit/:_id',
    component: EditPage,
    exact: true,
  },
  name: 'Edit Material',
  title: 'Edit Material',
};

export default MaterialEdit;
