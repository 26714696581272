import React from 'react';
import { useColorModeValue, Text, Flex, Badge } from '@chakra-ui/react';

const RenderSocietyOption = ({
  society,
  showCity,
  showDistrict,
  showPlant,
  showSupervisor,
}) => {
  // const bgColor = useColorModeValue('green.200', 'green.700');
  const labelTextColor = useColorModeValue('blue.800', 'blue.300');
  const mainTextColor = useColorModeValue('orange.700', 'orange.300');
  const secondaryTextColor = useColorModeValue('gray.800', 'gray.300');

  return (
    <Flex direction={'column'} px={1} py={2}>
      <Flex direction={'row'} alignItems="center">
        <Badge
          mr="2"
          colorScheme="orange"
          fontSize="md"
          fontWeight="bold"
          p={2}
        >
          {String(society.society_code).padStart(5, '0')}
        </Badge>

        <Text fontSize="md" fontWeight={'bolder'} color={mainTextColor}>
          {society.society_name}
        </Text>
      </Flex>
      <Flex direction={'row'} mt={2} justifyContent="space-start">
        {showCity && society.city && (
          <Flex direction={'row'} mr={2}>
            <Text
              fontSize="xs"
              fontWeight={'bolder'}
              color={labelTextColor}
              mr={2}
            >
              CITY :
            </Text>
            <Text
              fontSize="xs"
              fontWeight={'bolder'}
              color={secondaryTextColor}
            >
              {society.city}
            </Text>
          </Flex>
        )}
        {showDistrict && society.district && (
          <Flex direction={'row'} mr={2}>
            <Text
              fontSize="xs"
              fontWeight={'bolder'}
              color={labelTextColor}
              mr={2}
            >
              DISTRICT :
            </Text>
            <Text
              fontSize="xs"
              fontWeight={'bolder'}
              color={secondaryTextColor}
            >
              {society.district}
            </Text>
          </Flex>
        )}
        {showPlant && society.plant_id && (
          <Flex direction={'row'} mr={2}>
            <Text
              fontSize="xs"
              fontWeight={'bolder'}
              color={labelTextColor}
              mr={2}
            >
              PLANT :
            </Text>
            <Text
              fontSize="xs"
              fontWeight={'bolder'}
              color={secondaryTextColor}
            >
              {society.plant_id?.plant_code} - {society.plant_id?.plant_name}
            </Text>
          </Flex>
        )}
      </Flex>
      {showSupervisor && society.supervisor && (
        <Flex direction={'row'} mt={1}>
          <Text
            fontSize="xs"
            fontWeight={'bolder'}
            color={labelTextColor}
            mr={2}
          >
            SUPERVISOR :
          </Text>
          <Text fontSize="xs" fontWeight={'bolder'} color={secondaryTextColor}>
            {society.supervisor?.username}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default RenderSocietyOption;
