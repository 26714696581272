import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Flex,
  VStack,
  StackDivider,
  Box,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import vendorService from 'services/vendorService';
import editSchema from './editSchema';
import fields from 'modules/FiscalYears/fields';
import InputField from 'components/form/InputField';
import TextAreaField from 'components/form/TextAreaField';
import SwitchField from 'components/form/SwitchField';

const EditPage = () => {
  const FormTitle = 'EDIT VENDOR';
  const IndexPageTitle = 'VENDORS';
  const IndexPagePath = '/vendors';

  const defaultValues = {
    code: fields?.code?.default,
    name: fields?.name?.default,
    address: fields?.address?.default,
    catagory: fields?.catagory?.default,
    city: fields?.city?.default,
    state: fields?.state?.default,
    pan: fields?.pan?.default,
    pan_name: fields?.pan_name?.default,
    gst: fields?.gst?.default,
    gst_name: fields?.gst_name?.default,
    mobile: fields?.mobile?.default,
    email: fields?.email?.default,
    is_active: fields?.is_active?.default,
  };

  const apiError = useSelector(state => state.error);
  const [data, setData] = useState(null);

  const location = useLocation();

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  useEffect(() => {
    setData(location?.state);
  }, [location]);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(editSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    watch,
    setValue,
  } = useForm(formOptions);

  const watchStatus = watch('is_active', true);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      for (let [key, value] of Object.entries(data)) {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = async formData => {
    const fetchId = ++fetchIdRef.current;

    if (fetchId === fetchIdRef.current) {
      const formDataSend = {
        name: formData?.name,
        address: formData?.address,
        city: formData?.city,
        state: formData?.state,
        pan: formData?.pan?.name,
        pan_name: formData?.pan_name,
        gst: formData?.gst?.name,
        gst_name: formData?.gst_name,
        mobile: formData?.mobile,
        email: formData?.email,
        is_active: formData?.is_active,
      };

      for (const [key, value] of Object.entries(formDataSend)) {
        if (value === null || value === '' || value === undefined) {
          delete formDataSend[key];
        }
      }
      const responseData = await vendorService.update(data?._id, formDataSend);

      if (responseData) {
        toast.success(`${responseData.message}`);
      }
    }
  };

  return (
    <VStack
      divider={<StackDivider />}
      spacing={4}
      align="stretch"
      h="full"
      w="full"
    >
      <Box h="20px" mb={[4, 2]}>
        <Flex direction={['column', 'row']} justifyContent="space-between">
          <Text fontSize="xl" color="orange.500" fontWeight="bold">
            {FormTitle}
          </Text>
          <Button
            as={Link}
            to={IndexPagePath}
            type="button"
            colorScheme="orange"
            size="sm"
            px="8"
            mt={[2, 0]}
          >
            {IndexPageTitle}
          </Button>
        </Flex>
      </Box>

      {/* Form */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={['column', 'row']} spacing="24px">
            <VStack spacing={6} align="stretch" h="full" w="full">
              {/* Form Contents */}
              <Flex direction={['column', 'row']}>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="code"
                    id="code"
                    label="Code"
                    placeholder="Code"
                    error={errors.code}
                    register={register('code')}
                  />
                </Box>
                <Box w={['full', 5 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="name"
                    id="name"
                    label="Vendor Name"
                    placeholder="Vendor Name"
                    error={errors.name}
                    register={register('name')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="city"
                    id="city"
                    label="City"
                    placeholder="City"
                    error={errors.city}
                    register={register('city')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="state"
                    id="state"
                    label="State"
                    placeholder="State"
                    error={errors.state}
                    register={register('state')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="pan"
                    id="pan"
                    label="PAN"
                    placeholder="PAN"
                    error={errors.pan}
                    register={register('pan')}
                  />
                </Box>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gst"
                    id="gst"
                    label="GST"
                    placeholder="GST"
                    error={errors.gst}
                    register={register('gst')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'half']} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="pan_name"
                    id="pan_name"
                    label="Name as in PAN"
                    placeholder="Name as in PAN"
                    error={errors.pan_name}
                    register={register('pan_name')}
                  />
                </Box>
                <Box w={['full', 'half']} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="gst_name"
                    id="gst_name"
                    label="Name as in GST"
                    placeholder="Name as in GST"
                    error={errors.gst_name}
                    register={register('gst_name')}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 2 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="mobile"
                    id="mobile"
                    label="Mobile"
                    placeholder="Mobile"
                    error={errors.mobile}
                    register={register('mobile')}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <InputField
                    type="text"
                    name="email"
                    id="email"
                    label="E-Mail"
                    placeholder="E-Mail"
                    error={errors.email}
                    register={register('email')}
                  />
                </Box>
                <Box w={['full', 3 / 8]} mr={[0, 4]} mb={[1, 0]}>
                  <SwitchField
                    name="is_active"
                    id="is_active"
                    label="Status"
                    error={errors.is_active}
                    register={register('is_active')}
                    defaultChecked={watchStatus}
                  />
                </Box>
              </Flex>
              <Flex direction={['column', 'row']}>
                <Box w={['full', 'full']} mr={[0, 4]} mb={[1, 0]}>
                  <TextAreaField
                    name="address"
                    id="address"
                    label="Address"
                    placeholder="Write Your Address"
                    error={errors.address}
                    register={register('address')}
                  />
                </Box>
              </Flex>
              {/* Form Contents */}

              <Flex
                direction={['column', 'row']}
                w="full"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  colorScheme="orange"
                  size="sm"
                  mr={[0, 4]}
                  mb={[4, 0]}
                  px="12"
                  isLoading={isSubmitting}
                  disabled={!isValid || !isDirty}
                >
                  SAVE
                </Button>
              </Flex>
            </VStack>
          </Stack>
        </form>
      </Box>
      {/* Form End */}
    </VStack>
  );
};

// Exports

const VendorEdit = {
  routeProps: {
    path: '/vendors/edit/:_id',
    component: EditPage,
    exact: true,
  },
  name: 'Edit Vendor',
  title: 'Edit Vendor',
};

export default VendorEdit;
