import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  name: yup
    .string()
    .required(messages.name.required)
    .trim()
    .min(2)
    .max(30)
    .label('Unit'),
});

export default addSchema;
