import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  tr_date: yup
    .string(messages.tr_date.invalid)
    .trim()
    .required(messages.tr_date.required)
    .nullable(true)
    .label('Date'),
  referance: yup
    .string()
    .trim()
    .max(10)
    .required(messages.referance.required)
    .label('Referance'),
  plant_from_id: yup
    .object()
    .required(messages.plant_id.required)
    .label('Plant From'),
  plant_to_id: yup
    .object()
    .required(messages.plant_id.required)
    .label('Plant To'),
  materials: yup.array().min(1, messages.materials.required),
});

export default addSchema;
