import React from 'react';
import DocHeader from './common/DocHeader';
import DocFooter from './common/DocFooter';
import styles from './common/styles';
import moment from 'helpers/moment';
import { getFullname } from 'helpers/genHelpers';

const DOC_TYPE = 'CONSUMPTION RETURN';

export default function ConsumptionRetDoc({ data }) {
  const original_order = data?.original_order;
  return (
    <div style={styles.document}>
      <div style={styles.page}>
        <DocHeader docTYpe={DOC_TYPE} />
        <div style={styles.partyDetails}>
          <div style={styles.docDetailsLeft}>
            <div style={styles.sectionHeader}>Consumer Details :</div>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Username : </span>
              <span>{data?.consumer_id?.username}</span>
            </p>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Name : </span>
              <span>
                {getFullname(
                  data?.consumer_id?.firstname,
                  data?.consumer_id?.middlename,
                  data?.consumer_id?.lastname
                )}
              </span>
            </p>
          </div>
          {original_order && (
            <div style={styles.detailsRight}>
              <div style={styles.sectionHeader}>Original Doc :</div>
              <p style={styles.detailsP}>
                <span style={styles.detailsHeaders}>Referance No : </span>
                <span>
                  {original_order?.referance ? original_order?.referance : '-'}
                </span>
              </p>
              <p style={styles.detailsP}>
                <span style={styles.detailsHeaders}>Date : </span>
                <span>
                  {original_order?.tr_date
                    ? moment(data?.tr_date).format(moment.defaultDateFormat)
                    : '-'}
                </span>
              </p>
              <p style={styles.detailsP}>
                <span style={styles.detailsHeaders}>Posted By : </span>
                {original_order?.posted_by
                  ? original_order?.posted_by?.username
                  : '-'}
              </p>
            </div>
          )}
        </div>
        <div style={styles.docDetails}>
          <div style={styles.docDetailsLeft}>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Document Type : </span>
              <span>{data?.tr_type}</span>
            </p>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Referance No : </span>
              <span>{data?.referance ? data?.referance : '-'}</span>
            </p>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Date : </span>
              <span>
                {data?.tr_date
                  ? moment(data?.tr_date).format(moment.defaultDateFormat)
                  : '-'}
              </span>
            </p>
          </div>
          <div style={styles.docDetailsRight}>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Plant Code : </span>
              <span>{data?.plant_id ? data?.plant_id?.plant_code : '-'}</span>
            </p>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Plant Name : </span>
              <span>{data?.plant_id ? data?.plant_id?.plant_name : '-'}</span>
            </p>
            <p style={styles.detailsP}>
              <span style={styles.detailsHeaders}>Posted By : </span>
              <span>{data?.posted_by ? data?.posted_by?.username : '-'}</span>
            </p>
          </div>
        </div>
        <div style={styles.items}>
          <table style={styles.table}>
            <thead style={styles.thead}>
              <tr style={styles.tr}>
                <th style={styles.thRight}>#</th>
                <th style={styles.thLeft}>CODE</th>
                <th style={styles.thLeft}>MATERIAL</th>
                <th style={styles.thLeft}>CATAGORY</th>
                <th style={styles.thRight}>QTY</th>
              </tr>
            </thead>
            <tbody style={styles.none}>
              {data?.materialtransactions?.map((item, i) => (
                <tr style={styles.tr} key={i}>
                  <td style={styles.tdNumber}>{i + 1}</td>
                  <td style={styles.tdString}>{item?.material?.code}</td>
                  <td style={styles.tdString}>{item?.material?.name}</td>
                  <td style={styles.tdString}>{item?.material?.catagory}</td>
                  <td style={styles.tdNumber}>{item?.qty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={styles.note}>
          <p style={styles.detailsP}>NOTE :</p>
          <p>{data?.note && data?.note}</p>
        </div>
        <DocFooter />
      </div>
    </div>
  );
}
