import * as yup from 'yup';
import messages from './messages';

const addSchema = yup.object().shape({
  society_code: yup.string().required(messages.society_code.required).trim(),
  society_name: yup
    .string()
    .required(messages.society_name.required)
    .trim()
    .max(100, messages.society_name.max),
  gu_society_name: yup
    .string()
    .required(messages.gu_society_name.required)
    .trim()
    .max(100, messages.gu_society_name.max),
  gu_city: yup
    .string()
    .required(messages.city.required)
    .max(100, messages.gu_city.max),
  is_active: yup.bool(messages.is_active.invalid),
});

export default addSchema;
