import React, { useState, useEffect } from 'react';
import { Link as RLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Flex, Box, Button, Text, Input } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';

import profileService from 'services/profileService';
import profileActions from 'actions/api/profileActions';

import InputField from 'components/form/InputField';
import ProfileImageCard from './ProfileImageCard';
import schema from './schema';
import fields from './fields';

const IndexPage = () => {
  const dispatch = useDispatch();

  const apiError = useSelector(state => state.error);
  const user = useSelector(state => state.auth?.user);

  const [photo, setPhoto] = useState(null);
  const [photoname, setPhotoname] = useState(user?.profile_image_url);

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(schema),
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    // reset,
    setValue,
  } = useForm(formOptions);

  const onSubmit = async formData => {
    const formWithFileData = new FormData();

    if (photo && photoname) {
      formWithFileData.append('profile_picture', photo);
    }

    for (const [key, value] of Object.entries(formData)) {
      if (value && value !== '') {
        formWithFileData.append(key, value);
      }
    }

    const resData = await profileService.updateOwnProfile(formWithFileData);
    if (resData) {
      toast.success(`${resData.message}`);
      setPhoto(null);
      dispatch(profileActions.getProfile());
    }
  };

  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      let reader = new FileReader();

      reader.onloadend = () => {
        setPhoto(file);
        setPhotoname(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const setAllValues = () => {
    for (let i = 0; i < fields?.names.length; i++) {
      const field = fields?.names[i];
      setValue(field, user[field]);
    }
  };

  useEffect(() => {
    setAllValues();
    // eslint-disable-next-line
  }, [user]);

  const renderVerified = field => {
    let lable, verifyName, path;
    if (field === 'email') {
      lable = 'E-Mail';
      verifyName = 'email_verified';
      path = '/change-email';
    } else if (field === 'mobile') {
      lable = 'Mobile';
      verifyName = 'mobile_verified';
      path = '/change-mobile';
    }

    return (
      <Flex alignItems="center">
        <Text>{lable}</Text>
        <Text ml="2" color={user[verifyName] ? 'green' : 'red'} fontSize="xs">
          {user[verifyName] ? 'Verified' : 'Unverified'}
          {user[verifyName] ? (
            <CheckCircleIcon ml="1" />
          ) : (
            <WarningIcon ml="1" />
          )}
          <RLink to={path}>
            <Button variant="outline" ml="2" size="xs" type="button">
              CHANGE
            </Button>
          </RLink>
        </Text>
      </Flex>
    );
  };

  return (
    <Flex direction={['column', 'row']} h="full" w="full" mb={['10', '0']}>
      <Box>
        <ProfileImageCard profile={user} photoname={photoname} />
        <RLink to="change-password">
          <Button colorScheme="orange" size="sm" mt="4" type="button" w="full">
            CHANGE PASSWORD
          </Button>
        </RLink>
      </Box>
      <Box w="full" p="4">
        <Box boxShadow={'2xl'} rounded={'md'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={2} display={{ md: 'flex' }}>
              <InputField
                type="text"
                name="firstname"
                id="firstname"
                label="Firstname"
                placeholder="Firstname"
                error={errors.firstname}
                register={register('firstname')}
                disabled={fields.firstname.disabled}
                readOnly={fields.firstname.readonly}
              />
              <InputField
                type="text"
                name="middlename"
                id="middlename"
                label="Middlename"
                placeholder="Middlename"
                error={errors.middlename}
                register={register('middlename')}
                disabled={fields.middlename.disabled}
                readOnly={fields.middlename.readonly}
              />
              <InputField
                type="text"
                name="lastname"
                id="lastname"
                label="Lastname"
                placeholder="Lastname"
                error={errors.lastname}
                register={register('lastname')}
                disabled={fields.lastname.disabled}
                readOnly={fields.lastname.readonly}
              />
            </Box>
            <Box p={2} display={{ md: 'flex' }} w="full">
              <InputField
                type="mobile"
                name="mobile"
                id="mobile"
                label={renderVerified('mobile')}
                placeholder="Mobile"
                error={errors.mobile}
                register={register('mobile')}
                disabled={fields.mobile.disabled}
                readOnly={fields.mobile.readonly}
              />
            </Box>
            <Box p={2} display={{ md: 'flex' }} w="full">
              <InputField
                type="email"
                name="email"
                id="email"
                label={renderVerified('email')}
                placeholder="E-Mail"
                error={errors.email}
                register={register('email')}
                disabled={fields.email.disabled}
                readOnly={fields.email.readonly}
              />
            </Box>
            <Box p={2} display={{ md: 'flex' }} w="full">
              <Input
                type="file"
                width="full"
                name="profile_picture"
                onChange={handleImageUpload}
              />
            </Box>
            <Box p={2} display={{ md: 'flex' }} w="full">
              <Button
                colorScheme="orange"
                size="sm"
                w={['full']}
                type="submit"
                mt={4}
                isLoading={isSubmitting}
                disabled={!isValid || !isDirty}
              >
                SAVE
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

const Profile = {
  routeProps: {
    path: '/profile',
    component: IndexPage,
    exact: true,
  },
  name: 'Profile',
  title: 'Profile',
};

export default Profile;
