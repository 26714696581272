const fields = {
  names: [
    'plant_code',
    'plant_name',
    'is_active',
    'proc_type',
    'city',
    'gu_plant_name',
    'gu_city',
  ],
  plant_code: {
    default: '',
    disabled: true,
    readonly: false,
  },
  plant_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  is_active: {
    default: false,
    disabled: false,
    readonly: false,
  },
  proc_type: {
    default: null,
    disabled: false,
    readonly: false,
  },
  city: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gu_plant_name: {
    default: '',
    disabled: false,
    readonly: false,
  },
  gu_city: {
    default: '',
    disabled: false,
    readonly: false,
  },
};

export default fields;
