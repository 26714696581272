import React from 'react';
import TableActionColumn from 'components/tables/TableActionColumn';

import JobScheduleStatus from './JobScheduleStatus';

const hasCustom = ['superadmin'];

export default function ActionColumn({ row }) {
  return (
    <TableActionColumn
      row={row}
      hasCustom={hasCustom}
      customComponent={
        <JobScheduleStatus
          _id={row._id}
          status={row.disabled}
          type={row.type}
        />
      }
    />
  );
}
