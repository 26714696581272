import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'helpers/moment';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import filterSchema from './filterSchema';

import SelectField from 'components/form/SelectField';
import DateField from 'components/form/DateField';

export default function Filters({ onFilter, defaultFilters, setFilters }) {
  const apiError = useSelector(state => state.error);
  const plants = useSelector(state => state.plants);

  useEffect(() => {
    if (apiError.errors) {
      for (const [key, value] of Object.entries(apiError.errors)) {
        setError(key, {
          type: 'manual',
          message: value,
        });
      }
    }
    // eslint-disable-next-line
  }, [apiError]);

  const defaultValues = {
    proc_date: null,
    plant: '',
  };

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(filterSchema),
    defaultValues: defaultValues,
  };

  const {
    handleSubmit, // register,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    setValue,
    reset,
    control,
    register,
    watch,
  } = useForm(formOptions);

  const watchPlant = watch('plant', '');

  useEffect(() => {
    for (let [key, value] of Object.entries(defaultFilters)) {
      if (key === 'plant_id') {
        value = _.find(plants, { _id: value });
        setValue('plant', value);
      } else if (key === 'proc_date') {
        value = moment(value).format(moment.defaultDateFormat);
        setValue('proc_date', value);
      } else {
        setValue(key, value);
      }
    }
    // eslint-disable-next-line
  }, [plants]);

  const handleClear = () => {
    handlePlantChange(null, 'clear');
    if (!_.isEmpty(defaultFilters)) {
      reset(defaultValues);
      setFilters({});
    }
  };

  const onSubmit = formData => {
    if (formData?.plant) {
      formData.plant_id = formData?.plant?._id;
      delete formData['plant'];
    }

    for (const key in formData) {
      if (formData[key] === '') {
        delete formData[key];
      }
    }

    if (!_.isEmpty(formData)) {
      onFilter(formData);
    } else {
      toast.warning(
        `No Filters Selected. Please select atleast one serach value`
      );
    }
  };

  const handlePlantChange = (selected, action) => {
    if (action === 'clear') {
      setValue('plant', null);
      return;
    }
    setValue('plant', selected);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" h="full" justifyContent="space-between">
        {/* Filter Fields Start */}

        <Box flex="1">
          <DateField
            name="proc_date"
            label="Procurement Date"
            placeholder="DD-MM-YYYY"
            error={errors.proc_date}
            register={register('proc_date')}
          />

          <SelectField
            isMulti={false}
            isClearable={true}
            name="plant"
            label="Plant"
            labelKey="plant_code"
            valueKey="_id"
            options={plants}
            placeholder="Select Plant"
            closeMenuOnSelect={false}
            size="sm"
            getOptionLabel={option =>
              `${option.plant_code} - ${option.plant_name}`
            }
            getOptionValue={option => option?._id}
            handleChange={handlePlantChange}
            error={errors.plant_id}
            control={control}
            defaultValue={watchPlant}
          />
        </Box>
        {/* Filter Fields End */}

        {/* Footer Buttons */}
        <Box my="4">
          <Flex alignContent="space-between">
            <Button
              type="submit"
              colorScheme="orange"
              size="sm"
              w="full"
              mr="2"
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              APPLY
            </Button>
            <Button
              type="button"
              colorScheme="yellow"
              size="sm"
              w="full"
              onClick={handleClear}
              ml="2"
            >
              CLEAR
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
