import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Center,
  Flex,
  Box,
  Heading,
  Button,
  useColorModeValue,
  Spinner,
  Select,
  FormControl,
  InputGroup,
  InputLeftAddon,
  Stack,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useSelector } from 'react-redux';
import SelectFieldSimple from 'components/form/SelectFieldSimple';

import dashboardMilkService from 'services/dashboardMilkService';
import { MonthOptions } from 'configs/MonthOptions';

function TotalMilkMonth() {
  const chartYAxisColor = useColorModeValue('#50586C', '#D8D8D8');
  const chartXAxisColor = useColorModeValue('#50586C', '#D8D8D8');
  const strokeDotColor = useColorModeValue('#383838', '#383838');
  const goodLineColor = useColorModeValue('#1B998B', '#85FFF1');
  const sourLineColor = useColorModeValue('#F72C3D', '#FFB7BD');

  const fiscalyears = useSelector(state => state.fiscalyears);
  const fiscalyearObj = useSelector(state => state.fiscalyearObj);
  const plants = useSelector(state => state.plants);

  const [fiscal_year, setFiscalyear] = useState(null);

  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [plantOptions, setPlantOptions] = useState([]);
  const [selectedMonth, setSelecteMonth] = useState(4);
  const [selectedPlant, setSelectedPlant] = useState('ALL');

  const [property, setProperty] = useState('weight');
  const [type, setType] = useState('total');

  const refreshData = async () => {
    setLoading(true);
    if (!fiscal_year || !selectedMonth || !selectedPlant) return;
    setData([]);
    const formData = {
      fiscal_year: fiscal_year?.fiscal_year,
      month: selectedMonth?.value,
      plant_code: selectedPlant,
    };
    // console.log(formData);
    const resData = await dashboardMilkService.getTotalMilkByMonth(formData);
    if (resData && resData?.data) {
      setData(resData?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [fiscal_year, selectedMonth, selectedPlant, property, type]);

  useEffect(() => {
    setResults(data?.results);
    // eslint-disable-next-line
  }, [data]);

  const handleFiscalYearChange = (selected, action) => {
    if (action === 'clear') {
      setFiscalyear(null);
      return;
    }
    setFiscalyear(selected);
  };

  const handleMonthChange = (selected, action) => {
    if (action === 'clear') {
      setSelecteMonth(null);
      return;
    }
    setSelecteMonth(selected);
  };

  useEffect(() => {
    if (!fiscal_year) {
      const fy = _.find(fiscalyears, {
        fiscal_year: fiscalyearObj?.current?.fiscal_year,
      });
      handleFiscalYearChange(fy, 'select-option');
      if (fiscalyearObj?.now) {
        const mnth = _.find(MonthOptions, {
          value: fiscalyearObj?.now?.month * 1,
        });
        handleMonthChange(mnth, 'select-option');
      }
    }
    // eslint-disable-next-line
  }, [fiscalyears, fiscalyearObj]);

  useEffect(() => {
    setPlantOptions([{ plant_code: 'ALL', plant_name: 'ALL' }, ...plants]);
    // eslint-disable-next-line
  }, [plants]);

  return (
    <Box
      h="full"
      w="full"
      p={2}
      shadow="2xl"
      borderWidth="1px"
      borderRadius="md"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      bg={useColorModeValue('gray.50', 'gray.700')}
      color={useColorModeValue('gray.900', 'gray.200')}
    >
      <Flex
        direction={'column'}
        justifyContent="space-between"
        alignItems="stretch"
        h="full"
        w="full"
      >
        <Box height="10%" px={4} mb="4">
          <Flex justifyContent={'space-between'} alignItems={'center'} mb="4">
            <Heading
              fontSize="md"
              color={useColorModeValue('orange.500', 'orange.200')}
            >
              {`MILK SUMMARY FOR MONTH `}
            </Heading>

            <Box mx="2" my="2">
              <SelectFieldSimple
                size="sm"
                id="fiscal_year"
                name="fiscal_year"
                // label="Year"
                options={fiscalyears}
                value={fiscal_year}
                placeholder="Select Year"
                getOptionLabel={option => `${option?.fiscal_year}`}
                getOptionValue={option => option?.fiscal_year}
                handleChange={handleFiscalYearChange}
              />
            </Box>

            <Box mx="2" my="2">
              <SelectFieldSimple
                size="sm"
                id="month"
                name="month"
                // label="Month"
                options={MonthOptions}
                value={selectedMonth}
                placeholder="Select Month"
                getOptionLabel={option => `${option?.name}`}
                getOptionValue={option => option?.value}
                handleChange={handleMonthChange}
              />
            </Box>

            <Box mx="2" my="2">
              <FormControl>
                <InputGroup size="sm">
                  <InputLeftAddon children="Plant" />
                  <Select
                    size="sm"
                    varient="filled"
                    colorScheme="orange"
                    focusBorderColor="orange.500"
                    onChange={e => setSelectedPlant(e.target.value)}
                    defaultValue={selectedPlant}
                  >
                    {plantOptions.map(plant => (
                      <option key={plant.plant_code} value={plant.plant_code}>
                        {`${plant.plant_code} - ${plant.plant_name}`}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </FormControl>
            </Box>

            <Button
              variant="ghost"
              isLoading={loading}
              loadingText="Refreshing..."
              onClick={refreshData}
              outline="none"
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'transperent',
                scale: '50',
                color: 'teal.500',
                size: 'xs',
              }}
            >
              <RepeatIcon w={8} h={8} color="green.500" />
            </Button>
          </Flex>
        </Box>

        <Box height="10%" mb="2" px="16">
          <Flex justifyContent={'start'} alignItems={'center'} mb="4">
            <RadioGroup onChange={setType} value={type} mr="24">
              <Stack direction="row">
                <Radio value="total" size="lg">
                  TOTAL
                </Radio>
                <Radio value="buff" size="lg">
                  BUFF
                </Radio>
                <Radio value="cow" size="lg">
                  COW
                </Radio>
              </Stack>
            </RadioGroup>

            <RadioGroup onChange={setProperty} value={property}>
              <Stack direction="row">
                <Radio value="weight" size="lg">
                  Weight
                </Radio>
                <Radio value="kgfat" size="lg">
                  KgFat
                </Radio>
                <Radio value="kgsnf" size="lg">
                  KgSnf
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </Box>

        {loading && (
          <Box h="200px" w="full">
            <Center>
              <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="orange.500"
                size="xl"
              />
            </Center>
          </Box>
        )}

        {!loading && (
          <ResponsiveContainer
            width="100%"
            height="100%"
            // minWidth="100%"
            // minHeight="300px"
            // aspect={undefined}
            debounce={1}
          >
            <LineChart
              width={500}
              height={300}
              data={results}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              cursor="pointer"
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis
                dataKey="day"
                label={{
                  value: 'DAY',
                  offset: 0,
                  position: 'insideBottomLeft',
                  fill: chartXAxisColor,
                }}
                tick={{ stroke: chartXAxisColor, strokeWidth: 1 }}
                type="number"
                domain={[1, 31]}
                tickCount={31}
              />
              <YAxis
                label={{
                  value: property.toUpperCase(),
                  angle: -90,
                  position: 'insideLeft',
                  fill: chartYAxisColor,
                }}
                type="number"
                domain={[
                  0,
                  dataMax => Math.round(dataMax / 10000) * 10000 + 10000,
                ]}
                interval={`preserveStartEnd`}
                tickCount={20}
                tick={{ stroke: chartYAxisColor, strokeWidth: 1 }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey={`good.${type}.${property}`}
                stroke={goodLineColor}
                dot={{ stroke: strokeDotColor, strokeWidth: 2 }}
                // dot={<CustomizedDot />}
                name={`GOOD ${type.toUpperCase()} ${property.toUpperCase()}`}
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey={`sour.${type}.${property}`}
                stroke={sourLineColor}
                // dot={<CustomizedDot />}
                dot={{ stroke: strokeDotColor, strokeWidth: 2 }}
                name={`SOUR ${type.toUpperCase()} ${property.toUpperCase()}`}
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Flex>
    </Box>
  );
}

export default TotalMilkMonth;
