export const TAX_RATE_OPTIONS = {
  sgst: [
    { name: '0 %', value: 0 },
    { name: '2.5 %', value: 2.5 },
    { name: '6 %', value: 6 },
    { name: '9 %', value: 9 },
    { name: '14 %', value: 14 },
  ],
  cgst: [
    { name: '0 %', value: 0 },
    { name: '2.5 %', value: 2.5 },
    { name: '6 %', value: 6 },
    { name: '9 %', value: 9 },
    { name: '14 %', value: 14 },
  ],
  igst: [
    { name: '0 %', value: 0 },
    { name: '5 %', value: 5 },
    { name: '12 %', value: 12 },
    { name: '18 %', value: 19 },
    { name: '28 %', value: 28 },
  ],
};
