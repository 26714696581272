import tblConsts from 'components/tables/tblConsts';
import renderDate from 'components/tables/helpers/renderDate';
import RenderExpandArrows from 'components/tables/helpers/RenderExpandArrows';
import renderIndiaFormat from 'components/tables/helpers/renderIndiaFormat';

const columns = [
  {
    id: 'expander',
    accessor: 'expander',
    // Header: 'Details',
    // type: 'string',
    // hidden: false,
    headerAlign: 'left',
    Cell: ({ row }) => <RenderExpandArrows row={row} />,
    exportable: false,
    disableSortBy: true,
  },
  {
    id: '_id',
    accessor: '_id',
    Header: 'ID',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: false,
    disableSortBy: true,
  },
  {
    id: 'proc_date',
    accessor: 'proc_date',
    Header: 'Proc Date',
    type: 'date',
    hidden: false,
    headerAlign: 'center',
    Cell: ({ cell }) => renderDate(cell),
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.date.maxWidth,
    minWidth: tblConsts.date.minWidth,
  },
  {
    id: 'shift',
    accessor: 'shift',
    Header: 'Shift',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: true,
  },
  {
    id: 'plant_code',
    accessor: 'plant_code',
    Header: 'Code',
    type: 'string',
    hidden: false,
    headerAlign: 'center',
    Cell: cell => <div style={{ textAlign: 'center' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'plant_name',
    accessor: 'plant_name',
    Header: 'Name',
    type: 'string',
    hidden: false,
    headerAlign: 'left',
    Cell: cell => <div style={{ minWidth: '40px' }}>{cell.value}</div>,
    exportable: true,
    disableSortBy: false,
    maxWidth: tblConsts.plantname.maxWidth,
    minWidth: tblConsts.plantname.minWidth,
  },

  // Total Good
  {
    id: 'total_good_weight',
    accessor: 'total_good_weight',
    Header: 'Weight (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_good_fat',
    accessor: 'total_good_fat',
    Header: 'Avg FAT (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_good_snf',
    accessor: 'total_good_snf',
    Header: 'Avg SNF (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_good_kgfat',
    accessor: 'total_good_kgfat',
    Header: 'KGFAT (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_good_kgsnf',
    accessor: 'total_good_kgsnf',
    Header: 'KGSNF (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_good_amount',
    accessor: 'total_good_amount',
    Header: 'Amount (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },

  // Total Sour
  {
    id: 'total_sour_weight',
    accessor: 'total_sour_weight',
    Header: 'Weight (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_sour_fat',
    accessor: 'total_sour_fat',
    Header: 'Avg FAT (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_sour_snf',
    accessor: 'total_sour_snf',
    Header: 'Avg SNF (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_sour_kgfat',
    accessor: 'total_sour_kgfat',
    Header: 'KGFAT (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_sour_kgsnf',
    accessor: 'total_sour_kgsnf',
    Header: 'KGSNF (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'left',
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'total_sour_amount',
    accessor: 'total_sour_amount',
    Header: 'Amount (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },

  // Cow Good
  {
    id: 'cow_good_weight',
    accessor: 'cow_good_weight',
    Header: 'Cow Weight (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_good_fat',
    accessor: 'cow_good_fat',
    Header: 'Cow Avg FAT (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_good_snf',
    accessor: 'cow_good_snf',
    Header: 'Cow Avg SNF (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_good_kgfat',
    accessor: 'cow_good_kgfat',
    Header: 'Cow KGFAT (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_good_kgsnf',
    accessor: 'cow_good_kgsnf',
    Header: 'Cow KGSNF (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_good_amount',
    accessor: 'cow_good_amount',
    Header: 'Cow Amount (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },

  // Buff Good
  {
    id: 'buff_good_weight',
    accessor: 'buff_good_weight',
    Header: 'Buff Weight (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_good_fat',
    accessor: 'buff_good_fat',
    Header: 'Buff Avg FAT (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_good_snf',
    accessor: 'buff_good_snf',
    Header: 'Buff Avg SNF (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_good_kgfat',
    accessor: 'buff_good_kgfat',
    Header: 'Buff KGFAT (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_good_kgsnf',
    accessor: 'buff_good_kgsnf',
    Header: 'Buff KGSNF (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_good_amount',
    accessor: 'buff_good_amount',
    Header: 'Buff Amount (G)',
    type: 'string',
    hidden: false,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },

  // Cow Sour
  {
    id: 'cow_sour_weight',
    accessor: 'cow_sour_weight',
    Header: 'Cow Weight (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_sour_fat',
    accessor: 'cow_sour_fat',
    Header: 'Cow Avg FAT (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_sour_snf',
    accessor: 'cow_sour_snf',
    Header: 'Cow Avg SNF (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_sour_kgfat',
    accessor: 'cow_sour_kgfat',
    Header: 'Cow KGFAT (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_sour_kgsnf',
    accessor: 'cow_sour_kgsnf',
    Header: 'Cow KGSNF (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'cow_sour_amount',
    accessor: 'cow_sour_amount',
    Header: 'Cow Amount (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },

  // Buff Sour
  {
    id: 'buff_sour_weight',
    accessor: 'buff_sour_weight',
    Header: 'Cow Weight (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_sour_fat',
    accessor: 'buff_sour_fat',
    Header: 'Cow Avg FAT (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_sour_snf',
    accessor: 'buff_sour_snf',
    Header: 'Cow Avg SNF (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_sour_kgfat',
    accessor: 'buff_sour_kgfat',
    Header: 'Cow KGFAT (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_sour_kgsnf',
    accessor: 'buff_sour_kgsnf',
    Header: 'Cow KGSNF (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
  {
    id: 'buff_sour_amount',
    accessor: 'buff_sour_amount',
    Header: 'Cow Amount (S)',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },

  // Others
  {
    id: 'fiscal_year',
    accessor: 'fiscal_year',
    Header: 'Fiscal Year',
    type: 'string',
    hidden: true,
    headerAlign: 'right',
    Cell: ({ cell }) => renderIndiaFormat(cell),
    exportable: true,
    disableSortBy: false,
  },
];

export default columns;
