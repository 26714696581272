const fields = {
  names: [
    'type',
    'proc_date',
    'shift',
    'society_id',
    'cans',
    'weight_kg',
    'note',
    'sample_no',
  ],
  type: {
    default: '',
    disabled: false,
    readonly: false,
  },
  proc_date: {
    default: null,
    disabled: false,
    readonly: false,
  },
  shift: {
    default: null,
    disabled: false,
    readonly: false,
  },
  society_id: {
    default: null,
    disabled: false,
    readonly: false,
  },
  cans: {
    default: '',
    disabled: false,
    readonly: false,
  },
  weight_kg: {
    default: '',
    disabled: false,
    readonly: false,
  },
  note: {
    default: '',
    disabled: false,
    readonly: false,
  },
  sample_no: {
    default: '',
    disabled: false,
    readonly: false,
  },
};

export default fields;
