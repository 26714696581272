import * as yup from 'yup';
import messages from './messages';

const editSchema = yup.object().shape({
  cans: yup
    .number(messages.cans.invalid)
    .min(1, messages.cans.min)
    .max(30, messages.cans.max)
    .positive(messages.cans.invalid)
    .integer(messages.cans.invalid)
    .typeError(messages.cans.invalid)
    .label('Cans'),
  weight_kg: yup
    .number(messages.weight_kg.invalid)
    .min(1, messages.weight_kg.min)
    .max(1000, messages.weight_kg.max)
    .positive(messages.weight_kg.invalid)
    .typeError(messages.weight_kg.invalid)
    .label('Weight'),
  fat: yup
    .number(messages.fat.invalid)
    .min(3, messages.fat.min)
    .max(8, messages.fat.max)
    .positive(messages.fat.invalid)
    .typeError(messages.fat.invalid)
    .label('FAT'),
  snf: yup
    .number(messages.snf.invalid)
    .min(8, messages.snf.min)
    .max(12, messages.snf.max)
    .positive(messages.snf.invalid)
    .typeError(messages.snf.invalid)
    .label('SNF'),
  amount: yup
    .number(messages.amount.invalid)
    .min(1, messages.amount.min)
    .max(500000, messages.amount.max)
    .positive(messages.amount.invalid)
    .typeError(messages.amount.invalid)
    .label('Amount'),
  note: yup
    .string(messages.note.invalid)
    .required(messages.note.required)
    .trim()
    .label('Note'),
});

export default editSchema;
